/**
 @class PDProperties
 @classdesc Client-seitige Repräsentation der gleichnamigen Klasse aus
	 der JANUS-Laufzeitbibliothek. Damit können auf JavaScript-Ebene
	 Benutzereinstellungen gespeichert und gelesen werden, ohne dass
	 man sich um die Kommunikation mit dem Server kümmern muss.<br/>
	 Wie bei {@link PDMeta} und {@link PDClass} wird
	 auch von dieser Klasse automatisch eine globale Instanz in 
	 einer gleichnamigen Variablen erzeugt.<br/>
	 Die vom Server angeforderten Werte werden ge-cached, so dass
	 sie nicht erneut geholt werden müssen. Beim Setzen werden
	 die neuen Werte erst in den Cache geschrieben und beim
	 Beenden der Anwendung an den Server übertragen (es sei denn,
	 beim <code>set()</code>-Aufruf wird mit dem Parameter
	 <code>immediately=true</code> die sofortige Übetrtragung veranlasst.
	 Mit [storeCachedProperties()]{@link PDProperties#storeCachedProperties}
	 können Sie diesen Vorgang auch jederzeit manuell anstoßen.<br/>
	 Falls die Benutzereinstellungen nicht Server-seitig gespeichert werden
	 sollen, kann diese Klasse mit
	 [setStoreMode('local')]{@link PDProperties#setStoreMode} auf
	 lokale Speicherung umgeschaltet werden.
	 In diesem Fall werden die Werte in Browser-Cookies oder Browser-eigener
	 Datenhaltung abgelegt.
 @since 1.0
 */
class PDPropertiesClass {
	_storeMode = 'remote';
	_preloaded = false;
	PDClass = null;

	/**
	@ignore
	@constructs PDProperties
	@desc Dieser Konstruktor sollte niemals aufgerufen werden.
	Eine Instanz dieser Klasse steht unter deren Namen global
	zur Verfügung.
	*/
	constructor(preload, pdClass) {
		this._cache = new Object();
		this._cache._noclass = new Object();
		this._needsSync = false;
		this.retcode = 0;
		this._storeMode = 'remote';
		this._preloaded = false;
		if(preload)
			this.load();
		if(arguments[arguments.length - 1] instanceof PDClassClass)
			this.PDClass = arguments[arguments.length - 1];
		else
			this.PDClass = null;
	}
	
	/**
	@memberof PDProperties
	@function setStoreMode
	@desc Legt den Speicherort der durch diese Klasse verwalteten
	benutzerspezifischen Informationen fest.
	@param {string} mode Der Speicherort. Mögliche Werte sind:
	 <ul>
	 	<li><code>remote</code>: Die Werte werden auf dem Server
		 	gespeichert, so sie dem Benutzer unabhängig vom Client
		 	überall zur Verfügung stehen. Der JANUS-Server benutzt
		 	dafür die Klasse <code>PDProperties</code> der
		 	JANUS-Laufzeitumgebung. Diese Einstellung entspricht
		 	auch dem Standcardwert.</li>
	 	<li><code>cookie</code>: Die Werte werden in den Cookies des
	 		Browsers gespeichert. Damit stehen die Benutzereinstellungen
	 		nicht mehr überall zur Verfügung. Andererseits wird der
	 		Server dadurch von der Speicherung dieser Informationen
	 		entlastet. Nachteile dieser Methode sind die enge Begrenzung des
	 		Speicherplatzes (auf ca. 4 KB) sowie die Tatsache, dass die
	 		gespeicherten Werte immer mit zum Server übertragen werden,
	 		obwohl wir sie in diesem Fall dort gar nicht benötigen.</li>
	 	<li><code>localStorage</code>: <em>Noch nicht implemntiert!</em><br/>
	 		Der Werte werden mit Browser-Mitteln lokal gespeichert. Je
	 		nach Browser kommt dabei <code>localStorage</code> (aus HTML 5)
	 		oder <code>userData</code>-Behavior (IE) zum Einsatz.
	 		Beachten Sie, dass diese Techniken nicht von allen Browsern
	 		unterstützt werden; Browser mit <code>localStorage</code>
	 		sind zumindest:
	 		<ul>
	 			<li>Firefox ab 3.5</li>
	 			<li>Safari (WebKit) ab 4.0</li>
	 			<li>Internet Explorer ab 8.0</li>
	 			<li>Opera ab 10.5</li>
	 		</ul>
	 		Vgl. <a href="http://www.quirksmode.org/dom/html5.html" target="_blank">
				http://www.quirksmode.org/dom/html5.html</a>.</li>
	 	<li><code>local</code>: <em>Veraltet.</em></li>
	 </ul>
	 */
	setStoreMode(mode) {
		if('remote' === mode)
			this._storeMode = mode;
		else if(mode == 'cookie' || mode == 'local') // 'local': veraltet
		{
			if(typeof Ext == 'object')
			{
				Ext.state.Manager.setProvider(new Ext.state.CookieProvider({
						expires: new Date(new Date().getTime()+(1000*60*60*24*30)), // 30 Tage
						secure: Ext.isSecure
					}));
				// Testen, ob Cookies akzeptiert werden
				var tmp1 = Ext.id();
				Ext.state.Manager.set('._cookieTest', tmp1);
				var tmp2 = Ext.state.Manager.get('._cookieTest', '');
				if(tmp1 !== tmp2)
				{
					var msg = 'Your browser does not accept cookies. No user configurations will be restored on next login.';
					if(Ext.isReady && UIMessage)
						UIMessage.ok(msg, 'Cannot set cookies', UIMessage.INFORMATION);
					else
						console.error(msg);
				}
				Ext.state.Manager.clear('._cookieTest');
				this._storeMode = mode;
			}
		}
		else if(mode == 'localStorage')
		{
			if(Ext.isIE && typeof window['localStorage'] == 'undefined')
			{
				// Fuer IE:
				// Benoetigt wird ein HTML-Element mit einer ID und folgender
				// CSS-Angabe:
				// behavior:url(#default#userdata);
				// Vor dem Lesen muss einmal geladen werden durch Aufruf
				// von load(<eindeutige ID>) auf dem HTML-Element-Objekt.
				// Nach dem Setzen der Werte muss entsprechend gespeichert
				// werden mit save(<eindeutige ID>).
				// Gesetzt werden die Werte mit setAttribute(<name>, <value>),
				// gelesen mit getAttribute(<name>). Gelesen werden immer
				// Strings. Falls ein Wert nicht existiert, kommt null
				// zurueck.
				var userData = UIApplication.getIEUserDataObject();
				if(userData)
					this._storeMode = mode + 'IE';
			}
			else if(typeof window['localStorage'] == 'undefined') {
				console.warn("PDProperties.setStoreMode('localStorage'): Sorry, your browser doesn't support localStorage.");
			}
			else {
				// localStorage:
				// Setzen mit setItem(<name>, <value>), lesen mit
				// getItem(<name>). Auch hier ergibt ein fehlender
				// Wert die Rueckgabe null.
				this._storeMode = mode;
			}
		}
		else
			console.warn("PDProperties.setStoreMode(): unknown mode: '"+mode+"'");
	}
	
	/**
	@memberof PDProperties
	@function load
	@desc Lädt alle gespeicherten Properties des Benutzers in den
	Client-seitigen Cache. Dadurch wird vermieden, später jede Einstellung
	einzeln vom Server abfragen zu müssen, wenn sie benötigt wird.
	@param {Function} [callback] Wenn der Request asynchron ausgeführt
	werden soll, geben Sie hier eine Callback-Funktion an, die anschließend
	ausgeführt werden soll. Wird hier keine Funktion angegeben, erfolgt der
	Aufruf synchron.
	@throws {PDException}
	 */
	load(callback) {
		//console.log("PDProperties.load()");
		var pars = new JParamPacker(JafWebAPI.PDProperties.load.eventName, this.PDClass);
		this.retcode = -1;
		var props = this;
		var pdClass = this.PDClass;
		var successFn = function(req, options)
			{
				var res = 0;
				var resp = new JResponse(req, options, pdClass);
				if(resp.hasFatalError())
					throw new PDException(PDException.FATAL, "Request got empty or invalid response!",
							"PDProperties.load():\n"+resp.getResponseText());
				if(resp.hasError())
					throw new PDException(PDException.ERROR, resp.getErrorMessage(), "PDProperties.load()");
				var data = resp.getData();
				for(var prop in data)
				{
					if(!data.hasOwnProperty(prop))
						continue;
					//console.log("Loaded properties: ", data);
					var clName = '';
					var key = prop;
					var clId = 0;
					var sep = key.indexOf('.');
					if(sep >= 0)
					{
						clName = key.substring(0, sep);
						key = key.substring(sep + 1);
					}
					var cacheClName = (clName == '0' ? '_noclass' : (clName || '_noclass'));
					if(!props._cache[cacheClName])
						props._cache[cacheClName] = { };
					props._cache[cacheClName][key] = data[prop];
				}
				props._preloaded = true;
				if(typeof callback == 'function')
					callback();
			};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callback == 'function')
					callback();
			};
		JafWeb.ajaxRequest({
				url: this.PDClass.getURL(),
				authToken: this.PDClass.getAuthToken(),
				method: "GET",
				async: (!!callback),
				params: pars.get(),
				scope: this,
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
	}

	/**
	@memberof PDProperties
	@function get
	@desc Ein oder mehrere Einstellungen lesen.
	@param {string} clname Name der Fachkonzeptklasse, zu der die
	Eigenschaften gehören. Der Schlüssel der Eigenschaft setzt
	sich aus der numerischen Id dieser Klasse und dem als
	<code>key</code> anzugebenden Namen zusammen. Erlaubt ist
	auch die Angabe eines Leerstrings.
	@param {string} keys Schlüssel, unter dem die zu lesende
	Eigenschaft gespeichert ist. Statt des Strings kann hier auch
	ein Array angegeben werden. In dem Fall muss auch der folgende
	Parameter <code>vals</code> ein Array sein und es werden dann
	die Werte für die Schlüssel des ersten Arrays in dem zweiten 
	Array abgelegt.
	@return {Array} Falls bei <code>keys</code> ein Array zur
	Abfrage mehrerer Eigenschaften angegeben wurde, wird auch ein
	String-Array mit den Ergebnissen zurückgegeben. Ansonsten
	ist die Rückgabe der Wert des abgefragten PDProperties als
	String.
	@param {Function} [callback] Wenn der Request asynchron ausgeführt
	werden soll, geben Sie hier eine Callback-Funktion an, die anschließend
	ausgeführt werden soll. Wird hier keine Funktion angegeben, erfolgt der
	Aufruf synchron.
	@param [doRequest=false] Das Holen des Properties vom Server erzwingen,
	falls entfernte Speicherung benutzt wird und der lokale Cache umgangen
	werden soll.
	@throws {PDException}
	 */
	get(clname, keys, callback, doRequest) {
		//console.log("PDProperties["+this._storeMode+"].get("+clname+", "+inspect(keys)+")");
		var cb = null;
		var needsRequest = false;
		var pos = 2;
		if(pos < arguments.length && (typeof arguments[pos] == 'function'))
		{
			cb = arguments[pos];
			pos++;
		}
		if(pos < arguments.length && (typeof arguments[pos] == 'boolean'))
		{
			needsRequest = arguments[pos];
			pos++;
		}
		var clName = (clname || '');
		var cacheClName = (clname || '_noclass');
		if(this._storeMode == 'cookie' || this._storeMode == 'local')
		{
			if(keys && JafWeb.isArray(keys))
			{
				var v = [ ];
				for(var i=0; i<keys.length; i++)
					v.push(Ext.state.Manager.get(clName+'.'+keys[i], ''));
				//console.warn("PDProperties[cookie].get('"+clname+"', "+inspect(keys)+"): returns "+inspect(v));
				if(cb)
				{
					cb(v);
					return;
				}
				return v;
			}
			else if(keys)
			{
				var v = Ext.state.Manager.get(clName+'.'+keys, '');
				//console.warn("PDProperties[cookie].get('"+clname+"', '"+keys+"'): returns "+inspect(v));
				if(cb)
				{
					cb(v);
					return;
				}
				return v;
			}
			if(cb)
			{
				cb('');
				return;
			}
			return '';
		}
		else if(this._storeMode == 'localStorageIE')
		{
			var userData = UIApplication.getIEUserDataObject();
			if(keys && JafWeb.isArray(keys))
			{
				var vals = [ ];
				if(userData)
				{
					for(var i=0; i<keys.length; i++)
					{
						var tmpKey = clname + '_' + keys[i];
						// Key muss C++-konform sein
						var reg = /^\w/;
						if(!reg.test(tmpKey))
							tmpKey = '_' + tmpKey;
						vals.push(userData.getAttribute(tmpKey) || '');
					}
				}
				if(cb)
				{
					cb(vals);
					return;
				}
				return vals;
			}
			else if(keys)
			{
				if(userData)
				{
					var tmpKey = clname + '_' + keys;
					// Key muss C++-konform sein
					var reg = /^\w/;
					if(!reg.test(tmpKey))
						tmpKey = '_' + tmpKey;
					var v = (userData.getAttribute(tmpKey) || '');
					if(cb)
					{
						cb(v);
						return;
					}
					return v;
				}
				if(cb)
				{
					cb('');
					return;
				}
				return '';
			}
		}
		else if(this._storeMode == 'localStorage')
		{
			if(keys && JafWeb.isArray(keys))
			{
				var vals = [ ];
				for(var i=0; i<keys.length; i++)
				{
					var tmpKey = clname + '_' + keys[i];
					// Key muss C++-konform sein
					var reg = /^\w/;
					if(!reg.test(tmpKey))
						tmpKey = '_' + tmpKey;
					vals.push(localStorage.getItem(tmpKey) || '');
				}
				if(cb)
				{
					cb(vals);
					return;
				}
				return vals;
			}
			else if(keys)
			{
				var tmpKey = clname + '_' + keys;
				// Key muss C++-konform sein
				var reg = /^\w/;
				if(!reg.test(tmpKey))
					tmpKey = '_' + tmpKey;
				var v = (localStorage.getItem(tmpKey) || '');
				if(cb)
				{
					cb(v);
					return;
				}
				return v;
			}
		}
		if(this._preloaded && !needsRequest)
		{
			if(keys && JafWeb.isArray(keys))
			{
				var vals = [];
				for(var i=0; i<keys.length; i++)
				{
					//console.log('PDProperties[preloaded].get(): key '+i+': '+keys[i]);
					if(!this._cache[cacheClName] || this._cache[cacheClName][keys[i]] === undefined)
						vals.push('');
					else
						vals.push(this._cache[cacheClName][keys[i]]);
				}
				if(cb)
				{
					cb(vals);
					return;
				}
				return vals;
			}
			else
			{
				var v = '';
				if(this._cache[cacheClName] && this._cache[cacheClName][keys] !== undefined)
					v = (this._cache[cacheClName][keys] || '');
				if(cb)
				{
					cb(v);
					return;
				}
				return v;
			}
		}
		// remote
		if(!this._cache[cacheClName])
			this._cache[cacheClName] = new Object();
		var pars = new JParamPacker(JafWebAPI.PDProperties.get.eventName, this.PDClass);
		var vals = (JafWeb.isArray(keys) ? [ ] : '');
		//pars.add("clName", (clname||""));
		var requestAll = needsRequest;
		if(keys && JafWeb.isArray(keys))
		{
			for(var i=0; i<keys.length; i++)
			{
				//console.log('PDProperties[remote].get(): key '+i+': '+keys[i]);
				if(requestAll || this._cache[cacheClName][keys[i]] === undefined)
				{
					pars.add("key"+i, clName+'.'+keys[i]);
					needsRequest = true;
				}
				else
				{
					var v = this._cache[cacheClName][keys[i]];
					vals.push(v);
				}
			}
		}
		else
		{
			if(requestAll || this._cache[cacheClName][keys] === undefined)
			{
				pars.add("key0", clName+'.'+keys);
				needsRequest = true;
			}
			else
			{
				var v = (this._cache[cacheClName][keys] || '');
				if(cb)
				{
					cb(v);
					return;
				}
				return v;
			}
		}
		//console.log("PDProperties[remote].get(): Params: "+pars.toString());
		var props = this;
		if(needsRequest == true)
		{
			this.retcode = -1;
			var pdClass = this.PDClass;
			var successFn = function(req, options)
					{
						var res = 0;
						var resp = new JResponse(req, options, pdClass);
						if(resp.hasFatalError())
							throw new PDException(PDException.FATAL, "Request got empty or invalid response!",
									"PDProperties.get():\n"+resp.getResponseText());
						if(resp.hasError())
							throw new PDException(PDException.ERROR, resp.getErrorMessage(), "PDProperties.get()");
						if(!props._cache[cacheClName])
							props._cache[cacheClName] = {};
						if(JafWeb.isArray(keys))
						{
							for(var i=0; i<keys.length; i++)
							{
								var v = resp.getString(clName+'.'+keys[i]);
								if(typeof v == 'string')
								{
									props._cache[cacheClName][keys[i]] = v;
									vals[i] = v;
								}
								else if(props._cache[cacheClName] && props._cache[cacheClName][keys[i]])
									vals[i] = props._cache[cacheClName][keys[i]];
								else
									vals[i] = "";
							}
						}
						else
						{
							var v = resp.getString(clName+'.'+keys);
							if(typeof v == 'string')
								props._cache[cacheClName][keys] = v;
							if(props._cache[cacheClName][keys])
								vals = props._cache[cacheClName][keys];
						}
						this.retcode = resp.getInt(JafWebAPI.PROP_retCode, -1);
						if(cb)
							cb(vals);
					};
			var failureFn = function(response, opts) {
					console.error("Network communication error in " + pars.getEventName() + "!");
					if(cb)
						cb();
				};
			JafWeb.ajaxRequest({
					url: this.PDClass.getURL(),
					authToken: this.PDClass.getAuthToken(),
					method: "GET",
					async: (!!cb),
					params: pars.get(),
					scope: this,
					callerName: pars.getEventName(),
					disableCaching: true,
					success: successFn,
					failure: failureFn
				});
		}
		//console.log("PDProperties[remote].get('"+clname+"', "+inspect(keys)+"): returns "+inspect(vals));
		if(!cb)
			return vals;
	}
	
	/**
	@memberof PDProperties
	@function findKeys
	@desc Gespeicherte Schlüssel anhand eines Suchmusters ermitteln.
	Es werden nicht die Werte, sondern nur die Schlüssel ermittelt.<br/>
	<span class="important">Hinweis:</span> Wird derzeit nur für den
	StoreMode 'localStorage' unterstützt!
	@param {Function} [callback] Wenn der Request asynchron ausgeführt
	werden soll, geben Sie hier eine Callback-Funktion an, die anschließend
	ausgeführt werden soll. Wird hier keine Funktion angegeben, erfolgt der
	Aufruf synchron.
	@return {string[]} Array mit den Schlüsseln.
	@example
var keys = PDProperties.findKeys('', 'XDlgScale');
for(var i=0; i < keys.length; i++)
{
	var key = keys[i];
	console.log("" + (i + 1) + ". " +
			keys[i] + ": " +
			PDProperties.get('', keys[i]));
}
	*/
	findKeys(clname, keyPattern, callback) {
		// Test:
		// alert(PDProperties.findKeys('', 'XDlgScale'))
		//
		//console.log("PDProperties["+this._storeMode+"].find("+clname+", "+keyPattern+")");
		var clName = (clname || '');
		var cacheClName = (clname || '_noclass');
		var tmpKey = clName + '_' + keyPattern;
		// Key muss C++-konform sein
		var prefixed = false;
		if(!tmpKey.search(/^\w/))
		{
			tmpKey = '_' + keyPattern;
			prefixed = true;
		}
		var keys = [];
		var regex = new RegExp('^' + tmpKey);
		if(this._storeMode == 'localStorage')
		{
			for(var i=0; i < localStorage.length; i++)
			{
				var key = localStorage.key(i);
				if(regex.test(key))
					keys.push(prefixed && key.length > 1 ? key.substring(1) : key);
			}
		}
		if(typeof callback == 'function')
			callback(keys);
		else
			return keys;
	}

	/**
	@memberof PDProperties
	@function set
	@desc Einstellungen speichern.
	@param {string} clname Name der Fachkonzeptklasse, zu der die
	Eigenschaften gehören. Der Schlüssel der Eigenschaft setzt
	sich aus der numerischen Id dieser Klasse und dem als
	<code>key</code> anzugebenden Namen zusammen. Erlaubt ist
	auch die Angabe eines Leerstrings.
	@param {string} keys Schlüssel, unter dem die zu lesende
	Eigenschaft gespeichert ist. Statt des Strings kann hier auch
	ein Array angegeben werden. In dem Fall muss der folgende
	Parameter <code>vals</code> ein Array der gleichen Länge mit
	den zu speichernden Werten sein.
	@param {string} vals Der in der Eigenschaft zu speichernde Wert.
	Falls bei <code>keys</code> ein Array angegeben wurde, muss hier 
	ebenfalls ein Array angegeben werden.
	@param {boolean} asynchron Wird hier <code>true</code> angegeben,
	erfolgt der Request asynchron, d.h. es kann unmittelbar mit der
	lokalen Bearbeitung fortgefahren werden, während die Properties
	zum Server übertragen werden. Sie können die Properties trotzdem
	sofort nach Aufruf dieser Funktion wieder <code>PDProperties.get()</code>
	lesen, weil sie lokal zwischengespeichert werden.
	@param {boolean} immediately Standardmäßig werden die Einstellungen erst
	lokal zwischengespeichert und erst beim Abmelden bzw. bei explizitem
	Aufruf von [storeCachedProperties()]{@link PDProperties#storeCachedProperties}
	an den Server übermittelt.
	Wenn die Einstellungen jedoch unmittelbar übermittelt werden sollen, muss
	hier <code>true</code> angegeben werden.
	@throws {PDException}
	 */
	set(clname, keys, vals, asynchron, immediately) {
		//console.log("PDProperties.set('"+clname+"', "+inspect(keys)+", "+inspect(vals)+")");
		var clName = (clname || '');
		var cacheClName = (clname || '_noclass');
		if(this._storeMode == 'cookie' || this._storeMode == 'local')
		{
			//console.log("PDProperties[cookie].set('"+clname+"', "+inspect(keys)+", "+inspect(vals)+")");
			if(keys && vals && JafWeb.isArray(keys) && JafWeb.isArray(vals))
			{
				var v = [ ];
				for(var i=0; i<keys.length && i<vals.length; i++)
					Ext.state.Manager.set(clName+'.'+keys[i], '', (vals[i]||''));
				return v;
			}
			else if(keys)
				Ext.state.Manager.set(clName+'.'+keys, (vals||''));
			return;
		}
		else if(this._storeMode == 'localStorageIE')
		{
			var userData = UIApplication.getIEUserDataObject();
			if(userData)
			{
				if(JafWeb.isArray(keys) && JafWeb.isArray(vals))
				{
					for(var i=0; i<keys.length && i<vals.length; i++)
					{
						var tmpKey = clname + '_' + keys[i];
						// Key muss C++-konform sein
						var reg = /^\w/;
						if(!reg.test(tmpKey))
							tmpKey = '_' + tmpKey;
						userData.setAttribute(tmpKey, vals[i]);
					}
				}
				else if(keys)
				{
					var tmpKey = clname + '_' + keys;
					// Key muss C++-konform sein
					var reg = /^\w/;
					if(!reg.test(tmpKey))
						tmpKey = '_' + tmpKey;
					userData.setAttribute(tmpKey, vals);
					
					var testVal = userData.getAttribute(tmpKey);
					//console.log("PDProperties[localStorageIE].set('"+clname+"', "+inspect(keys)+", "+inspect(vals)+") - stored value: '"+testVal+"'");
				}
			}
			return;
		}
		else if(this._storeMode == 'localStorage')
		{
			if(JafWeb.isArray(keys) && JafWeb.isArray(vals))
			{
				for(var i=0; i<keys.length && i<vals.length; i++)
				{
					var tmpKey = clname + '_' + keys[i];
					// Key muss C++-konform sein
					var reg = /^\w/;
					if(!reg.test(tmpKey))
						tmpKey = '_' + tmpKey;
					try
					{
						localStorage.setItem(tmpKey, vals[i]);
					}
					catch(e)
					{
						if(e == QUOTA_EXCEEDED_ERROR)
							console.warn("PDProperties[localStorage].set(): Quota exeeded!");
						else
							console.warn("PDProperties[localStorage].set(): An exception occurred: "+e+"!");
					}
				}
			}
			else if(keys)
			{
				var tmpKey = clname + '_' + keys;
				// Key muss C++-konform sein
				var reg = /^\w/;
				if(!reg.test(tmpKey))
					tmpKey = '_' + tmpKey;
				try
				{
					localStorage.setItem(tmpKey, vals);
				}
				catch(e)
				{
					if(e == QUOTA_EXCEEDED_ERROR)
						console.warn("PDProperties[localStorage].set(): Quota exeeded!");
					else
						console.warn("PDProperties[localStorage].set(): An exception occurred: "+e+"!");
				}
				//var testVal = localStorage.getItem(tmpKey);
				//console.log("PDProperties[localStorageIE].set('"+clname+"', "+inspect(keys)+", "+inspect(vals)+") - stored value: '"+testVal+"'");
			}
			return;
		}
		// remote
		//console.log("PDProperties[remote].set("+clname+", "+inspect(keys)+", "+inspect(vals)+")");
		var async = (asynchron !== false);
		if(!this._cache[cacheClName])
			this._cache[cacheClName] = new Object();
		if(JafWeb.isArray(keys) && JafWeb.isArray(vals))
		{
			for(var i=0; i<keys.length && i<vals.length; i++)
			{
				if(this._cache[cacheClName][keys[i]] != vals[i])
				{
					this._cache[cacheClName][keys[i]] = vals[i];
					if(immediately !== true)
						this._needsSync = true;
				}
			}
		}
		else
		{
			if(this._cache[cacheClName][keys] != vals)
			{
				this._cache[cacheClName][keys] = vals;
				if(immediately !== true)
					this._needsSync = true;
			}
		}
		if(immediately === true)
		{
			var pars = new JParamPacker(JafWebAPI.PDProperties.set.eventName, this.PDClass);
			if(JafWeb.isArray(keys) && JafWeb.isArray(vals))
			{
				for(var i=0; i < keys.length && i < vals.length; i++)
					pars.add(clName+'.'+keys[i], vals[i]); // immer den Klassennamen dem Key voranstellen!
			}
			else if(keys && typeof keys == "string")
				pars.add(clName+'.'+keys, (vals||''));
			//console.log("PDProperties.set(): Params: "+pars.toString());
			this.retcode = -1;
			var pdClass = this.PDClass;
			var successFn = function(req, options)
					{
						var resp = new JResponse(req, options, pdClass);
						if(resp.hasFatalError())
							throw new PDException(PDException.FATAL, "Request got empty or invalid response!",
									"PDProperties.set():\n"+resp.getResponseText());
						if(resp.hasError())
							throw new PDException(PDException.ERROR, resp.getErrorMessage(), "PDProperties.set()");
						this.retcode = resp.getInt(JafWebAPI.PROP_retCode);
					};
			var failureFn = function(response, opts) {
					console.error("Network communication error in " + pars.getEventName() + "!");
				};
			JafWeb.ajaxRequest({
					url: this.PDClass.getURL(),
					authToken: this.PDClass.getAuthToken(),
					method: "POST",
					async: async,
					params: pars.getPostParams(),
					scope: this,
					callerName: pars.getEventName(),
					disableCaching: true,
					success: successFn,
					failure: failureFn
				});
			return this.retcode;
		}
	}
	
	/**
	@memberof PDProperties
	@function contains
	@desc Fragt für einen angegebenen Namen ab, ob ein Wert gespeichert ist.
	@param {string} clname Name der Fachkonzeptklasse, zu der die
	Eigenschaft gehört. Der Schlüssel der Eigenschaft setzt
	sich aus der numerischen Id dieser Klasse und dem als
	<code>key</code> anzugebenden Namen zusammen. Erlaubt ist
	auch die Angabe eines Leerstrings.
	@param {string} key Schlüssel, unter dem die zu lesende
	Eigenschaft gespeichert ist.
	@param {Function} [callback] Wenn der Request asynchron ausgeführt
	werden soll, geben Sie hier eine Callback-Funktion an, die anschließend
	ausgeführt werden soll. Wird hier keine Funktion angegeben, erfolgt der
	Aufruf synchron.
	@return {boolean} <code>true</code>, falls ein Wert gespeichert ist,
	sonst <code>false</code>.
	@throws {PDException}
	 */
	contains(clname, key, callback) {
		//console.log("PDProperties["+this._storeMode+"].contains("+clname+", "+inspect(keys)+")");
		var clName = (clname || '');
		var cacheClName = (clname || '_noclass');
		if(!key) return false;
		if(this._storeMode == 'cookie' || this._storeMode == 'local')
			return (Ext.state.Manager.get(clName+'.'+key, null) !== null);
		else if(this._storeMode == 'localStorageIE')
		{
			var userData = UIApplication.getIEUserDataObject();
			if(userData)
			{
				var tmpKey = clname + '_' + key;
				// Key muss C++-konform sein
				if(!tmpKey.search(/^\w/))
					tmpKey = '_' + tmpKey;
				var res = (userData.getAttribute(tmpKey) !== null);
				if(typeof callback == 'function')
				{
					callback(res);
					return;
				}
				return res;
			}
			else
			{
				if(typeof callback == 'function')
				{
					callback(false);
					return;
				}
				return false;
			}
		}
		else if(this._storeMode == 'localStorage')
		{
			var tmpKey = clname + '_' + key;
			// Key muss C++-konform sein
			if(!tmpKey.search(/^\w/))
				tmpKey = '_' + tmpKey;
			var res = (localStorage.getItem(tmpKey) !== null);
			if(typeof callback == 'function')
			{
				callback(res);
				return;
			}
			return res;
		}
		// remote
		var props = this;
		var needsRequest = false;
		if(this._cache[cacheClName])
		{
			if(props._cache[cacheClName][key] !== undefined)
			{
				if(typeof callback == 'function')
				{
					callback(true);
					return;
				}
				return true;
			}
		}
		var pars = new JParamPacker(JafWebAPI.PDProperties.contains.eventName, this.PDClass);
		pars.add("key", clName+'.'+keys);
		this.retcode = -1;
		var res = false;
		var pdClass = this.PDClass;
		var successFn = function(req, options)
			{
				var resp = new JResponse(req, options, pdClass);
				if(resp.hasFatalError())
					throw new PDException(PDException.FATAL, "Request got empty or invalid response!",
							"PDProperties.contains():\n"+resp.getResponseText());
				if(resp.hasError())
					throw new PDException(PDException.ERROR, resp.getErrorMessage(), "PDProperties.contains()");
				res = resp.getBool(JafWebAPI.PDProperties.contains.PROP_result, false);
				this.retcode = resp.getInt(JafWebAPI.PROP_retCode, -1);
				if(typeof callback == 'function')
					callback(res);
			};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callback == 'function')
					callback();
			};
		JafWeb.ajaxRequest({
				url: this.PDClass.getURL(),
				authToken: this.PDClass.getAuthToken(),
				method: "GET",
				async: (!!callback),
				params: pars.get(),
				scope: this,
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		if(!callback)
			return res;
	}
	
	/**
	@memberof PDProperties
	@function remove
	@desc Einstellung löschen.
	@param {string} clname Name der Fachkonzeptklasse, zu der die
	Eigenschaft gehört. Der Schlüssel einer Eigenschaft setzt
	sich aus der numerischen Id dieser Klasse und dem als
	<code>key</code> anzugebenden Namen zusammen. Erlaubt ist
	auch die Angabe eines Leerstrings.
	@param {string} keys Schlüssel, unter dem die zu lesende
	Eigenschaft gespeichert ist. Statt des Strings kann hier auch
	ein Array angegeben werden, um mehrere Einstellungen in einem
	Aufruf zu löschen.
	@throws {PDException}
	 */
	remove(clname, keys) {
		//console.log("PDProperties["+this._storeMode+"].remove("+clname+", "+inspect(keys)+")");
		var clName = (clname || '');
		var cacheClName = (clname || '_noclass');
		if(this._storeMode == 'cookie' || this._storeMode == 'local')
		{
			if(keys && JafWeb.isArray(keys))
			{
				for(var i=0; i<keys.length; i++)
					Ext.state.Manager.clear(clName+'.'+keys[i]);
			}
			else if(keys)
				Ext.state.Manager.clear(clName+'.'+keys, '');
		}
		else if(this._storeMode == 'localStorageIE')
		{
			var userData = UIApplication.getIEUserDataObject();
			if(keys && JafWeb.isArray(keys))
			{
				if(userData)
				{
					for(var i=0; i<keys.length; i++)
					{
						var tmpKey = clname + '_' + keys[i];
						// Key muss C++-konform sein
						if(!tmpKey.search(/^\w/))
							tmpKey = '_' + tmpKey;
						userData.removeAttribute(tmpKey);
					}
				}
			}
			else if(keys)
			{
				if(userData)
				{
					var tmpKey = clname + '_' + keys;
					// Key muss C++-konform sein
					if(!tmpKey.search(/^\w/))
						tmpKey = '_' + tmpKey;
					userData.removeAttribute(tmpKey);
				}
			}
		}
		else if(this._storeMode == 'localStorage')
		{
			if(keys && JafWeb.isArray(keys))
			{
				for(var i=0; i<keys.length; i++)
				{
					var tmpKey = clname + '_' + keys[i];
					// Key muss C++-konform sein
					if(!tmpKey.search(/^\w/))
						tmpKey = '_' + tmpKey;
					localStorage.removeItem(tmpKey);
				}
			}
			else if(keys)
			{
				var tmpKey = clname + '_' + keys;
				// Key muss C++-konform sein
				if(!tmpKey.search(/^\w/))
					tmpKey = '_' + tmpKey;
				localStorage.removeItem(tmpKey);
			}
		}
		else
		{
			// remote
			var pars = new JParamPacker(JafWebAPI.PDProperties.remove.eventName, this.PDClass);
			var vals = (JafWeb.isArray(keys) ? [ ] : '');
			if(keys && JafWeb.isArray(keys))
			{
				for(var i=0; i<keys.length; i++)
					pars.add(JafWebAPI.PDProperties.remove.PAR_key+i, clName+'.'+keys[i]);
			}
			else
				pars.add(JafWebAPI.PDProperties.remove.PAR_key+"0", clName+'.'+keys);
			// auch aus dem Cache entfernen
			var props = this;
			if(props._cache[cacheClName])
			{
				if(JafWeb.isArray(keys))
				{
					for(var i=0; i<keys.length; i++)
					{
						if(props._cache[cacheClName][keys[i]] !== undefined)
							props._cache[cacheClName][keys[i]] = undefined;
					}
				}
				else
				{
					if(props._cache[cacheClName][keys] !== undefined)
						props._cache[cacheClName][keys] = undefined;
				}
			}
			this.retcode = -1;
			var pdClass = this.PDClass;
			var successFn = function(req, options)
					{
						var resp = new JResponse(req, options, pdClass);
						if(resp.hasFatalError())
							throw new PDException(PDException.FATAL, "Request got empty or invalid response!",
									"PDProperties.remove():\n"+resp.getResponseText());
						if(resp.hasError())
							throw new PDException(PDException.ERROR, resp.getErrorMessage(), "PDProperties.remove()");
						this.retcode = resp.getInt(JafWebAPI.PROP_retCode, -1);
					};
			var failureFn = function(response, opts) {
					console.error("Network communication error in " + pars.getEventName() + "!");
				};
			JafWeb.ajaxRequest({
					url: this.PDClass.getURL(),
					authToken: this.PDClass.getAuthToken(),
					method: "GET",
					async: false,
					params: pars.get(),
					scope: this,
					callerName: pars.getEventName(),
					disableCaching: true,
					success: successFn,
					failure: failureFn
				});
		}
	}
	
	/**
	@memberof PDProperties
	@function clearProperties
	@desc Die Properties für einen bestimmten Benutzer löschen.
	@param {mixed} uid Benutzerkennung. Falls nicht angegeben, wird
	die des aktuell angemeldeten Benutzers verwendet.<br/>
	<span class="important">Hinweis:</span> Properties anderer Benutzer können nur gelöscht
	werden, wenn die <code>PDProperties</code> Server-seitig gespeichert
	werden (siehe <code>setStoreMode()</code>). In diesem Fall
	benötigen Sie zum Löschen der Properties anderer Benutzer
	normalerweise administrative Rechte. Sie können auf der
	Server-Seite aber in eigenem Code in
	<code>ClientInfo::getAdminPermissionUser()</code>
	für das Recht <code>ClientInfo::resetProperties</code> abweichende
	Berechtigungen bestimmen.<br/>
	In den anderen Modi werden nur die für den Browser lokalen
	Properties gelöscht.
	@param {Function} callback Optionale Callback-Funktion, die
	nach Verarbeitung der Antwort aufgerufen werden soll. Wird
	diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
	und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
	dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
	Ergebnis wird direkt zurückgegeben.
	@throws {PDException}
	 */
	clearProperties(uid, callback) {
		//console.log("PDProperties["+this._storeMode+"].clearProperties("+clname+", "+inspect(keys)+")");
		if(this._storeMode == 'cookie' || this._storeMode == 'local')
		{
			// TODO
			if(typeof callback == 'function')
				callback();
		}
		else if(this._storeMode == 'localStorageIE')
		{
			// TODO
			if(typeof callback == 'function')
				callback();
		}
		else if(this._storeMode == 'localStorage')
		{
			localStorage.clear();
			if(typeof callback == 'function')
				callback();
		}
		else
		{
			// remote
			var props = this;
			var pars = new JParamPacker(JafWebAPI.PDProperties.clear.eventName, this.PDClass);
			if(uid)
				pars.add(JafWebAPI.PDProperties.clear.PAR_uid, uid);
			// auch aus dem Cache entfernen
			if(props._cache)
				delete props._cache;
			props._cache = { };
			this.retcode = -1;
			var pdClass = this.PDClass;
			var successFn = function(req, options)
					{
						var resp = new JResponse(req, options, pdClass);
						if(resp.hasFatalError())
							throw new PDException(PDException.FATAL, "Request got empty or invalid response!",
									"PDProperties.clearProperties():\n"+resp.getResponseText());
						if(resp.hasError())
							throw new PDException(PDException.ERROR, resp.getErrorMessage(), "PDProperties.clearProperties()");
						this.retcode = resp.getInt(JafWebAPI.PROP_retCode, -1);
						if(typeof callback == 'function')
							callback();
					};
			var failureFn = function(response, opts) {
					console.error("Network communication error in " + pars.getEventName() + "!");
					if(typeof callback == 'function')
						callback();
				};
			JafWeb.ajaxRequest({
					url: this.PDClass.getURL(),
					authToken: this.PDClass.getAuthToken(),
					method: "GET",
					async: (!!callback),
					params: pars.get(),
					scope: this,
					callerName: pars.getEventName(),
					disableCaching: true,
					success: successFn,
					failure: failureFn
				});
		}
	}
	
	/**
	@memberof PDProperties
	@function clear
	@desc Alias für [clearProperties]{@link PDProperties#clearProperties}.
	@param {mixed} uid Benutzerkennung. Falls nicht angegeben, wird
	die des aktuell angemeldeten Benutzers verwendet.<br/>
	<span class="important">Hinweis:</span> Properties anderer Benutzer können nur gelöscht
	werden, wenn die <code>PDProperties</code> Server-seitig gespeichert
	werden (siehe <code>setStoreMode()</code>). In diesem Fall
	benötigen Sie zum Löschen der Properties anderer Benutzer
	normalerweise administrative Rechte. Sie können auf der
	Server-Seite aber in eigenem Code in
	<code>ClientInfo::getAdminPermissionUser()</code>
	für das Recht <code>ClientInfo::resetProperties</code> abweichende
	Berechtigungen bestimmen.<br/>
	In den anderen Modi werden nur die für den Browser lokalen
	Properties gelöscht.
	@param {Function} callback Optionale Callback-Funktion, die
	nach Verarbeitung der Antwort aufgerufen werden soll. Wird
	diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
	und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
	dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
	Ergebnis wird direkt zurückgegeben.
	@throws {PDException}
	*/
	clear(uid, callback) {
		this.clearProperties(uid, callback);
	}
	
	/**
	@memberof PDProperties
	@function storeCachedProperties
	@desc Die im Cache stehenden Properties zum Server
	übertragen. Diese Funktion wird automatisch beim Beenden
	der Sitzung aufgerufen, so dass manuelle Aufrufe normalerweise
	nicht erforderlich sind. Der Request wird asynchron ausgeführt.
	@param {Function} callback Optionale Callback-Funktion, die
	nach Verarbeitung der Antwort aufgerufen werden soll. Wird
	diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
	und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
	dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
	Ergebnis wird direkt zurückgegeben.
	@throws {PDException}
	 */
	storeCachedProperties(callback) {
		if(this._needsSync === false)
		{
			if(typeof callback == 'function')
				callback();
			return;
		}
		if(this._storeMode == 'cookie' || this._storeMode == 'local')
		{
			if(typeof callback == 'function')
				callback();
			return; // nothing to do
		}
		else if(this._storeMode == 'localStorageIE')
		{
			// TODO: im Falle des IE (userData behavior) speichern
			var userData = UIApplication.getIEUserDataObject();
			if(userData)
				userData.save('_1007'); // TODO!
			if(typeof callback == 'function')
				callback();
			return;
		}
		else if(this._storeMode == 'localStorage')
		{
			if(typeof callback == 'function')
				callback();
			return; // nothing to do
		}
		//console.log("PDProperties.storeCachedProperties()");
		var pars = new JParamPacker(JafWebAPI.PDProperties.set.eventName, this.PDClass);
		for(var clName in this._cache)
		{
			var keyPrefix = (clName == '_noclass' ? '' : clName);
			for(var key in this._cache[clName])
				pars.add(keyPrefix+'.'+key, this._cache[clName][key]); // immer den Klassennamen dem Key voranstellen!
		}
		//console.log("PDProperties.set(): Params: "+pars.toString());
		var pdClass = this.PDClass;
		var successFn = function(req, options)
				{
					var resp = new JResponse(req, options, pdClass);
					// weil wir auch beim Abmelden die Properties asynchron zu speichern
					// versuchen und den Rueckgabewert gar nicht benoetigen, werfen wir
					// hier keine Exceptions!
					if(!resp.hasFatalError() && !resp.hasError())
					{
						this.retcode = resp.getInt(JafWebAPI.PROP_retCode);
						if(this.retcode == 0)
							this._needsSync = false;
					}
					try
					{
						if(typeof callback == 'function')
							callback();
					}
					catch(ex)
					{ /* ignore */ }
				};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callback == 'function')
					callback();
			};
		JafWeb.ajaxRequest({
				url: this.PDClass.getURL(),
				authToken: this.PDClass.getAuthToken(),
				method: "POST",
				async: true,
				params: pars.getPostParams(),
				scope: this,
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
	}
}
// Objekt erzeugen im Workspace-Frameset!
