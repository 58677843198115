﻿
// TODOs:
// - Das Property-System in PDTools ersatzlos raus?
// - Alte Transsaktionssteuerung raus (PDObject.abortTransaction
//   usw.).
// 


// globale Funktionen:
/**
 @function OID_LO
 @desc Wandelt einen OID-String in den numerischen Wert
	 des unteren OID-Teils, also in den des Objekt-Anteils,
	 um.
 @param {string} oidstr Die String-Repräsentation der
	 vollständigen OID in der Form <code>xxx:yyyy</code>
	 oder <code>xxx_yyyy</code>, also oberer und unterer 
	 Teil durch Doppelpunkt oder Unterstrich getrennt.
 @return {number} Die Objekt-ID. Falls ein Fehler auftritt,
	 wird <code>0</code> zurückgegeben. Falls kein OID-Trenner
	 in dem übergebenen String vorkommt, wird der Wert als
	 der untere Teil der OID angenommen und numerisch 
	 zurückgegeben.
 @test BaseTest.testOID_HI
 @since 1.0
 */
const OID_LO = function(oidstr) {
	if(typeof oidstr == "number")
		return oidstr;
	if(typeof oidstr == "string") {
		const tmp = oidstr.match(/^([0-9]+)[:_]([0-9]+)$/);
		if(tmp && tmp.length >= 3) {
			try {
				return parseInt(tmp[2], 10);
			}
			catch(ex) { }
		}
	}
	return 0;
};

/**
 @function OID_HI
 @desc Wandelt einen OID-String in den numerischen Wert
	 des oberen OID-Teils, also in die Klassen-ID um.
 @param {string} oidstr Die String-Repräsentation der
	 vollständigen OID in der Form <code>xxx:yyyy</code>
	 oder <code>xxx_yyyy</code>, also oberer und unterer 
	 Teil durch Doppelpunkt oder Unterstrich getrennt.
 @return {number} Die Klassen-ID. Falls ein Fehler auftritt,
	 wird <code>0</code> zurückgegeben.
 @test BaseTest.testOID_LO
 @since 1.0
 */
const OID_HI = function(oidstr) {
	if(typeof oidstr == "string") {
		const tmp = oidstr.match(/^([0-9]+)[:_]([0-9]+)$/);
		if(tmp && tmp.length >= 3) {
			try {
				return parseInt(tmp[1], 10);
			}
			catch(ex) { }
		}
	}
	return 0;
};

/**
 @function OID_STRING
 @desc Wandelt den oberen und den unteren Teil der
	 Objekt-ID (OID) in eine Zeichenkette um.
	 Dabei wird der Doppelpunkt als Trenner zwischen
	 den Teilen benutzt.<br>
	 Die Parameter können auch als Zeichenketten
	 übergeben werden. In dem Fall wird versucht, sie 
	 in Zahlen zu konvertieren.
 @param {number} cid Der obere Teil der OID, also die
	 Klassen-ID.
 @param {number} oid Der untere Teil der OID, also die
	 eigentliche Objekt-ID.
 @return {string} OID-String in der Form 
	 <code>xxx:yyyy</code>. Im Fehlerfall wird eine 
	 leere Zeichenkette zurückgegeben.
 @test BaseTest.testOID_STRING
 @since 1.0
 */
const OID_STRING = function(cid, oid) {
	if(!oid)
		return "";
	if(typeof cid == "string")
		cid = parseInt(cid, 10);
	if(isNaN(cid)==true)
		return "";
	if(typeof oid == "string")
		oid = parseInt(oid, 10);
	if(isNaN(oid)==true)
		return "";
	return ""+cid+":"+oid;
};


/**
 @readonly
 @enum {number}
 @desc Flags für Zugriff auf {@link PDObject}-Attribute.
 */
class PDObjectFlags {
	// zu den Werten der folgenden Konstanten vgl. JANUS/include/pdo_remt.h
	/**
	@desc Das Attribut existiert. Das Flag ist immer gesetzt,
	sofern ein gültiges Attribut gelesen wurde.
	@const {number}
	 */
	static Available = 0x0001;
	/**
	@desc Es handelt sich um ein Schlüsselattribut.
	@const {number}
	 */
	static Key = 0x0002;
	/**
	@desc Es handelt sich um ein Muss-Attribut.
	@const {number}
	 */
	static Mandatory = 0x0004;
	/**
	@desc Das Attribut darf nur gelesen werden. Grundsätzlich 
	sind Schreibzugriffe aber erlaubt, wenn zusätzlich noch 
	<code>GUIReadOnly</code> zurückgegeben wird.
	@const {number}
	 */
	static ReadOnly = 0x0008;
	/**
	@desc Der aktuelle Benutzer hat keine Leserechte.
	@const {number}
	 */
	static NoReadPermission = 0x0010;
	/**
	@desc Der aktuelle Benutzer hat keine Schreibrechte.
	@const {number}
	 */
	static NoWritePermission = 0x0020;  
	/**
	@desc Gibt es für dieses Attribut einen Standardwert, der 
	sich ändern kann, z.B. weil er der Wert eines anderen 
	Attributes ist?
	@const {number}
	 */
	static DynamicDefault = 0x0080;
	/**
	@desc Das Objekt zu diesem Attribut ist neu erzeugt.
	@const {number}
	 */
	static NewObject = 0x0100;
	/**
	@desc Das Attribut kann für verschiedene Sprachen unterschiedliche Werte haben.
	@const {number}
	 */
	static Multilingual = 0x0200;
	/**
	@desc Das Attribut ist ein Fremdschlüssel zu einer anderen Klasse.
	@const {number}
	 */
	static ForeignKey = 0x0400;
	/**
	@desc Es sollte ein Attribut über eine Beziehung gelesen werden, der 
	kein Objekt zugeordnet ist.
	@const {number}
	 */
	static RelationNotSet = 0x0800;
	/**
	@desc Das Attribut ist abgeleitet und überschreibbar. Der zurückgegebene 
	Wert ist manuell eingegeben und nicht berechnet.
	@const {number}
	 */
	static Overwritten = 0x1000;
	/**
	@desc Das Attribut ist abgeleitet und überschreibbar. Der zurückgegebene 
	Wert ist berechnet.
	@const {number}
	 */
	static NotOverwritten = 0x2000;
	/**
	@desc Der Attributwert ist UTF-8-kodiert.
	@const {number}
	 */
	static Utf8Encoded = 0x4000;
	/**
	@desc Das Attribut ist als GUI-ReadOnly (nur lesbar auf der Benutzungsoberfläche) 
	modelliert. Schreibzugriffe sind aber grundsätzlich möglich.<br>
	Wenn dieses Flag gesetzt ist, ist immer auch ReadOnly gesetzt.
	@const {number}
	 */
	static GUIReadOnly = 0x8000;
}

/**
 @class PDObject
 @desc <p>Browser-seitige Repräsentation der Basisklasse aller generierten
	 Fachkonzeptklassen</p>
	 <p>Im Unterschied zum JANUS-Server
	 arbeitet das JafWeb nicht mit generierten Klassen,
	 sondern - wie der GUI-Client - mit der Basisklasse
	 <code>PDObject</code>. Die Zugriffe auf das Fachkonzept
	 müssen daher immer die generischen Funktionen verwenden:
	 [getAttribute()]{@link PDObject#getAttribute},
	 [setAttribute()]{@link PDObject#setAttribute},
	 [connect()]{@link PDObject#connect},
	 [disconnect()]{@link PDObject#disconnect},
	 [getFirstLink()]{@link PDObject#getFirstLink} und
	 [callOperation()]{@link PDObject#callOperation}.</p>
	 <p>Wenn Sie im Modell das Property <code>XDecorateClientPDObjects</code>
	 auf "True" setzen. erzeugt der Generator in <code>main.js</code>
	 Wrapper-Funktionen die - im Falle statischer Methoden - über den
	 Namespace <code>JafWeb.PD</code> und die fachliche Klasse bzw. - bei
	 Member-Methoden - direkt auf dem jeweiligen <code>PDObject</code> aufgerufen
	 werden können, weil sie zur Laufzeit automatisch in die Instanz "eingeblendet"
	 werden. Anders als die generischen Methoden können diese mit ihrer Signatur
	 gemäß dem Modell aufgerufen werden.
	 Diese generierten Funktionen ersparen bei Server-Aufrufen das Mapping der
	 Parameter auf die Listenparameter der generischen Funktionen.
	 Im Falle von Methoden, für die im Modell ein Wizard-Skript angegeben wurde,
	 wird der entsprechende Wizard-Aufruf generiert.</p>
	 <div class="important">Fachliche Objekte dürfen nur durch Abruf vom Server
	 instanziiert werden - entweder durch Neuanlage oder durch Zugriff auf dort
	 bereits bestehende Objekte mit [PDClass.ptr()]{@link PDClass#ptr},
	 [PDClass.getSingleton()]{@link PDClass#getSingleton},
	 [PDClass.getExtent()]{@link PDClass#getExtent},
	 [PDObject.getFirstLink()]{@link PDObject#getFirstLink} usw.!</div>
	 <h4>Beispiel Neuanlage:</h4>
	 <pre class="prettyprint"><code>const tid = ClientInfo.startTransaction();
const transObj = PDClass.startNewTransaction('FachlicheKlasse', tid);</code></pre>
	 <div class="important">Neuanlage und Bearbeitung im Web Client sollten immer
	 innerhalb einer Transaktion erfolgen!</div>
	 <h4>Beispiel bestehendes Objekt editieren:</h4>
	 <pre class="prettyprint"><code>const tid = ClientInfo.startTransaction();
const transObj = PDClass.startTransaction(realObj.classname, realObj.oidLow, tid);</code></pre>
	 <h4>Objekt bearbeiten und speichern:</h4>
	 <pre class="prettyprint"><code>// Attribute setzen, Beziehungen herstellen:
...
// Constraints pruefen:
const res = transObj.checkConstraints(true, true, false);
if(!res || res.retCode != 0) {
	if(res && res.errors)
		UIMessage.ok(res.errors.join('\n'));
	else
		UIMessage.ok(PDMeta.getString(res.retCode));
	ClientInfo.abortTransaction(tid);
}
else {
	const commitRes = ClientInfo.commitTransaction(tid, transObj);
	if(commitRes.retCode != 0) {
		if(commitRes.errors && commitRes.errors.length > 0)
			UIMessage.ok(commitErr.errors[0]);
		else
			UIMessage.ok("An unknown error occured.");
		ClientInfo.abortTransaction(tid);
	}
}</code></pre>
 @since 1.0
 @author Frank Fiolka
 */
class PDObject {
	// Benoetigt ein konkretes PDObject Informationen aus
	// der generierten konkreten Klasse??? - Wie werden
	// z.B. Attribute und Beziehungen unterschieden?
	// Woher kommt die Information ?ie Attributtypen? 
	
	// ACHTUNG: hier nicht unbedacht irgendwelche Member
	// einbauen! Die tauchen sonst, wenn sie nicht ausdruecklich
	// ausgenommen werden, auch in dem an den Server geposteten
	// PDObject auf!
	
	// Fehler-Code der zuletzt aufgerufenen Funktion
	/**
	@desc Rückgabe-Code der zuletzt aufgerufenen Funktion.
	@const {number}
	 */
	retcode = 1;
	/*
	Rückgabemeldung der zuletzt aufgerufenen Funktion.
	@tattr String
	 */
	_lastMsg = "";
		
	// spezielle oeffentliche Member (wie in Teil 2 dokumentiert)
	oid = "";
	oidLow = 0;
	oidHi = 0;
	cid = 0;
	pid = undefined; // nur, wenn PrincipalCapability
	classname = "";
	// neu, wenn keine MD5 angegeben?
	_isnew = true;
	_modified = false;
	_isTrans = false;
	_tid = 0; // Transaktions-ID
	_realOidLow = 0;
	_lockid = -1;
	_md5 = "";
	_allowEdit = true;
	_allowDelete = true;
	_deleted = false;
	_depDelete = [ ];
	_connected = false;
	_disconnected = false;
	_statusCache = { };
	_evtHandler = null;
	__mod = {}; // Modifizierungs-Flags
	_pdClass = null;
	
	get PDClass() {
		if(this._pdClass)
			return this._pdClass;
	}

	/*
	 @constructs PDObject
	 @desc <span class="important">Hinweis:</span> Diesen Konstruktor
		 sollten Sie nie direkt aufrufen! Die <code>PDObject</code>s
		 werden stattdessen immer über die Funktionen der Fachkonzeptschicht
		 geholt (z.B. {@link PDClass#ptr}).
	 @param {string} clname Der Name der Fachkonzeptklasse.
	 @param {number} oid Falls es sich nicht um eine Neuanlage
		 handelt, muss hier der untere Teil der OID
		 übergeben werden (des <i>Real Object</i>).
		 Statt der Parameter kann auch ein assoziatives Array
		 mit Attributname-Wert-Paaren und Beziehungsname-Objekt-Paaren
		 übergeben werden. Das <code>PDObject</code> wird dann aus
		 den übergebenen Daten initialisiert. Diese Variante wird
		 hauptsächlich intern benutzt.
	 */
	constructor(pdClass, clname, oid) {
		//console.log("### PDObject.constructor(" + (pdClass ? "pdClass" : "null") + ", '" +
		//		clname + "', '" + oid + "')");
		// ACHTUNG: Wenn das JafWeb in Selenium ausgefuehrt wird,
		// darf PDClass hier NICHT als Member belegt sein, weil
		// sonst beim Zugriff auf PDObjects eine Exception wegen
		// "Maximum call stack size exceeded" fliegt!
		if(typeof window['PDClass'] == 'undefined')
			this._pdClass = pdClass;
		else
			this._pdClass = PDClass;
		// das PDObject aus JSON-Daten erstellen
		this._statusCache = {};
		if(typeof clname == "object")
		{
			this._setValues(clname);
		}
		else
		{
			if(typeof clname == "string")
			{
				this.cid = (this.PDClass || PDClass).PDMeta.getId(clname);
				this.classname = clname;
			}
			else if(typeof clname == "number")
			{
				this.cid = clname;
				this.classname = (this.PDClass || PDClass).PDMeta.getClass(clname);
			}
			if(oid && typeof oid == "string")
			{
				this.oid = oid;
				this.oidHi = OID_HI(oid);
				this.oidLow = OID_LO(oid);
			}
			else if(oid && typeof oid == number)
			{
				this.oidHi = this.cid;
				this.oidLow = oid;
				this.oid = OID_STRING(this.oidHi, this.oidLow);
			}
		}
	}
	
	// der Kompatibilitaet mit JanusScript wegen
	hasProperty(name) {
		// sollte in Wizards nicht mehr verwendet werden, sondern
		// stattdessen PDWizard.hasProperty()!
		console.warn("### PDObject.hasProperty('" + name + "') is deprecated!");
		return this.hasOwnProperty(name);
	}

	/**
	 @function PDObject#getMD5
	 @desc Gibt die MD5-Summe des originalen Objekts zurück,
		 wie es vor dem Laden vom Server und der Bearbeitung
		 gespeichert war.
	 @return {string} Die MD5-Checksumme.
	 */
	getMD5() {
		return this._md5;
	}
	
	/**
	 @function PDObject#getPDClass
	 @desc Gibt die zu diesem Objekt gehörige
		 [PDClass]{@link PDClassClass}-Instanz
		 zurück.
	 @return {PDClassClass}
	 */
	getPDClass() {
		return (this.PDClass || PDClass);
	}
	
	// Events zur Aenderungsueberwachung
	/**
	 @function PDObject#addEventHandler
	 @desc Eine Handler-Funktion an das Objekt binden, um über
		 Änderungen benachrichtigt zu werden. Damit lässt sich auf einfache
		 Weise eine Änderungsüberwachung einzelner oder auch aller
		 Fachkonzept-Objekte herstellen.
		 Beispielsweise können Sie beim Start Ihres Web Clients
		 das Objekt des angemeldeten Benutzer holen und diesem einen
		 Handler für Änderungen hinzufügen. Sobald nun von irgendeiner
		 Stelle im Web Client aus das fachliche Objekt (nicht nur die
		 lokale Instanz) geändert wird, erfolgt automatisch die
		 Ausführung der Handler-Funktion.<br/>
		 Beachten Sie bitte, dass die Handler-Funktion nicht unmittelbar bei
		 einer Änderung des Server-seitigen Objekts (z.B. durch einen konkurrierenden
		 Benutzer) aufgerufen wird, sondern erst bei der nächsten Aktualisierung des
		 Objekts auf dem Client bzw. bei direkten Änderungen im Client. Um auch die
		 Server-seitigen Änderungen zu überwachen und unmittelbar zu melden, würde
		 ein Rückkanal vom Server (z.B. via "Slow load" oder WebSocket) benötigt, der
		 aber derzeit von der SoapWebApp noch nicht unterstützt wird.<br/>
		 Mit [unregisterOnChangeHandler()]{@link PDObject#unregisterOnChangeHandler}
		 lassen sich hinzugefügte Handler wieder entfernen.<br/>
		 <h4><Beispiel</h4>
		 <pre class="prettyprint"><code>const userObject = ClientInfo.getUserObject();
if(userObject) {
	userObject.addEventHandler('changed', function(data) {
			UIMessage.ok("Attr. " + data.elem + " of user object changed!");
		});
}</code></pre>
	 @param {string} type Art des Ereignisses. Folgende Werte sind
		 möglich:
		 <ul>
		  <li>"changed": Ein {@link PDObject} wurde geändert.</li>
		  <li>"created": Ein {@link PDObject} wurde fachlich neu
		  angelegt (vgl. [PDClass.newObject()]{@link PDClass#newObject}).</li>
		  <li>"delete": Ein {@link PDObject} soll fachlich gelöscht werden
		  (vgl. [PDClass.deleteObject()]{@link PDClass#deleteObject}).</li>
		 </ul>
	 @param {Function} handler Die Handler-Funktion. Je nach Ereignistyp enthält
		 das beim Aufruf an diese Funktion übergebene JavaScript-Objekt unterschiedliche
		 Properties:
		 <ul>
		  <li>"changed":
				<ul>
					<li><code>object</code>: Das geänderte {@link PDObject}.</li>
					<li><code>member</code>: Das geänderte Element (Attribut oder Beziehung).</li>
					<li><code>oldValue</code>: Der alte Wert (optional).</li>
					<li><code>newValue</code>: Der neue Wert (optional).</li>
				</ul>
		  </li>
		  <li>"created":
				<ul>
					<li><code>object</code>: Das neu angelegte {@link PDObject}.</li>
				</ul>
		  </li>
		  <li>"delete":
				<ul>
					<li><code>object</code>: Das zu löschende {@link PDObject}.</li>
				</ul>
		  </li>
		 </ul>
	 */
	addEventHandler(type, handler) {
		if(!this._evtHandler)
			this._evtHandler = {};
		if(!this._evtHandler[type])
			this._evtHandler[type] = [];
		this._evtHandler[type].push(handler);
	}
	
	/**
	 @function PDObject#removeEventHandler
	 @desc Eine registrierte Handler-Funktion entfernen.
	 @param {string} type Art des Ereignisses. Zu den
		 möglichen Werten vgl.
		 [addObjectEventHandler()]{@link PDClass#addObjectEventHandler}.
	 @param {Function} [handler] Die zu entfernende
		 Handler-Funktion. Wird diese nicht angegeben,
		 werden alle registrierten Handler des angegebenen
		 Typs entfernt!
	 */
	removeEventHandler(type, handler) {
		if(!this._evtHandler || !this._evtHandler[type])
			return;
		if(!handler)
			return;
		var i = 0;
		while(i < this._evtHandler[type].length) {
			if(this._evtHandler[type][i] == handler)
				this._evtHandler[type].splice(i, 1);
			else
				i++;
		}
	}
	
	/**
	 @function PDObject#dispatchPDEvent
	 @desc Verteilt Ereignisse an registrierte Handler.
	 @param {string} type Art des Ereignisses. Zu den
		 möglichen Werten vgl.
		 [addObjectEventHandler()]{@link PDClass#addObjectEventHandler}.
	 @param {Object} data An das Event zu übegebende Daten
		 (ereignistyp-abhängig).
	 */
	dispatchPDEvent(type, data) {
		//console.log("### PDObject.dispatchPDEvent('" + type + "') - data:", data);
		data.type = type;
		const custEvt = new CustomEvent('pd-updated', {
				bubbles: true,
				cancelable: false,
				composed: true,
				detail: data
			});
		document.dispatchEvent(custEvt);

		if(this._evtHandler && this._evtHandler[type]) {
			for(let i = 0; i < this._evtHandler[type].length; i++) {
				try {
					this._evtHandler[type][i](data);
				}
				catch(ex) {
					console.error("" + type + " event handler of PDObject " +
							this.classname + ":" + this.GetPDObjectIdLow() +
							" throwed an exception: " + ex);
				}
			}
		}
	}
	
	/*
	 */
	handleUpdated(member, oldValue, newValue) {
		if(this.isTrans() || oldValue === newValue)
			return;
		//console.log("##1# PDObject[" + this.classname + ":" + this.GetPDObjectIdLow() +
		//		"].handleUpdated('" + member + "')");
		const data = {
				'object': this,
				'member': member,
				'oldValue': oldValue,
				'newValue': newValue
			};
		const pdClass = (this.PDClass || PDClass);
		if(pdClass) {
			try {
				pdClass.dispatchPDEvent.call(pdClass, 'changed', data);
			} catch(ex) {
				console.error("### PDObject.handleUpdated(): exception caling PDClass.disptachPDEvent():", ex);
			}
		}
		else
			console.warn("PDObject.handleUpdated(): PDClass not set!");
	}
	
	/**
	 @function PDObject#Delete
	 @desc Destruktorfunktion.
	 */
	Delete() {
		//console.log("### PDObject.Delete()");
		if((this.PDClass || PDClass))
			(this.PDClass || PDClass).handleObjectDelete(this.cid, this.oidLow);
		// Properties loeschen
		for(var sProp in this)
			this[sProp] = null;
	}
	
	/**
	 @function PDObject#editAllowed
	 @desc Gibt zurück, ob der Benutzer das Objekt bearbeiten
		 darf.<br/>
		 <span class="important">Hinweis:</span> Beachten Sie bitte,
		 dass das hier zurückgegebene Flag
		 den Zustand beim Holen des Objekts vom Server widerspiegelt.
		 Es ist möglich, dass der Server (dessen Berechtigungsprüfung
		 immer gewinnt) das Recht verweigert, obwohl hier noch
		 <code>true</code> zurückgegeben wird.
	 @return {boolean} <code>true</code>, falls er das Recht zum
		 Bearbeiten hat, sonst <code>false</code>.
	 */
	editAllowed() {
		return (this._allowEdit === true);
	}
	
	/**
	 @function PDObject#deleteAllowed
	 @desc Gibt zurück, ob der Benutzer das Objekt löschen darf.
		 <span class="important">Hinweis:</span> Beachten Sie bitte, dass das hier zurückgegebene Flag
		 den Zustand beim Holen des Objekts vom Server widerspiegelt.
		 Es ist möglich, dass der Server (dessen Berechtigungsprüfung
		 immer gewinnt) das Recht verweigert, obwohl hier noch
		 <code>true</code> zurückgegeben wird.
	 @return {boolean} <code>true</code>, falls er das Recht zum
		 Löschen hat, sonst <code>false</code>.
	 */
	deleteAllowed() {
		return (this._allowDelete === true);
	}
	
	/**
	 @function PDObject#getReturnCode
	 @desc Gibt den Rückgabe-Code der zuletzt aufgerufenen Funktion
		 zurück.
	 @return {number} Der Code.
	*/
	getReturnCode() {
		return this.retcode;
	}
	
	/**
	 @function PDObject#getLastMessage
	 @desc Gibt die zuletzt gespeicherte Fehlermeldung zurück.
	 @return {string} Der Fehlertext.
	*/
	getLastMessage() {
		return this._lastMsg;
	}
	
	/**
	 @function PDObject#toString
	 @desc String-Repräsentation des Objekts ausgeben.
	 @return {string}
	 */
	toString() {
		return this.objectToString(false);
	}

	/*
	 @function PDObject#objectToString
	 @desc Gibt das Objekt mit seinen Attributen und Werten als
	 String zurück. Auf diese Weise können vollständige,
	 Client-seitige Fachkonzeptobjekte an oder auch durch 
	 den Server gereicht werden.<br>
	 Nützlich ist diese Funktion auch für Debug-Zwecke.
	 @param {boolean} [recursive=false] Zeigt an, ob das Objekt rekursiv,
	 d.h. mit den über seine Beziehungen verbundenen Objekten
	 ausgegeben werden soll.
	 @return {string} JSON-String, der das Objekt mit
	 allen seinen Attributen wiedergibt.
	 */
	objectToString(recursive) {
		this.retcode = 0;
		return this._getValues(false, [], (true === recursive));
	}

	/**
	 @function PDObject#isNewTrans
	 @desc Gibt zurück, ob sich das Objekt im Neuanlagemodus
		 befindet, es also noch keine Entsprechung auf der
		 Server-Seite und in der Datenbank hat.
	 @return {boolean} Neu oder nicht.
	 */
	isNewTrans() {
		this.retcode = 0;
		return this._isnew;
	}
	
	/**
	 @function PDObject#isTrans
	 @desc Fragt ab, ob das Objekt ein Transaktionsobjekt ist.
	 @return {boolean} <code>true</code>, wenn es sich um ein
		 Transaktionsobjekt handelt, <code>false</code> bei einem
		 Datenbankobjekt.
	 */
	isTrans() {
		this.retcode = 0;
		return this._isTrans;
	}
	
	/**
	 @function PDObject#getTransactionId
	 @desc Die Transaktions-ID abfragen.
	 @return {number} Die Id.
	 */
	getTransactionId() {
		this.retcode = 0;
		return this._tid;
	}
	
	/**
	 @function PDObject#isModified
	 @desc Gibt zurück, ob das Objekt lokal modifiziert wurde.
	 @return {boolean} Das Objekt wurde modifiziert (<code>true</code>)
		 oder nicht.
	 */
	isModified() {
		this.retcode = 0;
		return this._modified;
	}
	
	/**
	 @function PDObject#GetClass
	 @desc Den Namen der Fachkonzeptklasse zurückgeben.
	 @return {string} Technischer Name der Klasse.
	 */
	GetClass() {
		this.retcode = 0;
		return this.classname;
	}

	/**
	 @function PDObject#GetId
	 @desc Kennung der Fachkonzeptklasse zurückgeben.
	 @return {number} Id der Fachkonzeptklasse.
	 */
	GetId() {
		this.retcode = 0;
		return this.cid;
	}

	/**
	 @function PDObject#GetBaseId
	 @desc Kennung der Basisklasse zurückgeben.
	 @return {number} Id der Basisklasse.
	 */
	GetBaseId() {
		this.retcode = 0;
		return this.getSuperClass(this.classname);
	}

	/**
	 @function PDObject#GetPDObjectId
	 @desc Kennung des Objekts zurückgeben.
	 @return {string} OID des Objekts.
	 */
	GetPDObjectId() {
		this.retcode = 0;
		return this.oid;
	}

	/**
	 @function PDObject#GetPDObjectIdLow
	 @desc Unteren Teil der Objekt-Id zurückgeben.<br/>
		 <span class="important">Hinweis:</span> Statt dieser Funktion können Sie auch das Property
		 <code>oidLow</code> abfragen.
	 @return {number} Objektteil der OID.
	 */
	GetPDObjectIdLow() {
		this.retcode = 0;
		return this.oidLow;
	}

	/**
	 @function PDObject#GetPDObjectIdHi
	 @desc Oberen Teil der Objekt-Id zurückgeben, das ist die
		 Klassenkennung.<br/>
		 <span class="important">Hinweis:</span> Statt dieser Funktion können Sie auch das Property
		 <code>oidHi</code> abfragen.
	 @return {number} Klassenteil der OID.
	 */
	GetPDObjectIdHi() {
		this.retcode = 0;
		return this.oidHi;
	}

	/**
	 @function PDObject#getRealObject
	 @desc Gibt, wenn es sich bei dem aktuellen Objekt um ein
		 Transaktionsobjekt handelt, das zugehörige Datenbankobjekt
		 zurück. Falls es sich um eine Neuanlage handelt, existiert
		 das nicht und es wird <code>null</code> zurückgegeben.
	 @return {PDObject} Das Datenbankobjekt oder <code>null</code>.
	 */
	getRealObject() {
		this.retcode = 0;
		if(this._isTrans == false)
			return this;
		if(this._isnew == true)
			return null;
		return (this.PDClass || PDClass).ptr(this.classname, this._realOidLow);
	}

	/**
	 @function PDObject#GetRealObjectIdLow
	 @desc Unteren Teil der Objekt-Id des Datenbankobjekts zu
		 einem Transaktionsobjekt zurückgeben.
	 @return {number} Objektteil der OID. Falls es sich bei dem
		 aktuellen nicht um ein Transaktionsobjekt handelt oder
		 dieses im ZUstand Neuanlage ist (also noch kein Datenbankobjekt
		 dazu existiert), wird 0 zurückgegeben.
	 */
	GetRealObjectIdLow() {
		this.retcode = 0;
		return this._realOidLow;
	}
	
	/**
	 @function PDObject#getPrincipalUid
	 @desc Die Mandantenkennung des Objekts zurückgeben, falls die
		 Anwendung mandantenfähig ist.
	 @return {number} Die Mandanten-Id.
	 */
	getPrincipalUid() {
		this.retcode = 0;
		return this.pid;
	}
	
	/**
	 @function PDObject#hasProperty
	 @desc Fragt ab, ob das Property mit dem angegebenen Namen
		 in diesem Objekt existiert.
	 @param {string} name Der Property-Name.
	 @return {boolean} <code>true</code>, falls es existiert,
		 sonst <code>false</code>.
	 */
	hasProperty(name) {
		return (typeof this[name] !== 'undefined');
	}

	/// Vergleichsfunktionen
	/**
	 @function PDObject#isEqual
	 @desc Vergleicht das aktuelle mit dem übergebenen
		 Fachkonzeptobjekt bezüglich der Schlüsselattribute.
	 @param {PDObject} pdo Objekt, mit dem das aktuelle Objekt
		 verglichen werden soll.
	 @return {boolean} <code>true</code>, wenn die beiden Objekte
		 in ihren Schlüsselattributen übereinstimmen. Wenn <code>null</code>
		 übergeben wurde, lautet das Ergebnis <code>false</code>.
	*/
	isEqual(pdo) {
		this.retcode = -1;
		if(!pdo)
			return false;
		// TODO: es fehlt noch die Info, welche die Keys sind (PDMeta)
	}
	
	/**
	 @function PDObject#isIdentical
	 @desc Vergleicht das aktuelle mit dem übergebenen
		 Fachkonzeptobjekt bezüglich der OID. Falls es sich bei einem
		 der Vergleichsobjekte oder beiden um Transaktionobjekte handelt,
		 werden deren Datenbankobjekte für den Vergleich herangezogen.
	 @param {PDObject} pdo Objekt, mit dem das aktuelle Objekt
		 verglichen werden soll.
	 @return {boolean} <code>true</code>, wenn die beiden
		 Objekte in ihrer OID übereinstimmen. Wenn <code>null</code>
		 übergeben wurde, lautet das Ergebnis <code>false</code>.
	 */
	isIdentical(pdo) {
		this.retcode = 0;
		if(!pdo)
			return false;
		var obj1 = this;
		var obj2 = pdo;
		if(obj1.isTrans())
			obj1 = obj1.getRealObject();
		if(obj2.isTrans())
			obj2 = obj2.getRealObject();
		return (obj1 && obj2 && obj1.oid == obj2.oid);
	}
	
	/**
	 @function PDObject#isValid
	 @desc Gibt zurück, ob das lokal vorliegende Objekt noch gültig,
		 das heißt insbesondere: nicht als gelöscht markiert ist.
	 @return {boolean} <code>true</code>, wenn an dem Objekt
		 das Gelöscht-Flag gesetzt ist.
	 */
	isValid() {
		this.retcode = 0;
		return this._deleted;
	}
	
	/**
	 @function PDObject#isUptodate
	 @desc Gibt zurück, ob das Server-seitige Objekt seit der
		 Erstellung des lokalen verändert wurde. Das wird
		 über den Vergleich der MD5-Summen ermittelt.
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @return Wenn der MD5-Summe des Server-Objekts mit der
		 lokal gespeicherten MD5-Summe übereinstimmt, wird
		 <code>0</code> zurückgegeben; ebenso, wenn es sich
		 um ein neues Objekt handelt, es also noch keine
		 Server-seitige Entsprechung besitzt.
		 Anderenfalls werden Codes mit folgender Bedeutung
		 zurückgegeben:
		 <ul>
			<li><code>1</code>: Das Server-Objekt konnte nicht gefunden werden,
			möglicherweise wurde es zwischenzeitlich gelöscht.</li>
			<li><code>2</code>: Die MD5-Summen weichen voneinader ab, d.h. das
			Objekt ist Server-seitig verändert worden.</li>
			<li><code>-1</code>: Bei der Überprüfung ist ein Fehler aufgetreten.</li>
		 </ul>
	 */
	isUptodate(callback) {
		this.retcode = 0;
		if(this.isNew() == true)
			return 0;
		var pdClass = (this.PDClass || PDClass);
		var pars = new JParamPacker(JafWebAPI.PDObject.isUptodate.eventName, pdClass);
		pars.addPDObjectByIds(this);
		pars.add(JafWebAPI.PDObject.isUptodate.PAR_md5, this._md5);
		//console.log("### PDObject.isUptodate(): Params: "+pars.toString());
		var result = -1;
		var successFn = function(req, options)
				{
					var resp = new JResponse(req, options, pdClass);
					if(resp.hasFatalError())
						throw "Request got empty or invalid response!";
					if(resp.hasError())
						throw resp.getErrorMessage();
					result = resp.getInt(JafWebAPI.PDObject.isUptodate.PROP_res, -1);
					if(typeof callback == 'function')
						callback(result);
				};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callback == 'function')
					callback();
			};
		JafWeb.ajaxRequest({
				url: (this.PDClass || PDClass).getURL(),
				authToken: pdClass.getAuthToken(),
				method: "GET",
				async: (!!callback),
				params: pars.get(),
				scope: this,
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		if(!callback)
			return result;
	}
	
	/**
	 @function PDObject#reload
	 @desc Lädt das <code>PDObject</code> neu vom Server.
	 @param {Mixed} [attrs] Die zu ermittelnden Attribute - entweder eine Liste der
		 technischen Attributnamen oder ein boolescher Typ, der anzeigt, ob alle Attribute
		 laut Modell (<code>true</code>) oder gar keine Attribute (<code>false</code>)
		 ermittelt werden sollen.
	 @param {Mixed} [rels] Die zu ermittelnden Beziehungen. Wenn ein boolescher Typ
		 angegeben wird, zeigt <code>false</code> an, dass keine Beziehungen geholt werden
		 sollen, <code>true</code> dagegen, dass alle Zu-1-Beziehungen laut Modell geholt
		 werden sollen. Dabei wird für jede Zu-1-Beziehung das ggf. verknüpfte {@link PDObject}
		 mit lediglich dem im Modell spezifizierten Object Ident, jedoch ohne die übrigen
		 Attribute geholt. Statt des booleschen Typs kann eine Liste der zu ermittelnden
		 Beziehungen angegeben werden. Darin kann jedem technischen Beziehungsnamen, getrennt
		 durch Komma, der Template-Ausdruck für die RelationInfo angehängt werden. Das ist
		 wichtig, um spätere, vereinzelte [getStatus()]{@link PDObject#getStatus}-Aufrufe
		 zu vermeiden.
	 @param {Function} callback Wird die Callback-Funktion angegeben, wird der
		 Request asynchron ausgeführt, sonst synchron.
	 @return {PDObject} Gibt das neu geladene Objekt wieder zurück.
	 */
	reload(attrs, rels, callback) {
		//console.log("### PDObject.reload() - PRE: "+inspect(this, true));
		this.retcode = 0;
		var _attrs = [];
		var _rels = true;
		var _callb = null;
		var pos = 0;
		if(arguments.length > pos && (JafWeb.isArray(arguments[pos]) || (typeof arguments[pos] == 'boolean')))
		{
			_attrs = arguments[pos];
			pos++;
		}
		if(arguments.length > pos && (JafWeb.isArray(arguments[pos]) || (typeof arguments[pos] == 'boolean')))
		{
			_rels = arguments[pos];
			pos++;
		}
		if(arguments.length > pos && (typeof arguments[pos] == 'function'))
		{
			_callb = arguments[pos];
			pos++;
		}
		// Wir muessen sicherstellen, dass die lokal bereits gesetzten Attribute
		// aktualisiert werden, sonst kann es trotz reload zu Abweichungen kommen!
		if(JafWeb.isArray(_attrs))
		{
			for(var sProperty in this)
			{
				if(typeof this[sProperty] == "function" || sProperty == "PDClass" ||
						sProperty == "_evtHandler" || sProperty == "_inSetValues" ||
						sProperty == "oid" || sProperty == "oidHi" || sProperty == "oidLow" ||
						sProperty == "_realOidLow" || sProperty == "pid" || sProperty == "_tid" ||
						sProperty == "classname" || sProperty == "cid" || sProperty == "_isnew" ||
						sProperty == "_modified" || sProperty == "_isTrans" || sProperty == "_allowEdit" ||
						sProperty == "_allowDelete" || sProperty == "_deleted" || sProperty == "_connected" ||
						sProperty == "_disconnected" || sProperty == "_statusCache" ||
						sProperty == "_depDelete" || // muss eigtl. mit rein, wird aber aktuell nicht ausgewertet!
						sProperty == "retcode" || sProperty == "_lastMsg" ||
						sProperty == "_lockid" || sProperty == "_md5" ||
						sProperty == "__mod" || sProperty == "_pdClass" ||
						sProperty == "_ismodified"
						)
					continue;
				// keine Verwaltungs-Properties!
				if(sProperty.substring(0, 7) == '__perm_' || sProperty.substring(0, 4) == '__f_')
					continue;
				if(_attrs.indexOf(sProperty) < 0)
					_attrs.push(sProperty);
			}
		}
		if(_callb)
		{
			var thisObj = this;
			(this.PDClass || PDClass).ptr(this.classname, this.oidLow, /* noCache */ true, _attrs, _rels, function(tmpObj) {
					if(tmpObj)
					{
						JafWeb.apply(thisObj, tmpObj);
						thisObj.__mod = {};
					}
					else
						thisObj.setDeleted();
					_callb();
				});
		}
		else
		{
			var tmpObj = (this.PDClass || PDClass).ptr(this.classname, this.oidLow, /* noCache */ true, _attrs, _rels, _callb);
			if(tmpObj)
			{
				JafWeb.apply(this, tmpObj);
				this.__mod = {};
			}
			else
				this.setDeleted();
		}
		return this;
	}
	
	/**
	 @function PDObject#loadPermissions
	 @desc Lädt die Berechtigungen auf die Klassenelemente vom Server.
	 @param {string[]} [attrs] Attribute. Enthält die Liste nur ein leeres
		 Element, werden die Rechte für alle Attribute der Klasse ermittelt.
	 @param {string[]} [rels] Beziehungen. Enthält die Liste nur ein leeres
		 Element, werden die Rechte für alle Beziehungen der Klasse ermittelt.
	 @param {string[]} [ops] Operationen. Enthält die Liste nur ein leeres
		 Element, werden die Rechte für alle Operationen der Klasse ermittelt.
	 @param {Function} callback Wird die Callback-Funktion angegeben, wird der
		 Request asynchron ausgeführt, sonst synchron.
	 */
	loadPermissions(attrs, rels, ops, callback) {
		////console.log("### PDObject.loadPermissions[" + this.classname + "](", attrs, ", ", rels, ")");
		var pdClass = (this.PDClass || PDClass);
		var _obj = this;
		var _attrs = ['']; // = alle Attribute
		var _rels = [''];
		var _ops = [''];
		var _cb = null;
		var pos = 0;
		if(arguments.length > pos && JafWeb.isArray(arguments[pos]))
		{
			_attrs = arguments[pos];
			pos++;
		}
		if(arguments.length > pos && JafWeb.isArray(arguments[pos]))
		{
			_rels = arguments[pos];
			pos++;
		}
		if(arguments.length > pos && JafWeb.isArray(arguments[pos]))
		{
			_ops = arguments[pos];
			pos++;
		}
		if(arguments.length > pos && (typeof arguments[pos] == 'function'))
		{
			_cb = arguments[pos];
			pos++;
		}
		if(_cb && (_attrs.length > 0 || _rels.length > 0 || _ops.length > 0))
		{
			pdClass.ClientInfo.getElementPermissions(_obj.classname, _obj,
					_attrs, _rels, _ops,
					function(perms) {
						for(var el in perms)
						{
							if(!perms.hasOwnProperty(el))
								continue;
							// Die hier ermittelten ClientInfo-Flags sind
							// doch etwas anderes als die in _obj['__f_' + el]
							// gespeicherten, die von getAttribute() kommen!
							_obj['__perm_' + el] = perms[el];
						}
						_cb();
				});
			return;
		}
		if(_attrs.length > 0 || _rels.length > 0 || _ops.length > 0)
		{
			var perms = pdClass.ClientInfo.getElementPermissions(_obj.classname, _obj,
					_attrs, _rels, _ops);
			////console.log("### PDObject.loadPermissions():", perms);
			for(var el in perms)
			{
				if(!perms.hasOwnProperty(el))
					continue;
				if(perms[el] >= 0)
					_obj['__perm_' + el] = perms[el];
			}
		}
	}

	////////////////////////////////////////////////////////////
	/// Funktionen zum Datenabgleich (intern)
	// Initialisiert das PDObject aus dem vom Server geholten
	// und an die Funktion uebergebenen JSON-Objekt.
	_setValues(jsonObject) {
		var pdClass = (this.PDClass || PDClass);
		if(jsonObject === null)
			return null;
		if(this._inSetValues) // Endlosrekursion verhindern!
			return this;
		this._inSetValues = true;
		if(jsonObject && typeof jsonObject == "object")
		{
			if(!JafWeb.isPDObject(jsonObject))
			{
				this.cid = 0;
				this.oidHi = 0;
				this.oidLow = 0;
				this.classname = "";
				this.oid = "";
				this.pid = undefined;
				this._realOidLow = 0;
			}
			for(var sProperty in jsonObject)
			{
				if(typeof jsonObject[sProperty] == "function")
					continue;
				if(sProperty == "PDClass")
					continue;
				if(sProperty == "_evtHandler" || sProperty == "_inSetValues")
					continue;
				if(sProperty == "oid")
				{
					if(typeof jsonObject.oid == "string")
					{
						this.oid = jsonObject.oid;
						this.oidHi = OID_HI(jsonObject.oid);
						this.oidLow = OID_LO(jsonObject.oid);
					}
					else
						this.oidLow = jsonObject.oid;
				}
				else if(sProperty == "oidHi")
					this.oidHi = jsonObject.oidHi;
				else if(sProperty == "oidLow")
					this.oidLow = jsonObject.oidLow;
				else if(sProperty == "_realOidLow")
					this._realOidLow = jsonObject._realOidLow;
				else if(sProperty == "pid")
					this.pid = jsonObject.pid;
				else if(sProperty == "_tid")
					this._tid = jsonObject._tid;
				else if(sProperty == "classname")
					this.classname = jsonObject.classname;
				else if(sProperty == "cid" && typeof jsonObject.cid == "number")
					this.cid = jsonObject.cid;
				else if(sProperty == "_isnew"
						|| sProperty == "_modified"
						|| sProperty == "_isTrans"
						|| sProperty == "_allowEdit"
						|| sProperty == "_allowDelete"
						|| sProperty == "_deleted"
						|| sProperty == "_connected"
						|| sProperty == "_disconnected")
				{
					//this[sProperty] = (true === jsonObject[sProperty]);
					this[sProperty] = (jsonObject[sProperty] != undefined ? jsonObject[sProperty] : false);
				}
				else if(sProperty == "_statusCache")
				{
					JafWeb.apply(this._statusCache, jsonObject[sProperty]);
					////console.log("### deserialized statusCache: ", this._statusCache);
				}
				else if(sProperty == "__mod")
				{
					JafWeb.apply(this.__mod, jsonObject[sProperty]);
				}
				else if(sProperty == "_pdClass")
				{ }
				else if(jsonObject[sProperty] && typeof jsonObject[sProperty] == "object")
				{
					// Beziehung
					// Zu-1- und Zu-N-Beziehung unterscheiden.
					if(JafWeb.isArray(jsonObject[sProperty]))
					{
						var oldVal = this[sProperty];
						this[sProperty] = new Array();
						for(var i=0; i < jsonObject[sProperty].length; i++)
						{
							var tmpToNObj = new PDObject(pdClass, jsonObject[sProperty][i]);
							tmpToNObj._setValues(jsonObject[sProperty][i]);
							this[sProperty].push(tmpToNObj);
							////console.log("### PDObject._setValues() - Setting mod flag for '" + sProperty + "' to false");
							this.__mod[sProperty] = false;
						}
						if(oldVal !== undefined &&
								sProperty.substring(0, 4) != '__f_' && sProperty != "_depDelete" &&
								oldVal !== undefined)
							this.handleUpdated.call(this, sProperty, oldVal, this[sProperty]);
					}
					else
					{
						var oldVal = this[sProperty];
						var oldOidLow = (this[sProperty] ? this[sProperty].oidLow : 0);
						this[sProperty] = pdClass.toPDObject(jsonObject[sProperty]);
						////console.log("### PDObject._setValues() - Setting mod flag for '" + sProperty + "' to false");
						this.__mod[sProperty] = false;
						/*var tmpTo1Obj = new PDObject(this.PDClass, jsonObject[sProperty]);
						tmpTo1Obj._setValues(jsonObject[sProperty]);
						this[sProperty] = tmpTo1Obj;*/
						if(!JafWeb.isPDObject(this[sProperty]))
							console.warn('PDClass.toPDObject['+sProperty+']: result is not instance of PDObject');
						if(sProperty.substring(0, 4) != '__f_' && sProperty != "_depDelete")
						{
							if(oldVal !== undefined &&
									((this[sProperty] && this[sProperty].oidLow != oldOidLow) ||
									(!this[sProperty] && oldOidLow != 0)))
							{
								//console.log("#### oldVal:", oldVal, "newVal:", this[sProperty]);
								this.handleUpdated.call(this, sProperty, oldVal, this[sProperty]);
							}
						}
					}
				}
				else if(sProperty.substring(0, 4) != '__f_')
				{
					var old = this[sProperty];
					this[sProperty] = jsonObject[sProperty];
					if(sProperty.substring(0, 4) != '__f_' && sProperty != "_depDelete" &&
							sProperty != "_md5" && sProperty != "_pdClass" && sProperty != "_modified" && sProperty != "_ismodified" &&
							old !== undefined && this[sProperty] != old)
					{
						this.handleUpdated.call(this, sProperty, old, this[sProperty]);
						////console.log("### PDObject._setValues() - Setting mod flag for '" + sProperty + "' to false");
						this.__mod[sProperty] = false;
					}
				}
			}
			if(this.cid == 0)
				this.cid = pdClass.PDMeta.getId(this.classname);
			else if(this.cid == 0)
				this.classname = pdClass.PDMeta.getClass(this.cid);
			this._modified = false;
		}
		else
			console.warn("_setValues(): first Parameter is not a JSON object: "+jsonObject);
		this._inSetValues = false;
		return this;
	}
	
	/**
	 @function PDObject#getElementFlags
	 @desc Gibt die für ein Element des Objekts gesetzten Flags zurück.<br/>
		 <h4>Beispiel</h4>
		 <pre class="prettyprint"><code>// Folgender Code ermittelt, ob es sich um ein auf der GUI
// schreibgechütztes Attribut handelt:
if((obj.getElementFlags(attribut) & PDObjectFlags.GUIReadOnly) != 0) {
...
}</code></pre>
	 @param {string} elem Der Name des Klassenelements, dessen Flags
		 ermittelt werden sollen.
	 @return {number} Ein oder mehrere, miteinander durch binäres ODER
		 verkettete Werte aus {@link PDObjectFlags}. Sind die Flags noch
		 nicht bekannt, wird <code>undefined</code> zurückgegeben.
	 */
	getElementFlags(elem) {
		/*if(elem == 'Bearbeiter') {
			console.log("### Flags von " + (this.isTrans() ? "Trans-obj." : "Real-obj.") +
					" Bearbeiter: " + this.printRelFlags('Bearbeiter',
					(this['__f_'+elem] || this['__perm_'+elem])));
		}*/
		return (this['__f_'+elem] || this['__perm_'+elem]); // bei Beziehungen greift das zweite!
	}
	
	/**
	 @function PDObject#getElementPermissions
	 @desc Gibt die für ein Element des Objekts ermittelten
		 Berechtigungen zurück.
	 @param {string} elem Der Name des Klassenelements, dessen Rechte
		 ermittelt werden sollen.
	 @return {number} Ein oder mehrere, miteinander durch binäres ODER
		 verkettete Werte aus {@link ClientInfo}. Sind die Flags noch
		 nicht bekannt, wird <code>undefined</code> zurückgegeben, d.h.
		 es wird <em>kein Request</em> ausgelöst!
		 Zum Laden der Objektberechtigungen vgl.
		 [loadPermissions()]{@link PDObject#loadPermissions}
	 */
	getElementPermissions(elem) {
		return this['__perm_'+elem];
	}
	
	/*
	 @function PDObject#printAttrFlags
	 @desc Gibt die aktuell gesetzten Attribut-Flags als String aus
		(zu Debug-Zwecken).
	*/
	printAttrFlags(attr, flags) {
		const fl = (flags || this['__f_'+attr]);
		if(!fl)
			return '0';
		let str = '';
		if(fl & PDObjectFlags.Available) {
			if(str) str += '|';
			str += 'Available';
		}
		if(fl & PDObjectFlags.Key) {
			if(str) str += '|';
			str += 'Key';
		}
		if(fl & PDObjectFlags.Mandatory) {
			if(str) str += '|';
			str += 'Mandatory';
		}
		if(fl & PDObjectFlags.ReadOnly) {
			if(str) str += '|';
			str += 'ReadOnly';
		}
		if(fl & PDObjectFlags.NoReadPermission) {
			if(str) str += '|';
			str += 'NoReadPermission';
		}
		if(fl & PDObjectFlags.NoWritePermission) {
			if(str) str += '|';
			str += 'NoWritePermission';
		}
		if(fl & PDObjectFlags.DynamicDefault) {
			if(str) str += '|';
			str += 'DynamicDefault';
		}
		if(fl & PDObjectFlags.NewObject) {
			if(str) str += '|';
			str += 'NewObject';
		}
		if(fl & PDObjectFlags.Multilingual) {
			if(str) str += '|';
			str += 'Multilingual';
		}
		if(fl & PDObjectFlags.ForeignKey) {
			if(str) str += '|';
			str += 'ForeignKey';
		}
		if(fl & PDObjectFlags.RelationNotSet) {
			if(str) str += '|';
			str += 'RelationNotSet';
		}
		if(fl & PDObjectFlags.Overwritten) {
			if(str) str += '|';
			str += 'Overwritten';
		}
		if(fl & PDObjectFlags.NotOverwritten) {
			if(str) str += '|';
			str += 'NotOverwritten';
		}
		if(fl & PDObjectFlags.Utf8Encoded) {
			if(str) str += '|';
			str += 'Utf8Encoded';
		}
		if(fl & PDObjectFlags.GUIReadOnly) {
			if(str) str += '|';
			str += 'GUIReadOnly';
		}
		return str;
	}
	
	/*
	 @function PDObject#printRelFlags
	 @desc Gibt die aktuell gesetzten Beziehungs-Flags als String aus
		(zu Debug-Zwecken).
	*/
	printRelFlags(rel, flags) {
		const fl = (flags || this['__f_'+rel]);
		if(!fl)
			return '0';
		let str = '';
		if(fl & ClientInfo.RelTraverse) {
			if(str) str += '|';
			str += 'RelTraverse';
		}
		if(fl & ClientInfo.RelRemove) {
			if(str) str += '|';
			str += 'RelRemove';
		}
		if(fl & ClientInfo.RelInsert) {
			if(str) str += '|';
			str += 'RelInsert';
		}
		if(fl & ClientInfo.RelNewInsert) {
			if(str) str += '|';
			str += 'RelNewInsert';
		}
		if(fl & ClientInfo.RelOrder) {
			if(str) str += '|';
			str += 'RelOrder';
		}
		if(fl & ClientInfo.RelDelete) {
			if(str) str += '|';
			str += 'RelDelete';
		}
		return str;
	}

	/*
	 @desc Zur Übertragung an den Server in ein JSON-Objekt
		 serialisieren.
	 @param {boolean} [inclImpl=false] Implizite Attribute mit ausgeben?
	 @param {number[]} [added] Array von Oids (unterer Teil) der Objekte,
		 die bereits hinzugefügt wurden. Dient der Rekursionsverhinderung!
	 @param {boolean} [recursive=false] Gibt an, ob alle lokal verbundenen
		 Objekte rekursiv mit rausgeschrieben werden sollen. Wenn das
		 Ursprungsobjekt ein Transaktionsobjekt ist, werden auch nur die
		 verbundenen Transaktionsobjekte vollständig mit rausgeschrieben.
	 */
	_getValues(inclImpl, added, recursive) {
		//DEBJ("PDObject._getValues()["+this._cid+":"+this._oid+"]");
		var noRelAttrs = true; // keine Attribute ueber Beziehungen
		var rec = (recursive === true);
		if(!added)
			added = new Array();
		var jsonStr = "{";
		// TODO: wenn das Objekt als gelöscht markiert ist (this._deleted),
		// muessen ausser cid, oid und dem deleted-Flag keine Werte mehr
		// uebertragen werden.
		// OID zuerst
		jsonStr += "cid:" + this.cid;
		jsonStr += ",classname:\"" + this.classname +"\"";
		jsonStr += ",oidHi:" + this.oidHi;
		jsonStr += ",oidLow:" + this.oidLow;
		jsonStr += ",oid:\"" + this.oid + "\"";
		if(typeof this.pid != 'undefined')
			jsonStr += ",pid:" + this.pid;
		if(this.isTrans())
		{
			jsonStr += ",_realOidLow:" + this._realOidLow;
			jsonStr += ",_tid:" + this._tid;
		}
		// wurde das Objekt bereits rausgeschrieben?
		var exist = false;
		if(added.indexOf(this.oid) >= 0)
			exist = true;
		added.push(this.oid);
		var attr;
		for(attr in this)
		{
			if(typeof attr != 'string')
				continue;
			//implizite weglassen:
			if(!inclImpl || inclImpl == false)
			{
				if(attr == "PDClass" ||
						attr == "_allowDelete" ||
						attr == "_allowEdit" ||
						attr == "_lastMsg" ||
						attr == "retcode" ||
						attr == "_evtHandler" ||
						attr == "_inSetValues" ||
						attr == "__mod")
					continue;
			}
			if((typeof this[attr]) == "function")
				continue;
			if(attr == "classname" || attr == "IDENT" || attr == "_statusCache")
				continue;
			if(attr == "cid" || attr == "oid" || attr == "_tid" || attr == "pid"
					|| attr == "oidHi" || attr == "oidLow" || attr == "_realOidLow")
				continue;
			if(attr.substring(0, 4) == '__f_' || attr.substring(0, 7) == '__perm_')
				continue;
			// falls Objekt bereits ausgegeben wurde, nicht erneut 
			// ausgeben (Rekursionsgefahr!)
			if(exist == true)
				continue;
			// abgeleitet-ueberschreibbare und schreibgeschuetzte Attribute
			// gar nicht ausgeben.
			// Achtung: Die Flags ReadOnly und GUIReadOnly sind NICHT unabhaengig
			// voneinander - GUIReadOnly bedeutet geradezu das Gegenteil von dem,
			// was es suggeriert, naemlich die Einschraenkung, dass das mit angegebene
			// ReadOnly-Flag nur auf der GUI gelten soll! Siehe PD-Doku.
			if((this['__f_'+attr] & PDObjectFlags.NotOverwritten) != 0
					// || (this['__f_'+attr] & PDObjectFlags.NoWritePermission) != 0 ???
					|| ((this['__f_'+attr] & PDObjectFlags.ReadOnly) != 0 &&
						(this['__f_'+attr] & PDObjectFlags.GUIReadOnly) == 0))
				continue;
			// Ueber Beziehungen referenzierte Attribute nicht ausgeben
			if(noRelAttrs && attr.indexOf('->') >= 0)
				continue;
			if(attr == "_lockid" || attr == "_realOidLow")
			{
				// Trenner
				jsonStr += ",";
				jsonStr += attr + ":" + this[attr];
			}
			else if(attr == "_isnew" ||
					attr == "_modified" ||
					attr == "_ismodified" ||
					attr == "_isTrans" ||
					attr == "_allowEdit" ||
					attr == "_allowDelete" ||
					attr == "_deleted" ||
					attr == "_connected" ||
					attr == "_disconnected")
			{
				// Trenner
				jsonStr += ",";
				jsonStr += attr + ":" + (this[attr] === true ? true : false);
			}
			else if(attr == "_depDelete")
			{
				// Trenner
				jsonStr += ",";
				jsonStr += attr + ":[";
				if(JafWeb.isArray(this._depDelete))
				{
					for(var i=0; i<this._depDelete.length; i++)
					{
						if(i>0) jsonStr += ",";
						jsonStr += "\""+this._depDelete[i]+"\"";
					}
				}
				jsonStr += "]";
			}
			else
			{
				////console.log("### PDObject._getValues() - __mod:", this.__mod);
				if(this.__mod[attr])
				{
					// Trenner
					jsonStr += ",";
					jsonStr += "\"" + attr + "\":" +
						this._toJson(this[attr], inclImpl, added, rec);
				}
			}
		}
		jsonStr += "}";
		//console.log("### PDObject._getValues() - Result: "+jsonStr);
		return jsonStr;
	}

	/*
	 @param {mixed} val
	 @param {boolean} inclImpl Implizite Attribute mit ausgeben?
	 @param {number[]} [added] Array von Oids (unterer Teil) der Objekte,
		 die bereits hinzugefügt wurden. Dient der Rekursionsverhinderung!
	 @param {boolean} [deep=false] Gibt an, ob alle lokal verbundenen
		 Objekte rekursiv mit rausgeschrieben werden sollen. Wenn das
		 Ursprungsobjekt ein Transaktionsobjekt ist, werden auch nur die
		 verbundenen Transaktionsobjekte vollständig mit rausgeschrieben.
	 */
	_toJson(val, inclImpl, added, deep) {
		// alle als Strings ausgeben!? Fuer get- und setAttribute() reicht das!
		var jsonStr = "";
		switch(typeof val)
		{
			case "string":
				val = val.replace(/\\/g,'\\\\');
				val = val.replace(/"/g,'\\"');
				val = val.replace(/\n/g,'\\n');
				val = val.replace(/\r/g,'');
				jsonStr += "\"" + val + "\"";
				break;
			case "number":
				jsonStr += "\"" + val + "\"";
				break;
			case "boolean":
				jsonStr += "\"" + (val ? "true" : "false") + "\"";
				break;
			case "object":
				if(val == null)
					jsonStr += "null";
				else if(JafWeb.isArray(val))
				{
					// Zu-N-Bez.
					jsonStr += "[";
					for(var i=0; i < val.length; i++)
					{
						//if(!(val[i] instanceof PDObject))
						//	alert("_toJson(): "+val[i]+" is not a PDObject");
						if(i)
							jsonStr += ",";
						if(deep === true && val[i].isTrans && val[i].isTrans()) // nur Transaktionsobjekte komplett!
							jsonStr += val[i]._getValues(inclImpl, added);
						else
						{
							jsonStr += "{";
							jsonStr += "cid:" + val[i].cid;
							jsonStr += ",classname:\"" + val[i].classname + "\"";
							jsonStr += ",oidHi:" + val[i].oidHi;
							jsonStr += ",oidLow:" + val[i].oidLow;
							if(typeof val[i].pid != 'undefined')
								jsonStr += ",pid:" + val[i].pid;
							if(val.isTrans && val[i].isTrans())
							{
								jsonStr += ",_realOidLow:" + val[i]._realOidLow;
								jsonStr += ",_tid:" + val[i]._tid;
							}
							jsonStr += ",_deleted:" + val[i]._deleted;
							jsonStr += ",_connected:" + val[i]._connected;
							jsonStr += ",_disconnected:" + val[i]._disconnected;
							jsonStr += "}";
						}
					}
					jsonStr += "]";
				}
				else
				{
					//if(!(val instanceof PDObject))
					//	alert("_toJson(): "+val+" is not a PDObject");
					// Zu-1-Bez.
					if(deep === true && val.isTrans && val.isTrans()) // nur Transaktionsobjekte komplett!
						jsonStr += val._getValues(inclImpl, added);
					else
					{
						jsonStr += "{";
						jsonStr += "cid:" + val.cid;
						jsonStr += ",classname:\"" + val.classname + "\"";
						jsonStr += ",oidHi:" + val.oidHi;
						jsonStr += ",oidLow:" + val.oidLow;
						if(typeof val.pid != 'undefined')
							jsonStr += ",pid:" + val.pid;
						if(val.isTrans && val.isTrans())
						{
							jsonStr += ",_realOidLow:" + val._realOidLow;
							jsonStr += ",_tid:" + val._tid;
						}
						jsonStr += ",_deleted:" + val._deleted;
						jsonStr += ",_connected:" + val._connected;
						jsonStr += ",_disconnected:" + val._disconnected;
						jsonStr += "}";
					}
				}
				break;
			default:
				jsonStr += "undefined";
		}
		return jsonStr;
	}
	////////////////////////////////////////////////////////////

	/**
	 @function PDObject#sync
	 @desc Die Synchronisierung dieses Objekts mit seiner Entsprechung auf
		 der Server-Seite anstoßen.
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @return {boolean} <code>true</code>, wenn synchronisiert werden
		 konnte, sonst <code>false</code>.
	 */
	sync(callback) {
		// TODO: MD5-Kontrolle?
		this.retcode = 0;
		var pdClass = (this.PDClass || PDClass);
		var pars = new JParamPacker(JafWebAPI.PDObject.sync.eventName, pdClass);
		//pars.addPDObjectByIds(this);
		pars.add(JafWebAPI.PDObject.sync.PAR_clName, this.classname); // sonst klappt MO-Weiche nicht!
		pars.add(JafWebAPI.PDObject.sync.PAR_object, this);
		//console.log("### PDObject.sync(): Params: "+pars.toString());
		var result = {};
		var successFn = function(req, options)
				{
					var resp = new JResponse(req, options, pdClass);
					if(resp.hasFatalError())
						throw "Request got empty or invalid response!";
					if(resp.hasError())
						throw resp.getErrorMessage();
					//result.transObj = resp.getPDObject('object');
					result.retCode = resp.getInt(JafWebAPI.PROP_retCode, -1);
					this.retcode = result.retCode;
					var tid = resp.getInt(JafWebAPI.PDObject.sync.PROP_tid, -1);
					if(result && tid >= 0)
						result._tid = tid;
					if(typeof callback == 'function')
						callback(result);
				};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callback == 'function')
					callback(result);
			};
		JafWeb.ajaxRequest({
				url: (this.PDClass || PDClass).getURL(),
				authToken: pdClass.getAuthToken(),
				method: 'POST',
				async: (!!callback),
				params: pars.getPostParams(),
				scope: this,
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		if(!callback)
			return (result.retCode == 0);
	}
	
	/**
	 @function PDObject#abortTransaction
	 @desc Bricht eine mit [PDClass.startNewTransaction()]{@link PDClass#startNewTransaction} oder
		 [PDClass.startTransaction()]{@link PDClass#startTransaction} gestartete Transaktion ab.
		 Das erzeugte Transaktionsobjekt wird ohne Änderung des Datenbankobjekts
		 verworfen.
	 @param {boolean} [newTrans=false] Gibt an, ob die Transaktion erneut gestartet
		 werden soll. Dadurch ergibt sich im Dialog die Bedeutung "Zurücksetzen",
		 wonach der Dialog geöffnet bleiben und weiter bearbeitet werden kann.
	 @param {boolean} [recursive=false] Bestimmt, ob etwaige mit diesem
		 verbundene Transaktionsobjekte mitbehandelt werden sollen.
	 @param {boolean} [transactionMaster=true] Zeigt an, ob es sich um das Wurzelobjekt einer
		 - potentiell mehrere Objekte umfassenden - Transaktion handelt. Wenn solch ein
		 Transaktionskonzept unterstützt wird, kann hierüber unterscheiden werden, ob
		 der gesamte Objektzusammenhang committed werden muss. Alle an der
		 Transaktion beteiligten Objekte werden dann ebenfalls committed.
	 @return {Object} Objekt mit folgenden Properties:
		 <ul>
			<li><code>retcode Number</code> Fehlercode.
			Wenn alles geklappt hat, wird hier <code>0</code> zurückgegeben.</li>
			<li><code>transObj PDObject</code> Wird bei <code>holdTrans</code>
			<code>true</code> angegeben, so steht hier
			das Transaktionsobjekt zur weiteren Bearbeitung drin.</li>
		 </ul>
	 @deprecated Bitte [ClientInfo.abortTransaction]{@link ClientInfo#abortTransaction} benutzen.
	 */
	abortTransaction(newTrans, recursive, transactionMaster) {
		this.retcode = 0;
		if(UIApplication.USE_COMPLEX_TRANSACTIONS) // transactionMaster wird hier nicht ausgewertet u. sollte hier immer false sein!
		{
			//console.log("### PDObject.abortTransaction()");
			if(newTrans)
			{
				this.reload();
				return {
					retcode: 0,
					transObj: this
				};
			}
			else
				return (this.PDClass || PDClass).ClientInfo.removeFromTransaction(this._tid, this);
		}
		return {
				retcode: -1 // veraltet!
			};
	}

	/**
	 @function PDObject#checkConstraints
	 @desc Lokale Änderungen zum Server übertragen und speichern.
		 Das lokale Objekt wird anschließend wieder vom Server
		 aktualisiert, um Änderungen infolge des Speicherns
		 mitzubekommen.
	 @param {boolean} [recursive=false] Bestimmt, ob etwaige mit diesem
		 verbundene Transaktionsobjekte mitbehandelt werden sollen.
	 @param {boolean} [stopOnError=true] Wird hier <code>false</code> angegeben,
		 werden alle auftretenden Fehlermeldungen zurückgegeben, sonst
		 (Standardfall) nur der zuerst auftretende.
	 @param {boolean} [andCommit=false] Gibt an, ob das Transaktionsobjekt auch in das
		 Datenbankobjekt gespeichert werden soll.
	 @param {boolean} [holdTrans=false] Gibt an, ob das übergebene Transaktionsobjekt
		 für die weitere Bearbeitung behalten werden soll. Typischerweise ist das
		 der Fall, wenn diese Funktion im Rahmen der "Übernehmen"-Funktion des
		 Dialogs aufgerufen wird, wogegen beim Auslösen von "OK" das Transaktionsobjekt
		 freigegeben werden kann, weil der Dialog geschlossen wird.
	 @param {boolean} [transactionMaster=true] Zeigt an, ob es sich um das Wurzelobjekt einer
		 - potentiell mehrere Objekte umfassenden - Transaktion handelt. Wenn solch ein
		 Transaktionskonzept unterstützt wird, kann hierüber unterscheiden werden, ob
		 der gesamte Objektzusammenhang committed werden muss. Alle an der
		 Transaktion beteiligten Objekte werden dann ebenfalls committed.
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @return {Object} JavaScript-Objekt mit folgenden Properties:
		 <ul>
			<li><code>retCode Number</code> Der Fehlercode von <code>checkConstraints()</code>.
				Wenn alles geklappt hat, wird hier <code>0</code> zurückgegeben.
			<li><code>errors String[]</code> String-Array mit Fehlermeldungen. Falls
				<code>stopOnError</code> <code>true</code> angegeben wurde, enthält
				das Array nur ein Element.
			<li><code>warnings String[]</code> String-Array mit Meldungen, die nicht
				zum Abbruch führen.
			<li><code>pdobject PDObject</code> Wenn bei <code>andCommit</code> der
				Wert <code>true</code> und bei <code>holdTrans</code> <code>false</code>
				angegeben wurde, steht hier das gespeicherte und ggf. aktualisierte
				Originalobjekt drin.
			<li><code>transObj PDObject</code> Wird bei <code>andCommit</code> und
				bei <code>holdTrans</code> <code>true</code> angegeben, so steht hier
				das Transaktionsobjekt zur weiteren Bearbeitung drin.
		 </ul>
	 */
	checkConstraints(recursive, stopOnError, andCommit, holdTrans, transactionMaster, callback) {
		//console.log("### PDObject.checkConstraints("+recursive+", "+stopOnError+", "+andCommit+", "+holdTrans+")");
		if(UIApplication.USE_COMPLEX_TRANSACTIONS && andCommit === true)
		{
			//console.log("### PDObject.checkConstraints("+recursive+", "+stopOnError+", "+andCommit+", "+holdTrans+
			//		", "+transactionMaster+")");
			this.retcode = -1;
			var res = {
					retCode: -1,
					errors: ["checkConstraints(): Cannot commit single PDObject while using complex transactions. Use ClientInfo.commitTransaction() instead."],
					pdobject: (this.isTrans() ? this.getRealObject() : null),
					transObj: (this.isTrans() ? this : null)
				};
			if(typeof callback == 'function')
			{
				callback(res);
				return;
			}
			return res;
		}
		this.retcode = 0;
		//if(this.isNew() == true)
		//	return 0;
		var pdClass = (this.PDClass || PDClass);
		var pars = new JParamPacker(JafWebAPI.PDObject.checkConstraints.eventName, pdClass);
		if(recursive && recursive === true)
			pars.add(JafWebAPI.PDObject.checkConstraints.PAR_rec, recursive);
		if(stopOnError && stopOnError === false)
			pars.add(JafWebAPI.PDObject.checkConstraints.PAR_stopOnError, false);
		pars.add(JafWebAPI.PDObject.checkConstraints.PAR_andCommit, (andCommit === true));
		pars.add(JafWebAPI.PDObject.checkConstraints.PAR_holdTrans, (holdTrans !== false));
		//pars.addPDObjectByIds(this);
		pars.add(JafWebAPI.PDObject.checkConstraints.PAR_clName, this.classname); // sonst klappt MO-Weiche nicht!
		pars.add(JafWebAPI.PDObject.checkConstraints.PAR_object, this, (true === recursive));
		pars.add(JafWebAPI.PDObject.checkConstraints.PAR_strat, (transactionMaster === false ? "S" : "M"));
		if(this._tid)
			pars.add(JafWebAPI.PDObject.checkConstraints.PAR_tid, this._tid);
		//console.log("### PDObject.checkConstraints(): Params: "+pars.toString());
		var result = new Object();
		result.retCode = -1;
		var successFn = function(req, options)
				{
					var resp = new JResponse(req, options, pdClass);
					if(resp.hasFatalError())
						throw "Request got empty or invalid response!";
					result.retCode = resp.getInt(JafWebAPI.PROP_retCode, -1);
					result.retmsg = resp.getString(JafWebAPI.PDObject.checkConstraints.PROP_retMsg);
					result.transObj = resp.getPDObject(JafWebAPI.PDObject.checkConstraints.PROP_transObj);
					result.tid = resp.getInt(JafWebAPI.PDObject.checkConstraints.PROP_tid, -1);
					if(result.transObj && result.tid >= 0)
						result.transObj._tid = result.tid;
					result.pdobject = resp.getPDObject(JafWebAPI.PDObject.checkConstraints.PROP_realObj);
					result.errors = resp.getArray(JafWebAPI.PDObject.checkConstraints.PROP_errMsgs, [], 'string', '');
					result.warnings = resp.getArray(JafWebAPI.PDObject.checkConstraints.PROP_warnMsgs, [], 'string', '');
					if(!result.pdobject && andCommit && holdTrans)
						throw 'No PDObject found in result';
					if(typeof callback == 'function')
						callback(result);
				};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callback == 'function')
					callback(result);
			};
		JafWeb.ajaxRequest({
				url: (this.PDClass || PDClass).getURL(),
				authToken: pdClass.getAuthToken(),
				method: 'POST',
				async: (!!callback),
				params: pars.getPostParams(),
				scope: this,
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		if(!callback)
			return result;
	}

	/**
	 @function PDObject#checkConstraintsAndCommit
	 @desc Lokale Änderungen zum Server übertragen und speichern.
		 Das lokale Objekt wird anschließend wieder vom Server
		 aktualisiert, um Änderungen infolge des Speicherns
		 mitzubekommen.
	 @param {boolean} [recursive=false] Bestimmt, ob etwaige mit diesem
		 verbundene Objekte mitbehandelt werden sollen.
	 @param {boolean} [stopOnError=true] Wird hier <code>false</code> angegeben,
		 werden alle auftretenden Fehlermeldungen zurückgegeben, sonst
		 (Standardfall) nur der zuerst auftretende.
	 @param {boolean} [holdTrans=false] Gibt an, ob das übergebene Transaktionsobjekt
		 für weitere Bearbeitung behalten werden soll. Typischerweise ist das
		 der Fall, wenn diese Funktion im Rahmen der "Übernehmen"-Funktion des
		 Dialogs aufgerufen wird, wogegen beim Auslösen von "OK" das Transaktionsobjekt
		 freigegeben werden kann, weil der Dialog geschlossen wird.
	 @param {boolean} [transactionMaster=true] Zeigt an, ob es sich um das Wurzelobjekt einer
		 - potentiell mehrere Objekte umfassenden - Transaktion handelt. Wenn solch ein
		 Transaktionskonzept unterstützt wird, kann hierüber unterscheiden werden, ob
		 der gesamte Objektzusammenhang committed werden muss. Alle an der
		 Transaktion beteiligten Objekte werden dann ebenfalls committed.
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @return {Object} JavaScript-Objekt mit folgenden Properties:
		 <ul>
			<li><code>retCode Number</code> Der Fehlercode von <code>checkConstraints()</code>.
				Wenn alles geklappt hat, wird hier <code>0</code> zurückgegeben.
			<li><code>errors String[]</code> String-Array mit Fehlermeldungen. Falls für
				<code>stopOnError</code> <code>true</code> angegeben wurde, enthält
			das Array nur ein Element.
			<li><code>pdobject PDObject</code> Wenn bei <code>andCommit</code> der
				Wert <code>true</code> und bei <code>holdTrans</code> <code>false</code>
				angegeben wurde, steht hier das gespeicherte und ggf. aktualisierte
				Originalobjekt drin.
			<li><code>transObj PDObject</code> Wird bei <code>andCommit</code> und
				bei <code>holdTrans</code> <code>true</code> angegeben, so steht hier
				das Transaktionsobjekt zur weiteren Bearbeitung drin.
		 </ul>
	 @deprecated Bitte [ClientInfo.commitTransaction]{@link ClientInfo#commitTransaction} benutzen.
	 */
	checkConstraintsAndCommit(recursive, stopOnError, holdTrans, transactionMaster, callback) {
		return this.checkConstraints(recursive, stopOnError, true, false, transactionMaster, callback);
	}

	/**
	 @function PDObject#callOperation
	 @desc Ruft eine Operation fuer dieses Objekt auf dem Server auf.<br/>
		 <span class="important">Hinweis</span> zur Implementierung auf seiten des JANUS-Servers: Der
		 JafWeb-Client ruft, unabhängig von der Anzahl der Ein-/Ausgabeparameter
		 der modellierten Methode, immer die <code>callOperation()</code>-Variante
		 mit allen Ein-/Ausgabeparametern auf, weil anhand des Requests nicht erkannt
		 werden kann, ob es Ausgabeparameter gibt. Der GUI-Client hingegen ruft für
		 parameterlose Methoden direkt die <code>callOperation()</code>-Variante ohne
		 Parameter auf. Das müssen Sie berücksichtigen, wenn Sie in letzterer User Code
		 einfügen.<br/>
		 <h4>Beispiel</h4>
		 <pre class="prettyprint"><cde>// auf einem PDObject equipment wird eine Operation mit einer Objektliste
// als Ausgabeparameter synchron aufgerufen, die die Rollen ermitteln soll:
var outPdos = eq.callOperation('getAvailableUserRoles').outPdos;
console.log('Rollen:');
for(var i=0; i < outPdos.length; i++)
  console.log('  ' + outPdos[i].getAttribute('RoleErgName'), true);</code></pre>
	 @param {string} op Name der Objektoperation.
	 @param {boolean} [async=false] Bestimmt asynchrone (<code>true</code>) oder
		 synchrone (<code>false</code>) Ausführung der Operation.
		 Bei der asynchronen kann während der Ausführung auf der
		 Seite weitergearbeitet werden, die synchrone dagegen
		 blockiert alle Eingaben, bis die Operation zurückkommt.
	 @param {Array} [inStr] String oder String-Array mit Eingabeparametern.
	 @param {Array} [inPdo] OID-String oder Liste von OID-Strings mit 
		 Eingabeparametern für Objekte.
	 @param {Function} [callback] Hier kann eine JavaScript-Funktion angegeben
		 oder definiert werden, die bei der Rückkehr der Operation
		 ausgeführt werden soll (z.B. Meldung, Update einer Liste).
		 Bei asynchroner Ausführung wird diese Callback-Funktion mit
		 folgenden Parametern aufgerufen:
		 <ul>
			<li><code>outStrs</code> String oder Array von Strings mit den Ausgabeparametern
			der Operation.
			<li><code>outPdos</code> Ein einzelnes <code>PDObject</code> (oder <code>null</code>)
			oder ein Array von <code>PDObject</code>s mit den Ausgabeobjekten der
			Operation.
			<li><code>result</code> Numerischer Rückgabewert der Operation.
		 </ul>
	 @param {Object} [scope] JavaScript-Objekt, in dessen Kontext die bei <code>callback</code>
		 übergebene Funktion ausgeführt werden soll. Falls nicht angegeben, wird
		 die Funktion im Kontext des aktuellen <code>PDObject</code>s ausgeführt.
	 @param {string[]} [attrs] Wird dieser Parameter angegeben, werden
		 nach dem Aufruf nur die angegebenen Attribute des <code>this</code>-Objekts
		 vom Server aktualisiert.
	 @return {Object} Bei synchroner Ausführung wird ein JavaScript-Objekt
		 mit diesen Properties zurückgegeben:
		 <ul>
			<li><code>outStrs</code> Array mit den Ausgabe-Strings.
			<li><code>outPdos</code> Array mit den Ausgabe-<code>PDObject</code>s.
			<li><code>result</code> Rückgabewert der ausfgerufenen Operation (Number).
		 </ul>
	 @see [PDClass.callOperation()]{@link PDClass#callOperation}
	 @see Zu fortgeschrittenen Server-Operationsaufrufen siehe
		 auch die Klasse {@link PDOperationCall}.
	 */
	callOperation(op, async, inStr, inPdo, attrs, callback, scope) {
		//console.log("### PDObject.callOperation(): inPdos: "+inspect(inPdo, true));
		this.retcode = 0;
		var aInStr = null;
		var aInPdo = null;
		var bAsync = false;
		var fCallback = null;
		var oScope = null;
		var aAttrs = null;
		var pos = 1;
		if(arguments.length > pos && typeof arguments[pos] == "boolean")
		{
			bAsync = arguments[pos];
			pos++;
		}
		if(arguments.length > pos && (typeof arguments[pos] != "function"))
		{
			aInStr = arguments[pos];
			if(!JafWeb.isArray(aInStr))
				aInStr = [aInStr];
			pos++;
		}
		else
			aInStr = [ ];
		if(arguments.length > pos && (typeof arguments[pos] != "function"))
		{
			aInPdo = arguments[pos];
			if(!JafWeb.isArray(aInPdo))
				aInPdo = [aInPdo];
			pos++;
		}
		else
			aInPdo = [ ];
		if(arguments.length > pos && arguments[pos] && JafWeb.isArray(arguments[pos]))
		{
			aAttrs = arguments[pos];
			pos++;
		}
		if(arguments.length > pos && (typeof arguments[pos] == "function" || arguments[pos] == null))
		{
			fCallback = arguments[pos];
			pos++;
		}
		if(arguments.length > pos && typeof arguments[pos] == "object")
		{
			oScope = arguments[pos];
			pos++;
		}
		var pdClass = (this.PDClass || PDClass);
		var pars = new JParamPacker(JafWebAPI.PDObject.callOperation.eventName, pdClass);
		// Wenn das this-Objekt nach dem Op.-Aufruf aktualisiert wird, muss es
		// hier auch komplett hinzugefuegt werden! Sonst koennen dadurch lokale
		// Aenderungen ueberschrieben werden!
		if(this.isTrans())
			pars.add("object", this);
		// Bei const-Methoden wuerde es auch reichen, die Ids mitzugeben. Dann
		// sollte aber auch nicht nach der Rueckkehr des Aufrufs das Objekt
		// aktualisiert werden.
		else
			pars.addPDObjectByIds(this);
		pars.add(JafWebAPI.PDObject.callOperation.PAR_op, op);
		pars.add(JafWebAPI.PDObject.callOperation.PAR_async, bAsync);
		if(typeof this._lockId == 'number' && this._lockId != -1)
			pars.add(JafWebAPI.PDObject.callOperation.PAR_lockId, this._lockid);
		for(var i=0; i < aInStr.length; i++)
			pars.add(JafWebAPI.PDObject.callOperation.PAR_inStr + i, aInStr[i]);
		//console.log("### PDObject.callOperation(): sInPdos: "+inspect(aInPdo, true));
		for(var i=0; i < aInPdo.length; i++)
		{
			if(!aInPdo[i])
				pars.add(JafWebAPI.PDObject.callOperation.PAR_inPdo + i, "0");
			else if(typeof aInPdo[i] == 'object' && aInPdo[i].oid)
				pars.add(JafWebAPI.PDObject.callOperation.PAR_inPdo + i, aInPdo[i].oid);
			else if(typeof aInPdo[i] == 'string' && aInPdo[i].match(/[0-9]:[0-9]/))
				pars.add(JafWebAPI.PDObject.callOperation.PAR_inPdo + i, aInPdo[i]);
			else
				pars.add(JafWebAPI.PDObject.callOperation.PAR_inPdo + i, "0");
		}
		if(aAttrs)
		{
			for(var i=0; i<aAttrs.length; i++)
				pars.add(JafWebAPI.PDObject.callOperation.PAR_attrs + i, aAttrs[i]);
		}
		//console.log("### PDObject.callOperation(): Params: "+pars.toString());
		var res = new Object();
		var successFn = function(req, options)
				{
					var resp = new JResponse(req, options, pdClass);
					if(resp.hasFatalError())
						throw "Request got empty or invalid response!";
					if(resp.hasError())
						throw resp.getErrorMessage();
					var lockInfo = resp.get(JafWebAPI.PDObject.callOperation.PROP_lockInfo);
					if(lockInfo)
					{
						var msg = (pdClass.PDMeta.getString('SC::FormTitleLocked') || "Locked by %u, logged in from %c. Locked since %t.");
						msg = msg.replace(/%c/, lockInfo[JafWebAPI.PDObject.callOperation.PROP_client]);
						msg = msg.replace(/%u/, lockInfo[JafWebAPI.PDObject.callOperation.PROP_user]);
						msg = msg.replace(/%i/, lockInfo[JafWebAPI.PDObject.callOperation.PROP_uid]);
						if(typeof lockInfo[JafWebAPI.PDClass.lockInfo.PROP_locktime] == 'string') {
							msg = msg.replace(/%t/, lockInfo[JafWebAPI.PDClass.lockInfo.PROP_locktime]);
						}
						else {
							var dur = new JDuration(lockInfo[JafWebAPI.PDClass.lockInfo.PROP_locktime]);
							msg = msg.replace(/%t/, dur.toString());
						}
						UIMessage.ok(msg);
						return;
					}
					res.result = resp.getInt(JafWebAPI.PROP_retCode, -1);
					res.outStrs = resp.getArray(JafWebAPI.PDObject.callOperation.PROP_OUTSTR, [], 'string', '');
					res.outPdos = resp.getArray(JafWebAPI.PDObject.callOperation.PROP_OUTPDO, [], 'PDObject', null);
					var thisObj = resp.getPDObject(JafWebAPI.PDObject.callOperation.PROP_obj);
					if(thisObj)
						JafWeb.apply(this, thisObj);
					if(typeof fCallback == "function")
					{
						//fCallback(outStr, outPdo, this.retcode);
						fCallback.call((oScope || this), res.outStrs, res.outPdos, res.result);
					}
				};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof fCallback == 'function')
					fCallback.call((oScope || this));
			};
		JafWeb.ajaxRequest({
				url: (this.PDClass || PDClass).getURL(),
				authToken: pdClass.getAuthToken(),
				method: 'POST',
				async: bAsync,
				params: pars.getPostParams(),
				disableCaching: true,
				scope: this,
				callerName: pars.getEventName(),
				success: successFn,
				failure: failureFn
			});
		if(bAsync === false)
			return res;
	}

	/**
	 @function PDObject#connect
	 @desc Stellt eine Verbindung zwischen diesem und dem übergebenen
		 Objekt in einer Zu-N-Beziehung her. Für das Setzen einer
		 Zu-1-Beziehung verwenden Sie bitte die Funktion <code>setFirstLink()</code>.<br/>
		 Wenn die Verbindung nicht hergestellt werden konnte, ist der Fehler-Code kleiner
		 oder gleich 0. Ein Wert größer als 0 wird als Aktualisierungsinformation für die
		 Benutzungsoberfläche interpretiert. Die möglichen Werte sind in einem anonymen
		 Enum definiert. Ein Wert kleiner als 0 kann über
		 [PDMeta.getString()]{@link PDMeta#getString} in eine Fehlermeldung "übersetzt"
		 werden. 
	 @param {string} rolename Rollenname aus Sicht des Objektes, auf dem die
		 Funktion aufgerufen wird.
	 @param {PDObject} pdo Fachkonzeptobjekt, das über die Beziehung verbunden
		 werden soll. Statt eines einzelnen kann auch ein Array mit den zu
		 verbindenden {@link PDObject}s angegeben werden.
	 @param {number} [tid] Transaktions-Id
	 @param {number} [lockId] Lock-Id
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @see [setFirstLink()]{@link PDObject#setFirstLink}
	 */
	connect(rolename, pdo, tid, lockId, callback) {
		if(!pdo)
			return;
		let pos = 2;
		let _tid = 0;
		let _lockId = -1;
		let _callb = null;
		if(arguments.length > pos && (typeof arguments[pos] == 'number')) {
			_tid = arguments[pos];
			pos++;
		}
		if(arguments.length > pos && (typeof arguments[pos] == 'number')) {
			_lockId = arguments[pos];
			pos++;
		}
		if(arguments.length > pos && (typeof arguments[pos] == 'function')) {
			_callb = arguments[pos];
			pos++;
		}
		// TODO: alle in einem Request (in geordneter Reihenfolge)!
		if(JafWeb.isArray(pdo)) {
			for(let i = 0; i < pdo.length; i++) {
				const res = this.connect(rolename, pdo[i]);
				if(res < 0) {
					if(_callb) {
						_callb(res);
						return;
					}
					return res;
				}
			}
			if(_callb) {
				_callb(0);
				return;
			}
			return 0;
		}
		// TODO: auch verbinden mehrerer Objekte in einem Serveraufruf unterstuetzen!
		//console.log("### PDObject.connect("+rolename+", "+pdo+")");
		this.retcode = -1;
		if(!pdo || !JafWeb.isPDObject(pdo))
			return;
		// direkt Server-seitig verbinden
		const pdClass = (this.PDClass || PDClass);
		const pars = new JParamPacker(JafWebAPI.PDObject.connect.eventName, pdClass);
		pars.addPDObjectByIds(this);
		pars.add(JafWebAPI.PDObject.connect.PAR_relname, rolename);
		pars.add(JafWebAPI.PDObject.connect.PAR_relOid, pdo.oid);
		if(_tid)
			pars.add(JafWebAPI.PDObject.connect.PAR_tid, _tid);
		if(_lockId != -1)
			pars.add(JafWebAPI.PDObject.connect.PAR_lockId, _lockid);
		const thisObj = this;
		const successFn = function(req, options) {
				const resp = new JResponse(req, options, pdClass);
				if(resp.hasFatalError())
					throw "Request got empty or invalid response!";
				if(resp.hasError())
					throw resp.getErrorMessage();
				this.retcode = resp.getInt(JafWebAPI.PROP_retCode, -1);
				// Beziehungen werden jetzt generell nicht mehr Client-seitig vorgehalten,
				// muessen aber trotzdem eingetragen werden!
				if(this.retcode >= 0) {
					const card = resp.getInt(JafWebAPI.PDObject.setFirstLink.PROP_card, 0);
					if(card == 1) {
						//if(JafWeb.isArray(this[rolename]))
						//	console.log("PDObject.setFirstLink() - unexpected type of relation property for this cardinality!");
						const oldVal = this[rolename];
						this[rolename] = pdo;
						////console.log("### PDObject.connect('" + rolename + "') - Setting mod flag to true");
						this.__mod[rolename] = true;
						if(this[rolename] != oldVal)
							thisObj.handleUpdated.call(this, rolename, oldVal, this[rolename]);
					}
				}
				if(typeof callback == 'function')
					callback(this.retcode);
			};
		const failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callback == 'function')
					callback();
			};
		JafWeb.ajaxRequest({
				url: (this.PDClass || PDClass).getURL(),
				authToken: pdClass.getAuthToken(),
				method: "GET",
				async: (!!callback),
				params: pars.get(),
				disableCaching: true,
				scope: this,
				callerName: pars.getEventName(),
				success: successFn,
				failure: failureFn
			});
		return this.retcode;
	}
	
	/**
	 @function PDObject#setFirstLink
	 @desc Stellt eine Verbindung zwischen diesem und dem übergebenen
		 Objekt in einer Zu-1-Beziehung her. Falls bereits ein Objekt
		 verbunden war, wird dieses vorher getrennt.<br>
		 Für Zu-N-Beziehungen verwenden Sie stattdessen die Funktion
		 <code>connect()</code>.
	 @param {string} rolename Rollenname aus Sicht des Objektes, auf dem die
		 Funktion aufgerufen wird.
	 @param {PDObject} pdo Fachkonzeptobjekt, das über die Beziehung verbunden
		 werden soll.
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @see [connect()]{@link PDObject#connect}
	 */
	setFirstLink(rolename, pdo, callback) {
		//console.log("### PDObject.setFirstLink('"+rolename+"', "+inspect(pdo)+")");
		this.retcode = -1;
		// direkt Server-seitig trennen
		const pdClass = (this.PDClass || PDClass);
		const pars = new JParamPacker(JafWebAPI.PDObject.setFirstLink.eventName, pdClass);
		pars.addPDObjectByIds(this);
		pars.add(JafWebAPI.PDObject.setFirstLink.PAR_relname, rolename);
		pars.add(JafWebAPI.PDObject.setFirstLink.PAR_relOid, (pdo ? pdo.oid : 0));
		pars.add(JafWebAPI.PDObject.setFirstLink.PAR_lockId, (this._lockid || -1));
		const successFn = function(req, options) {
				const resp = new JResponse(req, options, pdClass);
				if(resp.hasFatalError())
					throw "Request got empty or invalid response!";
				if(resp.hasError())
					throw resp.getErrorMessage();
				this.retcode = resp.getInt(JafWebAPI.PROP_retCode, -1);
				if(this.retcode >= 0) {
					const card = resp.getInt(JafWebAPI.PDObject.setFirstLink.PROP_card, 0);
					if(card == 1) {
						//if(JafWeb.isArray(this[rolename]))
						//	console.log("PDObject.setFirstLink() - unexpected type of relation property for this cardinality!");
						const oldVal = this[rolename];
						this[rolename] = pdo;
						////console.log("### PDObject.setFirstLink('" + rolename + "') - Setting mod flag to true");
						this.__mod[rolename] = true;
						if(this[rolename] != oldVal)
							this.handleUpdated.call(this, rolename, oldVal, this[rolename]);
					}
				}
				// Beziehungen werden jetzt generell nicht mehr Client-seitig vorgehalten!
				// Zu-1-Beziehungen aber schon!
				if(typeof callback == 'function')
					callback(this.retcode);
			};
		const failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callback == 'function')
					callback();
			};
		JafWeb.ajaxRequest({
				url: (this.PDClass || PDClass).getURL(),
				authToken: pdClass.getAuthToken(),
				method: "GET",
				async: (!!callback),
				params: pars.get(),
				disableCaching: true,
				scope: this,
				callerName: pars.getEventName(),
				success: successFn,
				failure: failureFn
			});
		if(!callback)
			return this.retcode;
	}
	
	/**
	 @function PDObject#deleteConnected
	 @desc Löscht ein Objekt aus einer Beziehung.
	 @param {string} rolename Rollenname aus Sicht des Objektes, auf dem die
		 Funktion aufgerufen wird.
	 @param {PDObject} pdo Fachkonzeptobjekt, das gelöscht werden soll.
	 @param {number} [transId=0] ID der aktuellen Transaktion. Vgl.
		 [ClientInfo.startTransaction()]{@link ClientInfo#startTransaction}.
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 */
	deleteConnected(rolename, pdo, transId, callback) {
		this.retcode = -1;
		if(!pdo || (!JafWeb.isPDObject(pdo) && !JafWeb.isArray(pdo))) {
			if(typeof callback == 'function')
				callback();
			return;
		}
		let pos = 2;
		let _tid = (this._tid || 0);
		let _callb = null;
		if(arguments.length > pos && (typeof arguments[pos] == 'number')) {
			_tid = arguments[pos];
			pos++;
		}
		if(arguments.length > pos && (typeof arguments[pos] == 'function')) {
			_callb = arguments[pos];
			pos++;
		}
		// TODO: alle in einem Request (in geordneter Reihenfolge)!
		if(JafWeb.isArray(pdo)) {
			for(let i = 0; i < pdo.length; i++) {
				const res = this.deleteConnected(rolename, pdo[i], _tid);
				if(res != 0) {
					if(_callb) {
						_callb(res);
						return;
					}
					return res;
				}
			}
			if(_callb) {
				_callb(0);
				return;
			}
			return 0;
		}
		if(this.isTrans()) {
			if(!pdo.isTrans()) {
				console.warn("PDObject.deleteConnected() - The object to be deleted should be a transaction object!");
				return -1;
			}
			const pdClass = (this.PDClass || PDClass);
			const pars = new JParamPacker(JafWebAPI.PDObject.deleteConnected.eventName, pdClass);
			pars.addPDObjectByIds(this);
			pars.add(JafWebAPI.PDObject.deleteConnected.PAR_relname, rolename);
			pars.add(JafWebAPI.PDObject.deleteConnected.PAR_relOid, (pdo ? pdo.oid : 0));
			pars.add(JafWebAPI.PDObject.deleteConnected.PAR_lockId, (this._lockid || -1));
			if(_tid)
				pars.add(JafWebAPI.PDObject.deleteConnected.PAR_tid, _tid);
			const oldVal = pdo;
			const successFn = function(req, options) {
					const resp = new JResponse(req, options, pdClass);
					if(resp.hasFatalError())
						throw "Request got empty or invalid response!";
					if(resp.hasError())
						throw resp.getErrorMessage();
					this.retcode = resp.getInt(JafWebAPI.PROP_retCode, -1);
					if(!this.retcode) {
						////console.log("### PDObject.deleteConnected('" + rolename + "') - Setting mod flag to true");
						this.__mod[rolename] = true;
						this.handleUpdated.call(this, rolename, oldVal, null);
					}
					if(typeof callback == 'function')
						callback(this.retcode);
				};
			const failureFn = function(response, opts) {
					console.error("Network communication error in " + pars.getEventName() + "!");
					if(typeof callback == 'function')
						callback();
				};
			JafWeb.ajaxRequest({
					url: (this.PDClass || PDClass).getURL(),
					authToken: pdClass.getAuthToken(),
					method: "GET",
					async: (!!callback),
					params: pars.get(),
					disableCaching: true,
					scope: this,
					callerName: pars.getEventName(),
					success: successFn,
					failure: failureFn
				});
			if(!callback)
				return this.retcode;
		}
	}
			
	/**
	 @deprecated
	 @function PDObject#deleteRelatedObject
	 @desc Löscht ein Objekt aus einer Beziehung.
	 @param {string} rolename Rollenname aus Sicht des Objektes, auf dem die
		 Funktion aufgerufen wird.
	 @param {PDObject} pdo Fachkonzeptobjekt, das gelöscht werden soll.
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 */
	deleteRelatedObject(rolename, pdo, callback)	{
		if(JafWeb.isArray(pdo)) {
			// TODO: alle mit einem einzigen Request loeschen
			for(let i = 0; i < pdo.length; i++) {
				//this.disconnect(rolename, pdo[i]);
				(this.PDClass || PDClass).deleteObject(pdo[i]);
			}
		}
		else {
			//this.disconnect(rolename, pdo);
			(this.PDClass || PDClass).deleteObject(pdo, callback);
		}
		////console.log("### PDObject.deleteRelatedObject('" + rolename + "') - Setting mod flag to true");
		this.__mod[rolename] = true;
	}

	/**
	 @function PDObject#disconnect
	 @desc Die Beziehung zu dem übergebenen Objekt auflösen.
	 @param {string} rolename Rollenname aus Sicht des Objektes, auf dem die
		 Funktion aufgerufen wird.
	 @param {PDObject} pdo Fachkonzeptobjekt, zu dem die Verbindung getrennt
		 werden soll.
	 @param {number} [tid] Transaktions-Id
	 @param {number} [lockId] Lock-Id
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 */
	disconnect(rolename, pdo, tid, lockId, callback) {
		this.retcode = -1;
		if(!pdo)
			return -1;
		let pos = 2;
		let _tid = 0;
		let _lockId = -1;
		let _callb = null;
		if(arguments.length > pos && (typeof arguments[pos] == 'number')) {
			_tid = arguments[pos];
			pos++;
		}
		if(arguments.length > pos && (typeof arguments[pos] == 'number')) {
			_lockId = arguments[pos];
			pos++;
		}
		if(arguments.length > pos && (typeof arguments[pos] == 'function')) {
			_callb = arguments[pos];
			pos++;
		}
		//console.log("### PDObject.disconnect("+rolename+", "+inspect(pdo)+")");
		// TODO: alle in einem Request (in geordneter Reihenfolge)!
		if(JafWeb.isArray(pdo)) {
			for(let i = 0; i < pdo.length; i++) {
				const res = this.disconnect(rolename, pdo[i], _tid, _lockId);
				if(res < 0) {
					if(_callb) {
						_callb(res);
						return;
					}
					return res;
				}
			}
			if(_callb) {
				_callb(0);
				return;
			}
			return 0;
		}
		if(!pdo || !JafWeb.isPDObject(pdo))
			return -1;
		// direkt Server-seitig trennen
		const pdClass = (this.PDClass || PDClass);
		const pars = new JParamPacker(JafWebAPI.PDObject.disconnect.eventName, pdClass);
		pars.addPDObjectByIds(this);
		pars.add(JafWebAPI.PDObject.disconnect.PAR_relname, rolename);
		pars.add(JafWebAPI.PDObject.disconnect.PAR_relOid, pdo.oid);
		if(_tid)
			pars.add(JafWebAPI.PDObject.disconnect.PAR_tid, _tid);
		if(_lockId != -1)
			pars.add(JafWebAPI.PDObject.disconnect.PAR_lockId, _lockid);
		const successFn = function(req, options) {
				const resp = new JResponse(req, options, pdClass);
				if(resp.hasFatalError())
					throw "Request got empty or invalid response!";
				if(resp.hasError())
					throw resp.getErrorMessage();
				this.retcode = resp.getInt(JafWebAPI.PROP_retCode, -1);
				////console.log("### PDObject.disconnect('" + rolename + "') - Setting mod flag to true");
				this.__mod[rolename] = true;
				// Beziehungen werden jetzt generell nicht mehr Client-seitig vorgehalten!
				if(typeof _callb == 'function')
					_callb(this.retcode);
			};
		const failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof _callb == 'function')
					_callb();
			};
		JafWeb.ajaxRequest({
				url: (this.PDClass || PDClass).getURL(),
				authToken: pdClass.getAuthToken(),
				method: "GET",
				async: (!!_callb),
				params: pars.get(),
				disableCaching: true,
				scope: this,
				callerName: pars.getEventName(),
				success: successFn,
				failure: failureFn
			});
		if(typeof _callb != 'function')
			return this.retcode;
	}
	
	/**
	 @function PDObject#countObjects
	 @desc Ermittelt die Anzahl der über eine Zu-N-Beziehung verbundenen 
		 Objekte.
	 @param {string} rolename Name der Beziehung aus Sicht des Objekts,
		 auf dem die Funktion aufgerufen wird.
	 @param {string} [sel] Optionales Filterkriterium, um die Menge der
		 gezeigten Objekte einzuschränken.
	 @param {PDObject} [thisPdo] PDObject, das im Filter als this-Objekt
		 verwendet werden kann.
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @return {number} Anzahl der über die Beziehung verbundenen Objekte.
	 @see [PDClass.countObjects()]{@link PDClass#countObjects}
	 */
	countObjects(rolename, sel, thisPdo, callback) {
		this.retcode = 0;
		var pdClass = (this.PDClass || PDClass);
		var pars = new JParamPacker(JafWebAPI.PDObject.countObjects.eventName, pdClass);
		pars.addPDObjectByIds(this);
		pars.add(JafWebAPI.PDObject.countObjects.PAR_relname, rolename);
		if(sel)
		{
			pars.add(JafWebAPI.PDObject.countObjects.PAR_filter, sel);
			if(thisPdo)
			{
				if(JafWeb.isPDObject(thisPdo))
					pars.add(JafWebAPI.PDObject.countObjects.PAR_thisPdo, thisPdo.oid);
				else
					pars.add(JafWebAPI.PDObject.countObjects.PAR_thisPdo, thisPdo);
			}
		}
		var res = -1; // TODO: Fehler-Code fuer PDMeta.getString()
		var successFn = function(req, options)
			{
				var resp = new JResponse(req, options, pdClass);
				if(resp.hasFatalError())
					throw "Request got empty or invalid response!";
				if(resp.hasError())
					throw resp.getErrorMessage();
				res = resp.getInt(JafWebAPI.PDObject.countObjects.PROP_count, -1);
				if(typeof callback == 'function')
					callback(res);
			};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callback == 'function')
					callback();
			};
		JafWeb.ajaxRequest({
				url: (this.PDClass || PDClass).getURL(),
				authToken: pdClass.getAuthToken(),
				method: "POST",
				async: (!!callback),
				params: pars.getPostParams(),
				disableCaching: true,
				scope: this,
				callerName: pars.getEventName(),
				success: successFn,
				failure: failureFn
			});
		if(!callback)
			return res;
	}
	
	/**
	 @function PDObject#countObjectsMulti
	 @desc Ermittelt die Anzahlen der über Zu-N-Beziehungen verbundenen 
		 Objekte für mehrere Beziehungen in einem Aufruf.
	 @param {string[]} rolenames Namen der Beziehungen aus Sicht des Objekts,
		 auf dem die Funktion aufgerufen wird.
	 @param {string[]} sels Filterkriterien zum Einschränken der Objektmenge
		 für die jeweilige Beziehung. Für nicht zu filternde Beziehungen muss ein
		 Leerstring in das Array eingefügt werden.
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @return {number[]} Anzahlen der über die Beziehung verbundenen Objekte.
	 @see [PDObject.countObjects()]{@link PDObject#countObjects}
	 */
	countObjectsMulti(rolenames, sels, callback) {
		this.retcode = 0;
		var pdClass = (this.PDClass || PDClass);
		var pars = new JParamPacker(JafWebAPI.PDObject.countObjectsMulti.eventName, pdClass);
		pars.addPDObjectByIds(this);
		for(var i = 0; i < rolenames.length; i++)
		{
			pars.add(JafWebAPI.PDObject.countObjectsMulti.PAR_relname + i, rolenames[i]);
			pars.add(JafWebAPI.PDObject.countObjectsMulti.PAR_filter + i, (sels && sels.length > i ? sels[i] : ''));
		}
		var res = null;
		var successFn = function(req, options)
			{
				var resp = new JResponse(req, options, pdClass);
				if(resp.hasFatalError())
					throw "Request got empty or invalid response!";
				if(resp.hasError())
					throw resp.getErrorMessage();
				res = resp.getArray(JafWebAPI.PDObject.countObjectsMulti.PROP_count, [], 'number', -1);
				if(typeof callback == 'function')
					callback(res);
			};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callback == 'function')
					callback();
			};
		JafWeb.ajaxRequest({
				url: (this.PDClass || PDClass).getURL(),
				authToken: pdClass.getAuthToken(),
				method: "POST",
				async: (!!callback),
				params: pars.getPostParams(),
				disableCaching: true,
				scope: this,
				callerName: pars.getEventName(),
				success: successFn,
				failure: failureFn
			});
		if(!callback)
			return res;
	}
	
	/**
	 @function PDObject#invalidate
	 @desc Dieses Fachkonzeptobjekt ungültig setzen, damit alle
		 Attribute beim nächsten Zugriff neu geholt werden.
	 */
	invalidate() {
		//console.log("### PDObject.invalidate()");
		this._statusCache = {};
		for(var attr in this)
		{
			if(typeof attr != 'string' || !attr || attr == 'IDENT')
				continue;
			if(attr.length < 4 || attr.substring(0, 4) != '__f_')
				continue;
			if((this[attr] & PDObjectFlags.Available) != 0)
				this[attr] &= ~PDObjectFlags.Available;
		}
	}
	
	/**
	 @function PDObject#getAttribute
	 @desc Den Wert eines Attributes lesen.
	 @param {string} attr Der technische Name des Fachkonzeptattributs.
		Es können auch Traversierungspfade mit über Zu-1-Beziehungen erreichbaren
		Attributen angegeben werden ("to_Offer->Name"). Fehlt dabei das Attribut
		der Zielklasse ("to_Offer"), wird der <i>Object Ident</i> des
		Beziehungsobjekts ermittelt.<br/>
		Statt des Namens kann auch ein Musterausdruck angegeben werden, der anhand
		mindestens eines enthaltenen Prozentzeichens vom Namen unterschieden
		wird; so kann man, wenn eine Klasse <code>Anwender</code> den Vor- und
		Nachnamen in separaten Attributen speichert, den vollständigen Namen
		Abfragen mit <code>obj.getAttribute('%aVorname% %aNachname%')</code>.
		Zu den möglichen Musterausdrücken vgl. [getStatus]{@link PDObject#getStatus}.
	 @param {boolean} [request=false] Wird hier <code>true</code> angegeben, wird
		 der Attributwert auf jeden Fall vom Server aktualisiert. Ansonsten
		 erfolgt ein Server-Request nur, falls das Attribut noch nicht lokal
		 gespeichert ist.
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @return {string} Der Wert des Attributs. Unabhängig vom 
		 modellierten Datentyp handelt es sich hier immer um einen String.
		 Bei Enumeration Types mit Mehrfachauswahl enthält der String
		 alle selektierten Ausprägungen, miteinander verkettet durch
		 Pipe-Zeichen ("|").
	 @see [getAttributes()]{@link PDObject#getAttributes} zum Lesen mehrerer
		 Attribute in einem Aufruf.
	 @see [PDClass.getAttribute()]{@link PDClass#getAttribute}
	 */
	getAttribute(attr, request, callback) {
		//console.log("### PDObject.getAttribute("+attr+")");
		this.retcode = 0;
		if(!attr || attr == "")
			attr = "IDENT";
		if(typeof this[attr] != 'undefined' && JafWeb.isPDObject(this[attr]))
			return this[attr].getAttribute('', request, callback);
		// falls kein Available-Flag, unbedingt neu holen (ausser ObjectIdent)!
		if(attr != "IDENT" && (this['__f_'+attr] == undefined ||
				((this['__f_'+attr] & PDObjectFlags.Available) == 0) &&
				this['__f_'+attr] != PDObjectFlags.RelationNotSet)) // bei Abfragen auf Beziehungen kann das gen. getAttribute() RelationNotSet liefern!
			request = true;
		// TODO: Beziehungen ggf. aufloesen und
		// Beziehungsobjekt(e) noetigenfalls nachladen! 
		// Siehe getFirstLink()
		//attr = attr.replace(/[-\.>]/g, "_");
		var res = "";
		var self = this;
		var retFn = function() {
				// es kann eine Beziehung angegeben sein!
				if(self[attr] != undefined && typeof self[attr] == "object")
				{
					self.retcode |= PDObjectFlags.NoWritePermission;
					if(self[attr] == null)
						return; // Leerstring
					if(JafWeb.isPDObject(self[attr]))
					{
						res = self[attr].getAttribute("");
						return;
					}
					else
						console.warn('PDObject['+self.classname+'].getAttribute('+attr+
									'): Attr. has typeof object but is not instanceof PDObject!');
				}
				else if(self[attr] != undefined)
					res = self[attr];
				
				if(self['__f_'+attr] !== undefined)
					self.retcode = self['__f_'+attr];
				else
				{
					if(self["__perm_"+attr] == "no_write")
						self.retcode |= PDObjectFlags.NoWritePermission;
					// TODO: weitere Flags, z.B. overwritten!
			
					if(self[attr] === undefined)
						// kein Leserecht oder unbekanntes Attribut
						self.retcode |= PDObjectFlags.NoReadPermission;
				}
				// wenn kein Leserecht (NoReadPermission) zurueckkommt, dies bei
				// den ElementPermissions eintragen
				if(self['__perm_' + attr] === undefined)
				{
					self['__perm_' + attr] = 0;
					if((self.retcode & PDObjectFlags.NoReadPermission) == 0)
						self['__perm_' + attr] |= ClientInfo.AttrRead;
					if((self.retcode & PDObjectFlags.NoWritePermission) == 0)
						self['__perm_' + attr] |= ClientInfo.AttrWrite;
				}
				return res;
			};
		
		if(true === request || (this[attr] === undefined &&
				attr != "_allowEdit" &&
				attr != "_allowDelete" &&
				attr != "_lastMsg" &&
				attr != "retcode" &&
				attr != "function" &&
				attr != "classname" &&
				attr != "cid" && attr != "oid" &&
				attr != "oidHi" && attr != "oidLow" && attr != "pid" &&
				attr != "_lockid" && attr != "_realOidLow" &&
				attr != "_isnew" &&
				attr != "_modified" &&
				attr != "_isTrans" &&
				attr != "_deleted" &&
				attr != "_depDelete" &&
				attr != "_connected" &&
				attr != "_disconnected" &&
				attr != "_trav" && attr != "_del" && attr != "_ldd" && attr != "_disc" // interne von PDTable
			))
		{
			var pdClass = (this.PDClass || PDClass);
			var pars = new JParamPacker(JafWebAPI.PDObject.getAttribute.eventName, pdClass);
			pars.addPDObjectByIds(this);
			pars.add(JafWebAPI.PDObject.getAttribute.PAR_attr, (attr == 'IDENT' ? '' : attr));
			var successFn = function(req, options)
				{
					var resp = new JResponse(req, options, pdClass);
					if(resp.hasFatalError())
						throw "Request got empty or invalid response!";
					if(resp.hasError())
						throw resp.getErrorMessage();
					this.retcode = resp.getInt(JafWebAPI.PROP_retCode, -1);
					var v = resp.getString(JafWebAPI.PDObject.getAttribute.PROP_value);
					if(typeof v == 'string')
					{
						this[attr] = v;
						this['__f_'+attr] = resp.getInt(JafWebAPI.PDObject.getAttribute.PROP_flags, 0);
					}
					if(this['__f_'+attr] !== undefined)
						this.retcode = self['__f_'+attr];
					else
					{
						if((this["__perm_"+attr] & ClientInfo.AttrWrite) == 0)
							this.retcode |= PDObjectFlags.NoWritePermission;
						// TODO: weitere Flags, z.B. overwritten!
					
						if(this[attr] === undefined || (this["__perm_"+attr] & ClientInfo.AttrRead) == 0)
							// kein Leserecht oder unbekanntes Attribut
							this.retcode |= PDObjectFlags.NoReadPermission;
					}
					res = retFn();
					if(typeof callback == 'function')
						callback(v);
				};
			var failureFn = function(response, opts) {
					this.retcode = 0;
					if(typeof callback == 'function')
						callback();
				};
			JafWeb.ajaxRequest({
					url: (this.PDClass || PDClass).getURL(),
					authToken: pdClass.getAuthToken(),
					method: "GET",
					async: (!!callback),
					params: pars.get(),
					disableCaching: true,
					scope: this,
					callerName: pars.getEventName(),
					success: successFn,
					failure: failureFn
				});
			if(!callback)
				return this[attr];
			return;
		}
		retFn();
		if(callback)
			callback(res);
		else
			return res;
	}
	
	/**
	 @function PDObject#getAttributes
	 @desc Die Werte mehrerer Attribute in einem Aufruf lesen.
	 @param {string[]} attrs Die technischen Name der Fachkonzeptattribute.
	 @param {boolean} [request=false] Wird hier <code>true</code> angegeben, werden
		 die Attributwerte auf jeden Fall vom Server aktualisiert. Ansonsten
		 erfolgt ein Server-Request nur, falls eines der Attribute noch nicht lokal
		 gespeichert ist.
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @return {Object} JavaScript-Objekt folgender Form:
		 <ul>
			<li><code>values</code>: JavaScript-Objekt mit Properties entsprechend den abgefragten
				Attributnamen. Der ObjectIdent (der über einen leeren Attributnamen abgefragt
				werden kann), ist über den Property-Namen <code>IDENT</code> zugreifbar.
			<li><code>flags</code>: JavaScript-Objekt mit den gleichen Properties, die die
				entsprechenden Attribut-Flags (vom Typ Number) enthalten. Siehe
				<code>PDObjectFlags</code>.
		 </ul>
	 @see [getAttribute()]{@link PDObject#getAttribute}
	 */
	getAttributes(attrs, request, callback) {
		//console.log("### PDObject.getAttributes("+inspect(attrs, true)+")");
		this.retcode = 0;
		// TODO: Beziehungen ggf. aufloesen und
		// Beziehungsobjekt(e) noetigenfalls nachladen! 
		// Siehe getFirstLink()
		//attr = attr.replace(/[-\.>]/g, "_");
		var result = {
				values: { },
				flags: { }
			};
		var doRequest = (true === request);
		var self = this;
		for(var i=0; i < attrs.length; i++)
		{
			if(!attrs[i])
				attrs[i] = 'IDENT';
			if(attrs[i] != 'IDENT' && (self[attrs[i]] == undefined || self['__f_'+attrs[i]] == undefined))
				doRequest = true;
			if(doRequest === true)
				continue;
			// es kann eine Beziehung angegeben sein!
			if(self[attrs[i]] != undefined && typeof self[attrs[i]] == "object")
			{
				result.flags[attrs[i]] = PDObjectFlags.NoWritePermission;
				if(self[attrs[i]] === null)
					result.values[attrs[i]] = ''; // Leerstring
				else if(JafWeb.isPDObject(self[attrs[i]]))
					result.values[attrs[i]] = self[attrs[i]].getAttribute("");
				else
					console.warn('PDObject['+self.classname+'].getAttributes('+attrs[i]+
								'): Attr. has typeof object but is not instanceof PDObject!');
			}
			else
			{
				result.values[attrs[i]] = (self[attrs[i]] || '');
				result.flags[attrs[i]] = self['__f_'+attrs[i]];
				
				// wenn kein Leserecht (NoReadPermission) zurueckkommt, dies bei
				// den ElementPermissions eintragen
				if(self['__perm_' + attrs[i]] === undefined)
				{
					self['__perm_' + attrs[i]] = 0;
					if((self.retcode & PDObjectFlags.NoReadPermission) == 0)
						self['__perm_' + attrs[i]] |= ClientInfo.AttrRead;
					if((self.retcode & PDObjectFlags.NoWritePermission) == 0)
						self['__perm_' + attrs[i]] |= ClientInfo.AttrWrite;
				}
			}
		}
		if(doRequest)
		{
			var pdClass = (this.PDClass || PDClass);
			var pars = new JParamPacker(JafWebAPI.PDObject.getAttributes.eventName, pdClass);
			pars.addPDObjectByIds(this);
			var cnt = 0;
			for(var i=0; i < attrs.length; i++)
			{
				if(attrs[i] != "_allowEdit" &&
						attrs[i] != "_allowDelete" &&
						attrs[i] != "_lastMsg" &&
						attrs[i] != "retcode" &&
						attrs[i] != "function" &&
						attrs[i] != "classname" &&
						attrs[i] != "cid" && attrs[i] != "oid" &&
						attrs[i] != "oidHi" && attrs[i] != "oidLow" && attrs[i] != "pid" &&
						attrs[i] != "_lockid" && attrs[i] != "_realOidLow" &&
						attrs[i] != "_isnew" &&
						attrs[i] != "_modified" &&
						attrs[i] != "_isTrans" &&
						attrs[i] != "_deleted" &&
						attrs[i] != "_depDelete" &&
						attrs[i] != "_connected" &&
						attrs[i] != "_disconnected" &&
						attrs[i] != "_trav" && attrs[i] != "_del" && attrs[i]!= "_ldd" && attrs[i] != "_disc") // interne von PDTable
				{
					pars.add(JafWebAPI.PDObject.getAttributes.PAR_attr + cnt, (attrs[i]=='IDENT' ? '' : attrs[i]));
					cnt++;
				}
			}
			var successFn = function(req, options)
				{
					var resp = new JResponse(req, options, pdClass);
					if(resp.hasFatalError())
						throw "Request got empty or invalid response!";
					if(resp.hasError())
						throw resp.getErrorMessage();
					this.retcode = resp.getInt(JafWebAPI.PROP_retCode, -1);
					result.values = resp.get(JafWebAPI.PDObject.getAttributes.PROP_values);
					result.flags = resp.get(JafWebAPI.PDObject.getAttributes.PROP_flags);
					for(var attr in result.values)
					{
						if(attr === '')
							self['IDENT'] = result.values[attr];
						else
							self[attr] = result.values[attr];
						self['__f_'+attr] = result.flags[attr];
					}
					if(result.values[''] && !result.values['IDENT'])
						result.values['IDENT'] = result.values[''];
					if(typeof callback == 'function')
						callback(result);
				};
			var failureFn = function(response, opts) {
					console.error("Network communication error in " + pars.getEventName() + "!");
					if(typeof callback == 'function')
						callback();
				};
			JafWeb.ajaxRequest({
					url: (this.PDClass || PDClass).getURL(),
					authToken: pdClass.getAuthToken(),
					method: "POST",
					async: (!!callback),
					params: pars.getPostParams(),
					disableCaching: true,
					scope: this,
					callerName: pars.getEventName(),
					success: successFn,
					failure: failureFn
				});
		}
		else if(callback)
		{
			callback(result);
			return;
		}
		return result;
	}
	
	/**
	 @function PDObject#setAttribute
	 @desc Den Wert eines Fachkonzeptattributes setzen.
	 @param {string} attr Der technische Name des Attributs.
	 @param {string} val Der Wert, auf den das Attribut
		 gesetzt werden soll.
	 @param {boolean} [onServer=false] Falls hier <code>true</code> angegeben wird, erfolgt
		 eine unmittelbare Synchronisierung des Objekts auf der Server-Seite. Das kann
		 z.B. wichtig sein, wenn der Überschreiben-Status eines abgeleitet-überschreibbaren
		 Attributes geändert und anschließend der neu berechnete Wert geholt werden soll.
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @see [PDClass.setAttribute()]{@link PDClass#setAttribute}
	 */
	setAttribute(attr, val, onServer, callback) {
		//console.log("### PDObject.setAttribute('"+attr+"', '"+val+"', "+onServer+")");
		this.retcode = 0;
		var onS = false;
		var fCallb = null;
		var pos = 2;
		if(pos < arguments.length && (typeof arguments[pos] == 'boolean'))
		{
			onS = (arguments[pos] === true);
			pos++;
		}
		if(pos < arguments.length && (typeof arguments[pos] == 'function'))
		{
			fCallb = arguments[pos];
			pos++;
		}
		// ist das Attribut ueberschreibbar? Dann muessen wir bei Wegnahme
		// des Ueberschriebenstatus den Wert auf dem Server setzen und
		// neu holen
		if(!onS && this['__f_'+attr])
		{
			// wenn Ueberschrieben und Wert leer: Status auf dem Server wegnehmen
			if(this['__f_'+attr] & PDObjectFlags.Overwritten && (val ? false : true))
				onS = true;
			// wenn nicht Ueberschrieben und Wert nicht leer: Status setzen
			else if(this['__f_'+attr] & PDObjectFlags.NotOverwritten && (val ? true : false))
			{
				this['__f_'+attr] &= ~PDObjectFlags.NotOverwritten;
				this['__f_'+attr] |= PDObjectFlags.Overwritten;
			}
		}
		var oldVal = this[attr];
		if(attr)
		{
			// TODO: Beziehungen ggf. aufloesen und
			// Beziehungsobjekt(e) noetigenfalls suchen/anlegen!
			// Siehe getFirstLink()
			//attr = attr.replace(/[-\.>]/g, "_");
			this[attr] = val;
			// wenn mit Postfix ".Tech" gesetzt wird, muss das
			// entspr. Attribut ohne Erweiterung unwirksam gemacht
			// werden, sonst koennte es den gesetzten Wert wieder
			// ueberschreiben!
			if(attr.endsWith('.Tech')) {
				const attrBase = attr.substring(0, attr.length - 5);
				if(this[attrBase])
					delete this[attrBase];
			}
			////console.log("### PDObject.setAttribute('" + attr + "') - Setting mod flag to true");
			this.__mod[attr] = true;
			////console.log("### PDObject.setAttribute('" + attr + "', '" + val + "') - __mod:", this.__mod);
			this._modified = true;
			if(oldVal != val)
				this.handleUpdated.call(this, attr, oldVal, val);
		}
		// Status-Cache noetigenfalls zuruecksetzen
		if(this._modified && this._statusCache)
		{
			for(var pattern in this._statusCache)
			{
				if(!this._statusCache.hasOwnProperty(pattern))
					continue;
				if(pattern.indexOf('%a' + attr + '%') >= 0)
				{
					// rein Client-seitig aktualisieren?
					var updateStatusStringOnClient = true;
					if(updateStatusStringOnClient)
					{
						this._statusCache[pattern] = pattern;
						var found = pattern.match(/%a[A-Za-z0-9_\.]+%/g);
						for(var j = 0; found && j < found.length; j++)
						{
							this._statusCache[pattern] = this._statusCache[pattern].replace(found[j],
									this.getAttribute(found[j].substring(2, found[j].length - 1)));
							////console.log("### updated in cache: '" + this._statusCache[pattern] + "'");
						}
					}
					else
					{
						////console.log("### remove pattern '" + pattern + "' because of attr. " + attr);
						delete this._statusCache[pattern];
						// dann muss das Attribut aber auch auf dem Server gesetzt werden,
						// damit der Status-String neu ermittelt werden kann!
						onS = true;
					}
				}
			}
		}
		if(!this.isTrans() || onS)
		{
			var pdClass = (this.PDClass || PDClass);
			var pars = new JParamPacker(JafWebAPI.PDObject.setAttribute.eventName, pdClass);
			pars.addPDObjectByIds(this);
			pars.add(JafWebAPI.PDObject.setAttribute.PAR_attr, attr);
			pars.add(JafWebAPI.PDObject.setAttribute.PAR_value, val);
			var res = -1; // TODO: Fehler-Code fuer PDMeta.getString()
			var successFn = function(req, options)
				{
					if(!attr || attr == "")
						attr = "IDENT";
					var resp = new JResponse(req, options, pdClass);
					if(resp.hasFatalError())
						throw "Request got empty or invalid response!";
					if(resp.hasError())
					{
						// bei unbekannten Attributen schreibt der Server nur "unknown attribute" in die Fehleremeldung
						var msg = (resp.getErrorMessage() || '');
						msg = msg.replace('unknown attribute', attr);
						//throw msg;
						res = -1;
						this.retcode = res;
						this._lastMsg = msg;
						if(typeof fCallb == 'function')
							fCallb(msg);
						return;
					}
					res = resp.getInt(JafWebAPI.PROP_retCode, -1);
					// der tatsaechliche neue Wert wird zurueckgegeben,
					// weil er von dem gesetzten abweichen kann, z.B. bei
					// abgeleitet ueberschreibbaren Attributen!
					this[attr] = resp.getString(JafWebAPI.PDObject.setAttribute.PROP_value);
					this['__f_'+attr] = resp.getInt(JafWebAPI.PDObject.setAttribute.PROP_flags, 0);
					if(oldVal != this[attr])
					{
						this.handleUpdated.call(this, attr, oldVal, this[attr]);
						////console.log("### PDObject.setAttribute('" + attr + "') - Setting mod flag to false");
						this.__mod[attr] = false;
					}
					if(typeof fCallb == 'function')
						fCallb();
				};
			var failureFn = function(response, opts) {
					if(typeof fCallb == 'function')
						fCallb();
				};
			JafWeb.ajaxRequest({
					url: (this.PDClass || PDClass).getURL(),
					authToken: pdClass.getAuthToken(),
					method: "POST",
					async: (!!fCallb),
					params: pars.getPostParams(),
					disableCaching: true,
					scope: this,
					callerName: pars.getEventName(),
					success: successFn,
					failure: failureFn
				});
			return res;
		}
	}
	
	/**
	 @function PDObject#removeAttribute
	 @desc Ein Attribut aus dem lokalen Objekt entfernen.
		 Das verhinddert, dass es mit dem Objekt zum Server geschickt
		 wird.
		 Beim nächsten Zugriff auf das Attribute mit
		 [getAttribute()]{@link PDObject#getAttribute} würde es neu
		 vom Server geholt und wieder in das lokale Objekt eingefügt!
		 Ein Aufruf von [setAttribute()]{@link PDObject#setAttribute}
		 würde das Atribut wieder in das lokale Objekt eintragen.
	 @param {string} attr Der technische Name des Attributs.
	 */
	removeAttribute(attr) {
		if(this[attr] !== undefined)
			delete this[attr];
	}
	
	/**
	 @function PDObject#setAttributes
	 @desc Die Attribute des Objekts mit den Werten des übergebenen
		 Objekts belegen.
	 @param {Mixed} pdo {@link PDObject}, dessen Werte übernommen werden
		 sollen. Beide Objekte müssen von der selben Klasse sein!<br/>
		 Anstelle eines fachlichen Objekts kann auch ein einfaches
		 JavaScript-Objekt angegeben werden (wie es z.B. von
		 [getAttributes()]{@link PDObject#getAttributes} zurückgegeben
		 wird). Dessen Properties werden dann als Attributnamen interpretiert
		 und die Attribute auf dessen Property-Werte gesetzt.<br/>
		 Ebenso kann hier ein einfaches String-Array mit den Namen der zu
		 setzenden Attribute angegeben werden. In diesem Fall muss auch der
		 zweite Parameter <code>values</code> mit den Attributwerten
		 angegeben werden:
	 @param {String[]} [values] Wenn als erster Parameter nicht ein
		 Objekt, sondern ein Array angegeben wird, muss als zweiter Parameter
		 ein Array von selber Länge mit den Attributwerten angegeben werden.
	 @note Falls ein {@link PDObject} als <code>pdo</code> übergeben wird,
		 erfolgt ein Server-Request und ruft dort <code>PDObject::setAttributes()</code>
		 auf. Diese kopiert ale Atribute (außer Serials), aber keine Beziehungen.
		 Dieses Verhalten können Sie jedoch ändern, indem Sie diese Methode im
		 C++-Code für eine konkrete Klasse überschreiben.
	 */
	setAttributes(pdo, values, callback) {
		if(!pdo)
			return;
		var otherObj = null;
		var attrs = null;
		var vals = null;
		var callb = null;
		var pos = 0;
		if(pos < arguments.length && JafWeb.isPDObject(arguments[pos]))
		{
			otherObj = arguments[pos];
			pos++;
		}
		else if(pos < arguments.length && JafWeb.isArray(typeof arguments[pos]))
		{
			// nun muss auch der naechste ein Array sein!
			if(pos >= arguments.length || !JafWeb.isArray(typeof arguments[pos + 1]))
			{
				this._lastMsg = "setAttributes: when the first parameter is an array, the second needs to be an array too!";
				throw this._lastMsg;
			}
			vals = arguments[pos];
			vals = arguments[pos + 1];
			pos += 2;
		}
		if(pos < arguments.length && (typeof arguments[pos] != 'function'))
		{
			vals = arguments[pos];
			pos++;
		}
		if(pos < arguments.length && (typeof arguments[pos] == 'function'))
		{
			callb = arguments[pos];
			pos++;
		}
		if(attrs && vals)
		{
			this.retcode = 0;
			// wir akzeptieren auch zwei Arrays - eines mit den
			// Attributnamen und eines mit den Werten
			for(var i = 0; i < attrs.length && i < vals.length; i++)
			{
				var f = this.setAttribute(attrs[i], vals[i]);
				this._modified = true;
				if(f != 0)
					this.retcode = f;
			}
			return this.retcode;
		}
		else if(otherObj)
		{
			// auf dem Server kopieren!
			var pdClass = (this.PDClass || PDClass);
			var pdo = this;
			var pars = new JParamPacker(JafWebAPI.PDObject.setAttributes.eventName, pdClass);
			pars.addPDObjectByIds(this);
			pars.add(JafWebAPI.PDObject.setAttributes.PAR_other, otherObj.oid);
			var successFn = function(req, options)
				{
					var resp = new JResponse(req, options, pdClass);
					if(resp.hasFatalError())
						throw "Request got empty or invalid response!";
					if(resp.hasError())
						throw resp.getErrorMessage();
					pdo = resp.getPDObject(JafWebAPI.PDObject.setAttributes.PROP_obj, null);
					pdClass.retcode = resp.getInt(JafWebAPI.PROP_retCode, -1);
					if(typeof callb == 'function')
						callb();
				};
			var failureFn = function(response, opts) {
					console.error("Network communication error in " + pars.getEventName() + "!");
					if(typeof callb == 'function')
						callb();
				};
			JafWeb.ajaxRequest({
					url: (this.PDClass || PDClass).getURL(),
					authToken: pdClass.getAuthToken(),
					method: "GET",
					async: (!!callback),
					params: pars.get(),
					disableCaching: true,
					scope: self,
					callerName: pars.getEventName(),
					success: successFn,
					failure: failureFn
				});
			return;
		}
		else
		{
			this.retcode = 0;
			// wir akzeptieren auch ein nicht-fachliches
			// JavaScript-Objekt, wie es von getAttributes().values
			// ermittelt wird:
			for(var p in pdo)
			{
				if(!pdo.hasOwnProperty(p))
					continue;
				var f = this.setAttribute(p, pdo[p]);
				this._modified = true;
				if(f != 0)
					this.retcode = f;
			}
			return this.retcode;
		}
		// wir akzeptieren auf der rechten Seite auch Oberklassen
		var rhsCls = pdo.classname;
		var rhsIsSuperOrSameCls = (rhsCls == this.classname);
		while(!rhsIsSuperOrSameCls)
		{
			rhsCls = PDMeta.getSuperClass(rhsCls);
			if(!rhsCls)
				break;
			if(rhsCls == this.classname)
				rhsIsSuperOrSameCls = true;
		}
		if(!rhsIsSuperOrSameCls)
			return;
		for(var attr in this)
		{
			// diese duerfen nicht kopiert werden
			if(attr == "_allowEdit"
					|| attr == "_allowDelete"
					|| attr == "_lastMsg"
					|| attr == "retcode"
					|| attr == "function"
					|| attr == "classname"
					|| attr == "cid" || attr == "oid"
					|| attr == "oidHi" || attr == "oidLow" || attr == "pid"
					|| attr == "_lockid" || attr == "_realOidLow"
					|| attr == "_tid"
					|| attr == "_statusCache"
					|| attr == "_md5"
					|| attr == "_pdClass"
					|| attr == "_isnew"
					|| attr == "_modified" || attr == "_ismodified"
					|| attr == "_isTrans"
					|| attr == "_deleted"
					|| attr == "_depDelete"
					|| attr == "_connected"
					|| attr == "_disconnected"
					|| attr == "PDClass")
				continue;
			if(!this.hasOwnProperty(attr) ||
					(typeof this[attr] == 'function') ||
					attr.substring(0, 4) == '__f_')
				continue;
			var tmp = pdo.getAttribute(attr);
			var flags = pdo.retcode;
			// TODO: Serials ausnehmen
			var tmpRetcode = 0;
			if((flags & PDObjectFlags.Available)
					&& !(flags & PDObjectFlags.Key)
					&& !(flags & PDObjectFlags.ReadOnly))
			{
				var f = this.setAttribute(attr, tmp);
				////console.log("### PDObject.setAttributes() - Setting mod flag for '" + attr + "' to true");
				this.__mod[attr] = true;
				this._modified = true;
				if(f != 0)
					tmpRetcode = f;
			}
			this.retcode = tmpRetcode;
		}
		return this.retcode;
	}

	/**
	 @function PDObject#copy
	 @desc Ein {@link PDObject} in dieses {@link PDObject} kopieren. Es werden
		 die Attributwerte des übergebenen Objekts in dieses übertragen.
	 @param {PDObject} pdo Objekt, das kopiert werden soll.
		 Beide Objekte müssen von der selben Klasse sein!
	 */
	copy(pdo) {
		this.setAttributes(pdo);
	}
	
	/**
	 @function PDObject#getFirstLink
	 @desc Gibt ein über die angegebene Beziehung verbundenes
		 <code>PDObject</code> zurück. Normalerweise handelt es sich dabei um
		 eine Zu-1-Beziehung, d.h. es wird das verbundene Objekt ermittelt oder
		 <code>null</code>, wenn keines verbunden ist.<br/>
		 Die Funktion kann jedoch auch auf Zu-N-Beziehungen angewendet werden.
		 Dann wird das erste Objekt in der Beziehung ermittelt, oder, falls
		 Filter und/oder Sortierung angegeben sind, das erste Objekt aus der
		 entsprecehnden Ergebnismenge. So lässt sich z.B. aus der nach einem
		 Datumsattribut sortierten Menge der Beziehungsobjekte das jüngste oder
		 älteste ermitteln.
	 @param {string} rolename Name der Beziehung.
	 @param {boolean} [request=false] Wird hier <code>true</code> angegeben, wird
		 das Beziehungsobjekt auf jeden Fall vom Server aktualisiert. Ansonsten
		 erfolgt ein Server-Request nur, falls die Beziehung noch nicht lokal
		 vorliegt.
	 @param {string} [filter] JANUS-Filterausdruck zur Einschränkung der
		 Ergebnismenge aus einer Zu-N-Beziehung. Erzeugt der Filter kein eindeutiges
		 Ergebnis, so wird das erste Objekt der Ergebnismenge ermittelt - siehe
		 nächster Parameter, um die Reihenfolge zu bestimmen.
	 @param {string} [sort] Sortierausdruck zur Verwendung auf einer
		 Zu-N-Beziehung.
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @return {PDObject} Das Objekt oder <code>null</code>, wenn
		 keins verbunden ist.
	 */
	getFirstLink(rolename, request, filter, sort, callback) {
		//console.log("### PDObject.getFirstLink('"+rolename+"')");
		var self = this;
		var _req = false;
		var _filt = '';
		var _sort = '';
		var _cb = null;
		var pos = 1;
		if(pos < arguments.length && (typeof arguments[pos] == 'boolean'))
		{
			_req = arguments[pos];
			pos++;
		}
		if(pos < arguments.length && (typeof arguments[pos] == 'string'))
		{
			_filt = arguments[pos];
			pos++;
		}
		if(pos < arguments.length && (typeof arguments[pos] == 'string'))
		{
			_sort = arguments[pos];
			pos++;
		}
		if(pos < arguments.length && (typeof arguments[pos] == 'function'))
		{
			_cb = arguments[pos];
			pos++;
		}
		var pdClass = (this.PDClass || PDClass);
		let toN = false;
		// bei eingebetteten Typen muessen wir auf dem Typen abfragen!
		const pdMeta = (pdClass.PDMeta || PDMeta);
		const pt = rolename.indexOf('.');
		if(pt > 0) {
			const struct = pdMeta.getType(this.classname, rolename.substring(0, pt));
			// fuer einseitige Beziehungen gibt es keine Kard.-Angabe! Bsp. privacy:
			// ExtDL.Adresse.to_Land (Structure AdresseBLT)
			if(pdMeta.getType(struct, rolename.substring(pt + 1)) == 'OID')
				toN = false;
			else
				toN = (pdMeta.getMaxCard(struct, rolename.substring(pt + 1)) != 1);
		}
		else {
			if(pdMeta.getType(this.classname, rolename) == 'OID')
				toN = false;
			else
				toN = (pdMeta.getMaxCard(this.classname, rolename) != 1);
		}
		this.retcode = 0;
		if(toN || true === _req || self[rolename] === undefined ||
				(self[rolename] !== null && !JafWeb.isPDObject(self[rolename]))) {
			//console.log("### PDObject.getFirstLink('"+rolename+"') - Request!");
			// vom Server PDObject holen
			var pars = new JParamPacker(JafWebAPI.PDObject.getFirstLink.eventName, pdClass);
			pars.addPDObjectByIds(self);
			pars.add(JafWebAPI.PDObject.getFirstLink.PAR_relname, rolename);
			if(toN)
			{
				if(_filt)
					pars.add(JafWebAPI.PDObject.getFirstLink.PAR_filter, _filt);
				if(_sort)
					pars.add(JafWebAPI.PDObject.getFirstLink.PAR_sort, _sort);
			}
			var result = { };
			var successFn = function(req, options)
				{
					var resp = new JResponse(req, options, pdClass);
					if(resp.hasFatalError())
						throw "Request got empty or invalid response!";
					if(resp.hasError())
						throw resp.getErrorMessage();
					result.obj = resp.getPDObject(JafWebAPI.PDObject.getFirstLink.PROP_relObj);
					result.flags = resp.getInt(JafWebAPI.PDObject.getFirstLink.PROP_flags);
					if(!toN)
					{
						self[rolename] = result.obj;
						self['__f_'+rolename] = (result['flags'] || 0);
						self['__perm_'+rolename] = (result['flags'] || 0);
					}
					if(_cb)
						_cb((self[rolename] || null));
				};
			var failureFn = function(response, opts) {
					console.error("Network communication error in " + pars.getEventName() + "!");
					if(_cb)
						_cb();
				};
			JafWeb.ajaxRequest({
					url: (this.PDClass || PDClass).getURL(),
					authToken: pdClass.getAuthToken(),
					method: "GET",
					async: (!!_cb),
					params: pars.get(),
					disableCaching: true,
					scope: self,
					callerName: pars.getEventName(),
					success: successFn,
					failure: failureFn
				});
		}
		if(!_cb)
		{
			if(toN)
				return (result.obj || null);
			return (self[rolename] || null);
		}
	}

	/**
	 @function PDObject#getConnectedObjects
	 @desc Holt die über eine Beziehung verbundenen Fachkonzeptobjekte.
	 @param {string} rolename Rollenname der Beziehung.
	 @param {boolean} [infoOnly=false] <code>true</code> zeigt an, dass
		 statt der PDObjects nur die <i>Object Idents</i> geholt werden sollen.
	 @param {string[]} [attrs] Wird dieser Parameter angegeben, werden
		 statt der PDObjects nur die angegebenen Attributwerte geholt.
	 @param {Function} [callback] JavaScript-Funktion, die aufgerufen wird,
		 wenn der Request zurückkommt. Die Funktion
		 wird mit dem aktuellen <code>PDObject</code> als <code>this</code> und dem
		 Ergebnis-Array als Parameter aufgerufen.
	 @return {PDObject[]} Falls keine <code>callback</code>-Funktion angegeben wurde,
		 wird ein Array mit den Beziehungsobjekten zurückgegeben. Ist ein Fehler
		 aufgetreten, z.B. weil die Beziehung nicht existiert, so wird <code>null</code>
		 zurückgegeben.
	 @see [getExtent()]{@link PDObject#getExtent}.
	 */
	getConnectedObjects(rolename, infoOnly, attrs, callback) {
		var callb1 = callback;
		var callb2 = null;
		if(typeof callb1 == 'function')
			callb2 = function(res) { if(res && res.rows) callb1(res.rows); else callb1(null); };
		var result = this.getExtent(rolename, '', '', null, 0, -1, callb2, infoOnly, attrs);
		// wenn die PDObjects geholt wurden, Beziehung auch lokal fuellen
		if(!result || !result.rows)
			return null;
		if(!infoOnly && attrs == null)
			this[rolename] = result.rows;
		if(!callb2)
			return result.rows;
	}

	/**
	 @function PDObject#foreachRelationObject
	 @desc Eine Funktion für jedes über eine Zu-N-Beziehung verbundene
		 Objekt ausführen.
	 @param {String} relname Name der Zu-N-Beziehung.
	 @param {String} [filter] JANUS-Filterausdruck zur Einschränkung der
		 Ergebnismenge.
	 @param {String} [sort] JANUS-Sortierausdruck zur Festlegung der Reihenfolge.
	 @param {PDObject} [thisPdo] Objekt, das im Filterausdruck als "this"
		 referenziert werden kann.
	 @param {boolean} [asynchron=true] Legt fest, ob die Objekte asynchron
		 geholt werden sollen.
	 @param {Function} doFunction Funktion, die für jedes gefundene Objekt
		 ausgeführt wird. Diese bekommt beim Aufruf folgende Parameter übergeben:
		 <ul>
			 <li><code>count</code> (int): Position des Objekts in der Ergebnismenge
			 (0-basiert).</li>
			 <li><code>total</code> (int): Gesamtzahl der Objekte in der Ergebnismenge.</li>
		 </ul>
		 Das aktuell bearbeitete Objekt ist in der Funktion als <code>this</code>
		 referenzierbar. Wenn Sie ein außerhalb der Funktion bereits definiertes
		 <code>this</code> innerhalb der Funktion benutzen möchten, müssen Sie es
		 vor der Funktionsdefinition einer eigenen Variablen zuweisen und dann diese
		 benutzen.<br/>
		 Wenn Sie in der Funktion <code>false</code> zurückgeben, wird die Bearbeitung
		 abgebrochen.
	 @see {@link PDClass.foreachPDObject}
	 */
	foreachRelationObject(relname, filter, sort, thisPdo, asynchron, doFunction) {
		var pdClass = (this.PDClass || PDClass);
		pdClass.foreachPDObject(this, relname, filter, sort, thisPdo, asynchron, doFunction);
	}

	// Test:
	// PDClass.ptr("Customer",192).getConnectedObjects("to_Offer")
	/**
	 @function PDObject#getExtent
	 @desc Gibt ein Array der über die angegebene Zu-N-Beziehung
		 verbundenen PDObjects zurück.
	 @param {string} rolename Rollenname der Beziehung.
	 @param {string} [filter] Filter, der die Ergebnismenge einschränkt.
	 @param {string} [sort] Sortierkriterium, nach dem die Ergebnismenge 
		 ausgegeben werden soll.
	 @param {PDObject} [thisPdo] <code>PDObject</code>, das als <code>this</code>-Objekt
		 in dem Filterausdruck referenziert werden kann.
	 @param {number} [blockSize=0] Blockgröße für virtualisierte Darstellung. Es werden
		 dann immer nur der mit der <code>blockNo</code> angegebene Objektbereich
		 geholt.
	 @param {number} [blockNo=0] Nummer (0-basiert) des Blocks der geholt werden soll.
	 @param {Function} [callbackFn] Hier kann eine JavaScript-Funktion angegeben
		 werden, die ausgeführt werden soll, wenn der Request zurückkommt.
		 In diesem Fall wird der Request asynchron ausgeführt und anschließend die
		 hier übergebene Funktion mit dem als Rückgabe beschriebenen Objekt sowie dem
		 aktuellen {@link PDObject} als <code>this</code> aufgerufen.
		 Ist dieser Parameter dagegen nicht angegeben, wird der Request dagegen synchron
		 ausgeführt und das Ergebnis direkt zurückgegeben.
	 @param {boolean} [infoOnly=false] <code>true</code> zeigt an, dass
		 statt der PDObjects nur die Object Idents geholt werden sollen.
	 @param {string[]} [attrs] Wird dieser Parameter angegeben, werden
		 statt der PDObjects nur die angegebenen Attributwerte geholt.
	 @return {Object} Bei synchroner Ausführung ein Objekt mit folgenden Properties:
		 <ul>
			<li><code>blockSize</code> (number): Gibt die aktuelle Blockgröße an, wenn das Ergebnis
				virtualisiert, d.h. blockweise ausgegeben wird. Falls nicht blockweise ausgegeben wird,
				lautet der Wert <code>0</code>.
			<li><code>blockNo</code> (number): Gibt in der virtualisierten Ausgabe den (0-basierten)
				Index des aktuellen Blocks an.
			<li><code>total</code> (number): Gibt die Gesamtzahl der verbundenen Objekte an. Durch die
				Virtualisierung kann diese Zahl von der Anzahl der hier dargestellten Objekte
				(<code>rows.length</code>) abweichen.
			<li><code>rows</code> ([PDObject[]]{@link PDObject}): Die in diesem Block dargestellten
				Fachkonzeptobjekte.
				Falls kein Objekt verbunden ist, ist das Array leer.
			<li><code>retCode</code> (number): Fehler-Code.
			<li><code>errorMessage</code> (string): Fehlertext, falls <code>retCode</code> ungleich 0 ist.
		 </ul>
	 @param {boolean} [asArray=false] Statt der PDObjects nur die Attributwerte holen?
	 */
	getExtent(rolename, filter, sort, thisPdo, blockSize, blockNo, callback, infoOnly, attrs, asArray) {
		this.retcode = 0;
		var sFilter = "";
		var sSort = "";
		var thisObject = null;
		var bInfoOnly = false;
		var fCallback = null;
		var aAttrs = null;
		var blSize = 0;
		var blNo = 0;
		var asArr = false;
		var pos = 1;
		if(arguments.length > pos && typeof arguments[pos] == "string")
		{
			sFilter = arguments[pos];
			pos++;
		}
		if(arguments.length > pos && typeof arguments[pos] == "string")
		{
			sSort = arguments[pos];
			pos++;
		}
		if(arguments.length > pos && (arguments[pos] === null || JafWeb.isPDObject(arguments[pos])))
		{
			if(arguments[pos] > 0)
				thisObject = arguments[pos];
			pos++;
		}
		if(arguments.length > pos && typeof arguments[pos] == "number")
		{
			blSize = arguments[pos];
			pos++;
		}
		if(arguments.length > pos && typeof arguments[pos] == "number")
		{
			if(blSize > 0)
				blNo = arguments[pos];
			pos++;
		}
		if(arguments.length > pos && (arguments[pos] == null || typeof arguments[pos] == "function"))
		{
			fCallback = arguments[pos];
			pos++;
		}
		if(arguments.length > pos && typeof arguments[pos] == "boolean")
		{
			bInfoOnly = arguments[pos];
			pos++;
		}
		if(arguments.length > pos && arguments[pos] && (typeof arguments[pos] == "object" ||
				typeof arguments[pos] == "string"))
		{
			aAttrs = arguments[pos];
			pos++;
		}
		if(arguments.length > pos && typeof arguments[pos] == "boolean")
		{
			asArr = (arguments[pos] === true ? 'typed' : false);
			pos++;
		}
		// wenn die PDObjects noch nicht lokal vorliegen, diese 
		// erst holen!
		var pdClass = (this.PDClass || PDClass);
		var pars = new JParamPacker(JafWebAPI.PDObject.getExtent.eventName, pdClass);
		pars.addPDObjectByIds(this);
		pars.add(JafWebAPI.PDObject.getExtent.PAR_relname, rolename);
		pars.add(JafWebAPI.PDObject.getExtent.PAR_filter, sFilter);
		pars.add(JafWebAPI.PDObject.getExtent.PAR_sort, sSort);
		if(blSize > 0)
		{
			pars.add(JafWebAPI.PDObject.getExtent.PAR_blockSize, blSize);
			pars.add(JafWebAPI.PDObject.getExtent.PAR_blockNo, blNo);
		}
		// TODO: thisObject
		if(bInfoOnly == true)
		{
			pars.add(JafWebAPI.PDObject.getExtent.PAR_infoOnly, true);
			if(aAttrs && typeof aAttrs == "string")
				pars.add(JafWebAPI.PDObject.getExtent.PAR_info, aAttrs);
		}
		else if(aAttrs)
		{
			for(var i=0; i<aAttrs.length; i++)
				pars.add(JafWebAPI.PDObject.getExtent.PAR_attr + i, aAttrs[i]);
		}
		if(asArr)
			pars.add('asArray', asArr); // TODO: Den Namen in die API
		// TODO: Menge (Blockgroesse) begrenzen?
		var result = new Object();
		var successFn = function(req, options)
				{
					var resp = new JResponse(req, options, pdClass);
					if(resp.hasFatalError())
					{
						result.retCode = -1;
						throw "Request got empty or invalid response!";
					}
					result.retCode = resp.getInt('retCode', -1);
					if(resp.hasError())
						throw resp.getErrorMessage();
					// out-Array fuellen
					/*if(bInfoOnly == true || aAttrs != null)
						result.rows = resp.getArray(JafWebAPI.PDObject.getExtent.PROP_rows,
							[], 'object', []); // TODO: das sollte in JEDEM Fall als PDObject zurueckgegeben werden!
					else*/
					{
						result.rows = resp.getArray(JafWebAPI.PDObject.getExtent.PROP_rows,
							[], 'PDObject', null);
					}
					result.blockSize = resp.getInt(JafWebAPI.PDObject.getExtent.PROP_blockSize, -1);
					result.blockNo = resp.getInt(JafWebAPI.PDObject.getExtent.PROP_blockNo, 0);
					result.total = resp.getInt(JafWebAPI.PDObject.getExtent.PROP_total, -1);
					if(!bInfoOnly && aAttrs == null)
						this[rolename] = result.rows;
					if(typeof fCallback == "function")
					{
						//fCallback(outStr, outPdo, this.retcode);
						fCallback.call(this, result);
					}
				};
		var failureFn = function(response, opts) {
				if(typeof fCallback == 'function')
					fCallback();
			};
		JafWeb.ajaxRequest({
				url: (this.PDClass || PDClass).getURL(),
				authToken: pdClass.getAuthToken(),
				method: "POST", // weil der Filter extrem lang werden kann!
				async: (!!fCallback),
				params: pars.getPostParams(),
				disableCaching: true,
				scope: this,
				callerName: pars.getEventName(),
				success: successFn,
				failure: failureFn
			});
		// wenn die PDObjects geholt wurden, Beziehung auch lokal fuellen
		if(!bInfoOnly && aAttrs == null)
			this[rolename] = result.rows;
		if(!fCallback)
			return result;
	}

	/**
	 @function PDObject#getConnectedOids
	 @desc Gibt ein Array der über die angegebene Zu-N-Beziehung
		 verbundenen Objekt-Ids zurück.
	 @param {string} rolename Rollenname der Beziehung.
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @return {Array} Array von OIDs (String) aller Objekte, die 
		 über die Beziehung mit dem aktuellen Objekt verbunden sind. 
		 Falls kein Objekt verbunden ist, wird ein leeres Array 
		 zurückgegeben.
	 */
	getConnectedOids(rolename, callback) {
		// TODO: optional nur die OidLows (int) holen.
		this.retcode = 0;
		var res = new Array();
		// nur die OIDs vom Server holen:
		// PDObject holen, falls noch nicht lokal
		var pdClass = (this.PDClass || PDClass);
		var pars = new JParamPacker(JafWebAPI.PDObject.getConnectedOids.eventName, pdClass);
		pars.addPDObjectByIds(this);
		pars.add(JafWebAPI.PDObject.getConnectedOids.PAR_relname, rolename);
		this._lastMsg = '';
		this.retcode = -1;
		var res = null;
		var successFn = function(req, options)
				{
					var resp = new JResponse(req, options, pdClass);
					if(resp.hasFatalError())
						throw "Request got empty or invalid response!";
					if(resp.hasError())
						throw resp.getErrorMessage();
					this.retcode = resp.getInt(JafWebAPI.PROP_retCode, -1);
					res = resp.getArray(JafWebAPI.PDObject.getConnectedOids.PROP_oids, [], 'string', '');
					if(typeof callback == 'function')
						callback(res);
				};
		var failureFn = function(response, opts) {
				if(typeof callback == 'function')
					callback();
			};
		JafWeb.ajaxRequest({
				url: (this.PDClass || PDClass).getURL(),
				authToken: pdClass.getAuthToken(),
				method: "GET",
				async: (!!callback),
				params: pars.get(),
				disableCaching: true,
				callerName: pars.getEventName(),
				scope: this,
				success: successFn,
				failure: failureFn
			});
		if(!callback)
			return res;
	}
	
	/**
	 @function PDObject#setDeleted
	 @deprecated Bitte nicht mehr verwenden!
	 @desc Das Objekt als gelöscht markieren. Beim nächsten
		 Speichervorgang wird das Objekt auf dem Server
		 gelöscht.
	 @see [registerDependentDeletion()]{@link PDObject#registerDependentDeletion}
	 */
	setDeleted() {
		this.retcode = 0;
		this._deleted = true;
	}
	
	/**
	 @function PDObject#isDeleted
	 @desc Gibt das Gelöscht-Kennzeichen zurück.
	 @return {Boolean} Ist das Objekt als gelöscht
		 markiert?
	*/
	isDeleted() {
		return (this._deleted === true);
	}

	/**
	 @deprecated Bitte nicht mehr verwenden!
	 @function PDObject#setDisconnected
	 @desc Das Objekt in einer Beziehung als getrennt markieren.
		 Beim nächsten Speichervorgang wird das Objekt auf dem Server
		 aus der Beziehung entfernt.
	 */
	setDisconnected() {
		this.retcode = 0;
		this._deleted = true;
		this._disconnected = true;
	}

	/**
	 @deprecated Bitte nicht mehr verwenden!
	 @function PDObject#registerDependentDeletion
	 @desc Ein Objekt als zu löschen registrieren, sobald ein verbundenes
		 Objekt gespeichert oder gelöscht wird. Das tritt z.B.
		 auf, wenn von diesem Objekt ausgehend eines seiner
		 Beziehungsobjekt gelöscht wird. Dann kann das erst
		 Server-seitig gelöscht werden, wenn dieses Objekt
		 entweder selbst ebenfalls gelöscht oder aber die Trennung
		 der Beziehung zum gelöschten Objekt durch Speichern
		 bestätigt wird. Andernfalls würde es möglich, dass eine 
		 Beziehung zu einem bereits gelöschten Objekt bestehen
		 bleibt.
	 @param {PDObject} obj Das Objekt, das als zu löschen
		 registriert werden soll.
	 */
	registerDependentDeletion(obj) {
		this.retcode = 0;
		if(!obj)
			return;
		obj._deleted = true;
		if(obj._isnew)
			return;
		this._depDelete[this._depDelete.length] = obj.getPDObjectId();
		// die Attribute koennten hier auch raus
	}
	
	/**
	 @function PDObject#getEnumConst
	 @desc Die möglichen Werte für ein Attribut vom Typ <code>enum</code> ermitteln.<br/>
		 <span class="important">Hinweis:</span> Da im JANUS-Umfeld die Werte von Enum-Attributen über den ergonomischen
		 Bezeichner der Ausprägung in der ersten Sprache gesetzt wird, enthalten hier
		 die Properties <code>code</code> und <code>text</code> den gleichen Wert.<br/>
		 <span class="important">Hinweis:</span> Die Funktion ruft nicht die
		 Server-seitige <code>PDObject::getEnumConst()</code> auf, sondern benutzt über
		 [PDClass.getEnumConsts()]{@link PDClass#getEnumConsts} die - ge-cachten - Informationen
		 des Metamodells.
	 @param {string} attr Name des Attributs, das den Aufzählungstypen verwendet.
	 @param {boolean} [withIcons=false] Legt fest, ob auch die den Ausprägungen zugewiesenen
		 Icons ermittelt werden sollen. Standardwert ist <code>false</code>. Beachten Sie,
		 dass, falls hier <code>true</code> angegeben wird, die Rückgabe eine andere
		 Struktur hat.
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @return {Array} Array mit den Objekten für die Ausprägungen. Diese haben folgende
		 Properties:
		 <ul>
			<li><code>code</code> Technischer Name der Enum-Ausprägung.
			<li><code>icon</code> Optionale Angabe eines Web-Icons, das für die
				Ausprägung angezeigt werden soll. Image-Pfad und Name,
				relativ ab dem Image-Verzeichnis der Web-Anwendung.
			<li><code>text</code> Der für die Enum-Konstante auf der Oberfläche
				anzuzeigende Text.
		 </ul>
	 */
	getEnumConst(attr, withIcons, callback) {
		////console.log("### PDObject.getEnumConst('" + attr + "')");
		const pdClass = (this.PDClass || PDClass);
		let enumName = '';
		// das Attribut kann Element einer Structure sein, dann muessen
		// wir den Typen ausgehend von dieser ermitteln!
		const pt = attr.indexOf('.');
		if(pt > 0) {
			const structName = pdClass.PDMeta.getType(this.classname, attr.substring(0, pt));
			enumName = pdClass.PDMeta.getType(structName, attr.substring(pt + 1));
		}
		else
			enumName = pdClass.PDMeta.getType(this.classname, attr);
		const convert = function(res) {
				// im Cache u. in PDClass haben die Enums eine andere Struktur!
				const convRes = [];
				const numLangs = pdClass.PDMeta.getNumLanguages();
				const actLang = pdClass.PDMeta.getLang();
				for(let i = 0; i < res.codes.length; i++) {
					convRes.push({
							code: res.tech[i],
							icon: (withIcons ? (res.icons ? res.icons[i] : '') : ''),
							text: (res.vals.length == res.tech.length ?
									res.vals[i] : res.vals[i * numLangs + actLang])
						});
				}
				return convRes;
			};
		if(callback) {
			pdClass.getEnumConsts(enumName, withIcons, function(res) {
					callback(convert(res));
				});
			return;
		}
		return convert(pdClass.getEnumConsts(enumName, withIcons));
		/*if(pdClass._useEnumCache)
		{
			var res = pdClass.getEnumFromCache(enumName);
			if(res)
			{
				// im Cache haben die Enums eine andere Struktur!
				var convRes = [];
				var numLangs = pdClass.PDMeta.getNumLanguages();
				var actLang = pdClass.PDMeta.getLang();
				for(var i = 0; i < res.codes.length; i++)
				{
					convRes.push({
							code: res.tech[i],
							icon: (withIcons ? (res.icons ? res.icons[i] : '') : ''),
							text: (res.vals.length == res.tech.length ?
									res.vals[i] : res.vals[i * numLangs + actLang])
						});
				}
				////console.log("### PDObject.getEnumConst() - Found enum '" + enumName + "' in Cache:", convRes);
				if(callback)
				{
					callback(convRes);
					return;
				}
				return convRes;
			}
		}
		var pars = new JParamPacker(JafWebAPI.PDObject.getEnumConst.eventName, pdClass);
		pars.addPDObjectByIds(this);
		pars.add(JafWebAPI.PDObject.getEnumConst.PAR_attr, (attr || ''));
		pars.add(JafWebAPI.PDObject.getEnumConst.PAR_icns, (withIcons === true));
		var res = null;
		var successFn = function(req, options)
			{
				var resp = new JResponse(req, options, pdClass);
				if(resp.hasFatalError())
					throw "Request got empty or invalid response!";
				if(resp.hasError())
					throw resp.getErrorMessage();
				var vals = resp.getArray(JafWebAPI.PDObject.getEnumConst.PROP_res, [], 'string', '');
				var icns = resp.getArray(JafWebAPI.PDObject.getEnumConst.PROP_icons, [], 'string', '');
				var extensible = resp.getBool(JafWebAPI.PDObject.getEnumConst.PROP_extensible, false);
				res = new Array();
				for(var i = 0; i < vals.length; i++)
				{
					res.push({
							'code': vals[i],
							'text': vals[i],
							'icon': (icns[i] || '')
						});
				}
				if(typeof callback == 'function')
					callback(res);
			};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callback == 'function')
					callback();
			};
		JafWeb.ajaxRequest({
				url: (this.PDClass || PDClass).getURL(),
				authToken: pdClass.getAuthToken(),
				method: "GET",
				async: (!!callback),
				params: pars.get(),
				disableCaching: true,
				scope: this,
				callerName: pars.getEventName(),
				success: successFn,
				failure: failureFn
			});
		/ *if(result.length != res.length)
			console.error("PDObject.getEnumConst() - cached result differs from that one got from the server", result, res);
		else for(var j=0; j < result.length && j < res.length; j++)
		{
			if(result[j].code != res[j].code || result[j].value != res[j].value || result[j].icon != res[j].icon)
			{
				console.error("PDObject.getEnumConst() - cached result differs from that one got from the server", result, res);
				break;
			}
		}* /
		if(!callback)
			return res;*/
	}
	
	/**
	 @function PDObject#getStatus
	 @desc Statuszeile zusammenstellen. Mit dieser Funktion kann eine
		 Zeichenkette zusammengestellt werden, die beliebige Attributwerte
		 beinhalten kann. Hiermit kann z.B. eine Statuszeile gefüllt werden.<br/>
		 <span class="important">Hinweis:</span> Die Ersetzung erfolgt immer auf dem Server.
	 @param {string} pattern Hier wird eine Zeichenkette angegeben, die
		 das Format des Rückgabe-Strings beschreibt.
		 Es können beliebige der folgenden Platzhalter enthalten sein, die
		 durch entsprechende Werte ersetzt werden. Der übrige Text wird
		 unverändert zurückgegeben.
		 Ein Prozentzeichen leitet einen Platzhalter ein. Nach dem
		 Prozentzeichen kann ein Kennbuchstabe mit direkt angefügtem Attributnamen
		 folgen. Das Ende des Attributnamens muss durch ein weiteres Prozentzeichen
		 angekündigt werden.<br/>
		 Folgende Steuersequenzen zur Bezugnahme auf Attribute werden
		 derzeit unterstützt, wobei "Attr" dem technischen Attributnamen
		 entspricht:
		 <ul>
			<li><code>%aAttr%</code> Der Wert des Attributs. Hierbei können auch
			Attribute anderer Klassen, die über eine zu-1-Beziehung
			erreichbar sind, referenziert werden.</li>
			<li><code>%eAttr%</code> Der ergonomische Name des Attributs, siehe
			[PDMeta.getErgname()]{@link PDMeta#getErgname}. Es
			kann auch ein qualifizierter Name in der Form "Klasse::Attribut"
			angegeben werden.</li>
			<li><code>%EAttr%</code> Der ergonomische Name des Attributs (Listenversion),
			siehe [PDMeta.getListErgname()]{@link PDMeta#getListErgname}. Es
			kann auch ein qualifizierter Name in der Form "Klasse::Attribut"
			angegeben werden.</li>
			<li><code>%dAttr%</code> Eine Beschreibung des Attributs, siehe
			[PDMeta.getDescription()]{@link PDMeta#getDescription}. Es
			kann auch ein qualifizierter Name in der Form "Klasse::Attribut"
			angegeben werden.</li>
			<li><code>%sAttr%</code> Eine Kurzbeschreibung des Attributs,
			siehe [PDMeta.getDescription()]{@link PDMeta#getDescription}. Es
			kann auch ein qualifizierter Name in der Form "Klasse::Attribut"
			angegeben werden.</li>
			<li><code>%SString%</code> Ein Attribut einer Textklasse (Stereotyp <code>Text</code>) oder
			eine mehrsprachige Zeichenkette aus <code>strings.txt</code>.
			Über <code>%SText::Text1%</code> kann z.B. der
			Bezeichner "Text1" aus der Textklasse
			"Text" in der aktuellen Sprache referenziert werden.</li>
		 </ul>
		 Darüber hinaus existieren einige spezielle Steuersequenzen,
		 die direkt nach einem Prozentzeichen folgen können. Hier
		 muss kein zweites Prozentzeichen angegeben werden:
		 <ul>
			<li><code>%U</code>: Name des Benutzers, der das Objekt angelegt hat.</li>
			<li><code>%u</code>: Name des Benutzers, der das Objekt zuletzt geändert hat.</li>
			<li><code>%p</code>: Name des Mandanten, dem das Objekt zugeordnet ist.
			Ist die Anwendung nicht mandantenfähig oder
			gehört das Objekt zu keinem Mandanten, ist der Name
			eine leere Zeichenkette.</li>
			<li><code>%m</code>: Ist der aktuelle Mandant nicht gleich dem Mandanten,
			von dem das Objekt erzeugt wurde, wird dies durch
			den Mandantennamen gefolgt von ": " ersetzt.</li>
			<li><code>%CD</code>: Datum, an dem das Objekt erzeugt wurde.</li>
			<li><code>%cd</code>: Datum, an dem das Objekt zuletzt geändert wurde.</li>
			<li><code>%CT</code>: Uhrzeit der Objekterzeugung.</li>
			<li><code>%ct</code>: Uhrzeit der letzten Änderung.</li>
			<li><code>%cl</code>: Der ergonomische Name der Klasse, zu der das Objekt gehört.</li>
			<li><code>%i</code>: Das Ergebnis des Aufrufs der Operation <code>getIdentifier()</code>
			wird hier eingesetzt. Das entspricht normalerweise dem
			Titel des Dialogs, der für die Anzeige im GUI-Client
			verwendet wird.</li>
			<li><code>%date</code>: Das aktuelle Datum wird eingesetzt.</li>
			<li><code>%time</code>: Die aktuelle Uhrzeit wird eingesetzt.</li>
			<li><code>%timestamp</code>: Der aktuelle Zeitstempel wird eingesetzt.</li>
		 </ul>
		 Ist der Parameter leer oder nicht angegeben, wird der
		 <i>Object Identifier</i> wie im UML-Modell
		 spezifiziert zurückgegeben (was einem Aufruf von
		 [getAttribute('')]{@link PDObject#getAttribute} - mit
		 leerem ersten Parameter - entspricht).
	 @param {boolean} [request=false] Wird hier <code>true</code> angegeben, wird
		 der Wert auf jeden Fall vom Server aktualisiert. Ansonsten wird versucht,
		 einen bereits ermittelten Wert aus einem objektinternen Zwischenspeicher zu
		 holen und nur ein Request abgesetzt, wenn das nicht möglich ist.
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @return {string} Das Ergebnis der Ersetzung.
	 @see Die gleichnamiger Methode der JANUS Server Library.
	 */
	getStatus(pattern, request, callback) {
		if(!pattern)
		{
			var ident = this.getAttribute(''); // TODO: asynchron
			if(typeof callback == 'function')
			{
				callback(ident);
				return;
			}
			return ident;
		}
		var cachedRes = '';
		if(!request)
		{
			cachedRes = (this._statusCache[pattern] || '');
			if(cachedRes)
			{
				////console.log("### got status string from cache: ", cachedRes);
				if(typeof callback == 'function')
				{
					callback(cachedRes);
					return;
				}
				return cachedRes;
			}
		}
		var res = '';
		var pdClass = (this.PDClass || PDClass);
		var pars = new JParamPacker(JafWebAPI.PDObject.getStatus.eventName, pdClass);
		pars.addPDObjectByIds(this);
		pars.add(JafWebAPI.PDObject.getStatus.PAR_patt, pattern);
		this.retcode = 0;
		res = '';
		var successFn = function(req, options)
			{
				var resp = new JResponse(req, options, pdClass);
				if(resp.hasFatalError())
					throw "Request got empty or invalid response!";
				if(resp.hasError())
					throw resp.getErrorMessage();
				res = resp.getString(JafWebAPI.PDObject.getStatus.PROP_value);
				this._statusCache[pattern] = res;
				////console.log("### added status string to cache: ", res);
				if(typeof callback == 'function')
					callback(res);
			};
		var failureFn = function(response, opts) {
				if(typeof callback == 'function')
					callback();
			};
		JafWeb.ajaxRequest({
				url: (this.PDClass || PDClass).getURL(),
				authToken: pdClass.getAuthToken(),
				method: "POST",
				async: (!!callback),
				params: pars.getPostParams(),
				disableCaching: true,
				scope: this,
				callerName: pars.getEventName(),
				success: successFn,
				failure: failureFn
			});
		/*if(cachedRes)
		{
			if(cachedRes !== res)
				console.error("PDObject.getStatus() - cached result differs from that on got from the server", cachedRes, res);
		}*/
		if(!callback)
			return res;
	}
	
	/**
	 @function PDObject#moveInRelation
	 @desc Bewegen von einem oder mehreren Fachkonzeptobjekten in einer <i>Orderable</i>-Beziehung.
	 @param {string} relname Name der Beziehung, in der die Elemente verschoben werden sollen.
	 @param {mixed} objs Array von <code>PDObject</code>s oder Objekt-IDs (unterer Teil).
		 Alternativ kann auch ein <code>PDObject</code> oder eine Objekt-ID angegeben werden.
	 @param {number} idx Der 0-basiert Index im gesamten Extent, an den das bzw. die
		 Objekte verschoben werden sollen.
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @return {mixed} Im Fehlerfall wird ein Fehlertext zurückgegeben, sonst <code>0</code>.
	 */
	moveInRelation(relname, objs, idx, callback) {
		//console.log("### PDObject.moveInRelation('" + relname + "', objs, " + idx + ") - objs:", objs);
		var pdClass = (this.PDClass || PDClass);
		var pars = new JParamPacker(JafWebAPI.PDObject.moveInRelation.eventName, pdClass);
		pars.addPDObjectByIds(this);
		pars.add(JafWebAPI.PDObject.moveInRelation.PAR_relname, relname);
		var _objs = [ ];
		if(JafWeb.isArray(objs))
		{
			for(var i=0; i < objs.length; i++)
			{
				if(!objs[i])
					continue;
				if(typeof objs[i] == 'number')
					_objs.push(objs[i]);
				else if(typeof objs[i] == 'object' && objs[i].oidLow)
					_objs.push(objs[i].oidLow);
			}
		}
		else if(typeof objs == 'number')
			_objs.push(objs);
		else if(typeof objs == 'object' && objs.oidLow)
			_objs.push(objs.oidLow);
		pars.add(JafWebAPI.PDObject.moveInRelation.PAR_oids, _objs.join(','));
		pars.add(JafWebAPI.PDObject.moveInRelation.PAR_idx, idx);
		//pars.add("relname", relName);
		var result = -1;
		var thisObj = this;
		var successFn = function(req, options)
				{
					var resp = new JResponse(req, options, pdClass);
					if(resp.hasFatalError())
						throw "Request got empty or invalid response!";
					if(resp.hasError())
						throw resp.getErrorMessage();
					result = resp.getInt(JafWebAPI.PROP_retCode, -1);
					if(result > 0)
						result = resp.getString(JafWebAPI.PDObject.moveInRelation.PROP_retMsg);
					else
						thisObj.handleUpdated.call(thisObj, relname);
					if(typeof callback == 'function')
						callback(result);
				};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callback == 'function')
					callback(-1);
			};
		JafWeb.ajaxRequest({
				url: (this.PDClass || PDClass).getURL(),
				authToken: pdClass.getAuthToken(),
				method: "POST",
				async: (!!callback),
				params: pars.getPostParams(),
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		return result;
	}
	
	/**
	 @function PDObject#downloadDocument
	 @desc Die Datei zu einem Attribut vom Typ <code>Document</code> herunterladen.
	 @param {string} attr Der Name des Attributs.
	 */
	downloadDocument(attr) {
		var pdClass = (this.PDClass || PDClass);
		var pars = new JParamPacker(JafWebAPI.PDClass.jexecDownload.eventName, pdClass);
		var obj = this;
		if(obj)
		{
			pars.add(JafWebAPI.PDClass.jexecDownload.PAR_cid, obj.cid);
			pars.add(JafWebAPI.PDClass.jexecDownload.PAR_clName, obj.classname);
			pars.add(JafWebAPI.PDClass.jexecDownload.PAR_oid, obj.GetPDObjectIdLow());
		}
		pars.add(JafWebAPI.PDClass.jexecDownload.PAR_attr, attr);
		var url = pdClass.getDownloadURL() +
					"?janusFileOperation=downloadWebEvent&iid=" + pdClass.PDMeta.getInstanceID() +
					(pdClass.getAuthToken() ? '&sessionId=' + pdClass.getAuthToken() : '') +
					"&janusWebEvent=" + pars.getEventString(true);
		window.open(url);
	}

	/// weitere?:
	// isUnique() - fuer Beziehungen, vgl. PDClass.isUnique()
	// isConnected()
	// getMD5()
	// markModified()
	// isModified()
	// 
}


/**
 * @class PDGroupInfo
 * @desc Browser-seitige Repräsentation der Klasse <code>PDGroupInfo</code>
 * zur Verwaltung von Gruppierungsinformationen für Extent- und
 * Beziehungstabellen.
 * @author Frank Fiolka
 */
class PDGroupInfo {
	/**
	@constructs PDGroupInfo
	@desc Erzeugt das Gruppierungsobjekt.<br/>
	Objekte dieser Klasse sollten normalerweise nicht manuell
	angelegt werden, sondern entstehen beim Aufruf von
	[PDObject.getExtentGroups()]{@link PDObject#getExtentGroups} bzw.
	[PDClass.getExtentGroups()]{@link PDClass#getExtentGroups}.
	@param {string} filter Vollständiger JANUS-Filterausdruck, mit dem
	der Gruppeninhalt angefordert werden kann. Ein ggf. für die gesamte
	Tabelle eingestellter Filter ist hier bereits enthalten.
	@param {string} value Wert des gruppierten Attributs in dieser Gruppe. Falls
	nach mehreren Attributen gruppiert wurde, sind die Werte durch Kommata
	verkettet.
	@param {number} count Anzahl der in der Gruppe enthaltenen Elemente.
	 */
	constructor(filter, value, count) {
		this._filter = filter||"";
		this._value = value||"";
		this._count = count||0;
		this.PDClass = null;
	}
	
	/**
	@function PDGroupInfo#getValue
	@desc Gibt den in dieser Gruppe vorliegenden Wert der 
	Gruppierungsattribute zurück. Falls nach mehreren
	Attributen gruppiert wurde, sind die Werte der einzelnen
	Attribute durch Kommata verkettet.
	@return {string} Zeichenkette mit den Attributwerten
	der Gruppe.
	 */
	getValue() {
		return this._value;
	}
	
	/**
	@function PDGroupInfo#getFilter
	@desc Gibt den vollständigen Filterausdruck zurück, mit dem
	die zu dieser Gruppe gehörenden Objekte geholt werden 
	können. Filter, die bereits bei der Gruppierungsabfrage
	angegeben wurden, sind hierin bereits enthalten.
	@return {string} Fertig zusammengesetzter Filter zur
	Übergabe an den JANUS-Server.
	 */
	getFilter() {
		return this._filter;
	}
	
	/**
	@function PDGroupInfo#getCount
	@desc Gibt die Anzahl der in der Gruppe enthaltenen Fachkonzeptobjekte
	zurück.<br/>
	<span class="important">Hinweis:</span> Da die Abfrage der Gruppierung und das Laden der Fachkonzeptobjekte
	entkoppelt sind, kann es passieren, dass die hier gespeicherte Anzahl
	der Objekte von der dann tatsächlich mit dem Filterausdruck geladenen
	Anzahl abweicht.
	@return {number} Anzahl der Fachkonzeptobjekte in der Gruppe. Diese
	können mit dem über <code>getFilter()</code> abfragbaren Selektionsausdruck
	geholt werden.
	 */
	getCount() {
		return this._count;
	}
	
	/*
	@function PDGroupInfo#update
	@desc Aktualisiert die Anzahl der in dieser Gruppe enthaltenen
	Fachkonzeptobjekte.
	@return {number} Die neue Anzahl. Falls die Gruppe leer ist,
	wird 0 zurückgegeben, womit die Gruppierungsinformation
	eigentlich überflüssig ist. Bei erneuter Gruppierung würde
	sie dann nicht mehr auftauchen.
	@todo Implementierung
	 */
	update() {
		// TODO
	}
}

//var PDObjectCacheClass = Class.create();
/*
 * @class PDObjectCacheClass
 * @desc {@link PDObject}-Cache, zu intantiieren in jeder
 * PDClass-Instanz.
 * @author Frank Fiolka
 @example
var myObject = PDClass.newObject("MyClass");
 */
/*PDObjectCacheClass.prototype = {
	initialize : function()
	{
		this._objects = { };
	},

	add : function(obj)
	{
		if(obj && obj.oidHi && obj.oidLow)
		{
			var key = ""+obj.oidHi+":"+obj.oidLow;
			if(this._objects[key])
				return true; // ist bereits drin
			this._objects[key] = obj;
			return true;
		}
		return false;
	},

	Delete : function()
	{
		for(var sProp in this._objects)
		{
			this._objects[sProp].Delete();
			delete this._objects[sProp];
		}
		this._objects = null;
	},
	
	_getKey : function(oidHi, oidLow)
	{
		var key = "";
		if(arguments.length == 2)
			key = ""+oidHi+":"+oidLow;
		else if(oidHi && typeof oidHi == "string")
			key = oidHi.replace(/_/,":");
		return key;
	},
	
	removeObject : function(oidHi, oidLow)
	{
		var key = this._getKey(oidHi, oidLow);
		if(key != "" && this._objects[key])
		{
			this._objects[key] = undefined;
			return true;
		}
		return false;
	},
	
	get : function(oidHi, oidLow)
	{
		var key = this._getKey(oidHi, oidLow);
		if(key != "" && this._objects[key])
		{
			//console.log("PDObjectCache: object "+oidHi+":"+oidLow+" found");
			return this._objects[key];
		}
		//console.log("PDObjectCache: object "+oidHi+":"+oidLow+" not found");
		return null;
	},

	_objects : null
};*/


/**
 @class PDClass
 @classdesc Browser-seitige Repräsentation der JANUS-Laufzeit-Klasse
	 <code>PDClass</code>.
	 Diese Klasse ist über eine globale Variable ihres Namens zugänglich.
	 Es kann damit von überall her darauf zugegriffen werden,
	 beispielsweise um das Objekt einer Singleton-Klasse aus dem UML-Modell
	 zu holen.<br/>
	 <h4>Beispiel</h4>
	 <pre class="prettyprint"><code>const mySingle = PDClass.getSingleton("SingleClass")</code></pre>
 @author Frank Fiolka
 */
class PDClassClass {
	/// Erzeugt die Instanz. (Kein oeffentlicher Konstruktor!)

	// vgl. include/jlimit.h
	// int32
	static NON_INT = -2147483647;
	static MAX_INT = 2147483647;
	static MIN_INT = (-2147483647 + 1);
	// unsigned int32
	static NON_UINT = 4294967295;
	static MAX_UINT = 4294967294;
	static MIN_UINT = 0;
	// short 
	static NON_SHORT = -32768;
	static MAX_SHORT = 32767;
	static MIN_SHORT = (-32768 + 1);
	// unsigned short 
	static NON_USHORT = 65535;
	static MAX_USHORT = 65534;
	static MIN_USHORT = 0;
	//static NON_FLOAT = DBL_MAX; // ??? TODO

	/*
	 @constructs PDClass
	 @desc Dieser Konstruktor sollte niemals aufgerufen werden.
	 Eine Instanz dieser Klasse steht unter deren Namen global
	 zur Verfügung.
	 */
	constructor(url, downloadUrl, uploadUrl, authToken,
			logoutEvt, useCache, useEnumCache) {
		this.isClientServer = true;

		// speichert den jeweils letzten Rueckgabecode
		// einer Operation
		this.retcode = 0;
		// letzter Meldungstext
		this._lastMsg = '';

		this._userId = 0;
		this._userName = '';
		this._userFullname = '';
		this._princId = -1;
		this._princName = '';
		this._princFullname = '';
	
		this._useEnumCache = true;
		
		this.configure({
				rootURL:		(url || "janus"),
				downloadURL:	(downloadUrl || "download"),
				uploadURL:		(uploadUrl ||  "upload"),
				authToken:		(authToken || ""), // Authentifizierungs-Token, falls verwendet
				logoutEvent: 	(logoutEvt || "logout"),
				usePDObjectCache: true  // geholte Fachkonzeptobjekte lokal speichern?
			});
	}
	
	/*
	 Konfiguration erst nach dem Erzeugen setzen.
	 */
	configure(cfg) {
		// Server-Verbindung dieser PDClass-Instanz:
		this._url = (cfg.rootURL || '');
		this._dwnlUrl = (cfg.downloadURL || '');
		this._uplUrl = (cfg.uploadURL || '');
		this._authToken = (cfg.authToken || '');
		this._logoutEvt = (cfg.logoutEvent || '');
		if(cfg.usePDOBjectCache !== false)
			this.PDObjectCache = new PDObjectCacheClass();
		if(cfg.useEnumCache === false)
			this._useEnumCache = false;
	}
	
	// Datenbank Schnittstelle fuer Local storage erzeugen
	initLocalStorage() {
		// Local storage
		if(typeof ILocalStore != 'undefined')
		{
			var store = ILocalStore.getInstance(PDMeta.getModel(), ClientInfo.getLoginName());
			var pdClass = this;
			store.open(function() {
					pdClass._store = store;
				});
		}
	}
	
	/**
	 @memberof PDClass
	 @desc Speichert den Rückgabe-Code der jeweils zuletzt
		 aufgerufenen Funktion.
	 @member {number}
	 */
	retcode = 0;
	/*
	 @desc Rückgabemeldung der zuletzt aufgerufenen Funktion.
	 @member {string}
	 */
	_lastMsg = "";
	
	// Arrays fuer Aufnahme der Handler zur Aenderungsueberwachung
	_evtHandler = null;
	
	// Member zur Unterstuetzung mehrerer PD-Instanzen
	// (eine pro Verbindung)
	_url = '';
	_dwnlUrl = '';
	_uplUrl = '';
	_authToken = '';
	_logoutEvt = '';
	
	// User- und Principal-Daten merken. um wiederholte
	// Abfragen zu vermeiden
	_userId = 0;
	_userName = '';
	_userFullname = '';
	_princId = 0;
	_princName = '';
	_princFullname = '';
	// userObj und princObj s. ClientInfo!
	
	set authToken(tok) { this._authToken = tok; }
	get authToken() { return this._authToken; }

	/*
	 @function PDClass#getLocalStore
	 @desc Gibt die Schnittstelle zum Speichern von Eingaben
	 in der lokalen Browser-Datenbank zurück.
	 @return {ILocalStore} Die LocalStorage-Schnittstelle oder
	 <code>null</code>, falls nicht aktiviert oder nicht
	 verfügbar.
	 */
	getLocalStore() {
		return this._store;
	}
	
	/**
	 @function PDClass#createIterator
	 @desc Erzeugt ein {@link PDIterator}-Objekt. Wenn Sie das JafWeb in mehreren
		 Instanzen benutzen, müssen Sie, um einen gültigen Iterator zu bekommen, diesen
		 nicht mit <code>new</code> direkt erzeugen, sondern über diese Funktion!<br/>
		 Baechten Sie aber, das es im Web normalerweise nicht sinnvoll ist, Iteratoren
		 zu benutzen; damit werden unnötig viele Requests ausgelöst. Benutzen Sie
		 stattdessen [PDClass.getExtent()]{@link PDClass#getExtent} (für Klassen-Extents)
		 bzw. [PDObject.getExtent()]{@link PDObject#getExtent} (für Zu-N-Beziehungen), um
		 blockweise Objekte aus der Ergebnismenge zu holen.
	 @param {PDObject} rootObj (optional) Fachkonzeptobjekt, von dem aus über eine
		 Beziehung iteriert werden soll. Falls stattdessen über einen
		 Extent iteriert werden soll, entfällt dieser Parameter.
	 @param {string} name Der Name der Fachkonzeptklasse, wenn es sich
		 um einen Extent-Iterator handelt. Wenn stattdessen über eine Beziehung
		 iteriert werden soll (Fachkonzeptobjekt als erster Paremter), handelt
		 es sich hier um den Namen der Beziehung, über die iteriert werden
		 soll.
	 @param {string} filter (optional) JANUS-Filterausdruck, um die
		 von dem Iterator gelieferte Ergebnismenge einzuschränken.
	 @param {string} sort JANUS-Sortierausdruck.
	 @param {PDObject} thisObj (optional) Fachkonzeptobjekt, das im Filterausdruck
		 über das Schlüsselwort <code>this</code> referenziert werden kann.
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @return {string} Der Iterator.
	 */
	createIterator(rootObj, name, filter, sort, thisObj, callback) {
		return new PDIterator(rootObj, name, filter, sort, thisObj, callback, this);
	}
	
	/**
	 @function PDClass#getURL
	 @desc Gibt die AJAX-Request-URL zum mit dieser PD-Instanz
		 verbundenen Server zurück.
	 @return {string} Die URL.
	 */
	getURL() {
		return this._url;
	}
	
	/**
	 @function PDClass#getDownloadURL
	 @desc Gibt die Download-URL zum mit dieser PD-Instanz
		 verbundenen Server zurück.
	 @return {string} Die URL.
	 */
	getDownloadURL() {
		return this._dwnlUrl;
	}

	/**
	 @function PDClass#getUploadURL
	 @desc Gibt die Datei-Upload-URL zum mit dieser PD-Instanz
		 verbundenen Server zurück.
	 @return {string} Die URL.
	 */
	getUploadURL() {
		return this._uplUrl;
	}
	
	/**
	 @function PDClass#getAuthToken
	 @desc Gibt das Authentifizierungs-Token für die von der aktuellen
		 Instanz benutzte Server-Verbindung zurück.
	 @return {string} Das Token.
	 */
	getAuthToken() {
		return this._authToken;
	}
	
	/**
	 @function PDClass#getLogoutEvent
	 @desc Den Logout-Event-Namen abfragen.
	 @return {string} Der Name.
	 */
	getLogoutEvent() {
		return this._logoutEvt;
	}
	
	/**
	 @function PDClass#usePDObjectCache
	 @desc Fragt ab, ob diese PD-Instanz einen zentralen Cache
		 für die [PDObjects]{@link PDObject} beuutzt.
	 @return {boolean}
	 */
	usePDObjectCache() {
		return !!this.PDObjectCache;
	}
	
	/**
	 @function PDClass#getReturnCode
	 @desc Gibt den Rückgabe-Code der zuletzt aufgerufenen Funktion
		 zurück.
	 @return {number} Der Code.
	 */
	getReturnCode() {
		return this.retcode;
	}

	/**
	 @function PDClass#getLastMessage
	 @desc Gibt die zuletzt gespeicherte Fehlermeldung zurück.
	 @return {string} Der Fehlertext.
	 */
	getLastMessage() {
		return this._lastMsg;
	}

	/**
	 @function PDClass#toPDObject
	 @desc Ein <code>PDObject</code> aus einem JSON-String
		 wiederherstellen (deserialisieren und instantiieren).
	 @param {string} json Der JSON-String, der das
		 <code>PDObject</code> beschreibt.
	 @return {PDObject} Das aus der Zeichenkette 
		 erzeugte {@link PDObject}.
	 */
	toPDObject(json) {
		//console.log("PDClass.toPDObject("+json+")");
		var jsonObj = null;
		if(typeof json == "string")
		{
			try
			{
				eval("var jsonObj = "+json+";");
				if(typeof jsonObj != "object")
					return null; 
			}
			catch(ex)
			{
				console.warn("PDClass.toPDObject(): jsonStr not valid:\n"+json);
				return null;
			}
		}
		else
			jsonObj = json;
		if(!jsonObj)
			return null;
		//return new PDObject(jsonObj);
		var tmpOidHi = 0;
		var tmpOidLo = 0;
		if(typeof jsonObj['oidHi'] == 'number' && typeof jsonObj['oidLow'] == 'number')
		{
			tmpOidHi = jsonObj.oidHi;
			tmpOidLo = jsonObj.oidLow;
		}
		else
		{
			tmpOidHi = OID_HI(jsonObj.oid);
			tmpOidLo = OID_LO(jsonObj.oid);
		}
		var rootObj = null;
		var inCache = false;
		if(this.usePDObjectCache() && tmpOidHi && tmpOidLo)
			rootObj = this.PDObjectCache.get(tmpOidHi, tmpOidLo);
		if(rootObj)
			inCache = true;
		else
			rootObj = new PDObject(this, jsonObj.classname, jsonObj.oid);
		rootObj = rootObj._setValues(jsonObj);
		// PDObject-Cache-Verwaltung
		if(!inCache || !this.usePDObjectCache())
		{
			// mit den fachlichen Funktionen "dekorieren", und zwar aus
			// der gesamten Vererbungskette, von oben nach unten
			/*if(jsonObj)
			{
				var clss = PDMeta.getAllSuperClasses(jsonObj.classname);
				clss.push(jsonObj.classname);
				clss.reverse();
				for(var i = 0; i < clss.length; i++)
				{
					//console.log("### Class '" + clss[i] + "'");
					if(jsonObj && JafWeb.PD[clss[i] + '__mem'])
						JafWeb.apply(rootObj, JafWeb.PD[clss[i] + '__mem']); // nur die Member-Funktionen einhaengen
				}
			}*/
			// mit den fachlichen Funktionen "dekorieren"
			// TODO!!!
			if(jsonObj && JafWeb && JafWeb.PD && JafWeb.PD[jsonObj.classname + '__mem'])
				JafWeb.apply(rootObj, JafWeb.PD[jsonObj.classname + '__mem']); // nur die Member-Funktionen einhaengen
			if(this.usePDObjectCache())
				this.PDObjectCache.add(rootObj);
		}
		return rootObj;
	}

	/// Redefinierbare Funktionen, mit denen sich PDClass-Abfragen manipulieren lassen.
	
	/**
	 @function PDClass#onGetEnumConsts
	 @desc Redefinieren Sie diese Funktion, wenn Sie die Ermittlung der Enum-Konstanten
		 manipulieren möchten. Es werden die möglichen Werte und deren Codes eines
		 global erweiterbaren oder eines nicht erweiterbaren Aufzählungstyps ermittelt.<br/>
		 Global erweiterbare Aufzählungstypen zeichnen sich dadurch aus, dass 
		 die von einem beliebigen Benutzer bei einer beliebigen Klasse oder einem 
		 beliebigen Objekt eingetragenen Erweiterungen allen anderen Benutzern 
		 überall zur Verfügung stehen.
	 @param {string} ename Name des auszulesenden Aufzählungstyps.
	 @param {boolean} [icons=false] Zeigt an, dass auch die für die Enum-Ausprägungen
		 definierten Icons ermittelt werden sollen (Standardwert <code>false</code>).
	 @return {Object} JavaScript-Objekt mit folgenden Properties:
		 <ul>
			<li><code>tech</code> Bei nicht erweiterbaren Enums enthält dieses
			Property ein Array von <code>String</code> mit den technischen Namen der
			Ausprägungen.</li>
			<li><code>codes</code> Array von <code>Number</code> mit den numerischen Codes
			der Ausprägungen.</li>
			<li><code>active</code> Array von <code>boolean</code>, in dem für die
			Ausprägung angezeigt wird, ob sie noch aktiv, d.h. nicht gelöscht ist.
			Bei einem nicht erweiterbaren Aufzählungstyp enthält die Liste nur
			<code>true</code>-Werte.</li>
			<li><code>vals</code> Array von <code>String</code>, das die ergonomischen
			Bezeichner der Ausprägungen <em>in der aktuellen Sprache</em> enthält.</li>
			<li><code>icons</code> Array Falls der Parameter <code>icons</code> mit
			<code>true</code> angegeben wurde, ist dieses Array definiert und enthält
			für jede Enum-Ausprägung den Namen der Icon-Datei. Stellen Sie diesen
			Werten den von [UIApplication.getImageDir()]{@link UIApplication#getImageDir}
			gelieferten Pfad voran, um die Icons anzuzeigen. Die Icon-Dateien müssen
			im Web-Ressourcen-Verzeichnis zugänglich sein, um angezeigt werden zu können.</li>
			<li><code>result</code> number Wenn die Werte geholt werden konnten, steht hier <code>0</code>,
			ansonsten kann der Fehlercode mit <code>PDMeta.getString()</code> in 
			einen Text umgewandelt werden.</li>
		 </ul>
		 Wenn mit der Standardbehandlung fortgefahren werden soll, geben Sie
		 <code>null</code> oder nichts zurück.
	 @see [getEnumConsts()]{@link PDClass#getEnumConsts}
	 */
	onGetEnumConsts(ename, icons) { }
	
	/**
	 @function PDClass#onGetGlobalEnumConst
	 @desc Redefinieren Sie diese Funktion, wenn Sie die Ermittlung der Enum-Konstanten
		 manipulieren möchten. Es werden die möglichen Werte und deren Codes eines
		 global erweiterbaren oder eines nicht erweiterbaren Aufzählungstyps ermittelt.<br/>
		 Global erweiterbare Aufzählungstypen zeichnen sich dadurch aus, dass 
		 die von einem beliebigen Benutzer bei einer beliebigen Klasse oder einem 
		 beliebigen Objekt eingetragenen Erweiterungen allen anderen Benutzern 
		 überall zur Verfügung stehen.<br/>
		 <span class="important">Hinweis:</span> Bitte beachten Sie, dass bei
		 einem in mehreren Sprachen spezifizierten 
		 Aufzählungstyp in der Liste <code>vals</code> pro Sprache und 
		 Selektionsmöglichkeit ein Wert eingetragen wird, während in <code>codes</code> 
		 und <code>active</code> nur ein Wert pro Selektionsmöglichkeit geschrieben 
		 wird.
	 @param {string} ename Name des auszulesenden Aufzählungstyps.
	 @param {boolean} [icons=false] Zeigt an, dass auch die für die Enum-Ausprägungen
		 definierten Icons ermittelt werden sollen (Standardwert <code>false</code>).
	 @return {Object} JavaScript-Objekt mit folgenden Properties:
		 <ul>
			<li><code>vals</code> Array von <code>String</code>, das die ausgelesenen
			Werte - ggf. in allen Sprachen - aufnimmt.</li>
			<li><code>codes</code> Array von <code>Number</code> mit den numerischen Codes
			der Ausprägungen.</li>
			<li><code>active</code> Array von <code>boolean</code>, in dem für die
			Ausprägung angezeigt wird, ob sie noch aktiv, d.h. nicht gelöscht ist.
			Bei einem nicht erweiterbaren Aufzählungstyp enthält die Liste nur
			<code>true</code>-Werte.</li>
			<li><code>icons</code> Array Falls der Parameter <code>icons</code> mit
			<code>true</code> angegeben wurde, ist dieses Array definiert und enthält
			für jede Enum-Ausprägung den Namen der Icon-Datei. Stellen Sie diesen
			Werten den von [UIApplication.getImageDir()]{@link UIApplication#getImageDir}
			gelieferten Pfad voran, um die Icons anzuzeigen. Die Icon-Dateien müssen
			im Web-Ressourcen-Verzeichnis zugänglich sein, um angezeigt werden zu können.</li>
			<li><code>result</code> number Wenn die Werte geholt werden konnten, steht hier <code>0</code>,
			ansonsten kann der Fehlercode mit <code>PDMeta.getString()</code> in 
			einen Text umgewandelt werden.</li>
		 </ul>
		 Wenn mit der Standardbehandlung fortgefahren werden soll, geben Sie
		 <code>null</code> oder nichts zurück.
	 @deprecated Bitte statt dieser [getEnumConsts()]{@link PDClass#getEnumConsts} bzw.
		 [onGetEnumConsts()]{@link PDClass#onGetEnumConsts} benutzen!
	 */
	onGetGlobalEnumConst(ename, icons) { }

	// TODO: komplettieren


	/// Objekte anlegen und loeschen
	/**
	 @function PDClass#prepareDeletion
	 @desc Löschen vorbereiten. Diese Funktion kann verwendet werden, um vor dem
		 Löschen Informationen darüber anzuzeigen, wie viele abhängige Objekte
		 mit gelöscht würden.<br/>
		 <span class="important">Hinweis:</span> Wenn Sie diese Funktion für mehrere zu
		 löschende Objekte aufrufen, kann
		 es bei den abhägig zu löschenden Objekten zu Überschneidungen kommen, sodass
		 eine zu hohe Anzahl ermittelt wird. Insofern ist die hier ermittelte Anzahl
		 im Sinne von "bis zu X Objekten" zu verstehen.<br/>
		 <span class="important">Hinweis:</span> Im Unterschied zur gleichnamigen
		 Operation der JANUS-Server-Library
		 erstellt diese Funktion keine Sperre für die zu löschenden Objekte!
	 @param {mixed} objs Das zu löschende <code>PDObject</code> oder ein Array
		 der zu löschenden <code>PDObject</code>s.
	 @param {boolean} [transObj=false]
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @return {Object} Die Funktion gibt ein Objekt mit folgenden Properties
		 zurück:
		 <ul>
			<li><code>failedAt</code>: Falls das Löschen voraussichtlich fehlschlägt,
				enthält dieses Property die OID des ersten Objekts, das nicht gelöscht
				werden kann.
			<li><code>classes</code>: Array der Klassen-Ids, von denen Objekte
				gelöscht würden. Die Klassen-Ids können über
				[PDMeta.getErgname()]{@link PDMeta#getErgname}
				in die entspr. ergonomischen Bezeichner umgewandelt werden.
			<li><code>count</code>: Anzahl der Objekte, die insgesamt gelöscht würden.
			<li><code>reason</code>: Meldung, warum das Löschen fehlgeschlagen ist.
		 </ul>
	 */
	prepareDeletion(objs, transObj, callback) {
		//console.log("PDClass.prepareDeletion()");
		if(!objs)
			return null;
		var oids = [ ];
		if(JafWeb.isArray(objs))
		{
			for(var i=0; i < objs.length; i++)
				oids.push(objs[i].oid);
		}
		else if(typeof objs == 'object' && typeof objs.oidLow == "number")
			oids.push(objs.oid);
		var pars = new JParamPacker(JafWebAPI.PDClass.prepareDeletion.eventName, this);
		pars.add(JafWebAPI.PDClass.prepareDeletion.PAR_oids, oids.join(','));
		if(transObj && transObj.oid)
			pars.add(JafWebAPI.PDClass.prepareDeletion.PAR_transObj, transObj.oid);
		else if(typeof transobj == 'string')
			pars.add(JafWebAPI.PDClass.prepareDeletion.PAR_transObj, transObj);
		//console.log("prepareDeletion(): Params: "+pars.toString());
		this._lastMsg = '';
		this.retcode = -1;
		var res = { };
		var pdClass = this;
		var successFn = function(req, options)
				{
					var resp = new JResponse(req, options, pdClass);
					if(resp.hasFatalError())
						throw "Request got empty or invalid response!";
					//if(resp.hasError())
					//	throw resp.getErrorMessage();
					pdClass.retcode = resp.getInt(JafWebAPI.PROP_retCode, -1);
					res.failedAt = resp.getString(JafWebAPI.PDClass.prepareDeletion.PROP_failedAt, "");
					res.classes = resp.getArray(JafWebAPI.PDClass.prepareDeletion.PROP_classes, []);
					res.count = resp.getInt(JafWebAPI.PDClass.prepareDeletion.PROP_count, -1);
					res.reason = resp.getErrorMessage();
					if(typeof callback == 'function')
						callback(res);
				};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callback == 'function')
					callback();
			};
		JafWeb.ajaxRequest({
				url: this.getURL(),
				authToken: this.getAuthToken(),
				method: "GET",
				async: (!!callback),
				scope: this,
				callerName: pars.getEventName(),
				params: pars.get(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		//console.log("PDClass.prepareDeletion() - returns: "+inspect(res, true));
		if(!callback)
			return res;
	}
	
	/**
	 @function PDClass#deleteObject
	 @desc Ein Fachkonzeptobjekt löschen. 
	 @param {PDObject} obj Das zu löschende lokale <code>PDObject</code>.
	 @param {number} transactionId Optionale Angabe einer Transaktionsnummer. Diese kann
		 auf der Server-Seite zur Realisierung eines komplexen Transaktionskonzept
		 verwendet werden.
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @return {number} Wenn das Löschen des Objekts (und eventuell der davon
		 abhängigen Objekte) geklappt hat, wird <code>0</code> zurückgegeben.
		 Ansonsten wird ein Fehlercode zurückgegegeben, der mit
		 [PDMeta.getString()]{@link PDMeta#getString} in einen Text umgewandelt werden kann.
	 */
	deleteObject(obj, transactionId, callback) {
		if(!obj)
			return 0;
		const pars = new JParamPacker(JafWebAPI.PDClass.deleteObject.eventName, this);
		pars.addPDObjectByIds(obj);
		this.handleObjectDelete(obj);
		pars.add(JafWebAPI.PDClass.deleteObject.PAR_md5, obj._md5);
		let tid = 0;
		if(typeof transactionId == 'number')
			tid = transactionId;
		pars.add(JafWebAPI.PDClass.deleteObject.PAR_tid, tid);
		this._lastMsg = '';
		this.retcode = -1;
		const pdClass = this;
		const _cid = obj.cid;
		const _classname = obj.classname;
		const _oidLow = obj.oidLow;
		const successFn = function(req, options) {
				const resp = new JResponse(req, options, pdClass);
				if(resp.hasFatalError())
					throw "Request got empty or invalid response!";
				// hier darf keine Exception geschmissen werden, weil es dann fuer
				// eine benutzerdefinierte Fehlerbehandlung (in PDMeta.getDelErr())
				// zu spaet waere!
				//if(resp.hasError())
				//	throw resp.getErrorMessage();
				if(resp.hasError())
					pdClass._lastMsg = resp.getErrorMessage();
				pdClass.retcode = resp.getInt(JafWebAPI.PROP_retCode, -1);
				// aus dem PDObjectCache entfernen
				if(!resp.hasError() && pdClass.retcode == 0 && pdClass.PDObjectCache) {
					pdClass.PDObjectCache.removeObject(_cid, _oidLow);
					pdClass.handleObjectDeleted(_classname, _oidLow);
				}
				if(typeof callback == 'function')
					callback(pdClass.retcode, pdClass._lastMsg);
			};
		const failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callback == 'function')
					callback();
			};
		JafWeb.ajaxRequest({
				url: this.getURL(),
				authToken: this.getAuthToken(),
				method: "GET",
				async: (!!callback),
				scope: this,
				params: pars.get(),
				disableCaching: true,
				callerName: pars.getEventName(),
				success: successFn,
				failure: failureFn
			});
		if(!callback)
			return this.retcode;
	}
	
	/**
	 @function PDClass#deleteObjects
	 @desc Mehrere Fachkonzeptobjekte in einem einzigen Request löschen. 
	 @param {Array<PDObject>} objs Die zu löschenden <code>PDObject</code>s.
	 @param {number} transactionId Optionale Angabe einer Transaktionsnummer. Diese kann
		 auf der Server-Seite zur Realisierung eines komplexen Transaktionskonzept
		 verwendet werden.
	 @param {boolean} [abortOnError=true] Zeigt an, ob beim ersten Auftreten eines
		 Fehlers der Vorgang abgebrochen werden soll. Wird hier <code>false</code>
		 angegeben, wird der Vorgang nach Fehlern fortgesetzt; alle Fehler werden
		 im Rückgabe-Array bzw. im Aufrufparameter der Callback-Funktion übergeben.
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @return {Array<String>} Wenn das Löschen eines oder mehrerer Objekte nicht
		 geklappt hat, stehen hierin die Fehlermeldungen.
	 @since 3.0
	 */
	deleteObjects(objs, transactionId, abortOnError, callback) {
		const oids = [ ];
		if(JafWeb.isArray(objs)) {
			for(let i=0; i < objs.length; i++)
				oids.push(objs[i].oid);
		}
		const pars = new JParamPacker(JafWebAPI.PDClass.deleteObjects.eventName, this);
		pars.add(JafWebAPI.PDClass.deleteObjects.PAR_oids, oids.join(','));
		let pos = 1;
		if(arguments.length > pos && typeof arguments[pos] == 'number') {
			pars.add(JafWebAPI.PDClass.deleteObjects.PAR_tid, arguments[pos]);
			pos++;
		}
		if(arguments.length > pos && typeof arguments[pos] == 'boolean') {
			pars.add(JafWebAPI.PDClass.deleteObjects.PAR_abortOnErr, arguments[pos]);
			pos++;
		}
		let _callb = null;
		if(arguments.length > pos && typeof arguments[pos] == 'function') {
			_callb = arguments[pos];
			pos++;
		}
		this._lastMsg = '';
		this.retcode = -1;
		const pdClass = this;
		let errMsgs = [];
		const successFn = function(req, options) {
				const resp = new JResponse(req, options, pdClass);
				if(resp.hasFatalError())
					throw "Request got empty or invalid response!";
				// hier darf keine Exception geschmissen werden, weil es dann fuer
				// eine benutzerdefinierte Fehlerbehandlung (in PDMeta.getDelErr())
				// zu spaet waere!
				//if(resp.hasError())
				//	throw resp.getErrorMessage();
				if(resp.hasError()) {
					pdClass._lastMsg = resp.getErrorMessage();
					errMsgs = resp.getErrorMessages();
				}
				pdClass.retcode = resp.getInt(JafWebAPI.PROP_retCode, -1);
				// aus dem PDObjectCache entfernen
				if(!resp.hasError() && pdClass.retcode == 0 && pdClass.PDObjectCache) {
					for(let i = 0; i < oids.length; i++)
						pdClass.PDObjectCache.removeObject(OID_HI(oids[i]), OID_LO(oids[i]));
				}
				if(_callb)
					_callb(errMsgs, pdClass.retcode);
			};
		const failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(_callb)
					_callb();
			};
		JafWeb.ajaxRequest({
				url: this.getURL(),
				authToken: this.getAuthToken(),
				method: "GET",
				async: (!!callback),
				scope: this,
				params: pars.get(),
				disableCaching: true,
				callerName: pars.getEventName(),
				success: successFn,
				failure: failureFn
			});
		if(!_callb)
			return errMsgs;
	}
	
	/**
	 @function PDClass#startTransaction
	 @desc Für ein bestimmtes Datenbankobjekt eine neue Transaktion starten. Es wird
		 Server-seitig ein neues Transaktionsobjekt angelegt und mit den Daten aus
		 dem Datenbankobjekt belegt. Dieses Transaktionsobjekt wird in der Server-Session
		 vorgehalten, bis die Transaktion wieder beendet wird (mit
		 [PDObject.checkConstraintsAndCommit()]{@link PDObject#checkConstraintsAndCommit}
		 oder [PDObject.abortTransaction()]{@link PDObject#abortTransaction}).
	 @param {mixed} pdclass Name oder numerische Id der Fachkonzeptklasse oder das
		 Datenbankobjekt selbst.
	 @param {number} oid Objekt-Id (unterer Teil) des Datenbankobjekts. Statt dieser
		 beiden Parameter kann auch die Objekt-Id als String angegeben werden.
	 @param {number} [transactionId=0] Optionale Angabe einer Transaktionsnummer. Diese kann
		 auf der Server-Seite zur Realisierung eines komplexen Transaktionskonzept
		 verwendet werden.
	 @param {Mixed} [attrs=true] Die zu ermittelnden Attribute - entweder eine Liste der
		 technischen Attributnamen oder ein boolescher Typ, der anzeigt, ob alle Attribute
		 laut Modell (<code>true</code>) oder gar keine Attribute (<code>false</code>)
		 ermittelt werden sollen.
	 @param {Mixed} [rels=false] Die zu ermittelnden Beziehungen. Wenn ein boolescher Typ
		 angegeben wird, zeigt <code>false</code> an, dass keine Beziehungen geholt werden
		 sollen, <code>true</code> dagegen, dass alle Zu-1-Beziehungen laut Modell geholt
		 werden sollen. Dabei wird für jede Zu-1-Beziehung das ggf. verknüpfte {@link PDObject}
		 mit lediglich dem im Modell spezifizierten Object Ident, jedoch ohne die übrigen
		 Attribute geholt. Statt des booleschen Typs kann eine Liste der zu ermittelnden
		 Beziehungen angegeben werden. Darin kann jedem technischen Beziehungsnamen, getrennt
		 durch Komma, der Template-Ausdruck für die RelationInfo angehängt werden. Das ist
		 wichtig, um spätere, vereinzelte [getStatus()]{@link PDObject#getStatus}-Aufrufe
		 zu vermeiden.
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @return {PDObject} Das Transaktionsobjekt oder im Fehlerfall <code>null</code>.<br/>
		 Wenn es zum selben echten Objekt bereits ein Transaktionsobjekt gibt, und zwar
		 <em>innerhalb der selben Transaktion</em>, wird kein weiteres Transaktionsobjekt
		 erzeugt, sondern dieses zurückgegeben. Falls zum selben echten Objekt bereits ein
		 Transaktionsobjekt <em>in einer übergeordneten Transaktion</em> gibt, wird ein neues
		 Transaktionsobjekt erzeugt, das dessen bereits geänderte Werte übernimmt.
	 */
	startTransaction(pdclass, oid, transactionId, attrs, rels, callback) {
		//console.log("### PDClass.startTransaction("+pdclass+", "+oid+", "+transactionId+")");
		this.retcode = -1;
		var pars = new JParamPacker(JafWebAPI.PDClass.startTransaction.eventName, this);
		var tid = 0;
		var pos = 0;
		var callbFn = null;
		if(arguments.length > pos && JafWeb.isPDObject(arguments[pos]))
		{
			pars.add(JafWebAPI.PDClass.startTransaction.PAR_clName, pdclass.GetClass());
			pars.add(JafWebAPI.PDClass.startTransaction.PAR_cid, pdclass.GetId());
			pars.add(JafWebAPI.PDClass.startTransaction.PAR_oidLow, pdclass.GetPDObjectIdLow());
			pos++;
			if(arguments.length > pos && (typeof arguments[pos] == "number"))
			{
				tid = arguments[pos];
				pos++;
			}
		}
		else if(arguments.length > pos && /[0-9]+:[0-9]+/.test(arguments[pos]))
		{
			pars.add(JafWebAPI.PDClass.startTransaction.PAR_oid, arguments[pos]);
			pos++;
			if(arguments.length > pos && (typeof arguments[pos] == "number"))
			{
				tid = arguments[pos];
				pos++;
			}
		}
		else
		{
			if(pdclass && typeof pdclass == "string")
				pars.add(JafWebAPI.PDClass.startTransaction.PAR_clName, pdclass);
			else if(pdclass && typeof pdclass == "number")
				pars.add(JafWebAPI.PDClass.startTransaction.PAR_cid, pdclass);
			else {
				this._lastMsg = "startTransaction: Class-Id or Name missing!";
				throw "Class-Id or Name missing!";
			}
			pos++;
			if(oid && typeof oid == "number")
				pars.add(JafWebAPI.PDClass.startTransaction.PAR_oidLow, oid);
			else if(oid && typeof oid == "string" && /[0-9]+:[0-9]+/.test(oid))
				pars.add(JafWebAPI.PDClass.startTransaction.PAR_oid, oid);
			else
			{
				var iOid = parseInt(oid, 10);
				if(!Number.isNaN(iOid))
					pars.add(JafWebAPI.PDClass.startTransaction.PAR_oidLow, oid);
				else {
					this._lastMsg = "Oid missing!";
					throw "Oid missing!";
				}
			}
			pos++;
			if(typeof transactionId == 'number')
			{
				tid = transactionId;
				pos++;
			}
		}
		if(arguments.length > pos)
		{
			if(typeof arguments[pos] == 'boolean')
			{
				pars.add(JafWebAPI.PDClass.startTransaction.PAR_allAttrs, arguments[pos]);
				pos++;
			}
			else if(JafWeb.isArray(arguments[pos]))
			{
				for(var i=0; i < arguments[pos].length; i++)
					pars.add(JafWebAPI.PDClass.startTransaction.PAR_attrs + i, arguments[pos][i]);
				pos++;
			}
		}
		if(arguments.length > pos)
		{
			if(typeof arguments[pos] == 'boolean')
			{
				pars.add(JafWebAPI.PDClass.startTransaction.PAR_allTo1Rels, arguments[pos]);
				pos++;
			}
			else if(JafWeb.isArray(arguments[pos]))
			{
				for(var i=0; i < arguments[pos].length; i++)
					pars.add(JafWebAPI.PDClass.startTransaction.PAR_rels + i, arguments[pos][i]);
				pos++;
			}
		}
		if(arguments.length > pos)
		{
			if(typeof arguments[pos] == 'function')
			{
				callbFn = arguments[pos];
				pos++;
			}
		}
		// Neues Transaktionskonzept: die transactionId muss
		// unbedingt angegeben sein!
		if(!tid)
			throw "Cannot start without current transaction-ID";
		pars.add(JafWebAPI.PDClass.startTransaction.PAR_tid, tid);
		//console.log("PDClass.startTransaction(): Params: "+pars.toString());
		this._lastMsg = '';
		var result = null;
		var pdClass = this;
		var successFn = function(req, options)
				{
					var resp = new JResponse(req, options, pdClass);
					if(resp.hasFatalError())
						throw "Request got empty or invalid response!";
					if(resp.hasError())
						throw resp.getErrorMessage();
					var lockInfo = resp.get(JafWebAPI.PDClass.startTransaction.PROP_lockInfo);
					if(lockInfo)
					{
						var msg = (pdClass.PDMeta.getString('SC::FormTitleLocked') || "Locked by %u, logged in from %c. Locked since %t.");
						msg = msg.replace(/%c/, lockInfo[JafWebAPI.PDClass.lockInfo.PROP_client]);
						msg = msg.replace(/%u/, lockInfo[JafWebAPI.PDClass.lockInfo.PROP_user]);
						msg = msg.replace(/%i/, lockInfo[JafWebAPI.PDClass.lockInfo.PROP_uid]);
						if(typeof lockInfo[JafWebAPI.PDClass.lockInfo.PROP_locktime] == 'string') {
							msg = msg.replace(/%t/, lockInfo[JafWebAPI.PDClass.lockInfo.PROP_locktime]);
						}
						else {
							var dur = new JDuration(lockInfo[JafWebAPI.PDClass.lockInfo.PROP_locktime]);
							msg = msg.replace(/%t/, dur.toString());
						}
						pdClass._lastMsg = msg;
						//throw msg; // TODO
						//UIMessage.ok(msg);
					}
					else
					{
						result = resp.getPDObject(JafWebAPI.PDClass.startTransaction.PROP_transObj);
						var lockId = resp.getInt(JafWebAPI.PDClass.startTransaction.PROP_lockId, -1);
						if(result && lockId >= 0)
							result._lockid = lockId;
						var tid = resp.getInt(JafWebAPI.PDClass.startTransaction.PROP_tid, -1);
						if(result && tid >= 0)
							result._tid = tid;
					}
					pdClass.retcode = resp.getInt(JafWebAPI.PROP_retCode, -1);
					if(typeof callbFn == 'function')
						callbFn(result);
				};
		var failureFn = function(response, opts) {
				if(typeof callbFn == 'function')
					callbFn(result);
			};
		JafWeb.ajaxRequest({
				url: this.getURL(),
				authToken: this.getAuthToken(),
				method: "POST",
				async: (!!callbFn),
				scope: this,
				params: pars.getPostParams(),
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		if(!callback)
			return result;
	}
	
	/**
	 @function PDClass#startNewTransaction
	 @desc Eine neue Transaktion starten. Es wird Server-seitig ein neues Transaktionsobjekt
		 angelegt, mit den Default-Werten belegt und in der Server-Session vorgehalten.
	 @param {mixed} pdclass Name oder numerische Id der Fachkonzeptklasse, von der das 
		 Objekt erzeugt werden soll.
	 @param {number} [transactionId=0] Optionale Angabe einer Transaktionsnummer. Diese kann
		 auf der Server-Seite zur Realisierung eines komplexen Transaktionskonzepts
		 verwendet werden.
	 @param {Mixed} [attrs=true] Die zu ermittelnden Attribute - entweder eine Liste der
		 technischen Attributnamen oder ein boolescher Typ, der anzeigt, ob alle Attribute
		 laut Modell (<code>true</code>) oder gar keine Attribute (<code>false</code>)
		 ermittelt werden sollen.
	 @param {Mixed} [rels=false] Die zu ermittelnden Beziehungen. Wenn ein boolescher Typ
		 angegeben wird, zeigt <code>false</code> an, dass keine Beziehungen geholt werden
		 sollen, <code>true</code> dagegen, dass alle Zu-1-Beziehungen laut Modell geholt
		 werden sollen. Dabei wird für jede Zu-1-Beziehung das ggf. verknüpfte {@link PDObject}
		 mit lediglich dem im Modell spezifizierten Object Ident, jedoch ohne die übrigen
		 Attribute geholt. Statt des booleschen Typs kann eine Liste der zu ermittelnden
		 Beziehungen angegeben werden. Darin kann jedem technischen Beziehungsnamen, getrennt
		 durch Komma, der Template-Ausdruck für die RelationInfo angehängt werden. Das ist
		 wichtig, um spätere, vereinzelte [getStatus()]{@link PDObject#getStatus}-Aufrufe
		 zu vermeiden.
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @return {PDObject} Das neue Transaktionsobjekt oder im Fehlerfall <code>null</code>.
	 @throws {Exception}
	 */
	startNewTransaction(pdclass, transactionId, attrs, rels, callback) {
		//console.log("### PDClass.startNewTransaction("+pdclass+", "+transactionId+")");
		this.retcode = -1;
		var pars = new JParamPacker(JafWebAPI.PDClass.startNewTransaction.eventName, this);
		if(pdclass && typeof pdclass == "string")
			pars.add(JafWebAPI.PDClass.startNewTransaction.PAR_clName, pdclass);
		else if(pdclass && typeof pdclass == "number")
			pars.add(JafWebAPI.PDClass.startNewTransaction.PAR_cid, pdclass);
		else
		{
			this._lastMsg = "startNewTransaction: Class-Id or Name missing!";
			throw "Class-Id or Name missing!", "PDClass.startNewTransaction()";
		}
		if(typeof transactionId == 'number')
			pars.add(JafWebAPI.PDClass.startNewTransaction.PAR_tid, transactionId);
		// Neues Transaktionskonzept: die transactionId muss
		// unbedingt angegeben sein!
		else
		{
			throw "Cannot start without current transaction-ID",
					"PDClass.startNewTransaction()";
		}
		var pos = 2;
		var callbFn = null;
		if(arguments.length > pos)
		{
			if(typeof arguments[pos] == 'boolean')
			{
				pars.add(JafWebAPI.PDClass.startNewTransaction.PAR_allAttrs, arguments[pos]);
				pos++;
			}
			else if(JafWeb.isArray(arguments[pos]))
			{
				for(var i=0; i < arguments[pos].length; i++)
					pars.add(JafWebAPI.PDClass.startNewTransaction.PAR_attrs + i, arguments[pos][i]);
				pos++;
			}
		}
		if(arguments.length > pos)
		{
			if(typeof arguments[pos] == 'boolean')
			{
				pars.add(JafWebAPI.PDClass.startNewTransaction.PAR_allTo1Rels, arguments[pos]);
				pos++;
			}
			else if(JafWeb.isArray(arguments[pos]))
			{
				for(var i=0; i < arguments[pos].length; i++)
					pars.add(JafWebAPI.PDClass.startNewTransaction.PAR_rels + i, arguments[pos][i]);
				pos++;
			}
		}
		if(arguments.length > pos)
		{
			if(typeof arguments[pos] == 'function')
			{
				callbFn = arguments[pos];
				pos++;
			}
		}
		//console.log("PDClass.startNewTransaction(): Params: "+pars.toString());
		this._lastMsg = '';
		var result = null;
		var pdClass = this;
		var successFn = function(req, options)
				{
					var resp = new JResponse(req, options, pdClass);
					if(resp.hasFatalError())
						throw "Request got empty or invalid response! " +
								"PDClass.startNewTransaction():\n"+resp.getResponseText();
					if(resp.hasError())
						throw resp.getErrorMessage();
					var lockInfo = resp.get(JafWebAPI.PDClass.startNewTransaction.PROP_lockInfo); // wird vom mms benoetigt
					if(lockInfo)
					{
						var msg = (pdClass.PDMeta.getString('SC::FormTitleLocked') || "Locked by %u, logged in from %c. Locked since %t.");
						msg = msg.replace(/%c/, lockInfo[JafWebAPI.PDClass.lockInfo.PROP_client]);
						msg = msg.replace(/%u/, lockInfo[JafWebAPI.PDClass.lockInfo.PROP_user]);
						msg = msg.replace(/%i/, lockInfo[JafWebAPI.PDClass.lockInfo.PROP_uid]);
						if(typeof lockInfo[JafWebAPI.PDClass.lockInfo.PROP_locktime] == 'string') {
							msg = msg.replace(/%t/, lockInfo[JafWebAPI.PDClass.lockInfo.PROP_locktime]);
						}
						else {
							var dur = new JDuration(lockInfo[JafWebAPI.PDClass.lockInfo.PROP_locktime]);
							msg = msg.replace(/%t/, dur.toString());
						}
						pdClass._lastMsg = msg;
						//throw msg;
						//UIMessage.ok(msg);
					}
					else
					{
						result = resp.getPDObject(JafWebAPI.PDClass.startNewTransaction.PROP_transObj);
						var lockId = resp.getInt(JafWebAPI.PDClass.startNewTransaction.PROP_lockId, -1);
						if(result && lockId >= 0)
							result._lockid = lockId;
						var tid = resp.getInt(JafWebAPI.PDClass.startNewTransaction.PROP_tid, -1);
						if(result && tid >= 0)
							result._tid = tid;
					}
					pdClass.retcode = resp.getInt(JafWebAPI.PROP_retCode, -1);
					if(typeof callbFn == 'function')
						callbFn(result);
				};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callbFn == 'function')
					callbFn();
			};
		JafWeb.ajaxRequest({
				url: this.getURL(),
				authToken: this.getAuthToken(),
				method: "POST",
				async: (!!callbFn),
				scope: this,
				params: pars.getPostParams(),
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		if(!callbFn)
			return result;
	}
	
	/**
	 @function PDClass#newObject
	 @desc Ein neues Datenbankobjekt anlegen. Das Objekt wird Server-seitig
		 erzeugt und mit den Default-Werten belegt. Die Funktion gibt das Client-seitig
		 erzeugte {@link PDObject} zurück.<br/>
		 <span class="important">Hinweis:</span> Normalerweise sollte statt dieser Funktion
		 [startNewTransaction()]{@link PDClass#startNewTransaction} benutzt werden, das mit
		 einem Transaktionsobjekt arbeitet.
	 @param {mixed} pdclass Name oder numerische Id der Fachkonzeptklasse, von der das 
		 Objekt erzeugt werden soll.
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @return {PDObject} Das neue Objekt oder im Fehlerfall <code>null</code>.
	 */
	newObject(pdclass, callback) {
		var pars = new JParamPacker(JafWebAPI.PDClass.newObject.eventName, this);
		if(pdclass && typeof pdclass == "string")
			pars.add(JafWebAPI.PDClass.newObject.PAR_clName, pdclass);
		else if(pdclass && typeof pdclass == "number")
			pars.add(JafWebAPI.PDClass.newObject.PAR_cid, pdclass);
		else
		{
			this._lastMsg = "newObject: Class-Id or Name missing!";
			throw "Class-Id or Name missing!", "PDClass.newObject()";
		}
		this._lastMsg = '';
		this.retcode = -1;
		var result = null;
		var pdClass = this;
		var successFn = function(req, options)
				{
					var resp = new JResponse(req, options, pdClass);
					if(resp.hasFatalError())
						throw "Request got empty or invalid response!";
					if(resp.hasError())
						throw new resp.getErrorMessage();
					result = resp.getPDObject(JafWebAPI.PDClass.newObject.PROP_obj);
					this.retcode = resp.getInt(JafWebAPI.PROP_retCode, -1);
					pdClass.handleObjectCreated(result);
					if(typeof callback == 'function')
						callback(result);
				};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callback == 'function')
					callback();
			};
		JafWeb.ajaxRequest({
				url: this.getURL(),
				authToken: this.getAuthToken(),
				method: "GET",
				async: (!!callback),
				scope: this,
				params: pars.get(),
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		if(!callback)
			return result;
	}
	
	/// Objekte holen
	/**
	 @function PDClass#getExtentPromise
	 @desc Liste von Objekten holen.
	 @return {Promise}
	 */
	getExtentPromise(pdclass, filter, sort, thisPdo, blockSize, blockNo, asArray, attrs) {
		// TODO:
		// - Param. asArray raus (Wert: false)
		// - Nötig???
		var self = this;
		return new Promise(function(resolve, reject) {
			try {
				self.getExtent(pdclass, filter, sort, thisPdo, blockSize, blockNo,
						function(data, err) {
							//console.log("### getExtentPromise() - getExtent callback - data:", data);
							if(!data)
								reject(err); // TODO???
							else
								resolve(data);
						}, self, asArray, attrs);
			} catch (e) {
				//console.log("### getExtentPromise() - call reject");
				reject(e);
			}
		});
	}

	/**
	 @function PDClass#getExtent
	 @desc Liste von Objekten holen.<br/>
		 <h4>Beispiel</h4>
		 <pre class="prettyprint"><code>const filter = "to_EquipmentSite->Path!=''";
const sort = 'Number+';
const attrs = ['Number', 'Keyword', 'UnitActivation', 'to_EquipmentSite->Path'];
const blockSize = 50;
const blockNo = 4;
const handleExtentLoaded = function(result) {
    log('Extent geladen: ' + result.total + ' Objekte insgesamt.', true);
    log('  Block ' + blockNo + ':', true);
    for(var i=0; i < result.rows.length; i++)
       log('    ' + (blockNo * blockSize + i) + '. ' + result.rows[i].getAttribute('to_EquipmentSite->Path'), true);
  };
PDClass.getExtent('Equipment', filter, sort, null, blockSize, blockNo, handleExtentLoaded, attrs);
console.log('Warte auf Extent...');</code></pre>
	 @param {mixed} pdclass Name oder numerische Id der Fachkonzeptklasse.
	 @param {string} [filter] Filter, der die Ergebnismenge einschränkt.
	 @param {string} [sort] Sortierkriterium, nach dem die Ergebnismenge 
		 ausgegeben werden soll.
	 @param {PDObject} [thisPdo] PDObject (oder dessen OID), das als this-Objekt
		 in dem Filterausdruck referenziert werden kann.
	 @param {number} [blockSize=0] Größe des auf einmal zu holenden Ausschnitts aus der
		 Ergebnismenge. Zusammen mit <code>blockNo</code> kann damit die
		 Ergebnismenge nach und nach, blockweise abgefragt werden. Soll 
		 nicht blockweise, sondern die gesamte Ergebnismenge auf einmal
		 zurückgegeben werden, muss hier 0 angegeben werden. Beachten Sie jedoch, dass
		 dies bei großen Datenmengen das Risiko birgt, dass dadurch der Browser "hängt".
	 @param {number} [blockNo=0] Gibt in Kombination mit <code>blockSize</code> die
		 Nummer (0-basiert) des auszugebenden Blocks an.
	 @param {Function} [callbackFn] Hier kann eine JavaScript-Funktion angegeben
		 werden, die ausgeführt werden soll, wenn der Request zurückkommt.
		 In diesem Fall wird der Request asynchron ausgeführt und anschließend die
		 hier übergebene Funktion mit dem als Rückgabe beschriebenen Objekt aufgerufen.
		 Ist dieser Parameter dagegen nicht angegeben, wird der Request dagegen synchron
		 ausgeführt und das Ergebnis direkt zurückgegeben.
	 @param {boolean} [asArray=false] Gibt an, dass statt der {@link PDObject}s nur Arrays
		 zurückgegeben werden sollen.
	 @param {Array} [attrs] Statt <code>infoOnly</code> kann dieses Array angegeben werden,
		 um die einzelnen zu ermittelnden Attributwerte (in der angegebenen Reihenfolge)
		 zu ermitteln. Auch in diesem Fall werden statt der <code>PDObject</code>s Arrays
		 mit den gewünschten Werten ermittelt.
	 @return {Mixed} Wenn der Parameter <code>callbackFn</code> angegeben und der Request also asynchron
		 ausgeführt wird, wird mit <code>true</code> oder <code>false</code> zurückgegeben, ob der
		 Request abgesetzt werden konnte. Fehlt die Callback-Funktion,
		 wird das Ergebnis des Requests als JavaScript-Objekt mit folgenden Properties
		 zurückgegeben:
		 <ul>
			<li><code>rows</code> Array mit den zurückgelieferten {@link PDObject}s.</li>
			<li><code>blockNo</code> Nummer des aktuellen Blocks.</li>
			<li><code>total</code> Gesamtzahl der in dem Extent enthaltenen Objekte (kann 
			von <code>rows.length</code> abweichen).</li>
			<li><code>retCode</code> (Number): Fehler-Code.</li>
			<li><code>errorMessage</code> (String): Fehlertext, falls <code>retCode</code> ungleich 0 ist.</li>
			<li><code>hasMore</code> Zeigt an, ob in der Ergebnismenge noch weitergeblättert
			werden kann.</li>
		 </ul>
	 */
	getExtent(pdclass, filter, sort, thisPdo, blockSize, blockNo,
			callbackFn, callbackScope, asArray, attrs) {
		// TODO:
		// - Ab 3.0 werden die Tabellendaten std.-mäßig mit asArray=true
		//   geholt; an den Server wird dann, aus Gründen der Abwärtskomp.,
		//   der Wert "typed" übermittelt!
		
		//console.log("PDClass.getExtent("+pdclass+", "+filter+", "+sort+", "+thisPdo+", "+blockSize+", "+blockNo+", callbackFn, "+asArray+", "+attrs+")");
		var pars = new JParamPacker(JafWebAPI.PDClass.getExtent.eventName, this);
		if(typeof pdclass == "string" && pdclass != "")
			pars.add(JafWebAPI.PDClass.getExtent.PAR_clName, pdclass);
		else if(typeof pdclass == "number")
			pars.add(JafWebAPI.PDClass.getExtent.PAR_cid, pdclass);
		else {
			throw "First parameter (pdclass) missing or empty!";
		}
		var pos = 1;
		var thisObj = null;
		var iBlockSize = 0;
		var iBlockNo = 0;
		var fCallback = null;
		var oScope = null;
		var aAttrs = null;
		var asArray = false;
		if(arguments.length > pos && typeof arguments[pos] == "string")
		{
			if(arguments[pos] != "")
				pars.add(JafWebAPI.PDClass.getExtent.PAR_filter, arguments[pos]);
			pos++;
		}
		if(arguments.length > pos && typeof arguments[pos] == "string")
		{
			if(arguments[pos] != "")
				pars.add(JafWebAPI.PDClass.getExtent.PAR_sort, arguments[pos]);
			pos++;
		}
		if(arguments.length > pos && (arguments[pos] === null || (typeof arguments[pos] == 'object' && arguments[pos].oid)))
		{
			thisObj = arguments[pos];
			pos++;
		}
		if(arguments.length > pos && typeof arguments[pos] == "number")
		{
			if(arguments[pos] > 0)
				iBlockSize = arguments[pos];
			pos++;
		}
		if(arguments.length > pos && typeof arguments[pos] == "number")
		{
			if(iBlockSize > 0)
				iBlockNo = arguments[pos];
			pos++;
		}
		if(arguments.length > pos && (arguments[pos] === null || typeof arguments[pos] == "function"))
		{
			fCallback = arguments[pos];
			pos++;
		}
		if(arguments.length > pos && (arguments[pos] === null || typeof arguments[pos] == "object"))
		{
			oScope = arguments[pos];
			pos++;
		}
		if(arguments.length > pos && typeof arguments[pos] == "boolean")
		{
			asArray = (arguments[pos] === true ? 'typed' : '');
			pos++;
		}
		if(arguments.length > pos && (JafWeb.isArray(arguments[pos]) || typeof arguments[pos] == "string"))
		{
			aAttrs = arguments[pos];
			pos++;
		}
		// zum Request
		if(blockSize > 0)
		{
			pars.add(JafWebAPI.PDClass.getExtent.PAR_blockSize, iBlockSize);
			pars.add(JafWebAPI.PDClass.getExtent.PAR_blockNo, iBlockNo);
		}
		if(asArray)
			pars.add(JafWebAPI.PDClass.getExtent.PAR_asArr, asArray);
		if(aAttrs && typeof aAttrs == "string")
			pars.add(JafWebAPI.PDClass.getExtent.PAR_info, aAttrs);
		else if(aAttrs && JafWeb.isArray(aAttrs))
		{
			for(var i=0; i < aAttrs.length; i++)
				pars.add(JafWebAPI.PDClass.getExtent.PAR_attr + i, aAttrs[i]);
		}
		if(thisObj)
			pars.add(JafWebAPI.PDClass.getExtent.PAR_thisPdo, thisObj.oid);
		//console.warn("PDClass.getExtent(): Params: "+pars.toString());
		this._lastMsg = '';
		this.retcode = -1;
		var result = new Object();
		var pdClass = this;
		var successFn = function(req, options)
				{
					//console.warn("PDClass.getExtent(): Response: "+resp.getResponseText());
					var resp = new JResponse(req, options, pdClass);
					if(resp.hasFatalError()) {
						result.retCode = -1;
						throw "Request got empty or invalid response!";
					}
					if(resp.hasError()) {
						result.retCode = resp.getInt('retCode', -1);
						pdClass._lastMsg = resp.getErrorMessage();
						throw resp.getErrorMessage();
					}
					// out-Array fuellen
					if(asArray)
						result.rows = resp.getArray(JafWebAPI.PDClass.getExtent.PROP_rows, [], 'object', {});
					else
						result.rows = resp.getArray(JafWebAPI.PDClass.getExtent.PROP_rows, [], 'PDObject', null);
					result.blockSize = resp.getInt(JafWebAPI.PDClass.getExtent.PROP_blockSize, -1);
					result.blockNo = resp.getInt(JafWebAPI.PDClass.getExtent.PROP_blockNo, 0);
					result.total = resp.getInt(JafWebAPI.PDClass.getExtent.PROP_total, -1);
					result.retCode = resp.getInt(JafWebAPI.PROP_retCode, -1);
					result.hasMore = resp.getBool("hasMore", undefined);
					pdClass.retcode = result.retCode;
					if(typeof fCallback == "function")
					{
						//fCallback(outStr, outPdo, this.retcode);
						//try
						{
							fCallback.call((oScope || this), result);
						}
						/*catch(ex)
						{
							console.warn("Callback of PDClass.getExtent('" + pdclass + "', '" + filter +
									"', '" + sort + "', " + thisPdo + ", " + blockSize + ", " + blockNo + ", callbackFn, " +
									asArray + ", " + (JafWeb.isArray(attrs) ? "[" + attrs + "]" : attrs) +
									") throwed an exception: " + ex);
						}*/
					}
				};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof fCallback == 'function')
					fCallback.call((oScope || this));
			};
		JafWeb.ajaxRequest({
				url: this.getURL(),
				authToken: this.getAuthToken(),
				method: "POST",
				async: (!!fCallback),
				params: pars.getPostParams(),
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		if(!fCallback)
			return result;
	}
	
	/**
	 @function PDClass#foreachPDObject
	 @desc Eine Funktion für jedes Objekt einer Eregbnismenge ausführen.<br>
		 <h4>Beispiel</h4>
		 <pre class="prettyprint"><code>const report = this;
let tbl = null;
PDClass.foreachPDObject("Anwender", false, function(idx, total) {
		const anw = this;
		if(idx == 0) {
			// Tabellenklopf erzeugen
			tbl = new Table...
			// ...
		}
		// Zeile hinzufuegen
	});
if(tbl)
{
	// da wir synchron ausgefuehrt haben, sind hier alle
	// Zeilen hinzugefuegt!
	tbl.addToReport();
}</code></pre>
	 @param {String} pdClass Name der Fachkonzeptklasse.
	 @param {String} [filter] JANUS-Filterausdruck zur Einschränkung der
		 Ergebnismenge.
	 @param {String} [sort] JANUS-Sortierausdruck zur Festlegung der Reihenfolge.
	 @param {PDObject} [thisPdo] Objekt, das im Filterausdruck als "this"
		 referenziert werden kann.
	 @param {boolean} [asynchron=true] Legt fest, ob die Objekte asynchron
		 geholt werden sollen.
	 @param {Function} doFunction Funktion, die für jedes gefundene Objekt
		 ausgeführt wird. Diese bekommt beim Aufruf folgende Parameter übergeben:
		 <ul>
			 <li><code>count</code> (int): Position des Objekts in der Ergebnismenge
			 (0-basiert).</li>
			 <li><code>total</code> (int): Gesamtzahl der Objekte in der Ergebnismenge.</li>
		 </ul>
		 Das aktuell bearbeitete Objekt ist in der Funktion als <code>this</code>
		 referenzierbar. Wenn Sie ein außerhalb der Funktion bereits definiertes
		 <code>this</code> innerhalb der Funktion benutzen möchten, müssen Sie es
		 vor der Funktionsdefinition einer eigenen Variablen zuweisen und dann diese
		 benutzen.<br/>
		 Wenn Sie in der Funktion <code>false</code> zurückgeben, wird die Bearbeitung
		 abgebrochen.
	 */
	foreachPDObject(pdClass, filter, sort, thisPdo, asynchron, doFunction) {
		var _rootObj = null;
		var _clname = '';
		var _rel = '';
		var _filt = '';
		var _sort = '';
		var _thisObj = null;
		var _doFn = null;
		var _async = true;
		var pos = 0;
		if(arguments.length > pos && JafWeb.isPDObject(arguments[pos]))
		{
			_rootObj = arguments[pos];
			pos++;
			if(arguments.length > pos && typeof arguments[pos] == 'string')
			{
				_rel = arguments[pos];
				pos++;
			}
		}
		else if(arguments.length > pos && typeof arguments[pos] == 'string')
		{
			_clname = arguments[pos];
			pos++;
		}
		else {
			throw "First parameter (pdclass) missing or empty!";
		}
		if(arguments.length > pos && typeof arguments[pos] == 'string')
		{
			_filt = arguments[pos];
			pos++;
		}
		if(arguments.length > pos && typeof arguments[pos] == 'string')
		{
			_sort = arguments[pos];
			pos++;
		}
		if(arguments.length > pos && (JafWeb.isPDObject(arguments[pos]) || arguments[pos] === null))
		{
			_thisObj = arguments[pos];
			pos++;
		}
		if(arguments.length > pos && typeof arguments[pos] == 'boolean')
		{
			_async = arguments[pos];
			pos++;
		}
		if(arguments.length > pos && typeof arguments[pos] == 'function')
		{
			_doFn = arguments[pos];
			pos++;
		}
		var _extentHandler = function(res) {
				var objs = res.rows;
				for(var i = 0; i < objs.length; i++)
				{
					if(_doFn && _doFn.call(objs[i], i, res.rows.length) === false)
						break;
				}
			};
		if(_async)
		{
			if(_rootObj)
				_rootObj.getExtent(_rel, _filt, _sort, _thisObj, 0, 0, _extentHandler);
			else
				this.getExtent(_lname, _filt, _sort, _thisObj, 0, 0, _extentHandler);
		}
		else
		{
			var res = [];
			if(_rootObj)
				res = _rootObj.getExtent(_rel, _filt, _sort, _thisObj);
			else
				res = this.getExtent(_lname, _filt, _sort, _thisObj);
			_extentHandler(res);
		}
	}

	/**
	 @event PDClass#onExportExtent
	 @desc Siehe <code>exportExtent</code>.
	 */
	onExportExtent(pdclass, filter, sort, thisPdo, exportFormat, xsl, attrs,
			useGuiLabels, viewname) { }
	
	/**
	 @function PDClass#exportExtent
	 @desc Liste von {@link PDObject}s exportieren. Die Funktion startet unmittelbar
		 den Download der Export-Datei bzw. (je nach gewünschtem Format) zeigt die
		 Ergebnisseite in einem neuen Browser-Fenster an.<br/>
		 <span class="important">Hinweis:</span> Bitte beachten Sie, dass die Parameter
		 für diesen Request per GET übergeben werden müssen und deshalb die Gesamtlänge
		 der resultierenden URL begrenzt ist!
		 Die Grenze liegt bei mindestens 2000 Zeichen (die genaue Länge ist Browser-abhängig),
		 sodass diese Begrenzung normalerweise kein Problem darstellen wird. Kritisch könnte
		 höchstens der Parameter <code>filter</code> werden, wenn Sie z.B. einen sehr langen
		 OID-Filter angeben.
	 @param {mixed} pdclass Name oder numerische Id der Fachkonzeptklasse.
	 @param {string} [filter] Filter, der die Ergebnismenge einschränkt.
	 @param {string} [sort] Kriterium, nach dem die Ergebnismenge 
		 sortiert werden soll.
	 @param {PDObject} [thisPdo] PDObject (oder dessen OID), das als <code>this</code>-Objekt
		 in dem Filterausdruck referenziert werden kann.
	 @param {string} [search] Optionaler, freier Suchausdruck in ERgänzung zu <code>filter</code>.
	 @param {string} [exportFormat='csv'] Bestimmt, was für ein Export ausgegeben werden soll.
		 Mögliche Werte sind:<br/>
		 <ul>
			<li>"xml": Es wird ein Export über das JANUS-XML Interface erzeugt.</li>
			<li>"csv": Die Objekte werden als kommaseparierte Werte exportiert, d.h. jede
			Zeile enthält ein Objekt, dessen Attributwerte in doppelte Anführungszeichen
			eingeschlossen und durch Semikolons voneinander getrennt sind.</li>
			<li>"excel": Wie "csv", jedoch wird zum Maskieren innerhalb der Attributwerte
			statt "\" ein weiteres doppeltes Anführungszeichen verwendet. Dies ist die
			Variante, die von <i>Microsoft Excel</i> erwartet wird.</li>
			<li>"print": Die Objekte der Liste werden in einer tabellarisch formatierten HTML-Seite
			angezeigt. Auf die Gestaltung dieser Seite kann durch das CSS-Stylesheet
			<code>printuser.css</code> Einfluss genommen werden.</li>
		 </ul>
	 @param {string} [xsl] Falls bei <code>exportFormat</code> "xml" angegeben wurde,
		 kann hier der Dateiname eines XSL-Stylesheets angegeben werden, das zum Transformieren
		 der erzeugten XML-Datei verwendet werden soll. Die XSL-Datei muss, wie die anderen
		 XSL-Dateien, im Transformationsverzeichnis der Web-Anwendung liegen.
	 @param {string[]} [attrs] Liste mit den technischen Attributnamen, die exportiert
		 werden sollen. Falls die Liste leer bleibt oder der Parameter weggelassen wird,
		 werden automatisch alle Attribute der Klasse exportiert.
	 @param {boolean} [useGuiLabels] Falls das CSV-Exportformat benutzt wird, kann hier
		 festgelegt werden, ob für Spaltenüberschriften die technischen (<code>false</code>)
		 oder die ergonomischen Attributnamen (<code>true</code>) verwendet werden sollen.
		 Standardwert ist <code>true</code>.
	 @param {string} viewname Optionale Angabe.
	 @param {char} [csvSeparator=';'] Trennzeichen für CSV-Export.
	 @param {char} [csvDelimiter='"'] Texterkennungszeichen für CSV-Export.
	 @param {char} [csvDelimiterMask='\'] Maskierung für das Texterkennungszeichen.
	 @return {number} <code>0</code>, wenn der Download gestartet werden konnte, sonst
		 ein Fehler-Code.
	 */
	exportExtent(pdclass, filter, sort, thisPdo, search, exportFormat, xsl, attrs, useGuiLabels,
			viewname, csvSeparator, csvDelimiter, csvDelimiterMask) {
		if(this.onExportExtent(pdclass, filter, sort, thisPdo, exportFormat, xsl, attrs,
				useGuiLabels, viewname) === false)
			return;
		//console.log("PDClass.exportExtent() - viewname: '"+viewname+"'");
		const pars = new JParamPacker(JafWebAPI.PDClass.exportExtent.eventName, this);
		if(typeof pdclass == "string" && pdclass != "")
			pars.add(JafWebAPI.PDClass.exportExtent.PAR_clName, pdclass);
		else if(typeof pdclass == "number")
			pars.add(JafWebAPI.PDClass.exportExtent.PAR_cid, pdclass);
		else
			throw "PDClass.exportExtent(): first parameter (pdclass) missing or empty!";
		let pos = 1;
		let aAttrs = null;
		let filt = '';
		let format = "csv";
		if(arguments.length > pos && typeof arguments[pos] == "string")
		{
			if(arguments[pos] != "")
				filt = arguments[pos];
			pos++;
		}
		if(arguments.length > pos && typeof arguments[pos] == "string")
		{
			if(arguments[pos] != "")
				pars.add(JafWebAPI.PDClass.exportExtent.PAR_sort, arguments[pos]);
			pos++;
		}
		if(arguments.length > pos && (arguments[pos] === null || JafWeb.isPDObject(arguments[pos])))
		{
			if(arguments[pos])
				pars.add(JafWebAPI.PDClass.exportExtent.PAR_thisPdo, arguments[pos].oid);
			pos++;
		}
		else if(arguments.length > pos && typeof arguments[pos] == 'number')
		{
			if(arguments[pos])
				pars.add(JafWebAPI.PDClass.exportExtent.PAR_thisPdo, arguments[pos]);
			pos++;
		}
		if(arguments.length > pos && typeof arguments[pos] == "string")
		{
			if(arguments[pos] != "")
				pars.add(JafWebAPI.PDClass.exportExtent.PAR_search, arguments[pos]);
			pos++;
		}
		if(arguments.length > pos && typeof arguments[pos] == "string")
		{
			if(arguments[pos] != "")
			{
				pars.add(JafWebAPI.PDClass.exportExtent.PAR_exportFormat, arguments[pos]);
				format = arguments[pos];
			}
			pos++;
		}
		if(arguments.length > pos && typeof arguments[pos] == "string")
		{
			if(arguments[pos] != "")
				pars.add(JafWebAPI.PDClass.exportExtent.PAR_xsl, arguments[pos]);
			pos++;
		}
		if(arguments.length > pos && arguments[pos] && (typeof arguments[pos] == "object" ||
				typeof arguments[pos] == "string"))
			aAttrs = arguments[pos];
		if(aAttrs)
		{
			for(var i=0; i < aAttrs.length; i++)
				pars.add(JafWebAPI.PDClass.exportExtent.PAR_attr + i, aAttrs[i]);
			pos++;
		}
		if(arguments.length > pos && typeof arguments[pos] == "boolean")
		{
			if(arguments[pos] == true)
				pars.add(JafWebAPI.PDClass.exportExtent.PAR_guiLabels, true);
			pos++;
		}
		if(arguments.length > pos && typeof arguments[pos] == "string")
		{
			if(arguments[pos])
				pars.add(JafWebAPI.PDClass.exportExtent.PAR_viewname, arguments[pos]);
			pos++;
		}
		if(format == 'csv' && arguments.length > pos + 2)
		{
			if(typeof arguments[pos] == "string" && arguments[pos].length == 1 &&
					typeof arguments[pos + 1] == "string" && arguments[pos + 1].length == 1 &&
					typeof arguments[pos + 2] == "string" && arguments[pos + 2].length == 1)
			{
				pars.add(JafWebAPI.PDClass.exportExtent.PAR_delimiter,
						arguments[pos] + arguments[pos + 1] + arguments[pos + 2]);
				pos += 3;
			}
		}
		if(filt != '')
			pars.add(JafWebAPI.PDClass.exportExtent.PAR_filter, filt);
		var resDir = UIApplication.getResourceDir();
		if(resDir.charAt(resDir.length - 1) == '/')
			resDir = resDir.substring(0, resDir.length - 1);
		pars.add(JafWebAPI.PDClass.exportExtent.PAR_resDir, resDir);
		this._lastMsg = '';
		this.retcode = -1;
		// falls CSV-Format, Datei-Download verwenden
		var url = "";
		if(format === "csv" || format === "excel")
			url = this.getDownloadURL() + // UIApplication.getDownloadUrlRoot()
					"?janusFileOperation=downloadWebEvent&iid=" + this.PDMeta.getInstanceID() +
					(this.getAuthToken() ? '&sessionId=' + this.getAuthToken() : '') +
					"&janusWebEvent=" + pars.getEventString(true);
		else
			url = this.getURL() +
					"?JanusApplicationName=GenericJanusApplication&iid=" + this.PDMeta.getInstanceID() +
					(this.getAuthToken() ? '&sessionId=' + this.getAuthToken() : '') +
					"&oid=&janusWebEvent=" + pars.getEventString(true);
		// Achtung: URLs ueber 2000 Zeichen Laenge (die genaue Grenze ist Browser-
		// abhaengig) - z.B. durch extrem lange Filterausdruecke - koennen
		// den Download scheitern lassen! Die Web Server SOLLTEN in diesem Fall
		// den Code 414 zurueckgeben, aber auch das ist nicht verlaesslich.
		// Siehe auch #43389
		if(url.length > 2000) // 2000 gilt als fuer alle Browser noch sicherer Wert
		{
			var pdClass = this;
			if(format === "csv" || format === "excel")
				url = this.getDownloadURL() + // UIApplication.getDownloadUrlRoot()
						"?janusFileOperation=downloadWebEvent&iid=" + this.PDMeta.getInstanceID() +
						"&sessionId=" + this.getAuthToken() +
						"&janusWebEvent=" + JafWebAPI.PDClass.exportExtent.eventName +
						"&pickup=";
			else
				url = this.getURL() +
						"?JanusApplicationName=GenericJanusApplication&iid=" + this.PDMeta.getInstanceID() +
						"&sessionId=" + this.getAuthToken() +
						"&janusWebEvent=" + JafWebAPI.PDClass.exportExtent.eventName +
						"&oid=&pickup=";
			var paramPostCallback = function(req, options) {
					var resp = new JResponse(req, options, pdClass);
					var pickupId = resp.getString('parid');
					window.open(url + pickupId);
				};
			JafWeb.ajaxRequest({
					url: this.getURL(),
					authToken: this.getAuthToken(),
					method: "POST",
					async: true,
					params: pars.getPostParams('preGetEvent'),
					callerName: "PDClass.exportExtent",
					disableCaching: true,
					success: paramPostCallback
				});
			return;
		}
		window.open(url);
	}

	/**
	 @function PDClass#getExtentGroups
	 @desc Holt Gruppierungsinformationen über den angegebenen Extent.
	 @param {string} pdclass Name oder numerische Id der Fachkonzeptklasse.
	 @param {string} groupBy Attribut, nach dem gruppiert werden soll. Typischerweise
		 handelt es sich hierbei um ein Enum-Atribut der Klasse, so dass für jede
		 Ausprägung des Aufzählungstypen eine Gruppierung erzeugt wird.
	 @param {string} [filter] Filter, der die Ergebnismenge einschränkt.
	 @param {PDObject} [thisPdo] PDObject (oder dessen OID), das als <code>this</code>-Objekt
		 in dem Filterausdruck referenziert werden kann.
	 @param {Function} [callbackFn] Hier kann eine JavaScript-Funktion angegeben
		 werden, die ausgeführt werden soll, wenn der Request zurückkommt.
		 In diesem Fall wird der Request asynchron ausgeführt. Ist dieser
		 Parameter dagegen nicht angegeben, wird der Request synchron
		 ausgeführt und das Ergebnis als Array von {@link PDObject}s zurückgegeben.
	 @return {boolean} Wenn der Parameter callbackFn angegeben und also asynchron
		 ausgeführt wird, wird mit <code>true</code> oder <code>false</code> zurückgegeben, ob der
		 Request abgesetzt werden konnte. Fehlt die Callback-Funktion,
		 wird das Ergebnis des Requests als Array von {@link PDGroupInfo} zurückgegeben.
	 */
	getExtentGroups(pdclass, groupBy, filter, thisPdo, callbackFn) {
		var pars = new JParamPacker(JafWebAPI.PDClass.getExtentGroups.eventName, this);
		if(typeof pdclass == "string" && pdclass != "")
			pars.add(JafWebAPI.PDClass.getExtentGroups.PAR_clName, pdclass);
		else if(typeof pdclass == "number")
			pars.add(JafWebAPI.PDClass.getExtentGroups.PAR_cid, pdclass);
		else {
			throw "First parameter (pdclass) missing or empty!";
		}
		var pos = 1;
		if(arguments.length <= pos || typeof arguments[pos] !== "string" || arguments[pos] == '') {
			throw "group-by criteria missing!";
		}
		var fCallback = null;
		pars.add(JafWebAPI.PDClass.getExtentGroups.PAR_groupBy, arguments[pos++]);
		if(arguments.length > pos && typeof arguments[pos] == "string")
		{
			if(arguments[pos] != "")
				pars.add(JafWebAPI.PDClass.getExtentGroups.PAR_filter, arguments[pos]);
			pos++;
		}
		if(arguments.length > pos && (arguments[pos] == null || JafWeb.isPDObject(arguments[pos])))
		{
			if(arguments[pos] != null)
				pars.add(JafWebAPI.PDClass.getExtentGroups.PAR_thisObj, arguments[pos]);
			pos++;
		}
		if(arguments.length > pos && typeof arguments[pos] == "function")
			fCallback = arguments[pos++];
		var result = new Array();
		this._lastMsg = '';
		this.retcode = -1;
		var pdClass = this;
		var successFn = function(req, options)
				{
					var resp = new JResponse(req, options, pdClass);
					if(resp.hasFatalError())
						throw "Request got empty or invalid response!";
					if(resp.hasError())
						throw resp.getErrorMessage();
					var infos = resp.getArray(JafWebAPI.PDClass.getExtentGroups.PROP_infos, [], 'object', null);
					for(var i=0; i < infos.length; i++)
						result.push(new PDGroupInfo(infos[i].filter, infos[i].value, infos[i].count));
					pdClass.retcode = resp.getInt(JafWebAPI.PROP_retCode, -1);
					if(typeof fCallback == "function")
						fCallback.call(this, result);
				};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof fCallback == 'function')
					fCallback(null);
			};
		JafWeb.ajaxRequest({
				url: this.getURL(),
				authToken: this.getAuthToken(),
				method: "POST",
				async: (!!fCallback),
				params: pars.getPostParams(),
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		if(!fCallback)
			return result;
	}

	/**
	 @function PDClass#moveInExtent
	 @desc Bewegen von einem oder mehreren Fachkonzeptobjekten in einem <i>Orderable Extent</i>.
	 @param {mixed} pdclass Name oder numerische Id der Fachkonzeptklasse.
	 @param {mixed} objs Array von {@link PDObject}s oder Objekt-IDs (unterer Teil).
		 Alternativ kann auch ein {@link PDObject} oder eine Objekt-ID angegeben werden.
	 @param {number} idx Der 0-basiert Index im gesamten Extent, an den das bzw. die
		 Objekte verschoben werden sollen.
	 @param {Function} [callbackFn] Hier kann eine JavaScript-Funktion angegeben
		 werden, die ausgeführt werden soll, wenn der Request zurückkommt.
		 In diesem Fall wird der Request asynchron ausgeführt. Ist dieser
		 Parameter dagegen nicht angegeben, wird der Request synchron
		 ausgeführt und das Ergebnis als Array von {@link PDObject}s zurückgegeben.
	 @return {mixed} Im Fehlerfall wird ein Fehlertext zurückgegeben, sonst <code>0</code>.
	 */
	moveInExtent(pdclass, objs, idx, callback) {
		//console.log("### PDClass.moveInExtent('" + pdclass + "', objs, " + idx + ") - objs:", objs);
		var pars = new JParamPacker(JafWebAPI.PDClass.moveInExtent.eventName, this);
		var _cid = 0;
		if(pdclass && typeof pdclass == "string")
		{
			pars.add(JafWebAPI.PDClass.moveInExtent.PAR_clName, pdclass);
			_cid = this.PDMeta.getId(pdclass);
		}
		else if(pdclass && typeof pdclass == "number")
		{
			pars.add(JafWebAPI.PDClass.moveInExtent.PAR_cid, pdclass);
			_cid = pdclass;
		}
		else
		{
			this._lastMsg = "moveInExtent: Class-Id or Name missing!";
			return null;
		}
		var _objs = [ ];
		if(JafWeb.isArray(objs))
		{
			for(var i=0; i < objs.length; i++)
			{
				if(!objs[i])
					continue;
				if(typeof objs[i] == 'number')
					_objs.push(objs[i]);
				else if(JafWeb.isPDObject(objs[i]))
					_objs.push('' + objs[i].oidLow);
			}
		}
		else if(typeof objs == 'number')
			_objs.push(objs);
		else if(JafWeb.isPDObject(objs))
			_objs.push('' + objs.oidLow);
		pars.add(JafWebAPI.PDClass.moveInExtent.PAR_oids, _objs.join(','));
		pars.add(JafWebAPI.PDClass.moveInExtent.PAR_idx, idx);
		//pars.add("relname", relName);
		this._lastMsg = '';
		this.retcode = -1;
		var result = -1;
		var pdClass = this;
		var successFn = function(req, options)
				{
					var resp = new JResponse(req, options, pdClass);
					if(resp.hasFatalError())
						throw "Request got empty or invalid response!";
					if(resp.hasError())
						throw resp.getErrorMessage();
					result = resp.getInt(JafWebAPI.PROP_retCode, -1);
					if(result > 0)
						result = resp.getString(JafWebAPI.PDClass.moveInExtent.PROP_retMsg);
					pdClass.retcode = result;
					if(typeof callback == 'function')
						callback(result);
				};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callback == 'function')
					callback();
			};
		JafWeb.ajaxRequest({
				url: this.getURL(),
				authToken: this.getAuthToken(),
				method: "POST",
				async: (!!callback),
				params: pars.getPostParams(),
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		return result;
	}

	/**
	 @function PDClass#ptr
	 @desc Client-seitige Repräsentation eines Fachkonzeptobjekts holen.
	 @param {mixed} pdclass Name oder numerische Id der Fachkonzeptklasse. Kann auch
		 weggelassen werden, wenn der verbleibende Parameter einen
		 vollständigen OID-String darstellt.
	 @param {mixed} oid OID (String) oder, wenn der pdclass-Parameter
		 belegt ist, deren unterer, numerischer Teil.
	 @param {boolean} [noCache=false] <code>true</code> zeigt an, dass das Objekt nicht
		 aus dem Cache, sondern auf jeden Fall neu vom Server geholt werden soll.
	 @param {Mixed} [attrs=true] Die zu ermittelnden Attribute - entweder eine Liste der
		 technischen Attributnamen oder ein boolescher Typ, der anzeigt, ob alle Attribute
		 laut Modell (<code>true</code>) oder gar keine Attribute (<code>false</code>)
		 ermittelt werden sollen.
	 @param {Mixed} [rels=false] Die zu ermittelnden Beziehungen. Wenn ein boolescher Typ
		 angegeben wird, zeigt <code>false</code> an, dass keine Beziehungen geholt werden
		 sollen, <code>true</code> dagegen, dass alle Zu-1-Beziehungen laut Modell geholt
		 werden sollen. Dabei wird für jede Zu-1-Beziehung das ggf. verknüpfte {@link PDObject}
		 mit lediglich dem im Modell spezifizierten Object Ident, jedoch ohne die übrigen
		 Attribute geholt. Statt des booleschen Typs kann eine Liste der zu ermittelnden
		 Beziehungen angegeben werden. Darin kann jedem technischen Beziehungsnamen, getrennt
		 durch Komma, der Template-Ausdruck für die RelationInfo angehängt werden. Das ist
		 wichtig, um spätere, vereinzelte [getStatus()]{@link PDObject#getStatus}-Aufrufe
		 zu vermeiden.
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @return {PDObject} Das Objekt oder im Fehlerfall <code>null</code>.
	 */
	ptr(pdclass, oid, noCache, attrs, rels, callback) {
		//console.log("PDClass.ptr('"+pdclass+"', "+oid+", "+noCache+")");
		var pars = new JParamPacker(JafWebAPI.PDClass.ptr.eventName, this);
		var _cid = 0;
		var _oid = 0;
		var _noCache = false;
		var callbFn = null;
		var pos = 0;
		if(arguments.length > pos && typeof arguments[pos] == 'string' &&
				arguments[pos].match(/[0-9]+:[0-9]+/))
		{
			// zerlegen
			var tmp = arguments[pos].split(':');
			if(tmp.length == 2)
			{
				_cid = parseInt(tmp[0], 10);
				_oid = parseInt(tmp[1], 10);
			}
			pars.add(JafWebAPI.PDClass.ptr.PAR_cid, _cid);
			pars.add(JafWebAPI.PDClass.ptr.PAR_oidLow, _oid);
			pos++;
		}
		else if(arguments.length > pos)
		{
			if(pdclass && typeof pdclass == "string")
			{
				pars.add(JafWebAPI.PDClass.ptr.PAR_clName, pdclass);
				_cid = this.PDMeta.getId(pdclass);
				pos++;
			}
			else if(pdclass && typeof pdclass == "number")
			{
				pars.add(JafWebAPI.PDClass.ptr.PAR_cid, pdclass);
				_cid = pdclass;
				pos++;
			}
			else {
				throw "Class-Id or Name missing!";
			}
			if(oid && typeof oid == "number")
			{
				pars.add(JafWebAPI.PDClass.ptr.PAR_oidLow, oid);
				_oid = oid;
				pos++;
			}
			else
			{
				var iOid = parseInt(oid, 10);
				if(!Number.isNaN(iOid))
				{
					pars.add(JafWebAPI.PDClass.ptr.PAR_oidLow, oid);
					_oid = iOid;
					pos++;
				}
				else {
					throw "Oid missing!";
				}
			}
		}
		// Im Cache?
		if(arguments.length > pos && typeof arguments[pos] == 'boolean')
		{
			_noCache = arguments[pos];
			pos++;
		}
		if(arguments.length > pos)
		{
			if(typeof arguments[pos] == 'boolean')
			{
				pars.add(JafWebAPI.PDClass.ptr.PAR_allAttrs, arguments[pos]);
				pos++;
			}
			else if(JafWeb.isArray(arguments[pos]))
			{
				for(var i=0; i < arguments[pos].length; i++)
					pars.add(JafWebAPI.PDClass.ptr.PAR_attrs + i, arguments[pos][i]);
				pos++;
			}
		}
		if(arguments.length > pos)
		{
			if(typeof arguments[pos] == 'boolean')
			{
				pars.add(JafWebAPI.PDClass.ptr.PAR_allTo1Rels, arguments[pos]);
				pos++;
			}
			else if(JafWeb.isArray(arguments[pos]))
			{
				for(var i=0; i < arguments[pos].length; i++)
					pars.add(JafWebAPI.PDClass.ptr.PAR_rels + i, arguments[pos][i]);
				pos++;
			}
		}
		if(typeof arguments[pos] == 'function')
		{
			callbFn = arguments[pos];
			pos++;
		}
		if(!_noCache && this.PDObjectCache)
		{
			var obj = this.PDObjectCache.get(_cid, _oid);
			if(obj != null)
			{
				if(typeof callbFn == 'function')
					callbFn(obj);
				else
					return obj;
			}
		}
		//console.log("PDClass.ptr(): Params: "+pars.toString());
		this._lastMsg = '';
		this.retcode = -1;
		var result = null;
		var pdClass = this;
		var successFn = function(req, options)
				{
					var resp = new JResponse(req, options, pdClass);
					if(resp.hasFatalError())
						throw "Request got empty or invalid response!";
					// Wenn das Objekt nicht gefunden wird, ist das kein Grund fuer eine Exception!
					//if(resp.hasError())
					//	throw resp.getErrorMessage();
					result = resp.getPDObject(JafWebAPI.PDClass.ptr.PROP_obj, null);
					pdClass.retcode = resp.getInt(JafWebAPI.PROP_retCode, -1);
					if(typeof callbFn == 'function')
						callbFn(result);
				};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callbFn == 'function')
					callbFn(result);
			};
		JafWeb.ajaxRequest({
				url: this.getURL(),
				authToken: this.getAuthToken(),
				method: "POST",
				async: (!!callbFn),
				params: pars.getPostParams(),
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		if(!callback)
			return result;
	}
	
	/**
	 @function PDClass#ptrs
	 @desc Mehrere Client-seitige Objekt-Repräsentationen in einem Rutsch
		 holen.
	 @param {mixed} [pdclass] Name oder numerische Id der Fachkonzeptklasse. Kann auch
		 weggelassen werden, wenn der verbleibende Parameter
		 vollständige OID-Strings von Objekten (auch verschiedener) Klassen enthält.
	 @param {string[]} oids  Array der OIDs.
	 @param {Mixed} [attrs=true] Die zu ermittelnden Attribute - entweder eine Liste der
		 technischen Attributnamen oder ein boolescher Typ, der anzeigt, ob alle Attribute
		 laut Modell (<code>true</code>) oder gar keine Attribute (<code>false</code>)
		 ermittelt werden sollen. <em>Das geht jedoch nur, wenn die zu holenden Objekte der
		 selben Klasse angehören!</em>
	 @param {Mixed} [rels=false] Die zu ermittelnden Beziehungen. Wenn ein boolescher Typ
		 angegeben wird, zeigt <code>false</code> an, dass keine Beziehungen geholt werden
		 sollen, <code>true</code> dagegen, dass alle Zu-1-Beziehungen laut Modell geholt
		 werden sollen. Dabei wird für jede Zu-1-Beziehung das ggf. verknüpfte {@link PDObject}
		 mit lediglich dem im Modell spezifizierten Object Ident, jedoch ohne die übrigen
		 Attribute geholt. Statt des booleschen Typs kann eine Liste der zu ermittelnden
		 Beziehungen angegeben werden. Darin kann jedem technischen Beziehungsnamen, getrennt
		 durch Komma, der Template-Ausdruck für die RelationInfo angehängt werden. Das ist
		 wichtig, um spätere, vereinzelte [getStatus()]{@link PDObject#getStatus}-Aufrufe
		 zu vermeiden. <em>Das geht jedoch nur, wenn die zu holenden Objekte der
		 selben Klasse angehören!</em>
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @return {PDObject[]} Array mit den {@link PDObject}s in der Reihenfolge
		 der OIDs im <code>oids</code>-Parameter. Für Objekte, die nicht gefunden wurden,
		 stehten <code>null</code>-Elemente in dem Array.
	 */
	ptrs(pdclass, oids, attrs, rels, callback) {
		var pars = new JParamPacker(JafWebAPI.PDClass.ptrs.eventName, this);
		var pos = 0;
		var result = new Array();
		var callbFn = null;
		if(pdclass && typeof pdclass == "string")
		{
			pars.add(JafWebAPI.PDClass.ptrs.PAR_clName, pdclass);
			pos++;
		}
		else if(pdclass && typeof pdclass == "number")
		{
			pars.add(JafWebAPI.PDClass.ptrs.PAR_cid, pdclass);
			pos++;
		}
		if(arguments.length <= pos || typeof arguments[pos] != "object" ||
				!arguments[pos].length) {
			throw "Oid list missing!";
		}
		for(var i=0; i < arguments[pos].length; i++)
			pars.add(JafWebAPI.PDClass.ptrs.PAR_oid + i, arguments[pos][i]);
		pos++;
		if(arguments.length > pos)
		{
			if(typeof arguments[pos] == 'boolean')
			{
				pars.add(JafWebAPI.PDClass.ptrs.PAR_allAttrs, arguments[pos]);
				pos++;
			}
			else if(JafWeb.isArray(arguments[pos]))
			{
				for(var i=0; i < arguments[pos].length; i++)
					pars.add(JafWebAPI.PDClass.ptrs.PAR_attrs + i, arguments[pos][i]);
				pos++;
			}
		}
		if(arguments.length > pos)
		{
			if(typeof arguments[pos] == 'boolean')
			{
				pars.add(JafWebAPI.PDClass.ptrs.PAR_allTo1Rels, arguments[pos]);
				pos++;
			}
			else if(JafWeb.isArray(arguments[pos]))
			{
				for(var i=0; i < arguments[pos].length; i++)
					pars.add(JafWebAPI.PDClass.ptrs.PAR_rels + i, arguments[pos][i]);
				pos++;
			}
		}
		if(typeof arguments[pos] == 'function')
		{
			callbFn = arguments[pos];
			pos++;
		}
		this._lastMsg = '';
		this.retcode = -1;
		var result = [ ];
		var pdClass = this;
		var successFn = function(req, options)
				{
					var resp = new JResponse(req, options, pdClass);
					if(resp.hasFatalError())
						throw "Request got empty or invalid response!";
					if(resp.hasError())
						throw resp.getErrorMessage();
					result = resp.getArray(JafWebAPI.PDClass.ptrs.PROP_objs, [], 'PDObject', null);
					pdClass.retcode = resp.getInt(JafWebAPI.PROP_retCode, -1);
					if(typeof callbFn == 'function')
						callbFn(result);
				};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callbFn == 'function')
					callbFn(null);
			};
		JafWeb.ajaxRequest({
				url: this.getURL(),
				authToken: this.getAuthToken(),
				method: "POST",
				async: (!!callbFn),
				params: pars.get(),
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		if(!callback)
			return result;
	}
	
	/// Arbeiten mit Benutzern
	/**
	 @function PDClass#changePassword
	 @desc Passwort ändern.
	 @param {string} passw Passwort des aktuellen Benutzers.
	 @param {number} [uid=-1] Id des Benutzers, dessen Passwort geändert werden soll.
		 Wird dieser Parameter nicht oder mit <code>-1</code> angegeben, wird das
		 Passwort des aktuellen Benutzers geändert.
	 @param {string} newPassw Das neue Passwort.
	 @param {string} [passwConfirm=false] Passwortwiederholung (optional). Wird diese
		 angegeben, wird die Übereinstimmung von Passwort und Wiederholung Server-seitig
		 geprüft.
	 @param {boolean} [crypt=false] Zeigt an, ob das Passwort vor Übertragung verschlüsselt
		 werden soll. Standardwert ist <code>false</code>.
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @return {string} Falls das Passwort nicht gesetzt werden konnte, wird
		 ein Fehlertext zurückgegeben.
	 */
	changePassword(passw, uid, newPassw, passwConfirm, crypt, callback) {
		/* @param {boolean} crypted Legt fest, ob das Passwort verschlüsselt übertragen 
		werden soll. Fehlt der Parameter, wird <code>false</code> angenommen.*/
		// crypt: mittels Ext.ux.Crypto?
		// Der Benutzer muss ueber das Recht ClientInfo::changePassword verfuegen!
		// Wenn crypted==true, muss das Passwort bereits verschluesselt uebergeben
		// werden. Die Verschluesselung kann mit Javacrypt.crypt() gemacht werden, 
		// wenn crypt.js eingebunden ist.
		var pars = new JParamPacker(JafWebAPI.PDClass.changePassword.eventName, this);
		var callbFn = null;
		var myPw;
		var pos = 0;
		if(arguments.length > pos + 1 && typeof arguments[pos] == 'string' &&
				typeof arguments[pos + 1] == 'number')
		{
			myPw = arguments[pos];
			pos++;
		}
		else if(arguments.length > pos && arguments[pos] === undefined)
			pos++;
		if(arguments.length > pos && typeof arguments[pos] == 'number')
		{
			if(arguments[pos] >= 0)
				pars.add('uid', arguments[pos]);
			pos++;
		}
		var newPw = '', newPwConfirm = '';
		if(arguments.length > pos && typeof arguments[pos] == 'string')
		{
			newPw = arguments[pos];
			pos++;
		}
		if(arguments.length > pos && typeof arguments[pos] == 'string')
		{
			newPwConfirm = arguments[pos];
			pos++;
		}
		var crypt = false;
		if(arguments.length > pos && typeof arguments[pos] == 'boolean')
		{
			crypt = arguments[pos];
			pos++;
		}
		if(arguments.length > pos && typeof arguments[pos] == 'function')
		{
			callbFn = arguments[pos];
			pos++;
		}
		if(newPwConfirm && newPwConfirm !== newPw)
		{
			this._lastMsg = this.PDMeta.getString('SC::UMIncorrectPasswordConfirmation');
			this.retcode = -1;
			if(callbFn)
				callbFn(this._lastMsg);
			else
				return this._lastMsg;
		}
		if(crypt)
		{
			if(myPw !== undefined)
				pars.add(JafWebAPI.PDClass.changePassword.PAR_passw, (myPw ? this.PDTools.md5sum(myPw) : ''));
			pars.add(JafWebAPI.PDClass.changePassword.PAR_newPassw, this.PDTools.md5sum(newPw));
			//pars.add(JafWebAPI.PDClass.changePassword.PAR_passwRetype, newPwConfirm);
			pars.add(JafWebAPI.PDClass.changePassword.PAR_crypted, crypt);
		}
		else
		{
			if(myPw !== undefined)
				pars.add(JafWebAPI.PDClass.changePassword.PAR_passw, (myPw || ''));
			pars.add(JafWebAPI.PDClass.changePassword.PAR_newPassw, newPw);
			//pars.add(JafWebAPI.PDClass.changePassword.PAR_passwRetype, newPwConfirm);
		}
		this._lastMsg = '';
		this.retcode = -1;
		var pdClass = this;
		var successFn = function(req, options)
				{
					var resp = new JResponse(req, options, pdClass);
					if(resp.hasFatalError())
						throw "Request got empty or invalid response!";
					this.retcode = resp.getInt(JafWebAPI.PROP_retCode, -1);
					this._lastMsg = resp.getErrorMessage();
					if(this.retcode && !this._lastMsg && pdClass.PDMeta)
						pdClass._lastMsg = pdClass.PDMeta.getString(this.retcode).replace(/%v/, PDClass.getUserName());
					if(typeof callbFn == 'function')
						callbFn(pdClass._lastMsg);
					// TODO: ggf. focus-Rueckgabe auswerten
				};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callbFn == 'function')
					callbFn();
			};
		JafWeb.ajaxRequest({
				url: this.getURL(),
				authToken: this.getAuthToken(),
				method: "GET",
				async: (!!callbFn),
				params: pars.get(),
				scope: this,
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		if(!callback)
			return this._lastMsg;
	}
	
	/**
	 @function PDClass#changeUser
	 @desc Den Benutzer wechseln.<br/>
		 <span class="important">Hinweis:</span> Im standardmäßigen JafWeb-Client muss diese Funktion
		 normalerweise nicht aufgerufen werden, weil die Anmeldung
		 über die vorgeschaltete Login-Seite erfolgt. Falls diese
		 Funktion benutzt wird - z.B. in einer eigenständigen HTML-Seite,
		 die nur die PD-Schicht des JafWeb benutzt -, muss die Verbindung
		 mit [ClientInfo.disconnectClient()]{@link ClientInfo#disconnectClient} wieder
		 abgebaut werden! Für ein Beispiel vgl. a. {@tutorial tut_jafwebPD}.
		 <span class="important">Hinweis:</span> Nach Ausführung dieser Funktion
		 kann keine Funktion mehr ausgeführt werden, die einen Web-Request auslöst.
		 Das ist dann erst wieder möglich, wenn mit
		 [PDClass.changeUser()]{@link PDClass#changeUser} eine neue
		 Session gestartet wurde.
	 @param {string} login Login-Kennung des neuen
		 Benutzers.
	 @param {string} passw Passwort des neuen Benutzers.
	 @param {string} [principal] Optionaler Mandantenname, falls Sie
		 sich mit einer mandantenfähigen Anmwendung verbinden.
	 @param {string} [clientType] Optionale Angabe über die Anwendung. Diese wird,
		 falls angegeben, auf der Server-Seite in das <code>ClientInfo</code>-Objekt
		 geschrieben und ermöglicht es so, verschiedene JafWeb-Clients am selben
		 Server zu betreiben. Bei der Anmeldung (in <code>PDClass::changeUser()</code>
		 auf der Server-Seite) kann diese Information zum Beispiel (mittels
		 <code>cInfoP-&gt;getClientType()</code>) abgefragt werden, um festzustellen,
		 ob der sich anmeldende Benutzer für die angegebene Art des Clients
		 freigeschaltet ist. <span class="important">Hinweis</span>: Beachten Sie bitte,
		 dass der <code>clientType</code> nur auf dem Server gesetzt wird, wenn die
		 Kommunikation via FCGI-Service benutzt wird; die SoapWebApp unterstützt diese
		 Angabe leider nicht.
	 @param {Function} [callback] Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @return {Object} Es wird Objekt mit folgenden Properties (bei synchronem Aufruf)
		 zurückgegeben bzw. (bei asynchronem Aufruf) an die Callback-Funktion
		 übergeben:
		 <ul>
			<li><code>errCode</code> (Number): Hat den Wert <code>0</code>, wenn
			die Anmeldung erfolgreich war.</li>
			<li><code>msg</code> (String): Kann einen erläuternden Text zu dem
			Fehler-Code enthalten.</li>
		 </ul>
	 */
	changeUser(login, passw, principal, language, clientType, callback) {
		// TODO:
		//@param {string} [language] Optionale Angabe des Codes einer Anwenudngssprache.
		//Falls angegeben, wird direkt in dieser Sprache angemeldet. Etwaige
		//Fehlermeldungen dieser Funktion werden in dieser Sprache ausgegeben.
		var pdClass = this;
		var pars = new JParamPacker("PDClass.changeUser" /*JafWebAPI.PDClass.changeUser.eventName*/, pdClass);
		var callbFn = null;
		var pos = 1;
		if(arguments.length < 2)
			throw "At least 2 parameters needed.";
		while(pos < arguments.length)
		{
			if(typeof arguments[pos] == 'function')
			{
				callbFn = arguments[pos];
				break;
			}
			pos++;
		}
		this._lastMsg = '';
		this.retcode = -1;
		var result = {
				errCode: -1,
				msg: ''
			};
		var successFn = function(response, options, request)
			{
				console.log("### changeUser - response:", response);
				
				const tok = (request ? request.getResponseHeader('Authorization') : '');
				//console.log("### Header: '" + tok + "'");
				if(tok && tok.substring(0, 8) == 'Session ')
					pdClass._authToken = tok.substring(8);
				//console.log("### AuthToken: '" + pdClass._authToken + "'");
				
				var resp = new JResponse(response, options, pdClass);
				// Der AJAX-Request von JQuery verwirft die Exceptions, deshalb (und
				// wegen moeglichen asynchronen Aufrufs) sind die Exceptions hier ungeeignet!
				if(resp.hasFatalError())
				{
					result.errCode = -1;
					result.msg = "Request got empty or invalid response!\n" +
						"PDClass.changeUser():\n"+resp.getResponseText();
				}
				//	throw "Request got empty or invalid response!";
				else if(resp.hasError())
				{
					result.errCode = -1;
					result.msg = resp.getErrorMessage();
					//result.msg = "Request got empty or invalid response!\n" +
					//	"PDClass.changeUser():\n"+resp.getResponseText();
				}
				//	throw resp.getErrorMessage();
				else if(typeof pdClass.PDMeta != 'undefined')
				{
					result.errCode = resp.getInt(JafWebAPI.PROP_retCode, -1);
					if(result.errCode != 0)
						result.msg = pdClass.PDMeta.getString(pdClass.retcode);
					pdClass.retcode = result.errCode;
					pdClass._lastMsg = result.msg;

					const iid = resp.getInt('iid', 0);
					if(iid)
						pdClass.PDMeta._instanceID = iid;
				}
				if(result.errCode <= 0)
				{
					var authToken = resp.getString('sessionId');
					if(authToken)
						pdClass._authToken = authToken;
						//UIApplication.setAuthToken(authToken);
				}
				if(typeof callbFn == 'function')
					callbFn(result);
			};
		var failureFn = function(response, opts) {
				result.msg = "Network communication error in " + pars.getEventName() + "!";
				console.error(result.msg);
				if(typeof callbFn == 'function')
					callbFn(result);
			};
		JafWeb.ajaxRequest({
				url: pdClass.getURL(),
				authToken: pdClass.getAuthToken(),
				method: "GET",
				async: (!!callbFn),
				params: {
						janusWebEvent: pars.getEventString(false),
						JanusApplicationName: "GenericJanusApplication",
						iid: (typeof PDMeta == 'object' ? PDMeta.getInstanceID() : ''),
						oid: "",
						fmt: "json", // statt der Login-Seite JSON zurueckgeben (benoetigt soapwebapp2)!
						JanusLogin: login,
						JanusPasswd: passw,
						JanusPrinc: (principal || ''),
						JanusLang: (language || ''),
						ClientType: (clientType || '')
					},
				scope: this,
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			} );
		return result;
	}
	
	/**
	 @function PDClass#getUserId
	 @desc Zu einer Login-Kennung die numerische
		 Benutzer-Id ermitteln.
	 @param {string} [name] Login-Kennung. Fehlt dieser Parameter oder
		 wird er leer angegeben, so wird die Kennung des aktuell
		 angemeldeten Benutzers ermittelt.
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @return {number} Die zugehörige User-Id.
	 */
	getUserId(name, callback) {
		if(!name && this._userId > 0)
		{
			if(callback)
			{
				callback(this._userId);
				return;
			}
			else
				return this._userId;
		}
		var pars = new JParamPacker(JafWebAPI.PDClass.getUserId.eventName, this);
		if(name)
			pars.add(JafWebAPI.PDClass.getUserId.PAR_name, name);
		this._lastMsg = '';
		this.retcode = -1;
		this._userId = 0;
		var pdClass = this;
		var successFn = function(req, options)
				{
					var resp = new JResponse(req, options, pdClass);
					if(resp.hasFatalError())
						throw "Request got empty or invalid response!";
					if(resp.hasError())
						throw resp.getErrorMessage();
					pdClass._userId = resp.getInt(JafWebAPI.PDClass.getUserId.PROP_id);
					pdClass.retcode = resp.getInt(JafWebAPI.PROP_retCode, -1);
					if(typeof callback == 'function')
						callback(pdClass._userId);
				};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callback == 'function')
					callback();
			};
		JafWeb.ajaxRequest({
				url: this.getURL(),
				authToken: this.getAuthToken(),
				method: "GET",
				async: (!!callback),
				params: pars.get(),
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		if(!callback)
			return this._userId;
	}
	
	/**
	 @function PDClass#getUserName
	 @desc Zu einer gegebenen User-Id die Login-Kennung
	 	ermitteln.
	 @param {number} [uid=-1] Numerische Benutzer-Id. Fehlt die oder wird <code>-1</code>
	 	angegeben, so wird der Name des aktuell angemeldeten Benutzers ermittelt.
	 @param {Function} callback Optionale Callback-Funktion, die
	 	nach Verarbeitung der Antwort aufgerufen werden soll. Wird
	 	diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
	 	und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
	 	dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
	 	Ergebnis wird direkt zurückgegeben.
	 @return {string} Der Login-Name zu der User-Id.
	 */
	getUserName(uid, callback) {
		if(this._userName)
		{
			if(callback)
			{
				callback(this._userName);
				return;
			}
			else
				return this._userName;
		}
		var pars = new JParamPacker(JafWebAPI.PDClass.getUserName.eventName, this);
		var userId = -1;
		if(uid !== undefined)
			userId = -1;
		pars.add(JafWebAPI.PDClass.getUserName.PAR_uid, userId);
		this._lastMsg = '';
		this.retcode = -1;
		this._userName = '';
		var pdClass = this;
		var successFn = function(req, options)
				{
					var resp = new JResponse(req, options, pdClass);
					if(resp.hasFatalError())
						throw "Request got empty or invalid response!";
					if(resp.hasError())
						throw resp.getErrorMessage();
					pdClass._userName = resp.getString(JafWebAPI.PDClass.getUserName.PROP_name);
					pdClass.retcode = resp.getInt(JafWebAPI.PROP_retCode, -1);
					if(typeof callback == 'function')
						callback(pdClass._userName);
				};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callback == 'function')
					callback();
			};
		JafWeb.ajaxRequest({
				url: this.getURL(),
				authToken: this.getAuthToken(),
				method: "GET",
				async: (!!callback),
				params: pars.get(),
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		if(!callback)
			return this._userName;
	}

	/**
	 @function PDClass#userSettings
	 @desc Daten eines Benutzers auslesen. Jeder Benutzer kann seine
		 eigenen Daten auslesen. Für das Auslesen der Daten anderer 
		 Benutzer sind Administratorrechte erforderlich.
	 @param {string} [login] Loginname des Benutzers, dessen Daten
		 ausgelesen werden sollen. Wird der Parameter weggelassen, werden
		 die Daten des aktuellen Benutzers geholt.
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @return {Object} Wenn die Benutzerdaten geholt werden konnten,
		 wird ein JavaScript-Objekt mit folgenden Properties zurückgegeben:
		 <ul>
			<li><code>String login</code> Login-Name des Benutzers.
			<li><code>String fullname</code> Der Langname des Benutzers.
			<li><code>Number passExpire</code> Anzahl der Tage, die das Passwort
				insgesamt gültig ist.
			<li><code>String accountExpire</code> An diesem Tage wird das Benutzerkonto
				ungültig wird.
		 </ul>
		 Falls die Benutzerdaten nicht geholt werden konnten, wird
		 <code>null</code> zurückgegeben.
	*/
	userSettings(login, callback) {
		var pars = new JParamPacker(JafWebAPI.PDClass.userSettings.eventName, this);
		if(login && typeof login == "string")
			pars.add(JafWebAPI.PDClass.userSettings.PAR_login, login);
		this._lastMsg = '';
		this.retcode = -1;
		var result = null;
		var pdClass = this;
		var successFn = function(req, options)
				{
					var resp = new JResponse(req, options, pdClass);
					if(resp.hasFatalError())
						throw "Request got empty or invalid response!";
					if(resp.hasError())
						throw resp.getErrorMessage();
					result = new Object();
					result.login = resp.getString(JafWebAPI.PDClass.userSettings.PROP_login);
					result.fullname = resp.getString(JafWebAPI.PDClass.userSettings.PROP_fullname);
					result.passExpire = resp.getInt(JafWebAPI.PDClass.userSettings.PROP_passExp);
					result.accountExpire = resp.getString(JafWebAPI.PDClass.userSettings.PROP_accExp); // TODO: in Datum konvertieren?
					pdClass.retcode = resp.getInt(JafWebAPI.PROP_retCode, -1);
					if(typeof callback == 'function')
						callback(result);
				};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callback == 'function')
					callback(result);
			};
		JafWeb.ajaxRequest({
				url: this.getURL(),
				authToken: this.getAuthToken(),
				method: "GET",
				async: (!!callback),
				params: pars.get(),
				scope: this,
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		if(!callback)
			return result;
	}
	
	/**
	 @function PDClass#motd
	 @desc Gibt das "Motto des Tages" zurück. Zum "Motto des Tages" vgl.
		 die entspr. Operation in der Dokumentation der JANUS-Server-Bibliotheken.
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @return {string} Den Text des aktuellen Mottos des Tages.
	 */
	motd(callback) {
		var pars = new JParamPacker(JafWebAPI.PDClass.motd.eventName, this);
		this._lastMsg = '';
		this.retcode = -1;
		var result = "";
		var pdClass = this;
		var successFn = function(req, options)
				{
					var resp = new JResponse(req, options, pdClass);
					if(resp.hasFatalError())
						throw "Request got empty or invalid response!";
					if(resp.hasError())
						throw resp.getErrorMessage();
					result = resp.getString(JafWebAPI.PDClass.motd.PROP_motd);
					pdClass.retcode = resp.getInt(JafWebAPI.PROP_retCode, -1);
					if(typeof callback == 'function')
						callback(result);
				};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callback == 'function')
					callback();
			};
		JafWeb.ajaxRequest({
				url: this.getURL(),
				authToken: this.getAuthToken(),
				method: "GET",
				async: (!!callback),
				params: pars.get(),
				scope: this,
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		if(!callback)
			return result;
	}
	
	/// Klassenoperationen
	/**
	 @function PDClass#callOperation
	 @desc Ruft eine Klassenoperation auf dem Server auf.
		 Zum Vorgehen, wie Sie einen Knopf zum synchronen bzw. asynchronen
		 Aufruf einer Klassenoperation definieren, vgl. 
		 das {@tutorial tut_buttonClassOp}.<br/>
		 <span class="important">Hinweis</span> zur Implementierung auf seiten des JANUS-Servers: Der
		 JafWeb-Client ruft, unabhängig von der Anzahl der Ein-/Ausgabeparameter
		 der modellierten Methode, immer die <code>callOperation()</code>-Variante
		 mit allen Ein-/Ausgabeparametern auf, weil anhand des Requests nicht erkannt
		 werden kann, ob es Ausgabeparameter gibt. Der GUI-Client hingegen ruft für
		 parameterlose Methoden direkt die <code>callOperation()</code>-Variante ohne
		 Parameter auf. Das müssen Sie berücksichtigen, wenn Sie in letzterer User Code
		 einfügen.
	 @param {string} op Name der Klassenoperation. Der Name muss mit der
		 Klasse in der Form "Klasse::Operation" oder "Klasse.Operation"
		 angegeben werden.
	 @param {boolean} [async=false] Bestimmt asynchrone (<code>true</code>) oder
		 synchrone (<code>false</code>) Ausführung der Operation.
		 Bei der asynchronen kann während der Ausführung auf der
		 Seite weitergearbeitet werden, die synchrone dagegen
		 blockiert alle Eingaben, bis die Operation zurückkommt.
	 @param {string[]} [inStr] String oder String-Array mit Eingabeparametern.
	 @param {PDObject[]} [inPdo] OID-String oder Liste von OID-Strings mit 
		 Eingabeparametern für Objekte.
	 @param {Function} [callback] Hier kann eine JavaScript-Funktion angegeben
		 werden, die bei der Rückkehr der Operation
		 ausgeführt werden soll (z.B. Meldung, Update einer Liste).
		 Bei asynchroner Ausführung wird diese Callback-Funktion mit
		 folgenden Parametern aufgerufen:
		 <ul>
			<li><code>outStrs</code> String oder Array von Strings mit den Ausgabeparametern
			der Operation.</li>
			<li><code>outPdos</code> Ein einzelnes <code>PDObject</code> (oder <code>null</code>)
			oder ein Array von <code>PDObject</code>s mit den Ausgabeobjekten der
			Operation.</li>
			<li><code>result</code> Numerischer Rückgabewert der Operation.</li>
		 </ul>
	 @param {Object} [scope] JavaScript-Objekt, in dessen Kontext die bei <code>callback</code>
		 übergebene Funktion ausgeführt werden soll. Falls nicht angegeben, wird
		 die Funktion im <code>PDClass</code>-Kontext ausgeführt.
	 @return {Object} Bei synchroner Ausführung wird ein JavaScript-Objekt
		 mit diesen Properties zurückgegeben:
		 <ul>
			<li><code>outStrs</code> Array mit den Ausgabe-Strings.</li>
			<li><code>outPdos</code> Array mit den Ausgabe-PDObjects.</li>
			<li><code>result</code> Numerischer Rückgabewert der aufgerufenen Operation.</li>
		 </ul>
	 @see [PDObject.callOperation()]{@link PDObject#callOperation}
		 Zu fortgeschrittenen Server-Operationsaufrufen siehe
		 auch die Klasse {@link PDOperationCall}.
	 */
	callOperation(op, async, inStr, inPdo, callback, scope) {
		var aInStr = null;
		var aInPdo = null;
		var bAsync = false;
		var fCallback = null;
		var oScope = null;
		var pos = 1;
		if(arguments.length > pos && typeof arguments[pos] == "boolean")
		{
			bAsync = arguments[pos];
			pos++;
		}
		if(arguments.length > pos && (typeof arguments[pos] != "function"))
		{
			aInStr = arguments[pos];
			if(!JafWeb.isArray(aInStr))
				aInStr = [aInStr];
			pos++;
		}
		else
			aInStr = [ ];
		if(arguments.length > pos && (typeof arguments[pos] != "function"))
		{
			aInPdo = arguments[pos];
			if(!JafWeb.isArray(aInPdo))
				aInPdo = [aInPdo];
			pos++;
		}
		else
			aInPdo = [ ];
		if(arguments.length > pos && (typeof arguments[pos] == "function" || arguments[pos] == null))
		{
			fCallback = arguments[pos];
			pos++;
		}
		if(arguments.length > pos && typeof arguments[pos] == "object")
		{
			fCallback = arguments[pos];
			pos++;
		}
		var pars = new JParamPacker(JafWebAPI.PDClass.callOperation.eventName, this);
		pars.add(JafWebAPI.PDClass.callOperation.PAR_op, op);
		pars.add(JafWebAPI.PDClass.callOperation.PAR_async, bAsync);
		for(var i=0; i<aInStr.length; i++)
			pars.add(JafWebAPI.PDClass.callOperation.PAR_inStr + i, aInStr[i]);
		var aInOids = [ ];
		for(var i=0; i < aInPdo.length; i++)
		{
			if(!aInPdo[i])
				pars.add(JafWebAPI.PDClass.callOperation.PAR_inPdo + i, "0");
			else if(typeof aInPdo[i] == 'object' && aInPdo[i].oid)
				pars.add(JafWebAPI.PDClass.callOperation.PAR_inPdo + i, aInPdo[i].oid);
			else if(typeof aInPdo[i] == 'string' && aInPdo[i].match(/[0-9]:[0-9]/))
				pars.add(JafWebAPI.PDClass.callOperation.PAR_inPdo + i, aInPdo[i]);
			else
				pars.add(JafWebAPI.PDClass.callOperation.PAR_inPdo + i, "0");
		}
		this._lastMsg = '';
		this.retcode = -1;
		var res = new Object();
		var result = -1;
		var pdClass = this;
		var successFn = function(req, options)
				{
					var resp = new JResponse(req, options, pdClass);
					if(resp.hasFatalError())
						throw "Request got empty or invalid response!";
					if(resp.hasError())
						throw resp.getErrorMessage();
					res.result = resp.getInt('retCode', -1);
					res.outStrs = resp.getArray(JafWebAPI.PDClass.callOperation.PROP_OUTSTR, [], 'string', '');
					res.outPdos = resp.getArray(JafWebAPI.PDClass.callOperation.PROP_OUTPDO, [], 'PDObject', null);
					pdClass.retcode = resp.getInt(JafWebAPI.PROP_retCode, -1);
					if(typeof fCallback == "function")
					{
						fCallback.call((oScope || this), res.outStrs, res.outPdos, res.result);
					}
				};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof fCallback == 'function')
					fCallback(null);
			};
		JafWeb.ajaxRequest({
				url: this.getURL(),
				authToken: this.getAuthToken(),
				method: "POST",
				async: bAsync,
				params: pars.getPostParams(),
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		if(bAsync === false)
			return res;
	}

	/// Arbeiten mit Mandanten
	/**
	 @function PDClass#changePrincipal
	 @desc Zu einem anderen Mandanten wechseln.<br/>
		 <span class="important">Hinweis:</span> Beachten Sie bitte, dass Sie
		 diese Aktion auf der Server-Seite explizit erlauben müssen, indem Sie
		 die generierte Methode <code>PDClassWebProxy::changePrincipalUser()</code>
		 (in jcontroller.cpp) implementieren! Gibt diese Methode <code>false</code>
		 zurück, wird der Mandantenwechsel verweigert.
	 @param {Mixed} princ Die Mandanten-Kennung. Es kann die numerische
		 Mandanten-Id oder der technische Name des Mandanten angegeben werden.
		 Um zur globalen Anmeldung zu wechseln, geben Sie 0 oder einen
		 Leerstring an.
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @return {number} Wenn alles geklappt hat, wird
		 <code>0</code> zurückgegeben, ansonsten ein
		 Fehlercode, der mit <code>PDMeta.getString()</code>
		 in einen Text umgewandelt werden kann.
	 */
	changePrincipal(princ, callback) {
		var pars = new JParamPacker(JafWebAPI.PDClass.changePrincipal.eventName, this);
		if(typeof princ == 'number')
			pars.add(JafWebAPI.PDClass.changePrincipal.PAR_pid, princ);
		else
			pars.add(JafWebAPI.PDClass.changePrincipal.PAR_name, princ);
		this._lastMsg = '';
		this.retcode = -1;
		this._princId = '';
		this._princName = '';
		this._princFullname = '';
		this._princObj = null;
		var pdClass = this;
		var successFn = function(req, options)
				{
					var resp = new JResponse(req, options, pdClass);
					if(resp.hasFatalError())
						throw "Request got empty or invalid response!";
					if(resp.hasError())
						throw resp.getErrorMessage();
					pdClass.retcode = resp.getInt(JafWebAPI.PROP_retCode, -1);
					if(pdClass.retcode == 0)
					{
						pdClass._princId = resp.getInt(JafWebAPI.PDClass.changePrincipal.PROP_pid, -1);
						pdClass._princName = resp.getString(JafWebAPI.PDClass.changePrincipal.PROP_name);
						pdClass._princFullname = resp.getString(JafWebAPI.PDClass.changePrincipal.PROP_fullname);
					}
					if(typeof callback == 'function')
						callback(pdClass._princFullname);
				};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callback == 'function')
					callback();
			};
		JafWeb.ajaxRequest({
				url: this.getURL(),
				authToken: this.getAuthToken(),
				method: "GET",
				async: (!!callback),
				params: pars.get(),
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		if(!callback)
			return this.retcode;
	}
	
	/**
	 @function PDClass#getPrincipalFullname
	 @desc Zu einer gegebenen Mandanten-Id den
		 Langnamen des Mandanten ermitteln.
	 @param {number} pid Numerische Mandanten-Id.
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @return {string} Mandantenname.
	 */
	getPrincipalFullname(pid, callback) {
		if(this._princFullname)
		{
			if(callback)
			{
				callback(this._princFullname);
				return;
			}
			else
				return this._princFullname;
		}
		var pars = new JParamPacker(JafWebAPI.PDClass.getPrincipalFullname.eventName, this);
		pars.add(JafWebAPI.PDClass.getPrincipalFullname.PAR_pid, pid);
		this._lastMsg = '';
		this.retcode = -1;
		this._princFullname = '';
		var pdClass = this;
		var successFn = function(req, options)
				{
					var resp = new JResponse(req, options, pdClass);
					if(resp.hasFatalError())
						throw "Request got empty or invalid response!";
					if(resp.hasError())
						throw resp.getErrorMessage();
					pdClass._princFullname = resp.getString(JafWebAPI.PDClass.getPrincipalFullname.PROP_name);
					pdClass.retcode = resp.getInt(JafWebAPI.PROP_retCode, -1);
					if(typeof callback == 'function')
						callback(pdClass._princFullname);
				};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callback == 'function')
					callback();
			};
		JafWeb.ajaxRequest({
				url: this.getURL(),
				authToken: this.getAuthToken(),
				method: "GET",
				async: (!!callback),
				params: pars.get(),
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		if(!callback)
			return this._princFullname;
	}
	
	/**
	 @function PDClass#getPrincipalId
	 @desc Zu einem gegebenen Mandantennamen die
	 	numerische Id ermitteln.
	 @param {string} [name] Der Mandantenname. Fehlt diese
	 	Angabe, wird die Id des aktuell angemeldeten Mandanten ermittelt.
	 @param {Function} callback Optionale Callback-Funktion, die
	 	nach Verarbeitung der Antwort aufgerufen werden soll. Wird
	 	diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
	 	und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
	 	dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
	 	Ergebnis wird direkt zurückgegeben.
	 @return {number} Die Mandanten-Id. Wenn die nicht ermittelt
	 	werden konnte, wird <code>-1</code> zurückgegeben.
	 */
	getPrincipalId(name, callback) {
		var _name = '';
		var _callb = null;
		var pos = 0;
		if(arguments.length > pos && (typeof arguments[pos] == 'string') ||
				(typeof arguments[pos] == 'number'))
		{
			_name = arguments[pos];
			pos++;
		}
		if(arguments.length > pos && (typeof arguments[pos] == 'function'))
		{
			_callb = arguments[pos];
			pos++;
		}
		if(!_name && this._princId >= 0)
		{
			if(_callb)
			{
				_callb(this._princId);
				return;
			}
			else
				return this._princId;
		}
		var pars = new JParamPacker(JafWebAPI.PDClass.getPrincipalId.eventName, this);
		if(name)
			pars.add(JafWebAPI.PDClass.getPrincipalId.PAR_name, _name);
		this._lastMsg = '';
		this.retcode = -1;
		this._princId = -1;
		var pdClass = this;
		var result = 0;
		var successFn = function(req, options)
				{
					var resp = new JResponse(req, options, pdClass);
					if(resp.hasFatalError())
						throw "Request got empty or invalid response!";
					if(resp.hasError())
						throw resp.getErrorMessage();
					result = resp.getInt(JafWebAPI.PDClass.getPrincipalId.PROP_pid);
					if(!_name)
						pdClass._princId = result;
					pdClass.retcode = resp.getInt(JafWebAPI.PROP_retCode, -1);
					if(_callb == 'function')
						_callb(result);
				};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(_callb == 'function')
					_callb();
			};
		JafWeb.ajaxRequest({
				url: this.getURL(),
				authToken: this.getAuthToken(),
				method: "GET",
				async: (!!_callb),
				params: pars.get(),
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		if(!_callb)
			return result;
	}
	
	/**
	 @function PDClass#getPrincipalName
	 @desc Zu einer gegebenen Id die Mandantenkennung
		 ermitteln.
	 @param {number} [pid=-1] Die Mandanten-Id. Falls dieser Parameter fehlt
		 oder mit <code>-1</code> angegeben wurde, wird der Name der aktuell
		 angemeldeten Mandanten ermittelt.
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @return {string} Der Name (Kennung) des 
		 Mandanten.
	 */
	getPrincipalName(pid, callback) {
		if(this._princName)
		{
			if(callback)
			{
				callback(this._princName);
				return;
			}
			else
				return this._princName;
		}
		var pars = new JParamPacker(JafWebAPI.PDClass.getPrincipalName.eventName, this);
		var princId = -1;
		if(pid !== undefined)
			princId = -1;
		pars.add(JafWebAPI.PDClass.getPrincipalName.PAR_pid, princId);
		this._lastMsg = '';
		this.retcode = -1;
		this._princName = '';
		var pdClass = this;
		var successFn = function(req, options)
				{
					var resp = new JResponse(req, options, pdClass);
					if(resp.hasFatalError())
						throw "Request got empty or invalid response!";
					if(resp.hasError())
						throw resp.getErrorMessage();
					pdClass._princName = resp.getString(JafWebAPI.PDClass.getPrincipalName.PROP_name);
					pdClass.retcode = resp.getInt(JafWebAPI.PROP_retCode, -1);
					if(typeof callback == 'function')
						callback(pdClass._princName);
				};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callback == 'function')
					callback();
			};
		JafWeb.ajaxRequest({
				url: this.getURL(),
				authToken: this.getAuthToken(),
				method: "GET",
				async: (!!callback),
				params: pars.get(),
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		if(!callback)
			return this._princName;
	}

	/**
	 @function PDClass#getPrincipalsForUser
	 @desc Alle Mandanten zurückgeben, denen
		 der angegebene Benutzer zugeordnet ist.
	 @param {string} [user] Benutzerkennung. Falls der Parameter
		 leer, werden alle Mandanten zurückgegeben. Falls er fehlt,
		 werden die mandanten des aktuellen Benutzers ermittelt.
		 Beachten Sie bitte, dass für das Abfragen der Mandanetn
		 anderer Benutzer Adminstrattorrechte erforderlich sind!
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @return {string[]} String-Array mit den
		 Kennungen der Mandanten, denen der
		 Benutzer zugeordnet ist.
	 */
	getPrincipalsForUser(user, callback) {
		var cb = null;
		var pos = 0;
		var pars = new JParamPacker(JafWebAPI.PDClass.getPrincipalsForUser.eventName, this);
		if(arguments.length > pos && (typeof arguments[pos] == 'string'))
		{
			pars.add(JafWebAPI.PDClass.getPrincipalsForUser.PAR_user, arguments[pos]);
			pos++;
		}
		else if(arguments.length > pos && (typeof arguments[pos] == 'number'))
		{
			pars.add(JafWebAPI.PDClass.getPrincipalsForUser.PAR_uid, arguments[pos]);
			pos++;
		}
		if(arguments.length > pos && typeof arguments[pos] == 'function')
		{
			cb = arguments[pos];
			pos++;
		}
		this._lastMsg = '';
		this.retcode = -1;
		var result = null;
		var pdClass = this;
		var successFn = function(req, options)
				{
					var resp = new JResponse(req, options, pdClass);
					if(resp.hasFatalError())
						throw "Request got empty or invalid response!";
					if(resp.hasError())
						throw resp.getErrorMessage();
					result = resp.getArray(JafWebAPI.PDClass.getPrincipalsForUser.PROP_princs, [], 'string', '');
					pdClass.retcode = resp.getInt(JafWebAPI.PROP_retCode, -1);
					if(typeof cb == 'function')
						cb(pdClass._princName);
				};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof cb == 'function')
					cb();
			};
		JafWeb.ajaxRequest({
				url: this.getURL(),
				authToken: this.getAuthToken(),
				method: "GET",
				async: (!!cb),
				params: pars.get(),
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		if(!cb)
			return result;
	}

	/**
	 @function PDClass#getUsersForPrincipal
	 @desc Alle Benutzer ermitteln, die sich für den
		 angegebenen Mandanten anmelden können.
	 @param {string} princ Kennung des Mandanten.
	 @return {string[]} String-Array mit den Kennungen
		 der zu dem Mandanten gehörenden Benutzer.
	 @todo Implementierung
	 */
	getUsersForPrincipal(princ) {
		// TODO
	}
	
	/**
	 @function PDClass#setPrincipalName
	 @desc Einen Mandanten umbenennen.<br/>
		 Diese Funktion darf nur von Benutzern mit
		 Administratorrechten benutzt werden.
	 @param {number} pid Die numerische Id des
		 Mandanten.
	 @param {string} name Der neue Name.
	 @return {number} Wenn alles geklappt hat, wird
		 <code>0</code> zurückgegeben, ansonsten ein
		 Fehlercode, der mit [PDMeta.getString()]{@link PDMeta#getString}
		 in einen Text umgewandelt werden kann.
	 @todo Implementierung
	 */
	setPrincipalName(pid, name) {
		// TODO
	}
	
	/**
	 @function PDClass#setPrincipalsForUser
	 @desc Legt die zu dem angegebenen Benutzer gehörenden
		 Mandanten neu fest.
	 @param {string} user Benutzername.
	 @param {string[]} princs String-Array mit den Namen der
		 Mandanten, für die sich der Benutzer anmelden
		 können soll.
	 @return {number} Wenn alles geklappt hat, wird
		 <code>0</code> zurückgegeben, ansonsten ein
		 Fehlercode, der mit [PDMeta.getString()]{@link PDMeta#getString}
		 in einen Text umgewandelt werden kann.
	 @todo Implementierung
	 */
	setPrincipalsForUser(user, princs) {
		// TODO
	}
	
	/**
	@function PDClass#setUsersForPrincipal
	@desc Legt die zu einem Mandanten gehörenden Benutzer 
		neu fest.
	@param {string} princ Mandantenname.
	@param {string[]} users Array mit den
		Benutzer-Loginnamen, die dem Mandanten zugewiesen
		werden sollen.
	@return {number} Wenn alles geklappt hat, wird
		<code>0</code> zurückgegeben, ansonsten ein
		Fehlercode, der mit [PDMeta.getString()]{@link PDMeta#getString}
		in einen Text umgewandelt werden kann.
	@todo Implementierung
	 */
	setUsersForPrincipal(princ, users) {
		// TODO
	}
	
	/// Locks abfragen
	/**
	 @function PDClass#lockInfo
	 @desc Informationen über ein gesperrtes Objekt ermitteln.<br/>
		 <h4>Beispiel</h4>
		 <pre class="prettyprint"><code>// Vor dem Bearbeiten einer Anlage equipmentObj soll
// geprüft werden, ob eine Sperre besteht:
const li = PDClass.lockInfo(equipmentObj.oidHi, equipmentObj.oidLow);
if(li) {
	let msg = PDMeta.getString('SC::FormTitleLocked');
	msg = msg.replace(/%c/, li['client']);
	msg = msg.replace(/%u/, li['user']);
	const dur = new JDuration(li['locktime']);
	msg = msg.replace(/%t/, dur.toString());
	msg = msg.replace(/%i/, li['uid']);
	UIMessage.ok(msg);
	return false;
}</code></pre>
	 @param {mixed} cid Klassen-Id des zu prüfenden Objekts. Statt
		 der numerischen Id kann auch der Klassenname (String)
		 angegeben werden.
	 @param {number} oid Objekt-Id (unterer Teil) des zu prüfenden Objekts.
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @return {Object} Wenn das Objekt gesperrt ist und die Informationen
		 ermittelt werden konnten, wird ein JavaScript-Objekt mit folgenden
		 Properties zurückgegeben:
		 <ul>
			<li><code>client</code> Name oder IP-Adresse des Clients, der das
				Objekt gesperrt hat.</li>
			<li><code>user</code> Name des Benutzers, der die Sperre verursacht hat.</li>
			<li><code>locktime</code> (int) Zeit in Sekunden, seit der die Sperre besteht.</li>
			<li><code>uid</code> (int) User-ID des sperrenden Benutzers.</li>
		 </ul>
		 Andernfalls wird <code>null</code> zurückgegeben.
	*/
	lockInfo(cid, oid, callback) {
		if(arguments.length < 2)
			throw "Class- and/or object-Id missing!";
		var pars = new JParamPacker(JafWebAPI.PDClass.lockInfo.eventName, this);
		if(cid && typeof cid == "string")
			pars.add(JafWebAPI.PDClass.lockInfo.PAR_clName, cid);
		else if(cid && typeof cid == "number" &&
				oid && typeof oid == "number")
			pars.add(JafWebAPI.PDClass.lockInfo.PAR_cid, cid);
		pars.add(JafWebAPI.PDClass.lockInfo.PAR_oidLow, oid);
		this._lastMsg = '';
		this.retcode = -1;
		var result = null;
		var pdClass = this;
		var successFn = function(req, options)
				{
					var resp = new JResponse(req, options, pdClass);
					if(resp.hasFatalError())
						throw "Request got empty or invalid response!";
					if(resp.hasError())
						throw resp.getErrorMessage();
					var retCode = pdClass.retcode = resp.getInt(JafWebAPI.PROP_retCode, -1);
					if(retCode > 0)
					{
						result = new Object();
						result.client = resp.getString(JafWebAPI.PDClass.lockInfo.PROP_client);
						result.user = resp.getString(JafWebAPI.PDClass.lockInfo.PROP_user);
						result.locktime = resp.getInt(JafWebAPI.PDClass.lockInfo.PROP_locktime, -1);
						result.uid = resp.getInt(JafWebAPI.PDClass.lockInfo.PROP_uid, -1);
					}
					if(typeof callback == 'function')
						callback(result);
				};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callback == 'function')
					callback();
			};
		JafWeb.ajaxRequest({
				url: this.getURL(),
				authToken: this.getAuthToken(),
				method: "GET",
				async: (!!callback),
				params: pars.get(),
				scope: this,
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		if(!callback)
			return result;
	}
	
	/// Finden von Objekten
	/**
	 @function PDClass#findObjects
	 @desc Objekt-Suche über mehrere Klassen. Vgl. die <i>Quick Search</i>-Funktion
	 	im GUI-Client. Die Namen der Fachkonzeptklassen, in denen gesucht
	 	werden soll, stehen auf der Server-Seite in
	 	<code>PDClassWebProxy::QuickSearchClasses</code>, die maximale Trefferanzahl in
	 	<code>PDClassWebProxy::QuickSearchMaxRes</code> und die maximale Länge von
	 	Text-Attributen in <code>PDClassWebProxy::QuickSearchMaxLen</code>.
	 @param {string} value Das Suchmuster.
	 @param {number} [startRes=0] Start-Offset für die auszugebende Treffermenge.
	 @param {boolean} [asOidList=false] Legt fest, ob die Rückgabe als OID-Liste (<code>true</code>)
	 	oder als HTML-Seite des Suchergebnisses erfolgen soll. Standard ist <code>false</code>.
	 @param {string} [previewId=''] Wenn bei <code>asOidList</code> <code>false</code>
	 	angegeben wurde und das Suchergebnis in einem {@link PDPreview} dargestellt
	 	werden soll, muss hier der Name der Preview-Komponente
	 	([PDPreview.getName()]{@link PDPreview#getName}) angegeben werden, damit die
	 	Links in der Ergebnisseite entsprechend angepasst werden können.
	 @param {Function} callback Optionale Callback-Funktion, die
	 	nach Verarbeitung der Antwort aufgerufen werden soll. Wird
	 	diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
	 	und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
	 	dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
	 	Ergebnis wird direkt zurückgegeben.
	 @return {string} HTML-Seite mit dem Suchergebnis. Falls <code>asOidList</code>
	 	mit <code>true</code> angegeben wurde, wird stattdessen ein Array mit OID-Strings
	 	zurückgegeben.
	 */
	findObjects(value, startRes, asOidList, previewId, callback) {
		var pars = new JParamPacker(JafWebAPI.PDClass.findObjects.eventName, this);
		pars.add(JafWebAPI.PDClass.findObjects.PAR_value, value);
		var fOidList = false;
		var iStartRes = 0;
		var sPrevId = '';
		var callb = null;
		var pos = 1;
		if(arguments.length > pos && (typeof arguments[pos] == 'number'))
		{
			iStartRes = arguments[pos];
			pos++;
		}
		if(arguments.length > pos && (typeof arguments[pos] == 'boolean'))
		{
			fOidList = arguments[pos];
			pos++;
		}
		if(arguments.length > pos && (typeof arguments[pos] == 'string'))
		{
			sPrevId = arguments[pos];
			pos++;
		}
		if(arguments.length > pos && (typeof arguments[pos] == 'function'))
		{
			callb = arguments[pos];
			pos++;
		}
		pars.add(JafWebAPI.PDClass.findObjects.PAR_startRes, iStartRes);
		pars.add(JafWebAPI.PDClass.findObjects.PAR_asOidList, fOidList);
		pars.add(JafWebAPI.PDClass.findObjects.PAR_imgPath, UIApplication.getImageDir());
		pars.add(JafWebAPI.PDClass.findObjects.PAR_pid, sPrevId);
		this._lastMsg = '';
		this.retcode = -1;
		var result = (fOidList==true ? [] : "");
		var pdClass = this;
		var successFn = function(req, options) {
					if(fOidList == true) {
						var resp = new JResponse(req, options, pdClass);
						if(resp.hasFatalError()) {
							pdClass.retcode = (result && typeof result == 'string' && result != '' ? 0 : -1);
							throw "Request got empty or invalid response!";
						}
						if(resp.hasError()) {
							this.retcode = resp.getInt('retCode', -1);
							throw resp.getErrorMessage();
						}
						result = resp.getArray(JafWebAPI.PDClass.findObjects.PROP_oids, [], 'string', '');
					}
					else
						result = req;
					if(typeof callb == 'function') {
						try {
							callb(result);
						}
						catch(ex) {
							console.error("PDClass.findObjects() - Exception in Callback:", ex);
						}
					}
				};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callb == 'function')
					callb();
			};
		JafWeb.ajaxRequest({
				url: this.getURL(),
				authToken: this.getAuthToken(),
				method: "GET",
				dataType: (fOidList == true ? 'json' : 'html'),
				async: (!!callb),
				params: pars.get(),
				scope: this,
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		if(!callback)
			return result;
	}
	
	/**
	 @function PDClass#search
	 @desc Eine Suche nach Objekten einer Klasse ausführen.
		 Als Ergebnis der Suche wird eine Objekt-ID-Liste geliefert.<br/>
		 Wichtig: Diese Suche liefert nur ein sinnvolles Ergebnis, wenn die
		 Server-seitig generierte Methode <code>PDClass::quickSearch()</code>
		 eine entspr. Implementierung bekommen hat!
	 @param {mixed} cid Name der Klasse oder deren Klassen-Id.
	 @param {String} what Wonach gesucht werden soll.
	 @param {Array<String>} [attrs] Optionale Angabe der Klassenelemente
		(Attribute und Beziehungen), in denen gesucht werden soll. Falls
		nicht angegeben, werden diese aus dem Metamodell der Anwendung
		bestimmt - standardmäßig alle UI-relevanten Atribute der Klasse,
		was aber im Server-seitigen User Code auch verändert werden kann.
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @return {int[]} Ergebnisliste mit OIDs (unterem Teil).
	 */
	search(cid, what, attrs, callback) {
		this.retcode = -1;
		const pars = new JParamPacker(JafWebAPI.PDClass.search.eventName, this);
		if(cid && typeof cid == "string")
			pars.add(JafWebAPI.PDClass.search.PAR_clName, cid);
		else if(cid && typeof cid == "number")
			pars.add(JafWebAPI.PDClass.search.PAR_cid, cid);
		else {
			this._lastMsg = "search: Class-Id or Name missing!";
			throw "Class-Id or Name missing!";
		}
		pars.add(JafWebAPI.PDClass.search.PAR_what, what);
		let pos = 2;
		if(JafWeb.isArray(arguments[pos])) {
			pars.add(JafWebAPI.PDClass.search.PAR_cols, arguments[pos].join(','));
			pos++;
		}
		let _cb = null;
		if(typeof arguments[pos] == 'function') {
			_cb = arguments[pos];
			pos++;
		}
		this._lastMsg = '';
		let result = [];
		const pdClass = this;
		const successFn = function(req, options) {
				const resp = new JResponse(req, options, pdClass);
				if(resp.hasFatalError())
					throw "Request got empty or invalid response!";
				if(resp.hasError())
					throw resp.getErrorMessage();
				result = resp.getArray(JafWebAPI.PDClass.search.PROP_oids, null, 'number', 0);
				pdClass.retcode = resp.getInt(JafWebAPI.PROP_retCode, -1);
				if(_cb)
					_cb(result);
			};
		const failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(_cb)
					_cb();
			};
		JafWeb.ajaxRequest({
				url: this.getURL(),
				authToken: this.getAuthToken(),
				method: "GET",
				async: (!!callback),
				params: pars.get(),
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		if(!_cb)
			return result;
	}
	
	/**
	 @function PDClass#getSingleton
	 @desc Das Singleton-Objekt einer mit dem Stereotyp <code>Singleton</code>
		 modellierten Klasse holen.
	 @param {mixed} cid Name der Singleton-Klasse oder deren Klassen-Id.
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @return {PDObject} Das Singleton-Objekt.
	 */
	getSingleton(cid, callback) {
		this.retcode = -1;
		var pars = new JParamPacker(JafWebAPI.PDClass.getSingleton.eventName, this);
		if(cid && typeof cid == "string")
			pars.add(JafWebAPI.PDClass.getSingleton.PAR_clName, cid);
		else if(cid && typeof cid == "number")
			pars.add(JafWebAPI.PDClass.getSingleton.PAR_cid, cid);
		else
		{
			this._lastMsg = "getSingleton: Class-Id or Name missing!";
			throw "Class-Id or Name missing!";
		}
		this._lastMsg = '';
		var result = null;
		var pdClass = this;
		var successFn = function(req, options)
			{
				var resp = new JResponse(req, options, pdClass);
				if(resp.hasFatalError())
					throw "Request got empty or invalid response!";
				if(resp.hasError())
					throw resp.getErrorMessage();
				result = resp.getPDObject(JafWebAPI.PDClass.getSingleton.PROP_obj);
				pdClass.retcode = resp.getInt(JafWebAPI.PROP_retCode, -1);
				if(typeof callback == 'function')
					callback(result);
			};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callback == 'function')
					callback();
			};
		JafWeb.ajaxRequest({
				url: this.getURL(),
				authToken: this.getAuthToken(),
				method: "GET",
				async: (!!callback),
				params: pars.get(),
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		if(!callback)
			return result;
	}
	/*
	getSingletonPromise(cid){
		var self = this;
		return new Promise(function(resolve, reject){
			try {
				self.getSingleton(cid, function (obj) {
					resolve(obj);
				});
			} catch (e) {
				reject(e);
			}
		});
	}*/
		
	/**
	 @function PDClass#findObject
	 @desc Ein Fachkonzeptobjekt einer bestimmten Klasse anhand eines Filters finden.<br/>
		 <h4>Beispiel</h4>
		 <pre class="prettyprint"><code>let filt = "Number=";
filt += PDTools.makeFilter(currentEquipmentNumber);
const eq = PDClass.findObject("Equipment", filt);</code></pre>
	 @param {mixed} cid Name der Fachkonzeptklasse oder deren Klassen-Id.
	 @param {string} filter Der JANUS-Filterausdruck zum Selektieren des Objekts.
		 Der Ausdruck sollte typischerweise das oder die Schlüsselattribute der Klasse
		 beinhalten, sodass genau ein Objekt gefunden wird.
	 @param {Mixed} [attrs=true] Die zu ermittelnden Attribute - entweder eine Liste der
		 technischen Attributnamen oder ein boolescher Typ, der anzeigt, ob alle Attribute
		 laut Modell (<code>true</code>) oder gar keine Attribute (<code>false</code>)
		 ermittelt werden sollen.
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @return {PDObject} Das Objekt oder <code>null</code>, falls keines gefunden
		 wurde. Falls der Filter auf mehrere Objekte passt, wird trotzdem nur das
		 erste Objekt zurückgegeben!
	*/
	findObject(cid, filter, attrs, callback) {
		if(typeof callback == 'function')
		{
			var tmpCallb = function(extentRes) {
					if(extentRes && extentRes['rows'] && extentRes['rows'].length > 0)
						callback(extentRes['rows'][0]);
					else
						callback(null);
				};
			this.getExtent(cid, filter, '', null, 0, 1, tmpCallb, false, attrs);
			return;
		}
		var extnt = this.getExtent(cid, (filter || ''), '', null, 1, 0, null, false, attrs);
		if(!extnt || !extnt.rows || extnt.rows.length == 0)
			return null;
		return extnt.rows[0];
	}

	/**
	 @function PDClass#getMaxClients
	 @desc ERmittelt die Anzahl der maximal zulässigen Clients.
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @return {numer} Die Anzahl. Wennn die Information nicht gelesen
		 werden konnte - zum Beispiel wegen fehlender Berechtigung - wird
		 ein Wert kleiner als 0 zurückgegeben.
	 */
	getMaxClients(callback) {
		this.retcode = -1;
		var pars = new JParamPacker(JafWebAPI.PDClass.getMaxClients.eventName, this);
		var result = -1;
		var pdClass = this;
		var successFn = function(req, options)
			{
				var resp = new JResponse(req, options, pdClass);
				if(resp.hasFatalError())
					throw "Request got empty or invalid response!";
				if(resp.hasError())
					throw resp.getErrorMessage();
				result = resp.getInt(JafWebAPI.PDClass.getMaxClients.PROP_count);
				pdClass.retcode = resp.getInt(JafWebAPI.PROP_retCode, -1);
				if(typeof callback == 'function')
					callback(result);
			};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callback == 'function')
					callback(result);
			};
		JafWeb.ajaxRequest({
				url: this.getURL(),
				authToken: this.getAuthToken(),
				method: "GET",
				async: (!!callback),
				params: pars.get(),
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		if(!callback)
			return result;
	}

	/**
	 @function PDClass#buildFilterByToNRelation
	 @desc Baut einen JANUS-Filterausdruck, der eine Klasse nach Werten
		 in den Objekten einer Zu-N-Beziehung filtert.
	 @param {mixed} cid Id (Int) oder Name (String) der Fachkonzeptklasse,
		 auf die der Ausdruck angewendet werden soll.
	 @param {string} rel Name der Zu-N-Beziehung, in deren Objekten nach dem
		 Suchmuster gesucht werden soll.
	 @param {mixed} attrs Ein (String) bis n Attribute (Array von Strings)
		 oder Zu-1-Beziehungen der über die Zu-N-Beziehunge verbundenen Klasse,
		 in denen gesucht werden soll.
	 @param {string} op Der Vergleichsoperator. Für textuelle Attribute wird
		 auch die Tilde ("~", "Like"-Operator) akzeptiert; diese wird automatisch
		 in entspr. Wildcards expandiert.
	 @param {string} val Das Suchmuster.
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @return {string} Der resultierende Filterausdruck. Dieser ist nie
		 leer - wenn keine Objekte gefunden wurden, wird der Ausdruck "object=0"
		 zurückgegeben, der eine leere Ergebnismenge erzeugt.
	 */
	buildFilterByToNRelation(cid, rel, attrs, op, val, callback) {
		if(arguments.length < 5) {
			throw "Needs at least 5 parameters!";
		}
		var pars = new JParamPacker(JafWebAPI.PDClass.buildFilterByToNRelation.eventName, this);
		if(typeof cid == 'string')
			pars.add(JafWebAPI.PDClass.buildFilterByToNRelation.PAR_clName, cid);
		else
			pars.add(JafWebAPI.PDClass.buildFilterByToNRelation.PAR_cid, cid);
		pars.add(JafWebAPI.PDClass.buildFilterByToNRelation.PAR_rel, rel);
		if(!JafWeb.isArray(attrs))
			attrs = [attrs];
		pars.add(JafWebAPI.PDClass.buildFilterByToNRelation.PAR_attrs, attrs);
		pars.add(JafWebAPI.PDClass.buildFilterByToNRelation.PAR_op, op);
		pars.add(JafWebAPI.PDClass.buildFilterByToNRelation.PAR_val, val);
		this._lastMsg = '';
		this.retcode = -1;
		var result = '';
		var pdClass = this;
		var successFn = function(req, options)
				{
					var resp = new JResponse(req, options, pdClass);
					if(resp.hasFatalError())
						throw "Request got empty or invalid response!";
					if(resp.hasError())
						throw resp.getErrorMessage();
					result = resp.getString(JafWebAPI.PDClass.buildFilterByToNRelation.PROP_expr, '');
					pdClass.retcode = resp.getInt(JafWebAPI.PROP_retCode, -1);
					if(typeof callback == 'function')
						callback(result);
				};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callback == 'function')
					callback();
			};
		JafWeb.ajaxRequest({
				url: this.getURL(),
				authToken: this.getAuthToken(),
				method: "GET",
				async: (!!callback),
				params: pars.get(),
				scope: this,
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		if(!callback)
			return result;
	}
	
	/**
	 @function PDClass#getDynamicFilter
	 @desc Diese Funktion ruft die gleichnamige auf der Server-Seite
		 auf, um einen Filterausdruck dynamisch, d.h. in Abhängigkeit
		 von einem Bezugsobjekt sowie einer (im Modell definierbaren)
		 "Rolle" zu ermitteln.
		 Im Modell wird dazu bei der Einstellung Filter Expression
		 (techn. FilterExpr) ein Filter dieser der Form "dyn:rolle"
		 angegeben.
	 @param {string} classname Name der Fachkonzeptklasse.
	 @param {string} context Frei definierbarer Name, den Sie in
		 Ihrer User-Code-Implementierung von <code>PDClass.getDynamicFilter()</code>
		 auswerten können. Übergeben wird der Name, dem Sie im Modell hinter
		 "dyn:" angegeben haben.
	 @param {PDObject} thisObj Das Bezugsobjekt. Typischerweise handelt
		 es sich um das Objekt, von dem die zu filternde Beziehung ausgeht.
	 @param {string} relation Name der Beziehung, für die der Filter
		 ermittelt werden soll.
	 @param {Function} [callback] Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @return {string} Der ermittelte Filterausdruck.
	 */
	getDynamicFilter(classname, context, thisObj, relation, callback) {
		this.retcode = -1;
		var pars = new JParamPacker(JafWebAPI.PDClass.getDynamicFilter.eventName, this);
		if(classname)
			pars.add(JafWebAPI.PDClass.getDynamicFilter.PAR_clName, classname);
		if(context)
			pars.add(JafWebAPI.PDClass.getDynamicFilter.PAR_ctxt, context);
		if(thisObj)
		{
			if(JafWeb.isPDObject(thisObj))
				pars.add(JafWebAPI.PDClass.getDynamicFilter.PAR_thisPdo, thisObj.oid);
			else
				pars.add(JafWebAPI.PDClass.getDynamicFilter.PAR_thisPdo, thisObj);
		}
		pars.add(JafWebAPI.PDClass.getDynamicFilter.PAR_rel, (relation || ''));
		var filt = "";
		var pdClass = this;
		var successFn = function(req, options)
			{
				var resp = new JResponse(req, options, pdClass);
				if(resp.hasFatalError())
					throw "Request got empty or invalid response!";
				if(resp.hasError())
					throw resp.getErrorMessage();
				filt = resp.getString(JafWebAPI.PDClass.getDynamicFilter.PROP_filt);
				pdClass.retcode = resp.getInt(JafWebAPI.PROP_retCode, -1);
				if(typeof callback == 'function')
					callback(filt);
			};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callback == 'function')
					callback();
			};
		JafWeb.ajaxRequest({
				url: this.getURL(),
				authToken: this.getAuthToken(),
				method: "POST",
				async: (!!callback),
				params: pars.get(),
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		////console.log("### PDClass.getDynamicFilter() - returns [" + filt + "]");
		if(!callback)
			return filt;
	}

	/**
	 @function PDClass#isUnique
	 @desc Stellt fest, ob es bereits ein Objekt außer dem übergebenen
		 gibt, das in dem angegebenen Attribut den selben Wert
		 besitzt.
	 @param {number} cid Klassen-Id. Es werden auch Objekte berücksichtigt,
		 für die die angegebene Klasse Basisklasse ist.
	 @param {number} [oid] Id des Objekts, dessen Eindeutigkeit geprüft
		 werden soll. Dieser Parameter kann auch weggelassen werden.
	 @param {string} attr Name des zu prüfenden Attributs.
	 @param {string} attrVal Wert des Attributs.
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @return {boolean} <code>true</code> falls eindeutig, sonst
		 <code>false</code>.
	 */
	isUnique(cid, oid, attr, attrVal, callback) {
		var pars = new JParamPacker(JafWebAPI.PDClass.isUnique.eventName, this);
		// cid ist optional
		if(arguments.length < 3) {
			throw "Needs at least 3 parameters!";
		}
		var callbFn = null;
		var pos = 0;
		if(arguments.length == 3)
		{
			if(typeof cid == 'number')
				throw "If classname not given the first Parameter has to be a complete OID-String!";
			pars.add(JafWebAPI.PDClass.isUnique.PAR_oid, cid);
			pos = 1;
		}
		else
		{
			if(typeof cid == "string")
				pars.add(JafWebAPI.PDClass.isUnique.PAR_clName, cid);
			else if(typeof cid == "number")
				pars.add(JafWebAPI.PDClass.isUnique.PAR_cid, cid);
			pars.add(JafWebAPI.PDClass.isUnique.PAR_oidLow, oid);
			pos = 2;
		}
		while(pos < arguments.length)
		{
			if(typeof arguments[pos] == 'function')
			{
				callbFn = arguments[pos];
				break;
			}
			pos++;
		}
		pars.add(JafWebAPI.PDClass.isUnique.PAR_attr, arguments[pos]);
		pos++;
		pars.add(JafWebAPI.PDClass.isUnique.PAR_val, arguments[pos]);
		this._lastMsg = '';
		this.retcode = -1;
		var result = false;
		var pdClass = this;
		var successFn = function(req, options)
				{
					var resp = new JResponse(req, options, pdClass);
					if(resp.hasFatalError())
						throw "Request got empty or invalid response!";
					if(resp.hasError())
						throw resp.getErrorMessage();
					result.vals = resp.getBool(JafWebAPI.PDClass.isUnique.PROP_result, false);
					pdClass.retcode = resp.getInt(JafWebAPI.PROP_retCode, -1);
					if(typeof callbFn == 'function')
						callbFn(result.vals);
				};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callbFn == 'function')
					callbFn();
			};
		JafWeb.ajaxRequest({
				url: this.getURL(),
				authToken: this.getAuthToken(),
				method: "GET",
				async: (!!callbFn),
				params: pars.get(),
				scope: this,
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		if(!callbFn)
			return result;
	}
	
	/// Zaehlen von Objekten
	/**
	 @function PDClass#countObjects
	 @desc Dient zum Zählen der Objekte einer bestimmten Klasse.
		 Die zu zählende Menge kann über ein Selektionskriterium
		 eingeschränkt werden. 
	 @param {mixed} cid Klassen-ID. Statt der numerischen ID kann auch 
		 der Klassenname angegeben werden.
	 @param {string} [sel] Selektionskriterium, mit dem die Menge
		 der Objekte eingeschränkt werden soll.
	 @param {string} [thisOid] OID eines Objekts, das in dem
		 Selektionskriterium mit dem Schlüsselwort "this" referenziert 
		 werden kann. So lässt sich das Kriterium von diesem Objekt
		 oder seinen Attributen abhängig machen.
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @return {number}eger Anzahl der gefundenen Objekte. Im Fehlerfall
		 wird <code>-1</code> zurückgegeben.
	 */
	countObjects(cid, sel, thisOid, callback) {
		var pars = new JParamPacker(JafWebAPI.PDClass.countObjects.eventName, this);
		if(typeof cid == "string")
			pars.add(JafWebAPI.PDClass.countObjects.PAR_clName, cid);
		else if(typeof cid == "number")
			pars.add(JafWebAPI.PDClass.countObjects.PAR_cid, cid);
		var callbFn = null;
		var pos = 1;
		if(pos < arguments.length && typeof arguments[pos] == "string")
		{
			pars.add(JafWebAPI.PDClass.countObjects.PAR_sel, (sel||""));
			pos++;
			if(pos < arguments.length)
			{
				if(JafWeb.isPDObject(arguments[pos]))
					pars.add(JafWebAPI.PDClass.countObjects.PAR_thisOid, arguments[pos].oid);
				else if(typeof arguments[pos] == 'string')
					pars.add(JafWebAPI.PDClass.countObjects.PAR_thisOid, arguments[pos]);
				pos++;
			}
		}
		if(pos < arguments.length && typeof arguments[pos] == 'function')
		{
			callbFn = arguments[pos];
			pos++;
		}
		this.retcode = -1;
		this._lastMsg = '';
		var result = -1;
		var pdClass = this;
		var successFn = function(req, options)
				{
					//console.log("Response: "+resp.getResponseText());
					var resp = new JResponse(req, options, pdClass);
					if(resp.hasFatalError())
						throw "Request got empty or invalid response!";
					if(resp.hasError())
						throw resp.getErrorMessage();
					result = resp.getInt(JafWebAPI.PDClass.countObjects.PROP_count, -1);
					pdClass.retcode = resp.getInt(JafWebAPI.PROP_retCode, -1);
					if(typeof callbFn == 'function')
						callbFn(result);
				};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callbFn == 'function')
					callbFn();
			};
		JafWeb.ajaxRequest({
				url: this.getURL(),
				authToken: this.getAuthToken(),
				method: "POST",
				async: (!!callbFn),
				params: pars.getPostParams(),
				scope: this,
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		if(!callbFn)
			return result;
	}
	
	/// Arbeiten mit Aufzaehlungen
	/**
	 @function PDClass#getEnumCode
	 @desc Für nicht erweiterbare Aufzählungstypen oder erweiterbare 
		 Aufzählungstypen mit globaler Sichtbarkeit der Erweiterungen 
		 kann mit Hilfe dieser Funktion aus einem Aufzählungswert (in 
		 Form einer Zeichenkette) der entsprechende numerische Code 
		 ermittelt werden.
	 @param {mixed} cid ID der Klasse, zu der das Attribut <code>attr</code>
		 gehört. Statt der numerischen ID kann auch der Klassenname angegeben werden.
	 @param {string} attrName Name des Attributs mit dem Aufzählungstypen.
	 @param {string} enumVal Der abzufragende Wert des Aufzählungstypen.
	 @param {number} [lang] Wenn der Aufzählungstyp mehrsprachig spezifiziert ist, 
		 muss hier die Sprache angegeben werden, in der der in <code>enumVal</code>
		 angegebene Wert übergeben wurde.
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @return {number} Der numerische Code des Aufzählungswertes. Dieser kann z.B.
		 direkt in Filtern verwendet werden, die sich dadurch sprachunabhängig
		 formulieren lassen.
	 */
	getEnumCode(cid, attrName, enumVal, lang, callback) {
		if(arguments.length < 3)
			throw "Needs at least 3 parameters!";
		var clName = cid;
		if(typeof clName != 'string')
			clName = this.PDMeta.getClass(cid);
		var enumName = this.PDMeta.getType(clName, attrName);
		var actLang = this.PDMeta.getLang();
		if(this.PDMeta['_enumCache'])
		{
			var consts = this.PDMeta['_enumCache'][enumName];
			if(consts)
			{
				for(var i = 0; i < consts.length; i++)
				{
					if(consts[i]['tech'] == enumVal)
					{
						if(typeof callback == 'function')
						{
							callback(consts[i]['code']);
							return;
						}
						return consts[i]['code'];
					}
					if(consts[i]['vals'][actLang] == enumVal) // auch ergon. abfragen
					{
						if(typeof callback == 'function')
						{
							callback(consts[i]['code']);
							return;
						}
						return consts[i]['code'];
					}
				}
			}
		}
		var pars = new JParamPacker(JafWebAPI.PDClass.getEnumCode.eventName, this);
		if(typeof cid == "string")
			pars.add(JafWebAPI.PDClass.getEnumCode.PAR_clName, cid);
		else if(typeof cid == "number")
			pars.add(JafWebAPI.PDClass.getEnumCode.PAR_cid, cid);
		pars.add(JafWebAPI.PDClass.getEnumCode.PAR_attr, attrName);
		pars.add(JafWebAPI.PDClass.getEnumCode.PAR_enumVal, enumVal);
		if(arguments.length > 3 && typeof lang == "number")
			pars.add(JafWebAPI.PDClass.getEnumCode.PAR_lang, lang);
		//console.warn("PDClass.getEnumCode(): Params: "+pars.toString());
		this.retcode = -1;
		this._lastMsg = '';
		const pdClass = this;
		let result;
		var successFn = function(req, options)
				{
					var resp = new JResponse(req, options, pdClass);
					if(resp.hasFatalError())
						throw "Request got empty or invalid response!";
					if(resp.hasError())
						throw resp.getErrorMessage();
					result = resp.getInt(JafWebAPI.PDClass.getEnumCode.PROP_code, -1);
					pdClass.retcode = resp.getInt(JafWebAPI.PROP_retCode, -1);
					if(typeof callback == 'function')
						callback(result);
				};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callback == 'function')
					callback();
			};
		JafWeb.ajaxRequest({
				url: this.getURL(),
				authToken: this.getAuthToken(),
				method: "GET",
				async: (!!callback),
				params: pars.get(),
				scope: this,
				callerName: pars.getEventName(),
				disableCaching: false, // das Ergebnis aendert sich nicht, deshalb cachen lassen!
				success: successFn,
				failure: failureFn
			});
		if(!callback)
			return result;
	}
	
	/**
	 @function PDClass#getEnumConst
	 @desc Die möglichen Werte eines als <b>Klassen</b>attribut definierten Aufzählungstyps 
		 einer beliebigen Klasse lesen.<br/>
		 <span class="important">Hinweis:</span> Die Funktion ruft nicht die
		 Server-seitige <code>PDObject::getEnumConst()</code> auf, sondern benutzt über
		 [PDClass.getEnumConsts()]{@link PDClass#getEnumConsts} die - ge-cachten - Informationen
		 des Metamodells.
	 @param {string} cls Name der Klasse, die das Enum-Attribut enthält.
		 Statt der Klasse kann hier auch das Attribut in der Form "Klasse.Attribut"
		 angegeben werden. Der zweite Parameter muss dann entfallen.
	 @param {string} attr Name des Enum-Attributs.
	 @param {boolean} [withIcons=false] Legt fest, ob auch die den Ausprägungen zugewiesenen
		 Icons ermittelt werden sollen. Standardwert ist <code>false</code>. Beachten Sie,
		 dass, falls hier <code>true</code> angegeben wird, die Rückgabe eine andere
		 Struktur hat.
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @return {Array} Array mit den Objekten für die Ausprägungen. Diese haben folgende
		 Properties:
		 <ul>
			<li><code>code</code> Technischer Name der Enum-Ausprägung.</li>
			<li><code>icon</code> Optionale Angabe eines Web-Icons, das für die
				Ausprägung angezeigt werden soll. Image-Pfad und Name,
				relativ ab dem Image-Verzeichnis der Web-Anwendung.</li>
			<li><code>text</code> Der für die Enum-Konstante auf der Oberfläche
				anzuzeigende Text.</li>
		 </ul>
		 <span class="important">Hinweis:</span> Da im JANUS-Umfeld die Werte von Enum-Attributen über den ergonomischen
		 Bezeichner der Ausprägung in der ersten Sprache gesetzt wird, enthalten hier
		 die Properties <code>code</code> und <code>text</code> den gleichen Wert.
	 */
	getEnumConst(cls, attr, withIcons, callback) {
		////console.log("### PDClass.getEnumConst('" + cls + "', '" + attr + "')");
		const enumName = this.PDMeta.getType(cls, attr);
		const that = this;
		const convert = function(res) {
				// im Cache u. in PDClass haben die Enums eine andere Struktur!
				const convRes = [];
				const numLangs = that.PDMeta.getNumLanguages();
				const actLang = that.PDMeta.getLang();
				for(let i = 0; i < res.codes.length; i++) {
					convRes.push({
							code: res.tech[i],
							icon: (withIcons ? (res.icons ? res.icons[i] : '') : ''),
							text: (res.vals.length == res.tech.length ?
									res.vals[i] : res.vals[i * numLangs + actLang])
						});
				}
				return convRes;
			};
		if(callback) {
			that.getEnumConsts(enumName, withIcons, function(res) {
					callback(convert(res));
				});
			return;
		}
		return convert(that.getEnumConsts(enumName, withIcons));
/*
		this.retcode = -1;
		if(arguments.length < 1 || typeof cls != "string")
			throw "Needs at least one string parameter!";
		var clName = cls;
		var attrName = '';
		var pars = new JParamPacker(JafWebAPI.PDClass.getEnumConst.eventName, this);
		var icns = false;
		var callbFn = null;
		var pos = 1;
		if(arguments.length > pos && typeof arguments[pos] == "string")
		{
			attrName = arguments[pos];
			pos++;
		}
		else
		{
			var tmp = clName.replace(/::/g, '.').split('.');
			clName = tmp[0];
			attrName = tmp[1];
		}
		if(arguments.length > pos && typeof arguments[pos] == "boolean")
		{
			icns = arguments[pos];
			pos++;
		}
		if(arguments.length > pos && typeof arguments[pos] == "function")
		{
			callbFn = arguments[pos];
			pos++;
		}
		var enumName = this.PDMeta.getType(clName, attrName);
		if(this._useEnumCache)
		{
			var res = this.getEnumFromCache(enumName);
			if(res)
			{
				// im Cache haben die Enums eine andere Struktur!
				var convRes = [];
				var numLangs = this.PDMeta.getNumLanguages();
				var actLang = this.PDMeta.getLang();
				for(var i = 0; i < res.codes.length; i++)
				{
					convRes.push({
							code: res.tech[i],
							icon: (res.icons ? res.icons[i] : ''),
							text: res.vals[i * numLangs + actLang]
						});
				}
				////console.log("### PDClass.getEnumConst() - Found enum '" + enumName + "' in Cache:", convRes);
				if(callbFn)
				{
					callbFn(convRes);
					return;
				}
				return convRes;
			}
		}
		pars.add(JafWebAPI.PDClass.getEnumConst.PAR_attr, clName + '.' + attrName);
		pars.add(JafWebAPI.PDClass.getEnumConst.PAR_icns, (withIcons === true));
		//console.log("PDClass.getEnumConst(): Params: "+pars.toString());
		this._lastMsg = '';
		var res = null;
		var pdClass = this;
		var successFn = function(req, options)
				{
					var resp = new JResponse(req, options, pdClass);
					if(resp.hasFatalError())
						throw "Request got empty or invalid response!";
					if(resp.hasError())
						throw resp.getErrorMessage();
					var vals = resp.getArray(JafWebAPI.PDClass.getEnumConst.PROP_values, [], 'string', '');
					var icns = resp.getArray(JafWebAPI.PDClass.getEnumConst.PROP_icons, [], 'string', '');
					var extensible = resp.getBool(JafWebAPI.PDClass.getEnumConst.PROP_extensible, false);
					res = new Array();
					for(var i = 0; i < vals.length; i++)
					{
						res.push({
								'code': vals[i],
								'value': vals[i],
								'icon': (icns ? (icns[i] || '') : '')
							});
					}
					pdClass.retcode = resp.getInt(JafWebAPI.PROP_retCode, -1);
					if(typeof callbFn == 'function')
						callbFn(res);
				};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callbFn == 'function')
					callbFn();
			};
		JafWeb.ajaxRequest({
				url: this.getURL(),
				authToken: this.getAuthToken(),
				method: "GET",
				async: (!!callbFn),
				params: pars.get(),
				scope: this,
				callerName: pars.getEventName(),
				disableCaching: false, // das Ergebnis aendert sich nicht, deshalb cachen lassen!
				success: successFn,
				failure: failureFn
			});
		/ *if(result.length != res.length)
			console.error("PDClass.getEnumConst() - cached result differs from that one got from the server", result, res);
		else for(var j=0; j < result.length && j < res.length; j++)
		{
			if(result[j].code != res[j].code || result[j].value != res[j].value || result[j].icon != res[j].icon)
			{
				console.error("PDClass.getEnumConst() - cached result differs from that one got from the server", result, res);
				break;
			}
		}* /
		if(!callbFn)
			return res;*/
	}
	
	/**
	 @function PDClass#getEnumValueFromTech
	 @desc Gibt zu einer Enum-Ausprägung den ergonomischen Bezeichner
		 in der aktuellen Sprache zurück.
	 @param {string} enumName Name des Aufzählungstyps.
	 @param {mixed} techValue Technischer Name der gesuchten
		 Ausprägung oder aber deren numerischer Wert.
	 @return {string} Der Wert in der aktuellen Sprache.
	 */
	getEnumValueFromTech(enumName, techValue) {
		// koennen wir wiederholt aufrufen, weil die Ergebnisse
		// ge-cached werden
		var consts = this.getGlobalEnumConst(enumName, false);
		if(!JafWeb.isArray(consts.tech))
			return '';
		var idx = ((typeof techValue == 'string') ?
			consts.tech.indexOf(techValue) :
			consts.codes.indexOf(techValue));
		if(idx < 0)
			return '';
		var numLangs = this.PDMeta.getNumLanguages();
		var actLang = this.PDMeta.getLang();
		return consts.vals[idx * numLangs + actLang];
	}
	
	/**
	 @function PDClass#getEnumConsts
	 @desc Die möglichen Werte und deren Codes eines global erweiterbaren oder eines 
		 nicht erweiterbaren Aufzählungstyps ermitteln.<br/>
		 Global erweiterbare Aufzählungstypen zeichnen sich dadurch aus, dass 
		 die von einem beliebigen Benutzer bei einer beliebigen Klasse oder einem 
		 beliebigen Objekt eingetragenen Erweiterungen allen anderen Benutzern 
		 überall zur Verfügung stehen.<br/>
		 <pre class="prettyprint"><code>PDClass.getEnumConsts('TitelET', true, function(res) {
		$('#klappliste').setOptions(res.vals.sort());
	});</code></pre>
	 @param {string} ename Name des auszulesenden Aufzählungstyps.
	 @param {boolean} icons Zeigt an, dass auch die für die Enum-Ausprägungen
		 definierten Icons ermittelt werden sollen (Standardwert <code>false</code>).
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @return {Object} JavaScript-Objekt mit folgenden Properties:
		 <ul>
			<li><code>tech</code> Bei nicht erweiterbaren Enums enthält dieses
				Property ein Array von <code>String</code> mit den technischen Namen der
				Ausprägungen.</li>
			<li><code>vals</code> Array von <code>String</code>s der gleichen Länge wie
				<code>tech</code>, das die ergonomischen Bezeichner der Ausprägungen in der
				aktuellen Anwendungssprache enthält.</li>
			<li><code>codes</code> Array von <code>Number</code> mit den numerischen Codes
				der Ausprägungen.</li>
			<li><code>icons</code> (Array) Falls der Parameter <code>icons</code> mit
				<code>true</code> angegeben wurde, ist dieses Array definiert und enthält
				für jede Enum-Ausprägung den Namen der Icon-Datei. Stellen Sie diesen
				Werten den von [UIApplication.getImageDir()]{@link UIApplication#getImageDir}
				gelieferten Pfad voran, um die Icons anzuzeigen.
				Die Icon-Dateien müssen im Web-Ressourcen-Verzeichnis
				zugänglich sein, um angezeigt werden zu können.</li>
			<li><code>result</code> number Wenn die Werte geholt werden konnten, steht hier <code>0</code>,
				ansonsten kann der Fehlercode mit [PDMeta.getString()]{@link PDMeta#getString} in 
				einen Text umgewandelt werden.</li>
		 </ul>
		 Es werden <b>keine gelöschten</b> Ausprägungen ermittelt!
	 @test EnumTest.testGemischteZugriffe
	 */
	getEnumConsts(ename, icons, callback) {
		////console.log("### PDClass.getEnumConsts('" + ename + "')");
		this.retcode = -1;
		const tmp = this.onGetEnumConsts(ename, icons);
		if(tmp) {
			if(typeof callback == 'function') {
				callback(tmp);
				return;
			}
			return tmp;
		}
		const result = {};
		if(arguments.length < 1 || typeof ename != "string")
			throw "Needs at least one string parameter!";
		if(this._useEnumCache) {
			const res = this.getEnumFromCache(ename);
			if(res) {
				if(callback) {
					callback(res);
					return;
				}
				return res;
			}
		}
		const pars = new JParamPacker(JafWebAPI.PDClass.getEnumConsts.eventName, this);
		pars.add(JafWebAPI.PDClass.getEnumConsts.PAR_ename, ename);
		pars.add(JafWebAPI.PDClass.getEnumConsts.PAR_icns, icons);
		pars.add(JafWebAPI.PDClass.getEnumConsts.PAR_tech, true);
		this._lastMsg = '';
		const pdClass = this;
		const successFn = function(req, options) {
				const resp = new JResponse(req, options, pdClass);
				if(resp.hasFatalError())
					throw "Request got empty or invalid response!";
				if(resp.hasError())
					throw resp.getErrorMessage();
				result.tech = resp.getArray(JafWebAPI.PDClass.getEnumConsts.PROP_tech, [], 'string', '');
				result.vals = resp.getArray(JafWebAPI.PDClass.getEnumConsts.PROP_values, [], 'string', '');
				result.codes = resp.getArray(JafWebAPI.PDClass.getEnumConsts.PROP_codes, [], 'number', '');
				result.icons = resp.getArray(JafWebAPI.PDClass.getEnumConsts.PROP_icons, [], 'string', '');
				result.result = resp.getInt(JafWebAPI.PROP_retCode, -1);
				// Label Client-seitig auffuellen!
				for(let i = 0; i < result.tech.length; i++) {
					if(!result.vals[i])
						result.vals[i] = PDMeta.getErgname(ename, result.tech[i]);
				}
				// erweiterbare Enums duerfen nicht in den Cache!
				if(pdClass._useEnumCache) {
					const extensible = resp.getBool(JafWebAPI.PDClass.getEnumConsts.PROP_extensible, true);
					if(!extensible)
						pdClass.addEnumToCache(ename, result);
				}
				pdClass.retcode = resp.getInt(JafWebAPI.PROP_retCode, -1);
				if(typeof callback == 'function')
					callback(result);
			};
		const failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callback == 'function')
					callback();
			};
		JafWeb.ajaxRequest({
				url: this.getURL(),
				authToken: this.getAuthToken(),
				method: "GET",
				async: (!!callback),
				params: pars.get(),
				scope: this,
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		if(!callback)
			return result;
	}
	
	/**
	 @function PDClass#getGlobalEnumConst
	 @desc Die möglichen Werte und deren Codes eines global erweiterbaren oder eines 
		 nicht erweiterbaren Aufzählungstyps ermitteln.<br/>
		 Global erweiterbare Aufzählungstypen zeichnen sich dadurch aus, dass 
		 die von einem beliebigen Benutzer bei einer beliebigen Klasse oder einem 
		 beliebigen Objekt eingetragenen Erweiterungen allen anderen Benutzern 
		 überall zur Verfügung stehen.<br/>
		 <span class="important">Hinweis:</span> Bitte beachten Sie, dass bei
		 einem in mehreren Sprachen spezifizierten 
		 Aufzählungstyp in der Liste <code>vals</code> pro Sprache und 
		 Selektionsmöglichkeit ein Wert eingetragen wird, während in <code>codes</code> 
		 und <code>active</code> nur ein Wert pro Selektionsmöglichkeit geschrieben 
		 wird.
	 @param {string} ename Name des auszulesenden Aufzählungstyps.
	 @param {boolean} icons Zeigt an, dass auch die für die Enum-Ausprägungen
		 definierten Icons ermittelt werden sollen (Standardwert <code>false</code>).
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @param {boolean} [includeDeleted=false] Sollen auch als gelöscht markierte
		 Konstanten ermittelt werden? Vgl. Ergebnis-Array <code>active</code>.
		 Standardmäßig werden <b>keine gelöschten</b> Ausprägungen ermittelt.
	 @return {Object} JavaScript-Objekt mit folgenden Properties:
		 <ul>
			<li><code>vals</code> Array von <code>String</code>, das die ausgelesenen
			Werte - ggf. in allen Sprachen - aufnimmt.</li>
			<li><code>codes</code> Array von <code>Number</code> mit den numerischen Codes
			der Ausprägungen.</li>
			<li><code>active</code> Array von <code>boolean</code>, in dem für die
			Ausprägung angezeigt wird, ob sie noch aktiv, d.h. nicht gelöscht ist.
			Bei einem nicht erweiterbaren Aufzählungstyp enthält die Liste nur
			<code>true</code>-Werte.</li>
			<li><code>tech</code> Bei nicht erweiterbaren Enums enthält dieses
			Property ein Array von <code>String</code> mit den technischen Namen der
			Ausprägungen.</li>
			<li><code>icons</code> Array Falls der Parameter <code>icons</code> mit
			<code>true</code> angegeben wurde, ist dieses Array definiert und enthält
			für jede Enum-Ausprägung den Namen der Icon-Datei. Stellen Sie diesen
			Werten den von [UIApplication.getImageDir()]{@link UIApplication#getImageDir}
			gelieferten Pfad voran, um die Icons anzuzeigen.
			Die Icon-Dateien müssen im Web-Ressourcen-Verzeichnis
			zugänglich sein, um angezeigt werden zu können.</li>
			<li><code>result</code> (Number) Wenn die Werte geholt werden konnten, steht hier <code>0</code>,
			ansonsten kann der Fehlercode mit [PDMeta.getString()]{@link PDMeta#getString} in 
			einen Text umgewandelt werden.</li>
			<li><code>extensible</code> (Boolean) Ist der Aufzählungstyp erweiterbar?</li>
			<li><code>multi</code> (Boolean) Erlaubt das Selektionsmodell des Aufzählungstypen
			Mehrfachauswahl?</li>
		 </ul>
	 @deprecated Bitte statt dieser [getEnumConsts()]{@link PDClass#getEnumConsts} verwenden,
		 die unabhängig von den konfigurierten Anwendungssprachen arbeitet.
	 @test EnumTest.testGemischteZugriffe
	 @test EnumTest.testGetGlobalEnumConstMitGeloeschten
	*/
	getGlobalEnumConst(ename, icons, callback, includeDeleted) {
		////console.log("### PDClass.getGlobalEnumConst('" + ename + "')");
		this.retcode = -1;
		var tmp = this.onGetGlobalEnumConst(ename, icons);
		if(tmp)
		{
			if(typeof callback == 'function')
			{
				callback(tmp);
				return;
			}
			return tmp;
		}
		var result = {};
		if(arguments.length < 1 || typeof ename != "string")
			throw "Needs at least one string parameter!";
		if(this._useEnumCache)
		{
			var res = this.getEnumFromCache(ename, true);
			if(res)
			{
				if(callback)
				{
					callback(res);
					return;
				}
				return res;
			}
		}
		var _icons = false;
		var _callback = null;
		var _includeDeleted = false;
		var pos = 1;
		if(arguments.length > pos && typeof arguments[pos] == "boolean")
		{
			_icons = arguments[pos];
			pos++;
		}
		if(arguments.length > pos && typeof arguments[pos] == "function")
		{
			_callback = arguments[pos];
			pos++;
		}
		if(arguments.length > pos && typeof arguments[pos] == "boolean")
		{
			_includeDeleted = arguments[pos];
			pos++;
		}
		var pars = new JParamPacker(JafWebAPI.PDClass.getGlobalEnumConst.eventName, this);
		pars.add(JafWebAPI.PDClass.getGlobalEnumConst.PAR_ename, ename);
		pars.add(JafWebAPI.PDClass.getGlobalEnumConst.PAR_icns, true);
		pars.add(JafWebAPI.PDClass.getGlobalEnumConst.PAR_tech, true);
		if(_includeDeleted)
			pars.add(JafWebAPI.PDClass.getGlobalEnumConst.PAR_del, true);
		this._lastMsg = '';
		var pdClass = this;
		var successFn = function(req, options)
				{
					var resp = new JResponse(req, options, pdClass);
					if(resp.hasFatalError())
						throw "Request got empty or invalid response!";
					if(resp.hasError())
						throw resp.getErrorMessage();
					result.tech = resp.getArray(JafWebAPI.PDClass.getGlobalEnumConst.PROP_tech, [], 'string', '');
					/*if(PDMeta.hasMultilang2Support())
					{
						result.vals = [];
						var numLangs = PDMeta.getNumLanguages();
						// bei Multilang2 muessen die Label unbedingt gui-seitig ermittelt werden!
						for(var i=0; i < result.tech.length; i++)
						{
							for(var l = 0; l < numLangs; l++)
								result.vals.push(PDMeta.getErgname(ename, result.tech[i], l) || result.tech[i]);
						}
					}
					else*/
						result.vals = resp.getArray(JafWebAPI.PDClass.getGlobalEnumConst.PROP_values, [], 'string', '');
					result.codes = resp.getArray(JafWebAPI.PDClass.getGlobalEnumConst.PROP_codes, [], 'number', '');
					result.active = resp.getArray(JafWebAPI.PDClass.getGlobalEnumConst.PROP_active, [], 'boolean', -1);
					result.icons = resp.getArray(JafWebAPI.PDClass.getGlobalEnumConst.PROP_icons, [], 'string', '');
					result.result = resp.getInt(JafWebAPI.PROP_retCode, -1);
					result.multi = resp.getBool(JafWebAPI.PDClass.getGlobalEnumConst.PROP_multi, false);
					result.extensible = resp.getBool(JafWebAPI.PDClass.getGlobalEnumConst.PROP_extensible, true);
					// erweiterbare Enums duerfen nicht in den Cache!
					if(pdClass._useEnumCache) {
						if(!result.extensible)
							pdClass.addEnumToCache(ename, result);
					}
					pdClass.retcode = resp.getInt(JafWebAPI.PROP_retCode, -1);
					if(typeof _callback == 'function')
						_callback(result);
				};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof _callback == 'function')
					_callback();
			};
		JafWeb.ajaxRequest({
				url: this.getURL(),
				authToken: this.getAuthToken(),
				method: "GET",
				async: (!!callback),
				params: pars.get(),
				scope: this,
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		/*for(var p in {vals: 1, codes: 1, active: 1, tech: 1, icons: 1})
		{
			if(result[p].length != res[p].length)
				console.error("PDClass.getGlobalEnumConst() - cached result differs from that one got from the server", result, res);
			else for(var j=0; j < result[p].length && j < res[p].length; j++)
			{
				if(result[p][j] != res[p][j])
				{
					console.error("PDClass.getGlobalEnumConst() - cached result differs from that one got from the server", result, res);
					break;
				}
			}
		}*/
		if(!_callback)
			return result;
	}
	
	/*
	 * @memberof PDClass
	 * @function clearEnumCache
	 * @desc Den Enum-Cache zurücksetzen.
	 * @param {string} [ename] Name des zurückzusetzenden
	 * Aufzählungstypen. Wenn nicht angegeben, wird der gesamte
	 * Cache geleert.
	 */
	clearEnumCache(ename) {
		if(!this.PDMeta['_enumCache'])
			return;
		if(ename)
		{
			if(!this.PDMeta['_enumCache'][ename])
				return;
			delete this.PDMeta['_enumCache'][ename];
			return;
		}
		delete this.PDMeta['_enumCache'];
		this.PDMeta['_enumCache'] = {};
	}
	
	/*
	 Informatiionen über einen Aufzählungstypen in den globalen
	 Enum-Cache einfügen.
	 @param {boolean} [multilang=false] Sollen die ergonomischen
		 Bezeichner in allen Sprachen ausgegeben werden? Das Array
		 vals hat dann die Länge von Anzahl Sprachen mal Anzahl
		 Enum-Auspraegungen.
	 @return {Object} Die Informationen. Zur Struktur vgl.
		 [getGlobalEnumConst()]{@link PDClass#getGlobalEnumConst}.
		 Falls der Enum noch nicht im Cache ist, wird <code>null</code>
		 zurückgegeben.
	 @test EnumTest.testGemischteZugriffe
	 */
	getEnumFromCache(ename, multilang) {
		////console.log("### getFromEnumCachae('" + ename + "')");
		if(!this.PDMeta['_enumCache'])
			return null;
		var res = null;
		var consts = this.PDMeta['_enumCache'][ename];
		var iLangs = this.PDMeta.getNumLanguages();
		var actLang = this.PDMeta.getLang();
		if(consts)
		{
			var vals = [];
			var tech = [];
			var codes = [];
			var active = [];
			var icons = [];
			for(var i = 0; i < consts.length; i++)
			{
				// je nachdem, welche Enum-Zugriffsmethode zuerst benutzt
				// wurde, kann der Cache den Enum mit allen Sprachauspraegungen
				// oder nur der aktuelen Sprache enthalten; je nach Parameter
				// multilang muss dann entspr. angepasst werden.
				// (Ziel waere, die veraltete getGlobalEnumConst() gar nicht
				// mehr zu zu benutzen.)
				if(multilang) {
					for(var j = 0; j < iLangs; j++) {
						if(consts[i]['vals'].length <= j)
							vals.push(consts[i]['vals'][0]);
						else
							vals.push(consts[i]['vals'][j]);
					}
				}
				else {
					if(consts[i]['vals'].length > actLang)
						vals.push(consts[i]['vals'][actLang]);
					else
						vals.push(consts[i]['vals'][0]);
				}
				tech.push(consts[i]['tech']);
				codes.push(consts[i]['code']);
				active.push(consts[i]['active']);
				if(consts[i]['icon'])
					icons.push(consts[i]['icon']);
				else
					icons.push(this.PDMeta['_iconCache'] ? (this.PDMeta['_iconCache']['E_' +
							ename + '_val_' + consts[i]['code']] || '') : '');
			}
			res = {
					vals: vals,
					codes: codes,
					active: active,
					tech: tech,
					icons: icons,
					result: 0
				};
			if(typeof callback == 'function')
			{
				callback(res);
				return;
			}
		}
		////console.log("### getFromEnumCachae('" + ename + "') - result:", res);
		return res;
	}
	
	/*
	 * Informatiionen über einen Aufzählungstypen in den globalen
	 * Enum-Cache einfügen.
	 * @param {String} ename Der technische Name des Aufzählungstypen.
	 * @param {Object} theEnum Die Enum-Informationen. Zur Struktur vgl.
	 * [getGlobalEnumConst()]{@link PDClass#getGlobalEnumConst}.
	 */
	addEnumToCache(ename, theEnum) {
		////console.log("### addEnumToCache('" + ename + "', ", theEnum, ")");
		var consts = [];
		var iLangs = theEnum.vals.length / theEnum.codes.length;
		for(var i = 0; i < theEnum.codes.length; i++)
		{
			consts.push({
					tech: theEnum.tech[i],
					code: theEnum.codes[i],
					//active: theEnum.active[i],
					vals: []
				});
			if(theEnum.icons && theEnum.icons[i])
				consts[consts.length - 1].icon = theEnum.icons[i];
			for(var j = 0; j < iLangs; j++)
				consts[consts.length - 1].vals.push(theEnum.vals[iLangs * i + j]);
		}
		this.PDMeta._enumCache[ename] = consts;
	}
	
	// Interne Funktionen fuer den Enum-Editor.
	/*
	 @function PDClass#getEnumInfos
	 */
	getEnumInfos(callback) {
		this.retcode = -1;
		var pars = new JParamPacker(JafWebAPI.PDClass.getEnumInfos.eventName, this);
		this._lastMsg = '';
		var result = {};
		var pdClass = this;
		var successFn = function(req, options)
				{
					var resp = new JResponse(req, options, pdClass);
					if(resp.hasFatalError())
						throw "Request got empty or invalid response!";
					if(resp.hasError())
						this._lastMsg = resp.getErrorMessage();
					else
					{
						pdClass.retcode = resp.getInt(JafWebAPI.PROP_retCode, -1);
						result = resp.getData();
					}
					if(typeof callback == 'function')
						callback((resp.getErrorMessage() || ''), result);
				};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callback == 'function')
					callback("Network communication error in " + pars.getEventName() + "!", null);
			};
		JafWeb.ajaxRequest({
				url: this.getURL(),
				authToken: this.getAuthToken(),
				method: "GET",
				async: (!!callback),
				params: pars.get(),
				scope: this,
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		if(!callback)
			return result;
	}
	
	/*
	 @function PDClass#getEnumInfos
	 */
	updateEnums(modifiedEnums, callback) {
		this.retcode = -1;
		var pars = new JParamPacker(JafWebAPI.PDClass.updateEnums.eventName, this);
		pars.add(JafWebAPI.PDClass.updateEnums.PAR_modEnums, JafWeb.encode(modifiedEnums));
		this._lastMsg = '';
		var pdClass = this;
		var successFn = function(req, options)
				{
					var resp = new JResponse(req, options, pdClass);
					if(resp.hasFatalError())
						throw "Request got empty or invalid response!";
					if(resp.hasError())
						this._lastMsg = resp.getErrorMessage();
					pdClass.retcode = resp.getInt(JafWebAPI.PROP_retCode, -1);
					if(typeof callback == 'function')
						callback(resp.getErrorMessage() || '');
				};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callback == 'function')
					callback("Network communication error in " + pars.getEventName() + "!");
			};
		JafWeb.ajaxRequest({
				url: this.getURL(),
				authToken: this.getAuthToken(),
				method: "POST",
				async: (!!callback),
				params: pars.get(),
				scope: this,
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
	}

	/**
	 @function PDClass#getCurrencies
	 @desc Die für die Anwendung definierten Währungen mit zugehörigen
		 Umrechnungskursen ermitteln.
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @return {Object} JavaScript-Objekt mit den Währungsnamen als Properties
		 und den Umrechnungskursen als Werte.
	 */
	getCurrencies(callback) {
		this.retcode = -1;
		var pars = new JParamPacker(JafWebAPI.PDClass.getCurrencies.eventName, this);
		this._lastMsg = '';
		var result = {};
		var pdClass = this;
		var successFn = function(req, options)
				{
					var resp = new JResponse(req, options, pdClass);
					if(resp.hasFatalError())
						throw "Request got empty or invalid response!";
					if(resp.hasError())
						throw resp.getErrorMessage();
					var names = resp.getArray(JafWebAPI.PDClass.getCurrencies.PROP_names, [], 'string', '');
					var values = resp.getArray(JafWebAPI.PDClass.getCurrencies.PROP_values, [], 'number', '');
					//var precisions = resp.getArray(JafWebAPI.PDClass.getCurrencies.PROP_prec, [], 'number', '');
					for(var i=0; i < names.length && i < values.length; i++)
						result[names[i]] = values[i];
					pdClass.retcode = resp.getInt(JafWebAPI.PROP_retCode, -1);
					if(typeof callback == 'function')
						callback(result);
				};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callback == 'function')
					callback();
			};
		JafWeb.ajaxRequest({
				url: this.getURL(),
				authToken: this.getAuthToken(),
				method: "GET",
				async: (!!callback),
				params: pars.get(),
				scope: this,
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		if(!callback)
			return result;
	}
	
	/// Klassenattribute:
	/**
	 @function PDClass#getAttribute
	 @desc Den Wert eines Klassenattributs lesen.<br/>
	 <h4>Beispiel</h4>
	 <pre class="prettyprint"><code>const value = PDClass.getAttribute('Klasse.Attribut');
if(PDClass.retcode & PDObjectFlags.Available == 0)
		alert('Attribut konnte nicht gelesen werden.');</code></pre>
	 @param {string} classAttr Name des zu lesenden Klassenattributs in
		 der Form "Klasse.Attribut" oder "Klasse::Attribut". Wird auch der 
		 Parameter <code>attr</code> mit dem Attributnamen angegeben, muss 
		 hier der Klassenname angegeben werden.
	 @param {string} attr Name des zu lesenden Klassenattributs, wenn es nicht
		 bereits mit dem ersten Parameter angegeben wurde.
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @return {string} Wert des Attributs.
		 Wenn das Attribut erfolgreich gelesen
		 werden konnte, enthält [PDClass.retcode]{@link PDClass#retcode} unmittelbar nach dem
		 Aufruf dieser Funktion das Flag [PDObjectFlags.Available]{@link PDObjectFlags#Available}.
		 Das kann wie folgt abgefragt werden:
	*/
	getAttribute(classAttr, attr, callback) {
		if(!classAttr || typeof classAttr != "string" || classAttr == "" || (attr && attr == ""))
			throw "Needs at least one string parameter!"
		var pos = 0;
		var attrName = "";
		var fCallb = null;
		if(arguments.length >= 2 && (typeof arguments[1] == 'string'))
		{
			attrName = classAttr + "::" + attr;
			pos = 2;
		}
		else
		{
			var sep = classAttr.search(/\./);
			if(sep >= 0)
				attrName = classAttr.substring(0, sep) + "::" + classAttr.substring(sep+1);
			else
				attrName = classAttr;
			pos = 1;
		}
		if(arguments.length > pos && (typeof arguments[pos] == 'function'))
		{
			fCallb = arguments[pos];
			pos++;
		}
		var pars = new JParamPacker(JafWebAPI.PDClass.getAttribute.eventName, this);
		pars.add(JafWebAPI.PDClass.getAttribute.PAR_cattr, attrName);
		this.retcode = -1;
		this._lastMsg = '';
		var result = '';
		var pdClass = this;
		var successFn = function(req, options)
				{
					var resp = new JResponse(req, options, pdClass);
					if(resp.hasFatalError())
						throw "Request got empty or invalid response!";
					if(resp.hasError())
						throw resp.getErrorMessage();
					result = resp.getString(JafWebAPI.PDClass.getAttribute.PROP_value, '');
					pdClass.retcode = resp.getInt(JafWebAPI.PDClass.getAttribute.PROP_flags, -1);
					// TODO: flags?
					if(typeof fCallb == 'function')
						fCallb(result);
				};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof fCallb == 'function')
					fCallb();
			};
		JafWeb.ajaxRequest({
				url: this.getURL(),
				authToken: this.getAuthToken(),
				method: "GET",
				async: (!!fCallb),
				params: pars.get(),
				scope: this,
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		if(!fCallb)
			return result;
	}
	
	/**
	 @function PDClass#setAttribute
	 @desc Den Wert eines Klassenattributs setzen.<br/>
		 <span class="important">Hinweis:</span> Im Unterschied zu
		 [PDObject.setAttribute()]{@link PDObject#setAttribute} wird
		 der Wert hier sofort per Request an den Server zu setzen versucht.
	 @param {string} classAttr Name des zu setzenden Klassenattributs in
		 der Form "Klasse.Attribut" oder "Klasse::Attribut". Wird auch der 
		 Parameter <code>attr</code> mit dem Attributnamen angegeben, muss 
		 hier der Klassenname angegeben werden.
	 @param {string} [attr] Name des zu setzenden Klassenattributs, wenn es nicht
		 bereits mit dem ersten Parameter angegeben wurde.
	 @param {string} value Wert, auf den das Attribut gesetzt werden soll.
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @return {number} Wenn der Wert gesetzt werden konnte, wird 0 zurückgegeben,
		 ansonsten kann der Fehlercode mit [PDMeta.getString()]{@link PDMeta#getString}
		 in einen Text umgewandelt werden.
	 */
	setAttribute(classAttr, attr, value, callback) {
		if(!classAttr || classAttr == "" || arguments.length < 2)
			throw "Needs at least two parameters!";
		var attrName = "";
		var val = "";
		if(arguments.length == 3)
		{
			attrName = classAttr + "::" + attr;
			val = value;
		}
		else
		{
			var pos = classAttr.search(/\./);
			if(pos >= 0)
				attrName = classAttr.substring(0, pos) + "::" + classAttr.substring(pos+1);
			else
				attrName = classAttr;
			val = arguments[1];
		}
		var pars = new JParamPacker(JafWebAPI.PDClass.setAttribute.eventName, this);
		pars.add(JafWebAPI.PDClass.setAttribute.PAR_cattr, attrName);
		pars.add(JafWebAPI.PDClass.setAttribute.PAR_value, val);
		this.retcode = -1;
		this._lastMsg = '';
		var pdClass = this;
		var successFn = function(req, options)
				{
					var resp = new JResponse(req, options, pdClass);
					if(resp.hasFatalError())
						throw "Request got empty or invalid response!";
					if(resp.hasError())
					{
						// bei unbekannten Attributen schreibt der Server nur "unknown attribute" in die Fehleremeldung
						var msg = (resp.getErrorMessage() || '');
						msg = msg.replace('unknown attribute', attr);
						throw msg;
					}
					pdClass.retcode = resp.getInt(JafWebAPI.PROP_retCode, -1);
					if(typeof callback == 'function')
						callback(pdClass.retcode);
				};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callback == 'function')
					callback();
			};
		JafWeb.ajaxRequest({
				url: this.getURL(),
				authToken: this.getAuthToken(),
				method: "POST",
				async: (!!callback),
				params: pars.getPostParams(),
				scope: this,
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		if(!callback)
			return this.retcode;
	}

	/**
	 @function PDClass#downloadDocument
	 @desc Die Datei zu einem Attribut vom Typ <code>Document</code> herunterladen.
	 @param {Mixed} [classOrObj] Wenn es sich um ein Objektattribut handelt, kann
		 hier das {@link PDObject} oder dessen Klassenname oder Klassen-ID angegeben
		 werden. In den letzten beiden Fällen muss dann beim folgenden Parameter die
		 Objekt-ID angegeben werden.
	 @param {number} [oidLow] Unterer Teil der Objekt-ID.
	 @param {string} attr Der Name des Attributs. Im Falle eines Klassenattributs
		 in der Form "Klassename::Attributname", im Falle eines Objektattributs nur
		 der Attributname.
	 */
	downloadDocument(classOrObj, oidLow, attr) {
		var pars = new JParamPacker(JafWebAPI.PDClass.jexecDownload.eventName);
		if(arguments.length == 1)
		{
			// bisher einziger Fall: nur Klassenattribut
			pars.add(JafWebAPI.PDClass.jexecDownload.PAR_attr, attr);
		}
		else if(arguments.length > 0 && JafWeb.isPDObject(arguments[0]))
		{
			var obj = arguments[0];
			pars.add(JafWebAPI.PDClass.jexecDownload.PAR_cid, obj.cid);
			pars.add(JafWebAPI.PDClass.jexecDownload.PAR_clName, obj.classname);
			pars.add(JafWebAPI.PDClass.jexecDownload.PAR_oid, obj.GetPDObjectIdLow());
			if(arguments.length >= 2)
				pars.add(JafWebAPI.PDClass.jexecDownload.PAR_attr, arguments[1]);
			else
				throw new "Second parameter (attribute) has unexpected type";
		}
		else if(arguments.length >= 3)
		{
			if(typeof arguments[0] == 'string')
				pars.add(JafWebAPI.PDClass.jexecDownload.PAR_clName, arguments[0]);
			else if(typeof arguments[0] == 'number')
				pars.add(JafWebAPI.PDClass.jexecDownload.PAR_cid, arguments[0]);
			else
				throw "First parameter (class name or id) has unexpected type";
			if(typeof arguments[1] == 'number')
				pars.add(JafWebAPI.PDClass.jexecDownload.PAR_oid, arguments[1]);
			else
				throw "Second parameter (object id) has unexpected type";
			pars.add(JafWebAPI.PDClass.jexecDownload.PAR_attr, arguments[2]);
		}
		var url = this.getDownloadURL() +
					"?janusFileOperation=downloadWebEvent&iid=" + this.PDMeta.getInstanceID() +
					(this.getAuthToken() ? '&sessionId=' + this.getAuthToken() : '') +
					"&janusWebEvent=" + pars.getEventString(true);
		window.open(url);
	}
	
	/**
	 @function PDClass#showURL
	 @desc Der uebergebene String wird als URL verstanden und im neuen Fenster geoeffnet. (Eigentlich eine Standardfunktion im Javascript, aber aus irgendwelchen Gruenden wird diese in der main.js bei Neuanlage nicht mehr korrekt ausgefuehrt)
	 @param {cURL} Uebergebene String der versucht wird im neuen Fenster zu oeffnen.
	 */
	showURL(cURL){
		if(cURL != "")
			window.open(cURL);
	}

	// Zu Debugging-Zwecken:
	/*
	 @function PDClass#checkTransaction
	 @desc Informationen zu einer offenen Transaktion ermitteln.
	 @param {number} tid Die Transaktions-Id.
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @return {Object} Ein JavaScript-Objekt mit den Informationen.
	 */
	checkTransaction(tid, callback) {
		var pars = new JParamPacker(JafWebAPI.PDClass.checkTransaction.eventName, this);
		pars.add('tid', tid);
		this._lastMsg = '';
		this.retcode = -1;
		var res = { };
		var pdClass = this;
		var successFn = function(req, options)
				{
					var resp = new JResponse(req, options, pdClass);
					if(resp.hasFatalError())
						throw "Request got empty or invalid response!";
					if(resp.hasError())
						throw resp.getErrorMessage();
					res = resp.getData();
					pdClass.retcode = resp.getInt(JafWebAPI.PROP_retCode, -1);
					if(typeof callback == 'function')
						callback(res);
				};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callback == 'function')
					callback();
			};
		JafWeb.ajaxRequest({
				url: this.getURL(),
				authToken: this.getAuthToken(),
				method: "GET",
				async: (!!callback),
				params: pars.get(),
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		if(!callback)
			return res;
	}
	
	/*
	 @function PDClass#checkTransactions
	 @desc Informationen zu den offenen Transaktionen ermitteln.
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @return {Object} Ein JavaScript-Objekt mit den Informationen.
	 */
	checkTransactions(callback) {
		var pars = new JParamPacker(JafWebAPI.PDClass.checkTransactions.eventName, this);
		this._lastMsg = '';
		this.retcode = -1;
		var res = { };
		var pdClass = this;
		var successFn = function(req, options)
				{
					var resp = new JResponse(req, options, pdClass);
					if(resp.hasFatalError())
						throw "Request got empty or invalid response!";
					if(resp.hasError())
						throw resp.getErrorMessage();
					res = resp.getData();
					pdClass.retcode = resp.getInt(JafWebAPI.PROP_retCode, -1);
					if(typeof callback == 'function')
						callback(res);
				};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callback == 'function')
					callback();
			};
		JafWeb.ajaxRequest({
				url: this.getURL(),
				authToken: this.getAuthToken(),
				method: "GET",
				async: (!!callback),
				params: pars.get(),
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		if(!callback)
			return res;
	}
	
	// Events zur Aenderungsueberwachung
	/**
	 @function PDClass#addObjectEventHandler
	 @desc Eine Handler-Funktion hinzufügen, um über
		 Änderungen an Fachkonzeptobjekten benachrichtigt zu werden.<br/>
		 Es können beliebig viele Handler-Funktionen registriert
		 werden. Mit
		 [removeObjectEventHandler()]{@link PDObject#removeObjectEventHandler}
		 lassen sie sich wieder entfernen.
	 @param {string} type Art des Ereignisses. Folgende Werte sind
		 möglich:
		 <ul>
			  <li>"changed": Ein {@link PDObject} wurde geändert.</li>
			  <li>"created": Ein {@link PDObject} wurde neu
			  angelegt (vgl. [PDClass.newObject()]{@link PDClass#newObject}).</li>
			  <li>"delete": Ein {@link PDObject} soll gelöscht werden
			  (vgl. [PDClass.deleteObject()]{@link PDClass#deleteObject}).</li>
			  <li>"deleted": Ein {@link PDObject} wurde gelöscht
			  (vgl. [PDClass.deleteObject()]{@link PDClass#deleteObject}).</li>
		 </ul>
	 @param {Function} handler Die Handler-Funktion. Je nach Ereignistyp enthält
		 das beim Aufruf an diese Funktion übergebene JavaScript-Objekt unterschiedliche
		 Properties:
		 <ul>
		  <li>"changed":
				<ul>
					<li><code>object</code>: Das geänderte {@link PDObject}.</li>
					<li><code>member</code>: Das geänderte Element (Attribut oder Beziehung).</li>
					<li><code>oldValue</code>: Der alte Wert (optional).</li>
					<li><code>newValue</code>: Der neue Wert (optional).</li>
				</ul>
		  </li>
		  <li>"created":
				<ul>
					<li><code>object</code>: Das neu angelegte {@link PDObject}.</li>
				</ul>
		  </li>
		  <li>"delete":
				<ul>
					<li><code>object</code>: Das zu löschende {@link PDObject}.</li>
				</ul>
		  </li>
		  <li>"deleted":
				<ul>
					<li><code>classname</code>: Klassenname des gelöschten {@link PDObject}s.</li>
					<li><code>oidLow</code>: Objekt-Id (unterer Teil) des gelöschten {@link PDObject}s.</li>
				</ul>
		  </li>
		 </ul>
	 */
	addObjectEventHandler(type, handler) {
		if(!this._evtHandler)
			this._evtHandler = {};
		if(!this._evtHandler[type])
			this._evtHandler[type] = [];
		this._evtHandler[type].push(handler);
	}
	
	/**
	 @function PDClass#removeObjectEventHandler
	 @desc Eine registrierte Handler-Funktion entfernen.
	 @param {string} type Art des Ereignisses. Zu den
		 möglichen Werten vgl.
		 [addObjectEventHandler()]{@link PDClass#addObjectEventHandler}.
	 @param {Function} [handler] Die zu entfernende
		 Handler-Funktion. Wird diese nicht angegeben,
		 werden alle registrierten Handler des angegeben
		 Typs entfernt!
	 */
	removeObjectEventHandler(type, handler) {
		if(!this._evtHandler || !this._evtHandler[type])
			return;
		if(!handler) {
			this._evtHandler[type] = [];
			return;
		}
		let i = 0;
		while(i < this._evtHandler[type].length) {
			if(this._evtHandler[type][i] == handler)
				this._evtHandler[type].splice(i, 1);
			else
				i++;
		}
	}
	
	// umbenannt von "dispatchEvent"!
	/**
	 @function PDClass#dispatchPDEvent
	 @desc Verteilt Ereignisse an registrierte Handler.
	 @param {string} type Art des Ereignisses. Zu den
		 möglichen Werten vgl.
		 [addObjectEventHandler()]{@link PDClass#addObjectEventHandler}.
	 @param {Object} data An das Event zu übegebende Daten
		 (ereignistyp-abhängig).
	 */
	dispatchPDEvent(type, data) {
		//console.log("### PDClass.dispatchPDEvent('" + type + "') - data:", data);

		data.type = type;
		const custEvt = new CustomEvent('pd-updated', {
				bubbles: true,
				cancelable: false,
				composed: true,
				detail: data
			});
		document.dispatchEvent(custEvt);

		if(this._evtHandler && this._evtHandler[type]) {
			for(let i = 0; i < this._evtHandler[type].length; i++) {
				try {
					this._evtHandler[type][i](data);
				}
				catch(ex) {
					console.warn("PDClass " + type + "-event handler" +
							" throwed an exception: " + ex);
				}
			}
		}
	}
	
	/*
	 * Handler für Änderungsereignisse aufrufen.
	 */
	handleObjectChanged(obj, member, oldVal, newVal) {
		if(!obj || obj.oidLow <= 0)
			return; // fuer Trans-Objekte ignorieren!
		//console.log("### PDClass.handleObjectChanged(" +
		//		obj.classname + ":" + obj.oidLow + ", '" +
		//		member + "')");
		this.dispatchPDEvent('changed', {
				'object': obj,
				'member': member,
				'oldValue': oldVal,
				'newValue': newVal
			});
	}

	/*
	 * Handler für Neuanlageereignisse aufrufen.
	 */
	handleObjectCreated(obj) {
		if(!obj || obj.oidLow <= 0)
			return; // fuer Trans-Objekte ignorieren!
		//console.log("### PDClass.handleObjectCreated(" +
		//		obj.classname + ":" + obj.oidLow + ")");
		this.dispatchPDEvent('created', {
				'object': obj
			});
	}

	/*
	 * Handler für Löschereignisse aufrufen.
	 */
	handleObjectDelete(obj) {
		if(!obj || obj.oidLow <= 0)
			return; // fuer Trans-Objekte ignorieren!
		//console.log("### PDClass.handleObjectDelete(" +
		//		obj.classname + ":" + obj.oidLow + ")");
		this.dispatchPDEvent('delete', {
				'object': obj
			});
	}
	
	/*
	 * Handler für Löschereignisse aufrufen.
	 */
	handleObjectDeleted(classname, oidLow) {
		if(oidLow <= 0)
			return; // fuer Trans-Objekte ignorieren!
		//console.log("### PDClass.handleObjectDeleted(" +
		//		classname + ":" + oidLow + ")");
		//this.PDObjectCache.removeObject(_cid, _oidLow);
		this.dispatchPDEvent('deleted', {
				classname: classname,
				oidLow: oidLow
			});
	}
	
	// Zugriff auf Server-INI
	/**
	 @function PDClass#getIniParameters
	 @desc Alle aktuell gesetzten INI-Parameter holen. Dazu muss der
		 aktuelle Benutzer über das [Admin-Recht]{@link ClientInfo#getAdminPermission}
		 [ClientInfo.viewServerIni]{@link ClientInfo#viewServerIni} verfügen.
	 @param {Function} [callback] Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @return {Object} JavaScript-Objekt mit folgenden Properties:
		 <ul>
			<li><code>retCode</code>: Rückgabe-Code der Funktion. Ein Wert
				ungleich 0 bedeutet einen Fehler, Werte über 0 können mit
				[PDMeta.getString()]{@link PDMeta#getString} in einen Text
				umgewandelt werden.</li>
			<li><code>xmlIni</code>: Die aktuellen Einstellungen als XML.</li>
			<li><code>descr</code>: Die anwendungsspezifische Beschreibung der
				verfügbaren INI-Parameter.</li>
			<li><code>janusDescr</code>: Die JANUS-spezifische Beschreibung der
				verfügbaren INI-Parameter.</li>
		 </ul>
	 */
	getIniParameters(callback) {
		this.retcode = -1;
		var pars = new JParamPacker(JafWebAPI.PDClass.getIniParameters.eventName, this);
		var result = {
				retCode: -1
			};
		var pdClass = this;
		var successFn = function(req, options)
			{
				var resp = new JResponse(req, options, pdClass);
				if(resp.hasFatalError())
					throw "Request got empty or invalid response!";
				if(resp.hasError())
					throw resp.getErrorMessage();
				if(!result.retCode)
				{
					result.xmlIni = resp.getString(JafWebAPI.PDClass.getIniParameters.PROP_xml);
					result.descr = resp.getString(JafWebAPI.PDClass.getIniParameters.PROP_descr);
					result.janusDescr = resp.getString(JafWebAPI.PDClass.getIniParameters.PROP_jDescr);
				}
				if(typeof callback == 'function')
					callback(result);
			};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callback == 'function')
					callback(result);
			};
		JafWeb.ajaxRequest({
				url: this.getURL(),
				authToken: this.getAuthToken(),
				method: "GET",
				async: (!!callback),
				params: pars.get(),
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		if(!callback)
			return result;
	}

	/**
	 @function PDClass#setIniParameters
	 @desc Die INI-Einstellungen setzen. Dazu muss der
		 aktuelle Benutzer über das [Admin-Recht]{@link ClientInfo#getAdminPermission}
		 [ClientInfo.editServerIni]{@link ClientInfo#editServerIni} verfügen.
	 @param {string} xmlIni Die geänderten Einstellungen.
	 @param {Function} [callback] Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @return Rückgabe-Code der Funktion. Ein Wert
		 ungleich 0 bedeutet einen Fehler, Werte über 0 können mit
		 [PDMeta.getString()]{@link PDMeta#getString} in einen Text
		 umgewandelt werden.
	 */
	setIniParameters(xmlIni, callback) {
		this.retcode = -1;
		var pars = new JParamPacker(JafWebAPI.PDClass.setIniParameters.eventName, this);
		pars.add(JafWebAPI.PDClass.setIniParameters.PAR_xml, xmlIni);
		var result = -1;
		var pdClass = this;
		var successFn = function(req, options)
			{
				var resp = new JResponse(req, options, pdClass);
				if(resp.hasFatalError())
					throw "Request got empty or invalid response!";
				if(resp.hasError())
					throw resp.getErrorMessage();
				result = resp.getInt(JafWebAPI.PROP_retCode, -1);
				if(typeof callback == 'function')
					callback(result);
			};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callback == 'function')
					callback(result);
			};
		JafWeb.ajaxRequest({
				url: this.getURL(),
				authToken: this.getAuthToken(),
				method: "POST",
				async: (!!callback),
				params: pars.get(),
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		if(!callback)
			return result;
	}

	// JanusServerPages
	/**
	 @function PDClass#getJanusServerPageTemplate
	 @desc Fragt die HTML-Schablone für Objekte einer fachlichen
		Klasse ab. Entspricht <code>JanusServerPage::getPage()</code>
		in der Server-Lib.<br/>
		Benötigt das Recht
		[ClientInfo.editHtmlView]{@link ClientInfo#editHtmlView}
	 @param {string} cls Name der fachlichen Klasse
	 @param {Function} callback
	 @param {boolean} [edit=false]
	 @since 3.0
	 */
	getJanusServerPageTemplate(cls, callback, edit) {
		this.retcode = -1;
		const pars = new JParamPacker(JafWebAPI.PDClass.getJanusServerPageTemplate.eventName, this);
		pars.add(JafWebAPI.PDClass.getJanusServerPageTemplate.PAR_clName, cls);
		if(edit === true)
			pars.add(JafWebAPI.PDClass.getJanusServerPageTemplate.PAR_edit, true);
		let result = "";
		const pdClass = this;
		const successFn = function(req, options) {
				var resp = new JResponse(req, options, pdClass);
				if(resp.hasFatalError())
					throw "Request got empty or invalid response!";
				if(resp.hasError())
					throw resp.getErrorMessage();
				pdClass.retcode = resp.getInt(JafWebAPI.PROP_retCode, -1);
				result = resp.getString(JafWebAPI.PDClass.getJanusServerPageTemplate.PROP_templ);
				if(typeof callback == 'function')
					callback(result);
			};
		const failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callback == 'function')
					callback(result);
			};
		JafWeb.ajaxRequest({
				url: this.getURL(),
				authToken: this.getAuthToken(),
				method: "POST",
				async: (!!callback),
				params: pars.get(),
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		if(!callback)
			return result;
	}
	
	/**
	 @function PDClass#setJanusServerPageTemplate
	 @desc Setzt die HTML-Schablone für Objekte einer fachlichen
		Klasse. Entspricht <code>JanusServerPage::setPage()</code>
		in der Server-Lib.<br/>
		Benötigt das Recht
		[ClientInfo.editHtmlView]{@link ClientInfo#editHtmlView}
	 @param {string} cls Name der fachlichen Klasse
	 @param {string} templ Die Schablone, also HTML-Code mit speziellen
		Tags zum Zugriff auf Elemente der Klasse. Vgl.
		<code>JanusServerPage::parse()</code> der JANUS-Laufzeit-Doku.
	 @param {Function} callback
	 @param {boolean} [edit=false]
	 @since 3.0
	 */
	setJanusServerPageTemplate(cls, templ, callback, edit) {
		this.retcode = -1;
		const pars = new JParamPacker(JafWebAPI.PDClass.setJanusServerPageTemplate.eventName, this);
		pars.add(JafWebAPI.PDClass.setJanusServerPageTemplate.PAR_clName, cls);
		pars.add(JafWebAPI.PDClass.setJanusServerPageTemplate.PAR_clName, templ);
		if(edit === true)
			pars.add(JafWebAPI.PDClass.getJanusServerPageTemplate.PAR_edit, true);
		let result = -1;
		const pdClass = this;
		const successFn = function(req, options) {
				var resp = new JResponse(req, options, pdClass);
				if(resp.hasFatalError())
					throw "Request got empty or invalid response!";
				if(resp.hasError())
					throw resp.getErrorMessage();
				result = resp.getInt(JafWebAPI.PROP_retCode, -1);
				if(typeof callback == 'function')
					callback(result);
			};
		const failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callback == 'function')
					callback(result);
			};
		JafWeb.ajaxRequest({
				url: this.getURL(),
				authToken: this.getAuthToken(),
				method: "POST",
				async: (!!callback),
				params: pars.get(),
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		if(!callback)
			return result;
	}
}
// Objekt erzeugen im Workspace-Frameset!


/**
 @class PDMeta
 @classdesc Client-seitige Repräsentation der JANUS-Laufzeit-Klasse 
	 <code>PDMeta</code>. Diese stellt im wesentlichen Informationen
	 aus dem UML-Modell zur Verfügung.<br/>
	 Es gibt von dieser Klasse genau ein Objekt, das unter seinem
	 Namen als globale Variable zugänglich ist. Zum Beispiel
	 zum Ermitteln des ergonomischen Namens eines Attributs:
	 Das globale <code>PDMeta</code>-Objekt wird beim Laden des
	 Workspace automatisch per Server-Aufruf initialisiert.<br/>
	 <h4>Beispiel</h4>
	 <pre class="prettyprint"><code>const ergName = PDMeta.getErgname("MyClass", "MyAttr")</code></pre>
 @author Frank Fiolka
 */
class PDMetaClass {
	_iconCache = { };
	_loadCompleted = false;
	_showGetTextId = false;
	_journalAttr = undefined;
	_journalAttrLabel = undefined;
	_journalOid = undefined;
	PDClass = null;
	
	/// kein oeffentlicher Konstruktor!
	constructor(pdClass) {
		this.PDClass = (pdClass || null);
	}
	
	/// Konstanten zum Umgang mit <code>PDMeta.getTypeId()</code>.
	/// TypeIds und Flags
	/**
	 @memberof PDMeta
	 @desc Kein Typ definiert.
	 @const {number}
	 */
	TIdNone = 0;
	/**
	 @memberof PDMeta
	 @desc Ein ganzzahliges Attribut.
	 @const {number}
	 */  
	TIdInt = 1;
	/**
	 @memberof PDMeta
	 @desc Eine Fließkommazahl.
	 @const {number}
	 */
	TIdFloat = 2;
	/**
	 @memberof PDMeta
	 @desc Eine Zeichenkette.
	 @const {number}
	 */
	TIdString = 3;
	/**
	 @memberof PDMeta
	 @desc Ein Datum.
	 @const {number}
	 */
	TIdDate = 4;
	/**
	 @memberof PDMeta
	 @desc Eine Uhrzeit.
	 @const {number}
	 */  
	TIdTime = 5;
	/**
	 @memberof PDMeta
	 @desc Ein Zeitstempel.
	 @const {number}
	 */ 
	TIdTimestamp = 6;
	/**
	 @memberof PDMeta
	 @desc Eine Seriennummer.
	 @const {number}
	 */
	TIdSerial = 7;
	/**
	 @memberof PDMeta
	 @desc Eine E-Mail-Adresse.
	 @const {number}
	 */
	TIdEmail = 8;
	/**
	 @memberof PDMeta
	 @desc Eine URL.
	 @const {number}
	 */
	TIdURL = 9;
	/**
	 @memberof PDMeta
	 @desc Ein allgemeines Datenfeld (Dateiname).
	 @const {number}
	 */
	TIdFilename = 10;
	/**
	 @memberof PDMeta
	 @desc Eine Währung.
	 @const {number}
	 */
	TIdCurrency = 11;
	/**
	 @memberof PDMeta
	 @desc Ein boolescher Datentyp.
	 @const {number}
	 */
	TIdBool = 12;
	/**
	 @memberof PDMeta
	 @desc Ein Aufzählungstyp.
	 @const {number}
	 */
	TIdEnum = 13;
	/**
	 @memberof PDMeta
	 @desc Ein (verschlüsseltes) Passwort.
	 @const {number}
	 */
	TIdPassword = 14;
	/**
	 @memberof PDMeta
	 @desc Abstraktes Attribut.
	 @const {number}
	 */
	TIdAbstract = 15;
	/**
	 @memberof PDMeta
	 @desc C-Zeitstempel (Sekunden seit 1.1.1970).
	 @const {number}
	 */
	TIdCTime = 17;
	/**
	 @memberof PDMeta
	 @desc Benutzer-Kennung.
	 @const {number}
	 */
	TIdUser = 18;
	/**
	 @memberof PDMeta
	 @desc Währungstyp mit variablem Kurs.
	 @const {number}
	 */
	TIdVarCurrency = 19;
	/**
	 @memberof PDMeta
	 @desc N-Seite einer N-zu-N Beziehung.
	 @const {number}
	 */
	TIdRelNN = 20;
	/**
	 @memberof PDMeta
	 @desc N-Seite einer 1-zu-N Beziehung.
	 @const {number}
	 */
	TIdRel1N = 21;
	/**
	 @memberof PDMeta
	 @desc 1-Seite einer 1-zu-N Beziehung.
	 @const {number}
	 */
	TIdRelN1 = 22;
	/**
	 @memberof PDMeta
	 @desc 1-Seite einer 1-zu-1 Beziehung.
	 @const {number}
	 */
	TIdRel11 = 23;
	/**
	 @memberof PDMeta
	 @desc Attribut, das als Beziehung verwendet wird.
	 @const {number}
	 */
	TIdRef = 24;
	/**
	 @memberof PDMeta
	 @desc Eine allgemeine OID. Kann zur Implementierung 
		 einseitiger Beziehungen verwendet werden.
	 @const {number}
	 */
	TIdOID = 25;
	/**
	 @memberof PDMeta
	 @desc Bitmaske zum Ausfiltern der Typkennung aus der TypeId.
	 @const {number}
	 */
	TIdMask = 255;
	/**
	 @memberof PDMeta
	 @desc Attribut dient als Fremdschlüssel.
	 @const {number}
	 */
	TFlagForeignKey = 0x0100;
	/**
	 @memberof PDMeta
	 @desc Attribut belegt keinen Speicher in der Datenbank.
	 @const {number}
	 */
	TFlagNoStorage = 0x0200;
	/**
	 @memberof PDMeta
	 @desc Ein erweiterbarer Aufzählungstyp mit global sichtbaren 
		 Erweiterungen.
	 @const {number}
	 */
	TFlagGlobalEnum = 0x0400;
	/**
	 @memberof PDMeta
	 @desc Ein erweiterbaren Aufzählungstyp mit lokal sichtbaren 
		 Erweiterungen.
	 @const {number}
	 */
	TFlagLocalEnum = 0x0800;
	/**
	 @memberof PDMeta
	 @desc Das Attribut ist mehrsprachig gespeichert.
	 @const {number}
	 */
	TFlagMultiLang = 0x1000;
	/**
	 @memberof PDMeta
	 @desc Es handelt sich um einen (fachlichen) Schlüssel.
	 @const {number}
	 */
	TFlagKey = 0x2000;
	/**
	 @memberof PDMeta
	 @desc Es handelt sich um ein Muss-Attribut oder eine Muss-Beziehung.
	 @see <code>TFlagMandatoryWhen</code>.
	 @const {number}
	 */
	TFlagMandatory = 0x4000;
	/**
	 @memberof PDMeta
	 @desc Das Attribut kann nur verändert werden, wenn das Objekt neu 
		 angelegt wurde.
	 @const {number}
	 */
	TFlagNonMutable = 0x8000;
	/**
	 @memberof PDMeta
	 @desc Es handelt sich um ein implizites Attribut, das nicht im 
		 OOA-Modell spezifiziert wurde.
	 @const {number}
	 */
	TFlagImplicit = 0x10000;
	/**
	 @memberof PDMeta
	 @desc Auf das Attribut kann nur lesend zugegriffen werden.
	 @const {number}
	 */
	TFlagReadOnly = 0x20000;
	/**
	 @memberof PDMeta
	 @desc Auf das Attribut kann über die Benutzungsoberfläche nur 
		 lesend zugegriffen werden.
	 @const {number}
	 */
	TFlagGUIReadOnly = 0x40000;
	/**
	 @memberof PDMeta
	 @desc Das Attribut ist abgeleitet. Der abgeleitete Wert kann 
		 durch einen manuell angegebenen Wert gesetzt werden.<br>
		 Wird <code>PDObject.setAttribute()</code> mit einem gültigen Wert 
		 aufgerufen, ist dieser Wert fortan wirksam. Auf den berechneten 
		 Wert kann "umgeschaltet" werden, indem <code>PDObject::setAttribute()</code> 
		 mit einer leeren Zeichenkette als Attributwert aufgerufen wird.
	 @const {number}
	 */
	TFlagOverruleable = 0x80000;
	/**
	 @memberof PDMeta
	 @desc Ein Aufzählungstyp, der die Selektion von mehreren Werten erlaubt.
	 @const {number}
	 */
	TFlagMultiEnum = 0x100000;
	/**
	 @memberof PDMeta
	 @desc Die Währungsinformationen für diesen Währungstyp stammen von einem 
		 anderen Währungsattribut.<br/>
		 Der Wert ist nur für Währungs-Attribute gültig.
	 @const {number}
	 */
	TFlagDerivedCurrency = 0x200000;
	/**
	 @memberof PDMeta
	 @desc Das Attribut verwendet einen sogen. Technical Enum, d.h. der
		 technische Wert kann über <code>getAttribute("&lt;Attributname&gt;.Tech")</code>
		 abgefragt werden.<br/>
		 <span class="important">Hinweis:</span> Der Wert ist gleich dem Wert der Konstanten
		 <code>TFlagDerivedCurrency</code>, d.h. bei der Prüfung
		 auf dieses Flag muss zunächst der Datentyp überprüft werden.
	 @const {number}
	 */
	TFlagTechEnum = this.TFlagDerivedCurrency;
	/**
	 @memberof PDMeta
	 @desc Das Serial-Attribut soll Lücken, die durch das Löschen von Objekten 
		 oder andere Ereignisse entstanden sind, nicht wieder füllen.<br>
		 Die Implementierung des Attributs wird damit einfacher und effizienter, 
		 jedoch gelten dann starke Restriktionen für Definition eines 
		 solchen Datentyps.<br/>
		 <span class="important">Hinweis:</span> Der Wert ist gleich dem Wert der Konstanten <code>TFlagDerivedCurrency</code>, 
		 d.h. bei der Prüfung auf dieses Flag muss zunächst der Datentyp 
		 abgefragt werden.
	 @const {number}
	 */
	TFlagFastSerial = this.TFlagDerivedCurrency;
	/**
	 @memberof PDMeta
	 @desc Das String-Attribut wird in UTF-8 Kodierung (Unicode) gespeichert.<br/>
		 <span class="important">Hinweis:</span> Der Wert ist gleich dem Wert der Konstanten <code>TFlagDerivedCurrency</code>, 
		 d.h. bei der Prüfung auf dieses Flag muss zunächst der Datentyp abgefragt werden.
	 @const {number}
	 */
	TFlagUnicode = this.TFlagDerivedCurrency;
	/**
	 @memberof PDMeta
	 @desc Ein abgeleitetes Attribut, dessen Wert aus Gründen besserer Performanz 
		 in der (relationalen) Datenbank gespeichert wird.
	 @const {number}
	 */
	TFlagAutoCache = 0x400000;
	/**
	 @memberof PDMeta
	 @desc Beziehung ist im Baum darstellbar.
	 @const {number}
	 */
	TFlagRelTree = 0x800000;
	/**
	 @memberof PDMeta
	 @desc Beziehung bildet einen eigenständigen Knoten.
	 @const {number}
	 */
	TFlagRelNode = 0x1000000;
	/**
	 @memberof PDMeta
	 @desc In den Selektionslisten zur Herstellung dieser Beziehung werden 
		 die bereits konnektierten Objekte nicht mehr angezeigt.<br>
		 Das Flag ist gesetzt, wenn das Property <code>FilterConnected</code> für
		 die entsprechende Beziehung gesetzt ist.<br/>
		 <span class="important">Hinweis:</span> Der Wert ist gleich <code>TFlagMultiLang</code>.
	 @const {number}
	 */
	TFlagRelFilterConnected = this.TFlagMultiLang;
	/**
	 @memberof PDMeta
	 @desc Für Beziehung ist das Property <code>MandatoryWhen</code> gesetzt, d. h. 
		 ob die Beziehung eine Muss-Beziehung ist, entscheidet sich 
		 anhand eines User Codes, der im Wert des Properties <code>MandatoryWhen</code> 
		 angegeben werden muss.<br/>
		 <span class="important">Hinweis:</span> Das Property <code>MandatoryWhen</code> kann auch für Attribute spezifiziert 
		 werden, jedoch ist bei Attributen dieses Flag niemals gesetzt.
		 Der Wert ist gleich dem Wert der Konstanten <code>TFlagIsArrayIndex</code>. 
	 @const {number}
	 */
	TFlagMandatoryWhen = 0x40000000;
	/**
	 @memberof PDMeta
	 @desc Die Beziehung soll im Baum per Drag & Drop herstellbar sein.
	 @tattr int
	 */
	TFlagRelDragDrop = this.TFlagLocalEnum;
	/**
	 @memberof PDMeta
	 @desc Wenn die Beziehung über Drag & Drop hergestellt wird, soll 
		 eine Rückfrage beim Benutzer gestellt werden.
	 @const {number}
	 */
	TFlagRelDragDropAsk = this.TFlagGlobalEnum;
	/**
	 @memberof PDMeta
	 @desc Es handelt sich um ein manuelles Cache-Attribut.
	 @const {number}
	 */
	TFlagCache = 0x2000000;
	/**
	 @memberof PDMeta
	 @desc Das Attribut/die Beziehung darf für Mehrfachänderungen 
		 verwendet werden.
	 @const {number}
	 */
	TFlagMultiChange = 0x4000000;
	/**
	 @memberof PDMeta
	 @desc Die Beziehung ist manuell sortierbar. Neue Elemente werden am Ende 
		 der Liste eingefügt.
	 @const {number}
	 */
	TFlagOrderable = 0x400000;
	/**
	 @memberof PDMeta
	 @desc Die Beziehung ist manuell sortierbar. Neue Elemente werden am 
		 Anfang der Liste eingefügt.
	 @const {number}
	 */
	TFlagOrderableInverse = 0x2000000;
	/**
	 @memberof PDMeta
	 @desc Die Beziehung ist manuell sortierbar. Wo neue Werte eingefügt.
		 werden, entscheidet sich dynamisch.
	 @const {number}
	 */
	TFlagOrderableDynamic = 0x100000;
	/**
	 @memberof PDMeta
	 @desc Dieses Flag ist bei einer Beziehung gesetzt, wenn (im Falle 
		 einer Mussbeziehung) die abhängigen Objekte automatisch gelöscht 
		 werden sollen, wenn ein Objekt der Klasse gelöscht wird, von der 
		 die Beziehung ausgeht.<br/>
		 <span class="important">Hinweis:</span> Der Wert ist gleich dem Wert der Konstanten 
		 <code>TFlagDerivedCurrency</code>.
	 @const {number}
	 */
	TFlagAutoDelete = this.TFlagDerivedCurrency;
	/**
	 @memberof PDMeta
	 @desc Das Attribut/die Beziehung ist ein eindeutiger Schlüssel.
	 @const {number}
	 */
	TFlagUniqueKey = 0x8000000;
	/**
	 @memberof PDMeta
	 @desc Das Attribut/die Beziehung ist ein Klassenattribut.<br>
		 Das Flag ist auch bei Klassenoperationen gesetzt.
	 @const {number}
	 */
	TFlagClassGlobal = 0x10000000;
	/**
	 @memberof PDMeta
	 @desc Attribut Ist Teil eines Arrays.
	 @const {number}
	 */
	TFlagIsArrayPart = 0x20000000;
	/**
	 @memberof PDMeta
	 @desc Attribut Ist Index eines Arrays.
	 @const {number}
	 */
	TFlagIsArrayIndex = 0x40000000;
	/**
	 @memberof PDMeta
	 @desc Das Attribut ist nicht relevant für das Fachkonzept.<br/>
		 <span class="important">Hinweis:</span> Der Wert ist gleich dem Wert von <code>TRelTree</code>, 
		 der nur für Beziehungen gesetzt werden kann.
	 @const {number}
	 */
	TFlagNotPDRelevant = 0x800000;
	/**
	 @memberof PDMeta
	 @desc Nach dem Attribut kann nicht sortiert werden.<br/>
		 <span class="important">Hinweis:</span> Dieser Wert ist gleich <code>TFlagRelNode</code>, der 
		 nur für Beziehungen gesetzt sein kann. Das Sortieren nach einem 
		 Attribut, bei dem dieses Flag gesetzt ist, wird durch die 
		 Benutzungsoberfläche verhindert. In Iteratoren etc. kann trotzdem 
		 nach diesem Attribut sortiert werden.
	 @const {number}
	 */
	TFlagNotSortable = 0x1000000;
	/**
	 @memberof PDMeta
	 @desc Bitmaske zum Ausfiltern der Flags aus der TypeId.
	 @const {number}
	 */
	TFlagMask = 0x7FFFFF00;
	
	/// ClassFlags
	/**
	 @memberof PDMeta
	 @desc Wenn dieses Flag gesetzt ist, sind mandantenlose Objekte in
		 einer mandantenfähigen Anwendung nicht sichtbar. Das
		 Flag ist natürlich nur sinnvoll, wenn die Anwendung
		 mandantenfähig generiert wurde.
	 @const {number}
	 */
	FreeObjectsInvisible = 1;
	/**
	 @memberof PDMeta
	 @desc Wenn dieses Flag gesetzt ist, können keine Objekte dieser
		 Klasse erzeugt werden, die zu keinem Mandanten gehören. Das
		 Flag ist natürlich nur sinnvoll, wenn die Anwendung
		 mandantenfähig generiert wurde.
	 @const {number}
	 */
	NoFreeObjects = 2;
	/**
	 @memberof PDMeta
	 @desc Gesetzt bei abstrakten Klassen.
	 @const {number}
	 */
	AbstractClass = 4;
	/**
	 @memberof PDMeta
	 @desc Gesetzt bei mandantenfähigen Klassen. Dieses Flag muss nicht
		 konstant sein! Das Flag ist natürlich nur sinnvoll, wenn die
		 Anwendung mandantenfähig generiert wurde. Wenn das Flag
		 veränderlich ist, wenn also die Mandantenfähigkeit dieser
		 Klasse sich zur Laufzeit ändern kann, ist das Flag
		 <code>MultiClientDynamic</code> zusätzlich gesetzt.
	 @const {number}
	 */
	MultiClient = 8;
	/**
	 @memberof PDMeta
	 @desc Gesetzt, wenn in Listen die Anzahl der Objekte angezeigt
	 werden soll.
	 @const {number}
	 */
	ShowObjectCount = 128;
	/**
	 @memberof PDMeta
	 @desc Gesetzt bei Klassen mit vielen Attributen. Bewirkt, dass ein
		 weniger speicherintensives SQL-Kommando verwendet wird, das
		 bei größeren Datenmengen wesentlich schneller arbeitet.
	 @const {number}
	 */
	HugeClass = 16;
	/**
	 @memberof PDMeta
	 @desc Die Objekte sollen nur lesbar sein, wenn der aktuelle Mandant
		 nicht mit dem Besitzer des Objektes übereinstimmt. Das
		 Flag ist natürlich nur sinnvoll, wenn die Anwendung
		 mandantenfähig generiert wurde.
	 @const {number}
	 */
	ReadOnlyForOtherPrincipals = 32;
	/**
	 @memberof PDMeta
	 @desc Wenn dieses Flag gesetzt ist, dann kann sich die
		 Mandantenfähigkeit dieser Klasse zur Laufzeit ändern. Das
		 Flag ist natürlich nur sinnvoll, wenn die Anwendung
		 mandantenfähig generiert wurde.
	 @const {number}
	 */
	MultiClientDynamic = 64;
	/**
	 @memberof PDMeta
	 @desc Dieses Flag ist bei Klassen gesetzt, deren Objekte nur
		 von den Benutzern geändert und gelöscht werden können, die
		 sie erzeugt haben (sowie vom Administrator). Das Flag
		 ist natürlich nur sinnvoll, wenn die Anwendung
		 mehrbenutzerfähig generiert wurde.
	 @const {number}
	 */
	ChangeableByCreatorOnly = 256;
	/**
	 @memberof PDMeta
	 @desc Dieses Flag ist bei Klassen gesetzt, deren Zugriffsrecht
		 für das selbe Objekt bei allen Attributen gleich ist. Dies
		 wird vom JANUS-Laufzeitsystem genutzt, um die Abfrage der
		 Zugriffsrechte zu optimieren. Das Flag kann durch Setzen
		 des <i>Properties</i> <code>ClassLevelAccessRights</code>
		 beeinflusst werden. Das Flag ist gesetzt, wenn das
		 <i>Property</i> den Wert <code>True</code> hat.
	 @const {number}
	 */
	ClassLevelAccessRights = 512;
	/**
	 @memberof PDMeta
	 @desc Ist dieses Flag gesetzt, soll vor dem Erzeugen eines Objekts
		 der betreffenden Klasse der Benutzer gefragt werden. Das
		 Flag kann durch das Setzen des <i>Properties</i>
		 <code>AskNew</code> beeinflusst werden. Standardwert
		 für dieses <i>Property</i> ist <code>False</code>.
	 @const {number}
	 */
	AskNew = 1024;
	/**
	 @memberof PDMeta
	 @desc Ist dieses Flag gesetzt, soll vor dem Löschen eines Objekts
		 der betreffenden Klasse der Benutzer gefragt werden. Das
		 Flag kann durch das Setzen des <i>Properties</i>
		 <code>AskDelete</code> beeinflusst werden. Standardwert
		 für dieses <i>Property</i> ist <code>True</code>, so dass vor
		 dem Löschen eines Objekts eine Rückfrage erfolgt.
	 @const {number}
	 */
	AskDelete = 2048;
	/**
	 @memberof PDMeta
	 @desc Dieses Flag ist bei Klassen gesetzt, deren Leserecht
		 (nicht zwangsläufig auch das Schreibrecht)
		 für das selbe Objekt bei allen Attributen gleich ist. Dies
		 wird vom JANUS-Laufzeitsystem genutzt, um die Abfrage der
		 Zugriffsrechte zu optimieren. Das Flag kann durch Setzen
		 des <i>Properties</i> <code>ClassLevelAccessRightsRO</code>
		 eeinflusst werden. Das Flag ist gesetzt, wenn das
		 <i>Property</i> den Wert <code>True</code> hat. Das <i>Property</i>
		 kann nur im Zusammenhang mit
		 <code>ClientInfo::noAccessRights()</code> und dynamisch
		 implementierten Zugriffsrechten sinnvoll verwendet werden.
	 @const {number}
	 */
	ClassLevelAccessRightsRO = 4096;
	/**
	 @memberof PDMeta
	 @desc Dieses Flag kennzeichnet Klassen, deren Objekte in
		 Listenansichten mit verschiedenen, von einem Attributwert
		 abhängigen Icons angezeigt werden sollen.
	 @const {number}
	 */
	VariableListIcons = 8192;
	/**
	 @memberof PDMeta
	 @desc Dieses Flag kennzeichnet Klassen, deren Objekte im
		 Baum mit verschiedenen, von einem Attributwert
		 abhängigen Icons angezeigt werden sollen.
	 @const {number}
	 */
	VariableTreeIcons = 16384;
	/**
	 @memberof PDMeta
	 @desc Objekte von Klassen, bei denen dieses Flag gesetzt ist,
		 werden nicht komplett auf <code>ReadOnly</code> gesetzt,
		 wenn der Erfassungsdialog desselben Objekts zweimal
		 geöffnet wird.
	 @const {number}
	 */
	LockAttributesOnly = 32768;
	/**
	 @memberof PDMeta
	 @desc Dieses Flag kann über das Property
		 <code>NoCreatePermissionCache</code> gesetzt werden. Ist
		 das Flag gesetzt, wird das Ergebnis von
		 <code>ClientInfo::getCreatePermission()</code> Client-seitig
		 nicht gespeichert, sondern immer wieder neu vom Server
		 erfragt.
	 @const {number}
	 */
	NoCreatePermissionCache = 65536;
	/**
	 @memberof PDMeta
	 @desc Dieses Flag kann über das Property
	 <code>NoDelPermissionCache</code> gesetzt werden. Ist
	 das Flag gesetzt, wird das Ergebnis von
	 <code>ClientInfo::getDelPermission()</code> Client-seitig
	 nicht gespeichert, sondern immer wieder neu vom Server
	 erfragt.
	 @const {number}
	 */
	NoDelPermissionCache = 131072;
	/**
	 @memberof PDMeta
	 @desc Titel im Infobereich des GUI-Clients (weiße
	 Schrift auf grauem Hintergrund)
	 des Outlook-Look-And-Feels darstellen.
	 @const {number}
	 */
	ShowListTitleInInfoBar = 262144;
	/**
	 @memberof PDMeta
	 @desc Der Klassen-<i>Extent</i> ist manuell sortierbar.
	 Neue Elemente werden am Ende der Liste eingefügt.
	 @const {number}
	 */
	Orderable = 0x80000;
	/**
	 @memberof PDMeta
	 @desc Der Klassen-<i>Extent</i> ist manuell sortierbar.
	 Neue Elemente werden am Anfang der Liste eingefügt.
	 @const {number}
	 */
	OrderableInverse = 0x100000;
	/**
	 @memberof PDMeta
	 @desc Löschen von Objekten dieser Klasse soll im Baum
	 über das Kontextmenü möglich sein.
	 @const {number}
	 */
	TreeDelete = 0x200000;
	/**
	 @memberof PDMeta
	 @desc Erzeugen von Objekten dieser Klasse soll im Baum
	 über das Kontextmen möglich sein.
	 @const {number}
	 */
	TreeNew = 0x400000;
	/**
	 @memberof PDMeta
	 @desc Das Filtern nach "allen Textspalten" im GUI-Client soll
	 möglich sein. Dieses Flag lässt sich durch den Wert des
	 <i>Properties</i> <code>AllTextColumnsSearch</code>
	 beeinflussen.
	 @const {number}
	 */
	AllTextColumnsSearch = 0x800000;
	/**
	 @memberof PDMeta
	 @desc Die Objekte der Klasse werden nicht in der Datenbank
	 gespeichert. Das Feature wird zurzeit nur von JANUS/Embedded
	 unterstützt.
	 @const {number}
	 */
	TransientClass = 0x1000000;
	/**
	 @memberof PDMeta
	 @desc Die Objekte dieser Klasse sollen immer im Speicher gehalten
	 werden.
	 @const {number}
	 */
	Preload = 0x2000000;
	/**
	 @memberof PDMeta
	 @desc Die Objekte dieser Klasse sollen mit Hilfe von
	 <code>PDObject::submit()</code> an einen Web-Server
	 geschickt werden, wenn der Anwender ein Objekt bearbeitet.
	 @const {number}
	 */
	Submit = 0x4000000;
	/**
	 @memberof PDMeta
	 @desc Das <i>Flag</i> ist gesetzt, wenn die Klasse relevant für
	 den Baum sein soll, d.h. wenn im GUI-Client ein Baum-Knoten
	 (Objekt der Klasse <code>PDExtensionItem</code>) für diese
	 Klasse vorhanden ist.
	 @const {number}
	 */
	TreeRelevant = 0x8000000;
	/**
	 @memberof PDMeta
	 @desc Die Daten von Objekten dieser Klasse können asynchron
	 geschrieben werden. Asynchrones Schreiben ist nur möglich,
	 wenn die Anwendung mit einem Datenbank-Cluster betrieben wird.
	 @const {number}
	 */
	WriteAsync = 0x10000000;
	/**
	 @memberof PDMeta
	 @desc Dieses Flag ist gesetzt, wenn im UML-Modell angegeben wurde,
	 dass die Klasse im Stammdaten-Menue erscheinen soll.
	 @const {number}
	 */
	InMainMenu = 0x20000000;
	/**
	 @memberof PDMeta
	 @desc Dieses Flag ist gesetzt, wenn die Klasse für die
	 Benutzungsoberfläche relevant ist, d.h. wenn ein
	 Dialog für die Klasse existiert.
	 @const {number}
	 */
	UIRelevantClass = 0x40000000;
	/**
	 @memberof PDMeta
	 @desc Dieses <i>Flag</i> ist gesetzt um anzuzeigen, dass der
	 <i>Object-Identifier</i> Unicode-Zeichen enthält. Das
	 <i>Flag</i> wird automatisch auf <code>true</code> gesetzt,
	 wenn die UILabel in der Anwendung auf Unicode umgestellt
	 wurden. Über das <i>Property</i>
	 <code>UnicodeObjectIdent</code> kann der
	 <i>Object-Identifier</i> klassenweise auf Unicode umgeschaltet
	 oder auf einen Ein-Byte-Zeichensatz zurückgeschaltet
	 werden.
	 @const {number}
	 */
	UnicodeObjectIdent = 0x80000000;

	//// Widget-Flags, die von getWidget() zurückgegeben werden können.
	/**
	 @memberof PDMeta
	 @desc Unbekanntes Widget, sollte nicht auftreten.
	 @const {number}
	 */
	UnknownWidget = 0;
	/**
	 @memberof PDMeta
	 @desc Eine Gruppe (bei eingebetteten Typen).
	 @const {number}
	 */
	EmbeddedGroup = 25;
	/**
	 @memberof PDMeta
	 @desc Gruppe mit Check-Boxen (Aufzählung)
	 @const {number}
	 */
	CheckBoxGroup = 2;
	/**
	 @memberof PDMeta
	 @desc Gruppe mit Check-Boxen (Aufzählung).
	 @const {number}
	 */
	MultiSelectListBox = 3;
	/**
	 @memberof PDMeta
	 @desc List-Box mit Eingabefeld.
	 @const {number}
	 */
	ComboBox = 4;
	/**
	 @memberof PDMeta
	 @desc Klappliste mit Eingabefeld.
	 @const {number}
	 */
	DropDownComboBox = 5;
	/**
	 @memberof PDMeta
	 @desc Klappliste.
	 @const {number}
	 */
	DropDownListBox = 6;
	/**
	 @memberof PDMeta
	 @desc List-Box mit Einfachauswahl.
	 @const {number}
	 */
	ListBox = 7;
	/**
	 @memberof PDMeta
	 @desc Menüknopf.
	 @const {number}
	 */
	MenuButton = 8;
	/**
	 @memberof PDMeta
	 @desc Gruppe mit Radio-Knöpfen.
	 @const {number}
	 */
	RadioButtonGroup = 9;
	/**
	 @memberof PDMeta
	 @desc Eingabefeld mit Verstellknöpfen.
	 @const {number}
	 */
	SpinField = 10;
	/**
	 @memberof PDMeta
	 @desc Einfache Check-Box.
	 @const {number}
	 */
	CheckBox = 11;
	/**
	 @memberof PDMeta
	 @desc Einzeiliges Textfeld.
	 @const {number}
	 */
	TextField = 12;
	/**
	 @memberof PDMeta
	 @desc Mehrzeiliges Textfeld.
	 @const {number}
	 */
	TextArea = 13;
	/**
	 @memberof PDMeta
	 @desc Mehrzeiliges Textfeld HTML.
	 @const {number}
	 */
	TextAreaHTML = 14;
	/**
	 @memberof PDMeta
	 @desc Mehrzeiliges Textfeld RTF.
	 @const {number}
	 */
	TextAreaRTF = 15;
	/**
	 @memberof PDMeta
	 @desc Benutzerdefiniertes Widget.
	 @const {number}
	 */
	UserControl = 16;
	/**
	 @memberof PDMeta
	 @desc Menüeintrag.
	 @const {number}
	 */
	MenuItem = 17;
	/**
	 @memberof PDMeta
	 @desc Druckknopf.
	 @const {number}
	 */
	Button = 18;
	/**
	 @memberof PDMeta
	 @desc Beziehung mit Objektauswahl über den Fremdschlüssel.
	 @const {number}
	 */
	KeyedObjectReference = 19;
	/**
	 @memberof PDMeta
	 @desc Beziehung als Klappliste.
	 @const {number}
	 */
	DropDownListReference = 20;
	/**
	 @memberof PDMeta
	 @desc Beziehung mit Auswahl.
	 @const {number}
	 */
	ObjectReference = 21;
	/**
	 @memberof PDMeta
	 @desc Liste für Mehrfachauswahl bei Enums.
	 @const {number}
	 */
	DoubleList = 22;
	/**
	 @memberof PDMeta
	 @desc Liste für erweiterbare Mehrfachauswahl.
	 @const {number}
	 */
	ExtDoubleList = 23;
	/**
	 @memberof PDMeta
	 @desc Beziehung in Tabellenform.
	 @const {number}
	 */
	RelationTable = 24;
	/**
	 @memberof PDMeta
	 @desc Nicht relevant für Oberfläche.
	 @const {number}
	 */
	NotUIRelevant = 1;
	/**
	 @memberof PDMeta
	 @desc Eingabefeld für IP-Adressen.
	 @const {number}
	 */
	IPAddress = 26;
	/**
	 @memberof PDMeta
	 @desc Schieberegler.
	 @const {number}
	 */
	Slider = 27;
	/**
	 @memberof PDMeta
	 @desc Darstellung eines Icons.
	 @const {number}
	 */
	IconWidget = 28;
	/**
	 @memberof PDMeta
	 @desc Beziehung als Radio- und Checkbox-Gruppe darstellen.
	 @const {number}
	 */
	RelationGroupWidget = 29;
	/**
	 @memberof PDMeta
	 @desc Operation als Button.
	 @const {number}
	 */
	OpButton = 64;
	/**
	 @memberof PDMeta
	 @desc Operation als Popup.
	 @const {number}
	 */
	OpPopup = 128;
	/**
	 @memberof PDMeta
	 @desc Operation als Knopf in der Toolbar.
	 @const {number}
	 */
	OpToolbar = 256;
	/**
	 @memberof PDMeta
	 @desc Operation als Popup im Baum.
	 @const {number}
	 */
	OpTreePopup = 32;
	/**
	 @memberof PDMeta
	 @desc Operation im Pulldown-Menü des Dialogs.
	 @const {number}
	 */
	OpPulldown = 16;
	/**
	 @memberof PDMeta
	 @desc Operation als Popup in der Extent-Liste.
	 @const {number}
	 */
	OpListPopup = 8;
	/**
	 @memberof PDMeta
	 @desc Operation als Popup in Beziehungslisten.
	 @const {number}
	 */
	OpRelationPopup = 4;
	/**
	 @memberof PDMeta
	 @desc Maske für Zugriff.
	 @const {number}
	 */
	WidgetMask = 0x1ff;
	//
	WidgetFlagsMask = 0x0ffe00;
	// Die restlichen Bits werden von getLeftNeighbour() genutzt
	WidgetNeighbourbits = 20;
	
	//
	// Oeffentliche Properties
	//
	/**
	 @type {number}
	 @readonly
	 @desc Instanz-Kennung des Web Clients innerhalb der
		HTTP-Session.<br/>
		<em>Achtung:</em> Diese entspricht nicht unbedingt der
		[Client-Id]{@link PDMeta#clientId} auf der JANUS-Seite!
	 @see Zur Anzahl Instanzen <em>im selben Browser</em> siehe
		[JafWeb.getInstanceCount()]{@link JafWeb#getInstanceCount}.
	 */
	get instanceID() {
		return this._instanceID;
	}
	
	/**
	 @type {string}
	 @readonly
	 @desc Kennung des JANUS-Clients, falls diese gesetzt ist.
		Vgl. <code>ClientInfo::getClientId()</code> auf der
		Server-Seite.<br/>
		<em>Achtung:</em> Diese entspricht nicht unbedingt der
		[Instanz-Id]{@link PDMeta#instanceID}!
	 */
	get clientId() {
		return this._clientID;
	}

	/// Redefinierbare Funktionen, mit denen sich die PDMeta-Abfragen manipulieren lassen.
	/**
	 @event PDMeta#onGetString
	 @desc Redefinieren Sie diese Funktion, wenn Sie die Abfrage
		 von mehrsprachigen Texten manipulieren wollen.
	 @param {string} code Technische ID des Textes.
	 @param {number} lang Sprachindex.
	 @return {string} Der Text in der angegebenen Sprachausprägung.
		 Wenn das Standardverhalten benutzt werden
		 soll, geben Sie hier <code>null</code> oder nichts zurück.
	 */
	onGetString(code, lang) {
		if((typeof code == 'string') && code.substring(0, 4) == 'SC::')
		{
			var txt = this.getText('sc.' + code.substring(4));
			if(txt)
				return txt;
		}
	}
	/**
	 @event PDMeta#onGetErgname
	 @desc Redefinieren Sie diese Funktion, wenn Sie die Abfrage
		 von ergonomischen Bezeichnern manipulieren wollen.
	 @param {string} clname Name der Klasse.
	 @param {string} elem Element.
	 @param {number} lang Sprachindex.
	 @return {string} Ergonomischer Bezeichner.
		 Wenn das Standardverhalten benutzt werden
		 soll, geben Sie hier <code>null</code> oder nichts zurück.
	 */
	onGetErgname(clname, elem, lang) { }
	/**
	 @event PDMeta#onGetListErgname
	 @desc Redefinieren Sie diese Funktion, wenn Sie die Abfrage
		 von ergonomischen Listenbezeichnern manipulieren wollen.
	 @param {string} clname Name der Klasse.
	 @param {string} elem Element.
	 @param {number} lang Sprachindex.
	 @return {string} Ergonomischer Bezeichner für die Verwendung
		 in Listen.
		 Wenn das Standardverhalten benutzt werden
		 soll, geben Sie hier <code>null</code> oder nichts zurück.
	 */
	onGetListErgname(clname, elem, lang) { }
	/**
	 @event PDMeta#onGetDescription
	 @desc Redefinieren Sie diese Funktion, wenn Sie die Abfrage
		 von Elementbeschreibungen (Tooltipps) manipulieren wollen.
	 @param {string} clname Name der Klasse.
	 @param {string} elem Element.
	 @param {number} lang Sprachindex.
	 @return {string} Der Hinweistext.
		 Wenn das Standardverhalten benutzt werden
		 soll, geben Sie hier <code>null</code> oder nichts zurück.
	 */
	onGetDescription(clname, elem, lang) { }
	/**
	 @event PDMeta#onGetAssocClass
	 @desc Redefinieren Sie diese Funktion, wenn Sie die Abfrage
		 des Namens der Zielklasse einer Beziehung manipulieren wollen.<br/>
		 <h4>Beispiel</h4>
		 <pre class="prettyprint"><code>// Spezielle Klassen, erkennbar am Namenspräfix "MO", sollen
// ebenfalls über PDMeta abfragbar sein. Dazu
// wird ein eigener Request Handler aufgerufen.
PDMeta.onGetAssocClass = function(clname, rel) {
		if(clname.substring(0, 2) != 'MO')
			return null;
		// Request
		let res = '';
		const pars = new JParamPacker("MyPDMeta.getAssocClass");
		pars.add("clName", clname);
		pars.add("relname", rel);
		const successFn = function(req) {
				const resp = new JResponse(req);
				if(!resp.hasFatalError()) {
					if(resp.hasError())
						console.warn('PDMeta.onGetAssocClass failed!');
					else
						res = resp.getString('clName');
				}
			};
		// synchroner Request!
		JafWeb.ajaxRequest({
				url: getWorkspace().getUrlRoot(),
				method: "GET",
				async: false,
				params: pars.get(),
				disableCaching: true,
				scope: this,
				success: successFn,
				failure: function() { }
			});
		return res;
	};
</code></pre>
	 @param {string} clname Name der Klasse.
	 @param {string} rel Name des Beziehung.
	 @return {string} Name der Klasse, die der angegebenen
		 gegenüberliegt. Wenn das Standardverhalten benutzt werden
		 soll, geben Sie hier <code>null</code> oder nichts zurück.
	 */
	onGetAssocClass(clname, rel) { }
	/**
	 @event PDMeta#onGetInverseRelation
	 @desc Redefinieren Sie diese Funktion, wenn Sie die Abfrage
		 des inversen Namens einer Beziehung manipulieren wollen.
		 Vgl. das Beispiel zu [onGetAssocClass()]{@link PDMeta#onGetAssocClass}.
	 @param {string} clname Name der Klasse.
	 @param {string} rel Name des Beziehung.
	 @return {string} Name der Klasse, die der angegebenen
		 gegenüberliegt. Wenn das Standardverhalten benutzt werden
		 soll, geben Sie hier <code>null</code> oder nichts zurück.
	 */
	onGetInverseRelation(clname, rel) { }
	/**
	@event PDMeta#onGetTypeId
	@desc Redefinieren Sie diese Funktion, wenn Sie die Datentypermittlung
		manipulieren wollen.<br/>
		<h4>Beispiel</h4>
		<pre class="prettyprint"><code>PDMeta.onGetTypeId = function(clname, elem) {
		if(clname == 'MOEvent') {
			if(elem == 'Type' || elem == 'Level')
				return PDMeta.TIdEnum & PDMeta.TIdMask;
		}
		return 0;
	};</code></pre>
	@param {string} clname Name der Klasse.
	@param {string} elem Name des Elements.
	@return {number} Wenn es sich um einen benutzerdefinierten 
		Datentyp handelt, wird ein Wert &lt; 0 zurückgegeben,
		dessen Absolutwert die Id des Typen darstellt.
		Bei den anderen Datentypen wird eine Kombination
		aus Datentyp-Konstante und Flags. Vgl. dazu die
		entsprechenden Konstanten <code>TId...</code> 
		bzw. <code>TFlag...</code> in <code>PDMeta</code>.
		Um mit der Standardbehandlung fortzufahren, geben Sie
		<code>0</code> zurück.
	@see [getTypeId()]{@link PDMeta#getTypeId}, [getType()]{@link PDMeta#getType}
		zum Ermitteln der Namen von Aufzählungstypen.
	 */
	onGetTypeId(clname, elem) {
		return 0;
	}

	/**
	 @function PDMeta#onGetType
	 @desc Redefinieren Sie diese Funktion, wenn Sie die Ermittlung von
	 Datentypnamen aus den Metainformationen manipulieren möchten.<br/>
		<h4>Beispiel</h4>
		<pre class="prettyprint"><code>PDMeta.onGetType = function(clname, elem) {
		if(clname == 'MOEvent') {
			if(elem == 'Type')
				return 'MOEventTypeET';
			if(elem == 'Level')
				return 'MOEventLevelET';
		}
	};</code></pre>
	 @param {mixed} clname Name oder numerische Id der Fachkonzeptklasse.
	 @param {string} elem Name des Klassenelements, dessen Typ ermittelt
	 werden soll.
	 @return {string} Der Typbezeichner. Geben Sie einen Leerstring oder
	 nichts zurück, wenn mit der Standardbehandlung fortgefahren werden
	 soll.
	 @see [getType()]{@link PDMeta#getType}, [getTypeId()]{@link PDMeta#getTypeId}
	 */
	onGetType(clname, elem) { }

	/**
	 @function PDMeta#onIsAbstract
	 @desc Wird bei der Abfrage von [PDMeta.isAbstract()]{@link PDMeta#isAbstract}
	 aufgerufen. Sie können diese Funktion reimplementieren,
	 um das Standardverhalten zu übersteuern. Alle Rückgaben
	 mit booleschem Typ bewirken, dass das Standardverhalten
	 unterdrückt wird.
	 @param {string} clname Name der Fachkonzeptklasse.
	 */
	onIsAbstract(clname) { }
	
	/**
	 @event PDMeta#onGetAllSubClasses
	 @desc Wird beim Aufruf von [PDMeta.getSubClasses()]{@link PDMeta#getSubClasses}
	 ausgelöst.
	 @param {string} clname Name der Fachkonzeptklasse.
	 @return {string[]} Die Namen der Unterklassen. Geben Sie
	 keinen oder einen nach <code>false</code> auflösbaren
	 Wert zurück, um das Standardverhalten beizubehalten, oder
	 ein Array mit den Namen der Unterklassen.
	 */
	onGetAllSubClasses(clname) { }
	// TODO: komplettieren
	
	/*
	 @ignore
	 @function PDMeta#loadDyn
	 @desc Laedt PDMeta-Infos nach, wenn sie noch nicht vorliegen.
	 @param {String} what Name des PDMeta-Properties, das
		 nachgeladen werden soll.
	 @param {number} [idx] Optionaler Index, falls nur ein Element
		eines Arrays - z.B. _errorMessages - abgefragt werden soll.
	 */
	loadDyn(what, idx) {
		//console.log("### PDMeta.loadDyn('" + what + "')");
		const pdClass = this.PDClass;
		const pars = new JParamPacker(JafWebAPI.PDMeta.loadDyn.eventName, pdClass);
		pars.add(JafWebAPI.PDMeta.loadDyn.PAR_what, what);
		if(idx !== undefined)
			pars.add(JafWebAPI.PDMeta.loadDyn.PAR_idx, idx);
		const that = this;
		const successFn = function(req, options) {
				const resp = new JResponse(req, options, pdClass);
				if(resp.hasFatalError())
					throw "Request got empty or invalid response!";
				if(resp.hasError())
					throw resp.getErrorMessage();
				if(idx !== undefined)
					that[what][idx] = resp.getData()[what][idx];
				else
					that[what] = resp.getData()[what];
			};
		const failureFn = function(response, opts) {
			};
		JafWeb.ajaxRequest({
				url: pdClass.getURL(),
				authToken: pdClass.getAuthToken(),
				method: "GET",
				async: false,
				params: pars.getPostParams(),
				disableCaching: false,
				scope: this,
				callerName: pars.getEventName(),
				success: successFn,
				failure: failureFn
			});
		////console.log("### PDMeta.loadDyn('" + what + "') - geladen.");
	}
	
	// fuer UIApplication.hadPDMeta()!
	loaded() {
		return this._loadCompleted;
	}

	/**
	 @function PDMeta#getModel
	 @desc Gibt den technischen Modellnamen zurück.
	 @return {string} Der Name.
	 */
	getModel() {
		return this._model;
	}
	
	/**
	 @function PDMeta#getWidget
	 @desc Gibt Informationen über das spezifizierte 
		 GUI-Oberflächenelement zurück.
	 @param {string} clname Name der Klasse.
	 @param {string} elem Name des Elements.
	 @return {number} Eine Kombination aus Widget-Flags,
		 deren Konstanten in {@link PDMeta} definiert sind.
	 */
	getWidget(clname, elem) {
		if(!this["_"+clname+"_w"])
			this.loadDyn("_"+clname+"_w");
		if(this["_"+clname+"_w"] && this["_"+clname+"_w"][elem])
			return this["_"+clname+"_w"][elem];
		// Basisklasse?
		var baseCls = this.getSuperClass(clname);
		if(baseCls)
			return this.getWidget(baseCls, elem);
		return 0;
	}
	
	/**
	 @function PDMeta#getClassFlags
	 @desc Gibt Meta-Informationen über eine Fachkonzeptklasse
	 	zurück.
	 @param {string} clname Name der Klasse.
	 @return {number} Kombination aus einem oder mehreren Flags.
	 	Die Werte der Flags sind als Konstanten in {@link PDMeta}
	 	definiert.
	 */
	getClassFlags(clname) {
		if(!this["_classes_f"])
			this.loadDyn("_classes_f");
		if(!clname || !this._classes_f || !this._classes_f[clname])
			return 0;
		return this._classes_f[clname];
	}

	/**
	 @function PDMeta#isString
	 @desc Stellt für ein Attribut fest, ob es als Zeichenkette 
	 	gespeichert wird.
	 @param {string} clname Name der Klasse.
	 @param {string} attr Name des Elements.
	 @return {boolean} Es wird <code>true</code> zurückgegeben, 
	 	wenn es sich um ein als String gespeichertes Attribut 
	 	handelt.
	 */
	isString(clname, attr) {
		// Traversierungspfade aufloesen
		if(/->/.test(attr)==true)
		{
			var trav = attr.split("->");
			var trgtCl = this.getAssocClass(clname, trav.shift());
			return this.isString(trgtCl, trav.join('->'));
		}
		var t = this.getTypeId(clname, attr) & this.TIdMask;
		return (t == this.TIdString || t == this.TIdSerial || t == this.TIdEmail ||
			t == this.TIdURL || t == this.TIdFilename);
	}

	/**
	 @function PDMeta#isClassGlobal
	 @desc Stellt fest, ob es sich bei dem angegebenen Element
		 um ein Klassenattribut bzw. eine 
		 Klassenoperation handelt.
	 @param {string} clname Name der Klasse.
	 @param {string} elem Name des Elements.
	 @return {boolean} <code>true</code>, wenn es sich um
		 eine Klassenoperation resp. ein Klassenattribut
		 handelt, sonst <code>false</code>.
	 */
	isClassGlobal(clname, elem) {
		// Traversierungspfade aufloesen
		if(/->/.test(elem)==true)
		{
			var trav = elem.split("->");
			var trgtCl = this.getAssocClass(clname, trav.shift());
			return this.isClassGlobal(trgtCl, trav.join('->'));
		}
		return (this.getTypeId(clname, elem) & this.TFlagClassGlobal) != 0;
	}

	/**
	 @function PDMeta#isDerived
	 @desc Stellt fest, ob es sich um ein abgeleitetes
		 Attribut handelt.
	 @param {string} clname Name der Klasse.
	 @param {string} attr Name des Elements.
	 @return {boolean} <code>true</code>, wenn es sich um
		 ein abgeleitetes Attribut handelt, sonst <code>false</code>.
	 */
	isDerived(clname, attr) {
		// Traversierungspfade aufloesen
		if(/->/.test(attr)==true)
		{
			var trav = attr.split("->");
			var trgtCl = this.getAssocClass(clname, trav.shift());
			return this.isDerived(trgtCl, trav.join('->'));
		}
		if(!this["_"+clname+"_derAttrs"])
			this.loadDyn("_"+clname+"_derAttrs");
		for(var i=0; i < this["_"+clname+"_derAttrs"].length; i++)
		{
			if(this["_"+clname+"_derAttrs"][i] == attr)
				return true;
		}
		// Basisklasse?
		var baseCls = this.getSuperClass(clname);
		if(baseCls)
			return this.isDerived(baseCls, attr);
		return false;
	}

	/**
	 @function PDMeta#isKey
	 @desc Stellt fest, ob es sich bei dem angegebenen 
		 Attribut um einen fachlichen Schlüssel handelt.
	 @param {string} clname Name der Klasse.
	 @param {string} attr Name des Elements.
	 @return {boolean} <code>true</code>, wenn es sich um
		 ein Schlüsselattribut handelt, sonst <code>false</code>.
	 */
	isKey(clname, attr) {
		// Traversierungspfade aufloesen
		if(/->/.test(attr)==true)
		{
			var trav = attr.split("->");
			var trgtCl = this.getAssocClass(clname, trav.shift());
			return this.isKey(trgtCl, trav.join('->'));
		}
		return (this.getTypeId(clname, attr) &
				this.TFlagKey) != 0;
	}

	/**
	 @function PDMeta#isMandatory
	 @desc Stellt fest, ob das Element zwingend eine
		 Eingabe erfordert.
	 @param {string} clname Name der Klasse.
	 @param {string} elem Name des Elements.
	 @return {boolean} <code>true</code>, wenn es sich um
		 ein Mussattribut handelt, sonst <code>false</code>.
	 */
	isMandatory(clname, elem) {
		// Traversierungspfade aufloesen
		if(/->/.test(elem)==true)
		{
			var trav = elem.split("->");
			var trgtCl = this.getAssocClass(clname, trav.shift());
			return this.isMandatory(trgtCl, trav.join('->'));
		}
		return (this.getTypeId(clname, elem) &
				this.TFlagMandatory) != 0;
	}

	/**
	 @function PDMeta#isReadOnly
	 @desc Stellt fest, ob es sich bei dem angegebenen 
	 	Attribut um ein nur lesbares handelt.
	 @param {string} clname Name der Klasse.
	 @param {string} elem Name des Elements.
	 @return {boolean} <code>true</code>, wenn es sich um
	 	ein nur lesbares Attribut handelt, sonst <code>false</code>.
	 */
	isReadOnly(clname, elem) {
		// Traversierungspfade aufloesen
		if(/->/.test(elem)==true)
		{
			var trav = elem.split("->");
			var trgtCl = this.getAssocClass(clname, trav.shift());
			return this.isReadOnly(trgtCl, trav.join('->'));
		}
		return (this.getTypeId(clname, elem) &
				 this.TFlagReadOnly) != 0; // und TFlagGUIReadOnly?
	}

	/**
	 @function PDMeta#isChangeable
	 @desc Stellt fest, ob das Element nach seiner Ersterfassung
		 änderbar ist.
	 @param {string} clname Name der Klasse.
	 @param {string} elem Name des Elements.
	 @return {boolean} <code>true</code>, wenn es sich um
		 ein änderbares Attribut handelt, sonst <code>false</code>.
	 */
	isChangeable(clname, elem) {
		// Traversierungspfade aufloesen
		if(/->/.test(elem)==true)
		{
			var trav = elem.split("->");
			var trgtCl = this.getAssocClass(clname, trav.shift());
			return this.isChangeable(trgtCl, trav.join('->'));
		}
		return (this.getTypeId(clname, elem) & 
				this.TFlagNonMutable) == 0;
	}

	/**
	 @function PDMeta#isUIRelevant
	 @desc Stellt fest, ob das angegebene Element als relevant 
		 für die Benutzungsoberfläche modelliert ist.
	 @param {string} clname Name der Klasse.
	 @param {string} elem Name des Elements.
	 @return {boolean} <code>true</code>, wenn es sich um
		 ein UI-relevantes Element handelt, sonst <code>false</code>.
	 */
	isUIRelevant(clname, elem) {
		// Traversierungspfade aufloesen
		if(/->/.test(elem)==true)
		{
			var trav = elem.split("->");
			var trgtCl = this.getAssocClass(clname, trav.shift());
			return this.isUIRelevant(trgtCl, trav.join('->'));
		}
		if((this.getClassFlags(clname) & this.UIRelevantClass) == 0)
			return false;
		return (this.getWidget(clname, elem) & this.NotUIRelevant) == 0;
	}

	/**
	 @function PDMeta#isPDRelevant
	 @desc Stellt fest, ob das angegebene Element als relevant 
		 für das Fachkonzept modelliert ist, d.h. persistent
		 gespeichert wird.
	 @param {string} clname Name der Klasse.
	 @param {string} elem Name des Elements.
	 @return {boolean} <code>true</code>, wenn es sich um
		 ein Fachkonzept-relevantes Element handelt, sonst <code>false</code>.
	 */
	isPDRelevant(clname, elem) {
		// Traversierungspfade aufloesen
		if(/->/.test(elem)==true)
		{
			var trav = elem.split("->");
			var trgtCl = this.getAssocClass(clname, trav.shift());
			return this.isPDRelevant(trgtCl, trav.join('->'));
		}
		if((this.getClassFlags(clname) & this.TransientClass) != 0)
			return false;
		return (this.getTypeId(clname, elem) &
				this.TFlagNotPDRelevant) == 0;
	}

	/**
	 @function PDMeta#isAttribute
	 @desc Stellt fest, ob es sich bei dem angegebenen Element
		 um ein Attribut handelt.
	 @param {string} clname Name der Klasse.
	 @param {string} elem Name des Elements.
	 @return {boolean} <code>true</code>, wenn es sich um
		 ein Attribut handelt, sonst <code>false</code>.
	 */
	isAttribute(clname, elem) {
		if(!clname || clname=="")
			return false;
		// Traversierungspfade aufloesen
		if(/->/.test(elem)==true)
		{
			var trav = elem.split("->");
			var trgtCl = this.getAssocClass(clname, trav.shift());
			return this.isAttribute(trgtCl, trav.join('->'));
		}
		if(!this["_"+clname+"_attrs"])
			this.loadDyn("_"+clname+"_attrs");
		for(var i=0; i < this["_"+clname+"_attrs"].length; i++)
		{
			if(this["_"+clname+"_attrs"][i] == elem)
				return true;
		}
		// Basisklasse?
		var baseCls = this.getSuperClass(clname);
		if(baseCls)
			return this.isAttribute(baseCls, elem);
		return false;
	}
	
	/**
	 @function PDMeta#isRelation
	 @desc Stellt fest, ob es sich bei dem angegebenen Element
		 um eine Beziehung handelt.
	 @param {string} clname Name der Klasse.
	 @param {string} elem Name des Elements.
	 @return {boolean} <code>true</code>, wenn es sich um
		 eine Beziehung handelt, sonst <code>false</code>.
	 */
	isRelation(clname, elem) {
		// Traversierungspfade aufloesen
		if(/->/.test(elem)==true)
		{
			var trav = elem.split("->");
			var trgtCl = this.getAssocClass(clname, trav.shift());
			return this.isRelation(trgtCl, trav.join('->'));
		}
		if(!clname || clname=="")
			return false;
		if(!this["_"+clname+"_rels"])
			this.loadDyn("_"+clname+"_rels");
		for(var i=0; i<this["_"+clname+"_rels"].length; i++)
		{
			if(this["_"+clname+"_rels"][i] == elem)
				return true;
		}
		// Basisklasse?
		var baseCls = this.getSuperClass(clname);
		if(baseCls)
			return this.isRelation(baseCls, elem);
		return false;
	}
	
	/**
	 @function PDMeta#isOperation
	 @desc Stellt fest, ob es sich bei dem angegebenen Element
		 um eine Operation handelt.
	 @param {string} clname Name der Klasse.
	 @param {string} elem Name des Elements.
	 @return {boolean} <code>true</code>, wenn es sich um
		 eine Operation handelt, sonst <code>false</code>.
	 */
	isOperation(clname, elem) {
		if(!clname || clname=="")
			return false;
		if(!this["_"+clname+"_ops"])
			this.loadDyn("_"+clname+"_ops");
		for(var i=0; i<this["_"+clname+"_ops"].length; i++)
		{
			if(this["_"+clname+"_ops"][i] == elem)
				return true;
		}
		// Basisklasse?
		var baseCls = this.getSuperClass(clname);
		if(baseCls)
			return this.isOperation(baseCls, elem);
		return false;
	}
	
	/**
	 @function PDMeta#getSort
	 @desc Fragt die Standardsortierung für eine Klasse bzw.
		 für eine Beziehung ab.
	 @param {string} clname Name der Klasse.
	 @param {string} [rel] Beziehungsname, falls für eine Beziehung
		 abgefragt wird. Sonst wird die Sortierung für den Klassen-Extent
		 ermittelt.
	 @return {string} Der JANUS-Sortierausdruck gemäß UML-Modell.
		 Falls keiner definiert ist, wird ein Leerstring zurückgegeben.
	 */
	getSort(clname, rel) {
		if(!clname || clname=="")
			return '';
		if(!this["_"+clname+"_sort"])
			this.loadDyn("_"+clname+"_sort");
		var clsSort = this["_"+clname+"_sort"];
		if(!clsSort) return '';
		if(rel && clsSort[rel])
			return clsSort[rel];
		else if(clsSort["_classes_sort_extent"])
			return clsSort["_classes_sort_extent"];
		// Basisklasse?
		var baseCls = this.getSuperClass(clname);
		if(baseCls)
			return this.getSort(baseCls, rel);
		return '';
	}

	/**
	 @function PDMeta#getFilter
	 @desc Fragt die Standards-Filter und Srtierung für eine
		 Klasse bzw. für eine Beziehung ab.
	 @param {string} clname Name der Klasse.
	 @param {string} [rel] Beziehungsname, falls für eine Beziehung
		 abgefragt wird. Sonst wird die Sortierung für den Klassen-Extent
		 ermittelt.
	 @param {Object} holder In dieses Objekt werden die Properties
		 <code>filter</code> und <code>sort</code> geschrieben.
	 @note Die Vorfilterung von Extents und Beziehungen hat im
		 Web Client nichts zu suchen (deratiges sollte bereits auf der
		 Server-Seite stattfinden). Aus Gründen der Kompatibilität ist
		 diese Funktion hier trotzdem vorhanden.
		 Zur Abfrage nur der Sortierung
		 siehe [getSort()]{@link PDMeta#getSort}.
	 */
	getFilter(clname, rel, holder) {
		holder.filter = '';
		holder.sort = this.getSort(clname, rel);
	}
	
	/**
	 @function PDMeta#getErgname
	 @desc Den ergonomischen Namen der Anwendung, einer Klasse
		 oder eines Klassenelements zurückgeben.<br/>
		 Wenn <code>elem</code> nicht angegeben
		 ist, wird der ergonomische Name der Klasse, wenn auch 
		 dieser nicht angegeben ist, derjenige der
		 Anwendung zurückgegeben.
	 @param {string} [clname] Name der Klasse.
	 @param {string} [elem] Name des Elements (Attribut,
		 Beziehung oder Operation).
	 @param {number} [lang] Nummer der Anwendungssprache.
		 Bei fehlendem <code>lang</code> wird für die aktuelle
		 Sprache abgefragt.
	 @return {string} Der ergonomische Name.
	 */
	getErgname(clname, elem, lang) {
		var cl = '';
		var el = '';
		var lng = this._actLang;
		for(var p = 0; p < arguments.length; p++)
		{
			/* kollidiert mit Sprachparameter, wenn Par. 1 u. 2 fehlen!
			if(p == 0 && typeof arguments[p] == 'number')
			{
				cl = this.getClass(arguments[p]);
				continue;
			}*/
			if(typeof arguments[p] == 'number')
			{
				lng = arguments[p];
				break;
			}
			if(typeof arguments[p] != 'string')
				break;
			if(p == 0)
				cl = arguments[p];
			else if(p == 1)
				el = arguments[p];
		}
		var tmp = this.onGetErgname(cl, el, lng);
		if(tmp)
			return tmp;
		if(this._ergnamesFromGetText && !!clname)
		{
			// Traversierungspfade aufloesen
			if(el && /->/.test(el)==true)
			{
				var trav = el.split("->");
				var res = [];
				var trgtCl = cl;
				for(var i = 0; i < trav.length; i++)
				{
					res.push(this.getErgname(trgtCl, trav[i]) || trav[i]);
					if(i + 1 < trav.length)
						trgtCl = this.getAssocClass(trgtCl, trav[i]);
				}
				return res.join('-');
			}
			var cls = cl;
			var tmpLbl = '';
			var key = '';
			// Sonderfall implizite Attribute (ohne Klassennamen):
			if(el == "_created" || el == "_created_by" ||
					el == "_last_modified" || el == "_last_modified_by" ||
					el == "principal")
				tmpLbl = this.getText(el);
			while(cls && !tmpLbl)
			{
				key = this._ergnamePrefix + cls;
				if(el)
					key += '.' + el;
				tmpLbl = this.getText(key);
				if(!tmpLbl)
					cls = this.getSuperClass(cls);
			}
			if(!tmpLbl)
			{
				// bei Beziehungen soll der ergon. Name
				// der Zielklasse versucht werden
				var trgtCls = this.getAssocClass(cl, el);
				if(trgtCls)
				{
					// bei Zu-N das ListLabel bevorzugen
					if(this.getMaxCard(cl, el) != 1)
						tmpLbl = this.getListErgname(trgtCls);
					else
						tmpLbl = this.getErgname(trgtCls);
				}
			}
			return (tmpLbl || el || cl);
			//return (tmpLbl || '[' + key + ']');
		}
		if(this.hasMultilang2Support() && this._erg)
		{
			if(cl && cl.substring(0, 6) == '@FORM:')
				cl = cl.substring(6);
			if(this._erg[(cl || '')])
			{
				if(!cl)
					return this._erg['']['e'];
				if(!el)
					return this._erg[cl]['']['e'];
				if(this._erg[cl][el])
					return (this._erg[cl][el]['e'] || '');
			}
			return ''; //"[Ergname for " + cl + "::" + elem + " not found with multilang2 support]";
		}
		if(!cl)
			return (this._eModel && this._eModel.length > lng ? this._eModel[lng] : ''); // Anwendungsname
		if(this._classes_s && !el)
		{
			if(!this._classes_s[cl])
				return cl;
			return this._classes_s[cl][lng]; // Klassenname
		}
		// Traversierungspfade aufloesen
		if(el && /->/.test(el)==true)
		{
			var trav = el.split("->");
			var trgtCl = this.getAssocClass(cl, trav.shift());
			return this.getErgname(trgtCl, trav.join('->'));
		}
		if(this["_"+cl+"_attrs_s"] && this["_"+cl+"_attrs_s"][el])
			return this["_"+cl+"_attrs_s"][el][lng];
		// Basisklasse?
		var baseLbl = '';
		var baseCls = this.getSuperClass(clname);
		while(baseCls && !baseLbl)
		{
			baseLbl = this.getErgname(baseCls, elem, lang);
			baseCls = this.getSuperClass(baseCls);
		}
		if(baseLbl)
			return baseLbl;
		// bei Beziehungen geht auch das Label der Zielklasse
		var assocCl = this.getAssocClass(cl, el);
		if(assocCl)
		{
			// bei Zu-N das ListLabel bevorzugen
			if(this.getMaxCard(cl, el) != 1)
				return this.getListErgname(assocCl, '', lang);
			return this.getErgname(assocCl, '', lang);
		}
		return '';
	}
	
	/**
	 @function PDMeta#getText
	 @desc Gibt einen über [getString()]{@link PDMeta#getString} ermittelten
		 Text nach Ersetzung der angegebenen Platzhalter zurück.<br/>
		 <h4>Beispiel:</h4>
		 <pre class="prettyprint"><code>PDMeta.getText('SC::NewerVersion', '%c', 'über %s', '%s', 4)</code></pre>
		 gibt aus:
		 <pre class="prettyprint"><code>Dieser Client arbeitet mit Version über 4, während der Server die ältere Version 4 verwendet. Ein automatischer "Downgrade" des Clients ist leider nicht möglich. Bitte wenden Sie sich an den Administrator des Servers, damit dieser aktualisiert wird.</code></pre>
		 Die angegebenen Paare von Ersetzungsparametern werden von links
		 nach rechts bearbeitet, d.h. es wird nicht allgemein rekursiv
		 vorgegangen, sondern nur Platzhalter ersetzt, die bereits in den
		 vorangegangenen Ersetzungen vorhanden waren resp. eingefügt
		 wurden. Beispielsweise kann man
		 <code>PDMeta.getText('beschluss_dsfolgen', '%b', 'eingesetztes %b', '%b', 'B')</code>
		 angeben: das erzeugt keine Endlosschleife, sondern ersetzt im
		 gefundenen Text erst alle "%b" durch "eingesetztes %b" und danach
		 alle "%b" durch "B", sodass das Ergebnis lauten würde:
		 "Schutzmaßnahme eingesetztes B".<br/>
		 Wenn der Ersetzungstext seinerseits eine Text-Id mit vorangestelltem
		 Hash-Zeichen ("#") enthält, wird diese durch erneuten Aufruf von
		 <code>getText()</code> aufgelöst. Die Id wird gelesen bis zur Wortgrenze
		 bzw. bis zum ersten Zeichen, das weder Buchstabe noch Ziffer noch Punkt
		 ist. Diese Auflösung arbeitet <em>nicht</em> rekursiv. Ein Hash-Zeichen
		 kann durch Verdoppelung literal eingefügt werden.
	 @param {string} id Eindeutiger Schlüssel, unter dem der Text - in der
		 aktuellen Anwendungssprache - nachgeschlagen wird.
	 @param {string} [tag1] Erster Platzhalter.
	 @param {string} [val1] Ersetzungstext für den ersten Platzhalter.
		 Es können beliebieg viele weitere Parameterpaare für die Ersetzung
		 angegeben werden. Platzhalter in früheren Ersetzungswerten werden in
		 späteren Parametern ersetzt.
	 @return {string} Der Text. Oder ein Leerstring, wenn dieser nicht gefunden
		wurde.
	 @test PDMetaTest.testGetText
	 @test PDMetaTest.testGetText2
	 */
	getText(id, tag1, val1) {
		////console.log("### PDMeta.getText('" + id + "')");
		let txt = '';
		let tmp = '';
		if(typeof this._extLang == 'object') {
			//
			// Localize schneidet die Ids nach 50 Zeichen ab.
			// Statt den Fehler zu beheben, bauen wir in unsere
			// Schnittstellen diese Kruecke ein (#33885):
			//
			if (id.length > 50) {
				tmp = this.getText(id.substring(0, 50), tag1, val1);
				if(tmp)
					return tmp;
			}
			if(typeof this._extLang[id] == 'string') {
				txt = (this._extLang[id] || "").
					replace(/\\"/g, '"').
					replace(/\\'/g, "'").
					replace(/\\r/g, "\r").
					replace(/\\n/g, "\n"). // zu dieser Demaskierung vgl. redmine #17456
					replace(/\\u0020/g, " "); // und zu dieser #31178
			}
		}
		else {
			/*if(this._ergnamesFromGetText)
			{
				if(id.substring(0, 4) == 'erg.')
				{
					var tmp = id.substring(4);
					if(!tmp)
						txt = this.getErgname();
					else
					{
						var sep = tmp.indexOf(':');
						if(sep)
							txt = this.getErgname(tmp.substring(0, sep), tmp.substring(sep + 1));
						else
							txt = this.getErgname(tmp);
					}
				}
				else if(id.substring(0, 5) == 'lerg.')
				{
					var tmp = id.substring(5);
					var sep = tmp.indexOf(':');
					if(sep)
						txt = this.getListErgname(tmp.substring(0, sep), tmp.substring(sep + 1));
					else
						txt = this.getListErgname(tmp);
				}
				else if(id.substring(0, 4) == 'tip.')
				{
					var tmp = id.substring(4);
					var sep = tmp.indexOf(':');
					if(sep)
						txt = this.getDescription(tmp.substring(0, sep), tmp.substring(sep + 1));
					else
						txt = this.getDescription(tmp);
				}
				if(!txt)
				{
					console.warn("getText() - Text '" + id + "' nicht gefunden!");
					txt = "[" + id + "]";
					//txt = this.getString(id);
				}
				return txt;
			}*/
		}
		// Referenzen auf andere Textkonstanten (s. #52858)
		const regex = /#(#|[-\.\w]+)\b/;
		let pos = 0;
		let mtch = txt.match(regex);
		while(mtch && mtch.index >= 0) {
			tmp = (mtch[1] == '#' ? '#' : this.getText(mtch[1]));
			txt = txt.substring(0, pos + mtch.index) + tmp +
					txt.substring(pos + mtch.index + mtch[0].length);
			pos += mtch.index + tmp.length;
			mtch = txt.substring(pos).match(regex);
		}
		// die als Parameter uebergebenen Ersetzungen
		pos = 1;
		while(pos + 1 < arguments.length) {
			mtch = '' + arguments[pos];
			var repl = '' + arguments[pos + 1];
			var i = txt.indexOf(mtch);
			while(i >= 0) {
				txt = txt.substring(0, i) + repl + txt.substring(i + mtch.length);
				i = txt.indexOf(mtch, i + repl.length);
			}
			pos += 2;
		}
		if(this._showGetTextId)
			return "[" + id + "] " + txt;
		return txt;
	}

	/**
	 @function PDMeta#getListErgname
	 @desc Den ergonomischen Namen für eine Klasse
		 oder ein Klassenelement zurückgeben, der
		 in der Listendarstellung benutzt wird.<br/>
		 Wenn <code>elem</code> nicht angegeben
		 ist, wird der ergonomische Name der Klasse, wenn auch 
		 dieser nicht angegeben ist, derjenige der
		 Anwendung zurückgegeben.
	 @param {string} [clname] Name der Klasse.
	 @param {string} [elem] Name des Elements (Attribut,
		 Beziehung oder Operation).
	 @param {number} [lang] Nummer der Anwendungssprache.
	 @return {string} Bei fehlendem Parameter <code>lang</code> 
		 wird für die aktuelle Sprache abgefragt.
	 */
	getListErgname(clname, elem, lang) {
		var cl = '';
		var el = '';
		var lng = this._actLang;
		for(var p = 0; p < arguments.length; p++)
		{
			if(typeof arguments[p] == 'number')
			{
				lng = arguments[p];
				break;
			}
			if(typeof arguments[p] != 'string')
				break;
			if(p == 0)
				cl = arguments[p];
			else if(p == 1)
				el = arguments[p];
		}
		var tmp = this.onGetListErgname(cl, el, lng);
		if(tmp)
			return tmp;
		if(this._ergnamesFromGetText)
		{
			// Traversierungspfade aufloesen
			if(/->/.test(el)==true)
			{
				var trav = el.split("->");
				var res = [];
				var trgtCl = cl;
				for(var i = 0; i < trav.length; i++)
				{
					res.push(this.getListErgname(trgtCl, trav[i]) || trav[i]);
					if(i + 1 < trav.length)
						trgtCl = this.getAssocClass(trgtCl, trav[i]);
				}
				return res.join('-');
			}
			var cls = cl;
			var tmpLbl = '';
			var key = '';
			while(cls && !tmpLbl)
			{
				key = this._listErgnamePrefix + cls;
				if(el)
					key += '.' + el;
				tmpLbl = this.getText(key);
				if(!tmpLbl)
					cls = this.getSuperClass(cls);
			}
			// falls leer, auf Ergname zurueckfallen
			if(!tmpLbl)
				tmpLbl = this.getErgname(clname, elem, lang);
			if(!tmpLbl)
			{
				// bei Beziehungen soll der ergon. Name
				// der Zielklasse versucht werden
				var trgtCls = this.getAssocClass(cl, el);
				if(trgtCls)
					tmpLbl = this.getListErgname(trgtCls);
			}
			return (tmpLbl || el || cl);
			//return (tmpLbl || '[' + key + ']');
		}
		if(this.hasMultilang2Support())
		{
			if(clname && clname.substring(0, 6) == '@LIST:')
				clname = clname.subtring(6);
			if(this._erg[(clname || '')])
			{
				if(!elem)
					return (this._erg[(clname || '')]['l'] || this._erg[(clname || '')]['e']);
				if(this._erg[(clname || '')][(elem || '')])
					return (this._erg[(clname || '')][(elem || '')]['l'] || this._erg[(clname || '')][(elem || '')]['e'] || '');
			}
			return ''; //"[ListErgname '"+code+"' not found with multilang2 support]";
		}
		if(!cl)
			return "";
		if(this._classes_l && !el)
		{
			if(!this._classes_l[cl])
				return '';
			return this._classes_l[cl][lng]; // Klassenname
		}
		// Traversierungspfade aufloesen
		if(/->/.test(el)==true)
		{
			var trav = el.split("->");
			var trgtCl = this.getAssocClass(cl, trav.shift());
			return this.getListErgname(trgtCl, trav.join('->'));
		}
		if(this["_"+cl+"_attrs_l"] && this["_"+cl+"_attrs_l"][el])
			return this["_"+cl+"_attrs_l"][el][lng];
		// bei Beziehungen geht auch das Label der Zielklasse
		var assocCl = this.getAssocClass(cl, el);
		if(assocCl)
		{
			// bei Zu-N das ListLabel bevorzugen
			if(this.getMaxCard(cl, el) != 1)
				return this.getListErgname(assocCl, '', lang);
			return this.getErgname(assocCl, '', lang);
		}
		// Basisklasse?
		var baseCls = this.getSuperClass(clname);
		if(baseCls)
			return this.getListErgname(baseCls, elem, lang);
		return '';
	}

	/**
	 @function PDMeta#getDescription
	 @desc Die Beschreibung für eine Klasse
		 oder ein Klassenelement zurückgeben, die
		 normalerweise als Tooltip verwendet wird.<br/>
		 Wenn <code>elem</code> nicht angegeben
		 ist, wird der ergonomische Name der Klasse zurückgegeben.
	 @param {string} [clname] Name der Klasse.
	 @param {string} [elem] Name des Elements (Attribut,
		 Beziehung oder Operation).
	 @param {number} [lang] Nummer der Anwendungssprache.
	 @return {string} Bei fehlendem Parameter <code>lang</code> 
		 wird für die aktuelle Sprache abgefragt.
	 */
	getDescription(clname, elem, lang) {
		var cl = '';
		var el = '';
		var lng = this._actLang;
		for(var p = 0; p < arguments.length; p++)
		{
			if(typeof arguments[p] == 'number')
			{
				lng = arguments[p];
				break;
			}
			if(typeof arguments[p] != 'string')
				break;
			if(p == 0)
				cl = arguments[p];
			else if(p == 1)
				el = arguments[p];
		}
		var tmp = this.onGetDescription(cl, el, lng);
		if(tmp)
			return tmp;
		if(this._ergnamesFromGetText)
		{
			var cls = cl;
			var tmpLbl = '';
			var key = '';
			while(cls && !tmpLbl)
			{
				key = this._tooltipPrefix + cls;
				if(el)
					key += '.' + el;
				tmpLbl = this.getText(key);
				if(!tmpLbl)
					cls = this.getSuperClass(cls);
			}
			if(!tmpLbl)
			{
				// bei Beziehungen soll der ergon. Name
				// der Zielklasse versucht werden
				var trgtCls = this.getAssocClass(cl, el);
				if(trgtCls)
					tmpLbl = this.getDescription(trgtCls);
			}
			return (tmpLbl /*|| el || cl*/); // anders als bei den Ergnames hier keine techn. Namen zurueckgeben
			//return (tmpLbl || '[' + key + ']');
		}
		if(this.hasMultilang2Support())
		{
			if(this._erg[(clname || '')])
			{
				if(!elem)
					return this._erg[(clname || '')]['s'];
				if(this._erg[(clname || '')][(elem || '')])
					return (this._erg[(clname || '')][(elem || '')]['s'] || '');
			}
			return ''; //"[Description '"+code+"' not found with multilang2 support]";
		}
		if(!cl)
			return "";
		//if(!this["_classes_d"])
		//	this.loadDyn("_classes_d");
		if(this._classes_d && !el)
			return this._classes_d[cl][lng]; // Klassenbeschr.
		// Traversierungspfade aufloesen
		if(/->/.test(el)==true)
		{
			var trav = el.split("->");
			var trgtCl = this.getAssocClass(cl, trav.shift());
			return this.getDescription(trgtCl, trav.join('->'));
		}
		else if(/->/.test(el)==true)
		{
			// TODO: Ein Punkt wurde gefunden.
			// wenn ein Punkt im Klassennamen steht, dann in
			// dem eingebetteten Typ suchen
			
			// wenn ueber eine Beziehung gesucht wird ("->"),
			// das Zielattribut in der Zielklasse der Beziehung 
			// suchen
			var trav = elem.split("->");
			for(var i=0; i<trav.length-1; i++)
			{
				if(cl == "")
					return "";
				cl = this.getAssocClass(cl, trav[i]);
			}
			return this.getDescription(cl, trav[trav.length-1], lng);
		}
		if(this["_"+cl+"_attrs_d"] && this["_"+cl+"_attrs_d"][el])
			return this["_"+cl+"_attrs_d"][el][lng];
		// Basisklasse?
		var baseCls = this.getSuperClass(clname);
		if(baseCls)
			return this.getDescription(baseCls, elem, lang);
		return '';
	}

	/**
	 @function PDMeta#parseErg
	 @desc Ein ergonomischer Bezeichner kann literal angegeben oder
		 aber in geschweiften Klammern ein Ausdruck angegeben werden, über
		 den der Text zur Laufzeit ermittelt werden soll. Der Ausdruck kann
		 die Form "SC::<id>" haben und verweist dann auf die in der
		 strings.txt von JANUS definierten Texte (aufgelöst über
		 [getString()]{@link PDMeta#getString}. Oder er bgeinnt mit
		 "@", gefolgt von "FORM:", "LIST:" oder "TIP:"
		 und wird dann über [getErgname()]{@link PDMeta#getErgname},
		 [getListErgname()]{@link PDMeta#getListErgname} bzw.
		 [getShortDescription()]{@link PDMeta#getShortDescription} aufgelöst.
	 */
	parseErg(str) {
		//console.log("### PDClass.parseErg('" + str + "')");
		let res = (str || '');
		if(res && res.startsWith('{') && res.endsWith('}')) {
			res = res.substring(1, res.length - 1);
			if(res.startsWith('@FORM:')) {
				res = res.substring(6);
				let sep = res.indexOf('::');
				if(sep >= 0)
					res = this.getErgname(res.substring(0, sep), res.substring(sep + 2));
				else
					res = this.getErgname(res);
			}
			else if(res.startsWith('@LIST:')) {
				res = res.substring(6);
				let sep = res.indexOf('::');
				if(sep >= 0)
					res = this.getListErgname(res.substring(0, sep), res.substring(sep + 2));
				else
					res = this.getListErgname(res);
			}
			else if(res.startsWith('@TIP:')) {
				res = res.substring(5);
				let sep = res.indexOf('::');
				if(sep >= 0)
					res = this.getShortDescription(res.substring(0, sep), res.substring(sep + 2));
				else
					res = this.getShortDescription(res);
			}
			else
				res = this.getString(res);
		}
		return res;
	}

	/**
	 @function PDMeta#getShortDescription
	 @desc Alias für [getDescription()]{@link PDMeta#getDescription}.
	 @param {string} [clname] Name der Klasse.
	 @param {string} [elem] Name des Elements (Attribut,
		 Beziehung oder Operation).
	 @param {number} [lang] Nummer der Anwendungssprache.
	 @return {string} Bei fehlendem Parameter <code>lang</code> 
		 wird für die aktuelle Sprache abgefragt.
	 */
	getShortDescription(clname, elem, lang) {
		return this.getDescription(clname, elem, lang);
	}

	/**
	 @function PDMeta#getLang
	 @desc Gibt, wenn kein Parameter angegeben wird, die
		 aktuell eingestellte Sprache zurück. Andernfalls
		 wird der Sprach-Code des mit dem Parameter
		 angegebenen Sprachkürzels zurückgegeben.
	 @param {string} langCode Zweibuchstabiges Sprachenkürzel,
		 für das der Code ermittelt werden soll.
	 @return {number} Der Sprach-Code, falls nicht gefunden -1;
	 */
	getLang(langCode) {
		if(typeof langCode == 'undefined')
			return this._actLang;
		// TODO
		// "uk" ist nicht ISO-konform - implizit umwandeln?
		//if(langCode == 'uk')
		//	langCode = 'en-UK';
		for(var i=0; i<this._shortLang.length; i++)
		{
			if(langCode == this._shortLang[i])
				return i;
		}
		// falls nur der zweibuchstabige Code angegeben
		// und bis hier nichts gefunden wurde, das erste
		// passende Locale benutzen
		if(langCode.length == 2)
		{
			for(var i=0; i<this._shortLang.length; i++)
			{
				if(langCode == this._shortLang[i].substring(0, 2))
					return i;
			}
		}
		return -1;
	}
	
	/**
	 @function PDMeta#getNumLanguages
	 @desc Gibt die Anzahl der Anwendungssprachen zurück.
	 @return {number} Anzahl der im UML-Modell definierten
		 Sprachen.
	 */
	getNumLanguages() {
		return this._numLanguages;
	}

	/**
	 @function PDMeta#getLanguages
	 @desc Gibt die Langbezeichner der Anwendungssprachen zurück.
	 @return {String[]} Die Bezeichner der im UML-Modell definierten
		 Sprachen.
	 */
	getLanguages() {
		return this._languages;
	}
	
	/**
	 @function PDMeta#getLangCode
	 @desc Gibt für den Index der Anwendungssprache
		 deren zweibuchstabiges Sprachkürzel zurück.
	 @param {number} langIdx Index der Anwendungssprache.
	 @return {string} Das Sprachkürzel. Falls nicht gefunden
		 oder Fehler aufgetreten, wird ein Leerstring 
		 zurückgegeben.
	 */
	getLangCode(langIdx) {
		if(isNaN(langIdx))
			langIdx = this._actLang; 
		if(langIdx < 0 || langIdx >= this._shortLang.length)
			return "";
		return this._shortLang[langIdx];
	}
	
	/**
	 @function PDMeta#getDefaultLanguageCode
	 @desc Ermittelt die Standardsprache der Anwendung.
	 @return {string} Das Kürzel der Standardsprache. Im Falle
		 der erweiterten Mehrsprachigkeitsunterstützung (siehe
		 [PDMeta.hasMultilang2Support]{@link PDMeta#hasMultilang2Support})
		 ist das die in <code>langs/default</code> festgelegte, sonst immer
		 die erste im Modell festgelegte Sprache.
	 */
	getDefaultLanguageCode() {
		if(this.hasMultilang2Support())
			return this._defaultLang;
		return this.getLangCode(0); // immer die erste
	}

	/**
	 @function PDMeta#getLength
	 @desc Ermittelt die Länge eines Attributs, mit der es auf
		 der Benutzungsoberfläche dargestellt wird.
	 @param {string} clname Name der Klasse.
	 @param {string} attr Name des Attributs.
	 @return {number} Länge, im Fehlerfall <code>-1</code>.
	 @see [getStringLength]{@link PDMeta#getStringLength},
		 mit dem die maximal mögliche Eingabelänge bei
		 String-Attributen ermittelt wird.
	 */
	getLength(clname, attr) {
		// Traversierungspfade aufloesen
		if(/->/.test(attr)==true)
		{
			var trav = attr.split("->");
			var trgtCl = this.getAssocClass(clname, trav.shift());
			return this.getLength(trgtCl, trav.join('->'));
		}
		if(!this["_"+clname+"_attrs_len"])
			this.loadDyn("_"+clname+"_attrs_len");
		if(this["_"+clname+"_attrs_len"] && this["_"+clname+"_attrs_len"][attr])
			return this["_"+clname+"_attrs_len"][attr];
		// Basisklasse?
		var baseCls = this.getSuperClass(clname);
		if(baseCls)
			return this.getLength(baseCls, attr);
		return -1;
	}

	/**
	 @function PDMeta#getUnit
	 @desc Gibt die spezifizierte Maßinheit des 
		 angegebenen Attributs zurück.
	 @param {string} clname Name der Klasse.
	 @param {string} attr Name des Attributs.
	 @return {string} Die Einheit (String).
	 */
	getUnit(clname, attr) {
		// Traversierungspfade aufloesen
		if(/->/.test(attr)==true)
		{
			var trav = attr.split("->");
			var trgtCl = this.getAssocClass(clname, trav.shift());
			return this.getUnit(trgtCl, trav.join('->'));
		}
		if(!this["_"+clname+"_unit"])
			this.loadDyn("_"+clname+"_unit");
		if(this["_"+clname+"_unit"] && this["_"+clname+"_unit"][attr])
			return this["_"+clname+"_unit"][attr];
		// Basisklasse?
		var baseCls = this.getSuperClass(clname);
		if(baseCls)
			return this.getUnit(baseCls, attr);
		return '';
	}

	/**
	 @function PDMeta#getStringCode
	 @desc Den numerischen Code einer String-Konstante ermitteln.
	 @param {string} id Identifizierer des Textes in der Form
		 "&lt;Textklasse&gt;::&lt;Attribut&gt;", bzw.
		 "SC::&lt;Attribut&gt;" für JANUS-eigene Texte.
	 @return {number} der numerische Code des Textes.
	 */
	getStringCode(id, lang) {
		if(this.hasMultilang2Support()) {
			if(id.substring(0, 3) == "SC.")
				id = "SC::" + id.substring(3);
			if(!this._stringIdentMap)
				return -1;
			return (this._stringIdentMap[id] || -1);
		}
		if(id.substring(0, 3) != "SC." && id.substring(0, 4) != "SC::") {
			// Konstanten aus Text-Klassen
			try {
				var sep = id.indexOf("::");
				var sepW = 2;
				if(sep < 0) {
					sep < id.indexOf(".");
					sepW = 1;
				}
				if(sep > 0)
					return this.getStringCode(window[id.substring(0, sep)][id.substring(sep+sepW)]);
			}
			catch(ex) {
				console.warn("PDMeta.getStringCode() - Text const '"+id+"' not found");
			}
		}
		// Prefix "SC::" oder "SC." ueberlesen
		if(id.substring(0, 3) == "SC.")
			id = id.substring(3);
		else if(id.substring(0, 4) == "SC::")
			id = id.substring(4);
		// Code raussuchen
		if(this._stringsIdent) {
			for(var i=0; i<this._stringsIdent.length; i++) {
				if(this._stringsIdent[i] == id) {
					if(i >= this._stringsIndex.length) {
						console.log("PDMeta.getStringCode() - Invalid index of stringsIdent: "+
								i+", searched for code: '"+id+"'");
						return;
					}
					return this._stringsIndex[i];
				}
			}
		}
	}
	
	/**
	 @function PDMeta#getString
	 @desc Die String-Konstante für den angegebenen Code
		 ermitteln. Damit können z.B. Fehler-Codes aus
		 Operationen in Texte umgewandelt werden. Außerdem können
		 Sie hiermit auf die in JANUS vordefinierten Meldungstexte
		 (vgl. <code>strings.txt</code>) sowie eigene, im Modell in
		 Klassen mit dem Stereotypen <code>&lt;&lt;Text&gt;&gt;</code>
		 definierte, mehrsprachige Texte zugreifen.<br/>
		 Falls zu dem angegebenen Code kein
		 Text gefunden werden kann, wird ein Leerstring zurückgegeben,
		 sodass Sie z.B. kodieren können:
		 <pre class="prettyprint"><code>const meinText = (PDMeta.getString('Klasse::Text') || 'Mein text')</code></pre>
	 @param {mixed} code Code des Textes. Dieser kann entweder als
		 String in der Form "&lt;Textklasse&gt;::&lt;Attribut&gt;" bzw.
		 "SC::&lt;Attribut&gt;" für JANUS-eigene Texte angegeben werden
		 oder aber direkt über den numerischen Code. Letzteren können Sie
		 z.B. in Operationen vom Server zurückgeben, um dann mit dieser
		 Funktion die Nummer in den zugehörigen mehrsprachigen Text
		 aufzulösen.
	 @param {number} [lang] Numerischer Code der Sprache, in der
		 der Text ermittelt werden soll. Wird der Parameter
		 nicht angegeben, wird die aktuelle Anwendungssprache
		 benutzt.
	 @return {string} Der Text
	 @since 1.0
	*/
	getString(code, lang) {
		//console.log("PDMeta.getString('"+code+"')");
		if(isNaN(lang))
			lang = this._actLang;
		var tmp = this.onGetString(code, lang);
		if(tmp)
			return tmp;
		/*if(!this['_strings'])
		{
			console.warn("PDMeta.getString('" + code + "', " + lang + ") used before PDMeta.load is completed!");
			//alert("PDMeta.getString('" + code + "', " + lang + ") used before PDMeta.load is completed!");
			return '' + code;
		}*/
		if(this.hasMultilang2Support())
		{
			if(typeof code == 'number' && code >= 0 && code < this._strings.length)
				return this._strings[code];
			var id = this.getStringCode(code);
			if(id >= 0 && id < this._strings.length)
				return this._strings[id];
			console.log("PDMeta.getString('"+code+"') - Text const not found with multilang2 support");
			return ""; // "[Text const '"+code+"' not found with multilang2 support]";
		}
		try
		{
			if(typeof code == "string")
				code = this.getStringCode(code);
			//console.log("PDMeta.getString('"+code+"') - Num. code: "+code+" ["+(typeof code)+"]");
			if(typeof code == "number" && code < 0)
				code *= -1;
			if(typeof code != "number" 
					|| code >= this._munStrings * this._numLanguages)
				return ''; //"[this.getString(): INVALID CODE: "+code+"]";
			if(code < this._numStrings) {
				if(!this._strings)
					this._strings = {};
				if(!this._strings[code * this._numLanguages + lang])
					this.loadDyn("_strings", code * this._numLanguages + lang);
				return this._strings[code * this._numLanguages + lang].unescapeHTML();
			}
			if((code - this._numStrings) < this._numErrorMessages) {
				if(!this._errorMessages)
					this._errorMessages = {};
				if(!this._errorMessages[(code - this._numStrings) * this._numLanguages + lang])
					this.loadDyn("_errorMessages", (code - this._numStrings) * this._numLanguages + lang);
				return this._errorMessages[(code - this._numStrings) * this._numLanguages + lang];
			}
			return '' + code;
		}
		catch(ex)
		{ return '' + (code || ''); }
	}
	
	/**
	 @function PDMeta#getErrMsg
	 @desc Fehlermeldung aus dem Rückgabewert von [PDObject#setAttribute]{@link PDObject#setAttribute}
		und dem dort übergebenen Wert für das Attribut konstruieren.
	 @param {number} res
	 @param {string} val
	 @param {string} [attr]
	 @param {Function} [cb]
	 @return {string}
	 @since 3.0
	 */
	getErrMsg(res, val, attr, cb) {
		const pars = new JParamPacker(JafWebAPI.PDMeta.getErrMsg.eventName, this.PDClass);
		let _attr = '';
		let _cb = null;
		let pos = 2;
		if(arguments.length > pos && typeof arguments[pos] == 'string') {
			_attr = arguments[pos];
			pos++;
		}
		if(arguments.length > pos && typeof arguments[pos] == 'function') {
			_attr = arguments[pos];
			pos++;
		}
		pars.add(JafWebAPI.PDMeta.getErrMsg.PAR_errCode, res);
		pars.add(JafWebAPI.PDMeta.getErrMsg.PAR_val, val);
		if(_attr)
			pars.add(JafWebAPI.PDMeta.getErrMsg.PAR_attr, _attr);
		const pdClass = this.PDClass;
		let result = '';
		const successFn = function(req, options) {
				const resp = new JResponse(req, options, pdClass);
				if(resp.hasFatalError())
					throw "Request got empty or invalid response!";
				if(resp.hasError())
					throw resp.getErrorMessage();
				result = resp.getString(JafWebAPI.PDMeta.getErrMsg.PROP_result, '');
				if(typeof _cb == 'function')
					_cb(result);
			};
		const failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof _cb == 'function')
					_cb();
			};
		JafWeb.ajaxRequest({
				url: pdClass.getURL(),
				authToken: pdClass.getAuthToken(),
				method: "GET",
				async: (!!_cb),
				params: pars.get(),
				scope: this,
				callerName: pars.getEventName(),
				disableCaching: false, // das Ergebnis aendert sich nicht, deshalb cachen lassen!
				success: successFn,
				failure: failureFn
			});
		if(!_cb)
			return result;
	}
	
	/**
	 @function PDMeta#getClasses
	 @desc Gibt die technischen Namen aller im Modell
		 spezifizierten Fachkonzeptklassen zurück.
	 @return {string[]} Array von Strings, das die technischen Namen
		 der Klassen enthält.
	 */
	getClasses() {
		if(!this["_classes"])
			this.loadDyn("_classes");
		return (this._classes || []);
	}
	
	/**
	 @function PDMeta#isAbstract
	 @desc Ermittelt, ob eine Fachkonzeptklasse als abstrakte 
	 	Klasse modelliert ist.
	 @param {string} clname Technischer Name der Klasse.
	 @return {boolean} <code>true</code>, falls abstrakt.
	 */
	isAbstract(clname) {
		const tmp = this.onIsAbstract(clname);
		if(tmp !== undefined)
			return tmp;
		return ((this.getClassFlags(clname) & this.AbstractClass) != 0);
	}
	
	/**
	 @function PDMeta#getStereotype
	 @desc Ermittelt die Klassen mit dem Stereotyp <<User>>
	 @return {string} Falls es keine solche gibt, wird ein Leerstring
		zurückgegeben.
	 @since 3.0
	 */
	getUserClass() {
		if(this["_user_class"] === undefined)
			this.loadDyn("_user_class");
		return (this._user_class || '');
	}
	
	/**
	 @function PDMeta#getStereotype
	 @desc Ermittelt die Klassen mit dem Stereotyp <<Principal>>
	 @return {string} Falls es keine solche gibt, wird ein Leerstring
		zurückgegeben.
	 @since 3.0
	 */
	getPrincipalClass() {
		if(this["_princ_class"] === undefined)
			this.loadDyn("_princ_class");
		return (this._princ_class || '');
	}
	
	/**
	 @function PDMeta#getStereotype
	 @desc Ermittelt für eine Fachkonzeptklasse den 
		 Stereotypen.
	 @param {string} clname Stereotyp der Klasse.
	 @return {string} Der Name des Stereotypen.
	 */
	getStereotype(clname) {
		if(!this["_classes_stereo"])
			this.loadDyn("_classes_stereo");
		return (this._classes_stereo ? this._classes_stereo[clname] : '');
	}
	
	/**
	 @function PDMeta#getPrincipalClass
	 @desc Gibt die im Anwendungsmodell definierte Mandantenklasse,
		 also die mit dem Stereotyp "Principal" zurück.
	 @return {string} Name der Mandantenklasse oder ein Leerstring,
		 wenn keine definiert ist.
	 */
	getPrincipalClass() {
		if(!this["_classes"])
			this.loadDyn("_classes");
		for(var i = 0; i < this._classes.length; i++) {
			if(this.getStereotype(this._classes[i]).toLowerCase() == 'principal')
				return this._classes[i];
		}
		return '';
	}
	
	/**
	 @function PDMeta#getJournalClass
	 @desc Gibt die im Anwendungsmodell definierte Protokollierungsklasse,
		 also die mit dem Stereotyp "Journal" zurück.
	 @return {string} Name der Journalklasse oder ein Leerstring,
		 wenn keine definiert ist.
	 */
	getJournalClass() {
		if(!this["_classes"])
			this.loadDyn("_classes");
		for(var i = 0; i < this._classes.length; i++) {
			if(this.getStereotype(this._classes[i]).toLowerCase() == 'journal')
				return this._classes[i];
		}
		return '';
	}
	
	/**
	 @function PDMeta#getStereotypeAttr
	 @desc Gibt den Namen eines Attributs zurück, das im Modell
		 mit einem bestimmten Stereotyp gekennzeichnet ist.
	 @param {string} stereotype Der gesuchte Stereeotyp. Einer der
		 folgenden Werte (Groß-/Kleinschreibung ist nicht relevant):
		 <ul>
			<li>"Login": </li>
			<li>"Fullname": </li>
			<li>"PassExpire": </li>
			<li>"AccountExpire": </li>
			<li>"ValidFrom": </li>
			<li>"CurrencyName": </li>
			<li>"Euro": </li>
			<li>"DefaultExchange": </li>
			<li>"CurrencyPrecision": </li>
			<li>"PrincipalName": </li>
			<li>"PrincipalFullname": </li>
			<li>"JClass": </li>
			<li>"JAttribute": </li>
			<li>"JObjectIdent": </li>
			<li>"JOldValue": </li>
			<li>"JNewValue": </li>
			<li>"JUsername": </li>
			<li>"JTimestamp": </li>
			<li>"JComment": </li>
			<li>"JCID": </li>
			<li>"JOID": </li>
			<li>"JClassLabel": </li>
			<li>"JAttributeLabel": </li>
			<li>"JKey": </li>
			<li>"DocId": </li>
			<li>"DocType": </li>
			<li>"DocName": </li>
			<li>"DocExtension": </li>
			<li>"DocMimeType": </li>
			<li>"DocActiveVersion": </li>
			<li>"DocSize": </li>
			<li>"DocTimestamp": </li>
			<li>"DocVersions": </li>
			<li>"Day0_Start": </li>
			<li>"Day0_End": </li>
			<li>"Day1_Start": </li>
			<li>"Day1_End": </li>
			<li>"Day2_Start": </li>
			<li>"Day2_End": </li>
			<li>"Day3_Start": </li>
			<li>"Day3_End": </li>
			<li>"Day4_Start": </li>
			<li>"Day4_End": </li>
			<li>"Day5_Start": </li>
			<li>"Day5_End": </li>
			<li>"Day6_Start": </li>
			<li>"Day6_End": </li>
			<li>"HolidayDay": </li>
			<li>"HolidayActive": </li>
			<li>"HolidayDescription": </li>
			<li>"UpdateJournalStart": </li>
			<li>"UpdateJournalStartVersion": </li>
			<li>"UpdateJournalEnd": </li>
			<li>"UpdateJournalEndVersion": </li>
			<li>"UpdateJournalEndText": </li>
		 </ul>
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @return {string} clname Name des Attributs oder Leerstring, wenn
		 es nicht gefunden wurde.
	 */
	getStereotypeAttr(stereotype, callback) {
		if(stereotype.toLowerCase() == 'jattribute' && this._journalAttr !== undefined)
			return this._journalAttr;
		if(stereotype.toLowerCase() == 'jattributelabel' && this._journalAttrLabel !== undefined)
			return this._journalAttrLabel;
		if(stereotype.toLowerCase() == 'joid' && this._journalOid !== undefined)
			return this._journalOid;
		const pars = new JParamPacker(JafWebAPI.PDMeta.getStereotypeAttr.eventName, this.PDClass);
		pars.add(JafWebAPI.PDMeta.getStereotypeAttr.PAR_stereo, (stereotype||""));
		const pdClass = this.PDClass;
		let result = '';
		const successFn = function(req, options) {
				const resp = new JResponse(req, options, pdClass);
				if(resp.hasFatalError())
					throw "Request got empty or invalid response!";
				if(resp.hasError())
					throw resp.getErrorMessage();
				result = resp.getString(JafWebAPI.PDMeta.getStereotypeAttr.PROP_result, '');
				if(stereotype.toLowerCase() == 'jattribute')
					this._journalAttr = result;
				else if(stereotype.toLowerCase() == 'jattributelabel')
					this._journalAttrLabel = result;
				else if(stereotype.toLowerCase() == 'joid')
					this._journalOid = result;
				if(typeof callback == 'function')
					callback(result);
			};
		const failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callback == 'function')
					callback();
			};
		JafWeb.ajaxRequest({
				url: (this.PDClass || PDClass).getURL(),
				authToken: this.PDClass.getAuthToken(),
				method: "GET",
				async: (!!callback),
				params: pars.get(),
				scope: this,
				callerName: pars.getEventName(),
				disableCaching: false, // das Ergebnis aendert sich nicht, deshalb cachen lassen!
				success: successFn,
				failure: failureFn
			});
		if(!callback)
			return result;
	}

	/**
	 @function PDMeta#getJournalizedClasses
	 @desc Ermittelt die Klassen des Modells, die als zu journalisieren
		 gekennzeichnet sind (Property "Journal").
	 @return {Array<string>} Die Namen der zu journalisierenden Klassen.
		 Wenn es kein Journal gibt, wird <code>null</code> zurückgegeben.
	 @since 3.0
	 */
	getJournalizedClasses(clname) {
		return (this._journCls || null);
	}
	
	/**
	 @function PDMeta#getSuperClass
	 @desc Direkte Oberklasse ermitteln.
	 @param {string} clname Klasse, deren Oberklasse ermittelt
		 werden soll.
	 @return {string} clname Name der Oberklasse.
	 */
	getSuperClass(clname) {
		if(!this["_superClasses"])
			this.loadDyn("_superClasses");
		if(this._superClasses && this._superClasses[clname])
			return this._superClasses[clname];
		return "";
	}
	
	/**
	 @function PDMeta#getAllSuperClasses
	 @desc Alle Oberklassen ermitteln.
	 @param {string} clname Klasse, deren Oberklassen
		 ermittelt werden sollen.
	 @return {string[]} Array mit den Namen der
		 Oberklassen, in der Reihenfolge ausgehend von
		 <code>clname</code> nach oben.
	 */
	getAllSuperClasses(clname) {
		var superclasses = new Array();
		var sclass = this.getSuperClass(clname);
		while(sclass != "")
		{
			superclasses.push(sclass);
			sclass = this.getSuperClass(sclass);
		}
		return superclasses;
	}
	
	/**
	 @function PDMeta#getSubClasses
	 @desc Direkte Unterklassen ermitteln. 
	 @param {string} clname Klasse, deren Unterklassen
		 ermittelt werden sollen.
	 @return {string[]} Array, das die Namen der
		 direkten Unterklassen enthält.
	 @see Um rekursiv alle Unterklassen zu ermitteln,
		benutzen Sie [getAllSubClasses]{@link PDMeta#getAllSubClasses}.
	 */
	getSubClasses(clname) {
		var subclasses = new Array();
		if(!this["_subClasses"])
			this.loadDyn("_subClasses");
		if(!this._subClasses || !this._subClasses[clname])
			return null;
		for(var i=0; i < this._subClasses[clname].length; i++)
			subclasses.push(this._subClasses[clname][i]);
		return subclasses;
	}
	
	/**
	 @function PDMeta#getAllSubClasses
	 @desc Alle Unterklassen ermitteln. 
	 @param {string} clname Klasse, deren Unterklassen
		 ermittelt werden sollen.
	 @return {string[]} Array, das die Namen der
		 Unterklassen enthält.
	 */
	getAllSubClasses(clname) {
		var tmp = this.onGetAllSubClasses(clname);
		if(tmp)
			return tmp;
		var subclasses = new Array();
		if(!this["_subClasses"])
			this.loadDyn("_subClasses");
		if(!this._subClasses || !this._subClasses[clname])
			return [];
		for(var i=0; i < this._subClasses[clname].length; i++)
		{
			var subClname = this._subClasses[clname][i];
			subclasses.push(subClname);
			subclasses = subclasses.concat(this.getAllSubClasses(subClname));
		}
		return subclasses;
	}
	
	/**
	 @function PDMeta#isSubClassOf
	 @desc Ermittelt, ob einem bestimmte Klasse eine Unterklasse einer
		anderen ist.
	 @return {boolean}
	 @since 3.0
	 */
	isSubClassOf(clname, superClname) {
		return (this.getAllSubClasses(clname).indexOf(superClname) >= 0);
	}
	
	/**
	 @function PDMeta#getId
	 @desc Zu einem Klassennamen die numerische Id
		 der Fachkonzeptklasse ermitteln.<br/>
		 <span class="important">Hinweis:</span> Die Ids der Fachkonzeptklassen sind auch als Konstanten
		 in <code>PDMeta</code> definiert in der Form "Id<Klasse>",
		 also z.B. für Klasse Offer als <code>PDMeta.IdOffer</code>.
	 @param {string} clname Technischer Name der Fachkonzeptklasse.
	 @return {number} Id der Klasse oder 0, falls die Klasse
		 nicht gefunden wurde.
	 */
	getId(clname) {
		if(!clname || clname == "")
			return 0;
		if(!this["_classIds"])
			this.loadDyn("_classIds");
		if(this._classIds && this._classIds[""+clname])
			return this._classIds[""+clname];
		return 0;
	}
	
	/**
	 @function PDMeta#getClass
	 @desc Zu einer numerischen Klassen-Id den technischen
		 Namen der Klasse ermitteln.
	 @param {number} cid Id der Fachkonzeptklasse.
	 @return {string} Name der Fachkonzeptklasse mit der angebenen
		 Id. Falls die nicht gefunden werden kann, wird ein
		 leerer String zurückgegeben.
	 */
	getClass(cid) {
		if(!cid)
			return "";
		if(!this["_classes"])
			this.loadDyn("_classes");
		if(!this._classes)
			return '';
		if(!this["_classIds"])
			this.loadDyn("_classIds");
		for(var cl in this._classIds)
		{
			if(this._classIds[cl] == cid)
				return cl;
		}
		return '';
	}
	
	/**
	 @function PDMeta#getPDId
	 @desc Zu einer Klassen-Id, die möglicherweise zu einer
		 Transaktionsklasse gehört, die Id der echten,
		 persistenten Klasse ermitteln.<br/>
		 <span class="important">Hinweis:</span> Die Ids der Fachkonzeptklassen sind auch als Konstanten
		 in <code>PDMeta</code> definiert in der Form "Id<Klasse>",
		 also z.B. für Klasse Offer als <code>PDMeta.IdOffer</code>.
		 Die Ids der Transaktionsklassen besitzen die Form
		 "IdBACKUP_<Klasse>", also z.B. <code>PDMeta.IdBACKUP_Offer</code>
	 @param {number} cid Id einer persistenten oder Transaktionsklasse.
	 @return {number} Id der persistenten Klasse oder 0, falls die Klasse
		 nicht gefunden wurde.
	 */
	getPDId(cid) {
		var cl = cid;
		if(typeof cid == 'number')
			cl = this.getClass(cid);
		if(cl.substring(0, 7) == 'BACKUP_')
			cl = cl.substring(7);
		return this.getId(cl);
	}
	
	/**
	 @function PDMeta#getAbout
	 @desc Den im UML-Modell spezifizierten String für die
		 die About-Meldung zurückgeben.
	 @param {number} [lang] Nummer der Anwendungssprache.
		 Falls dieser Parameter fehlt, wird der Text
		 in der aktuellen Sprache abgefragt.
	 @return {string} Der Meldungstext.
	 */
	getAbout(lang) {
		if(!lang || lang < 0)
			lang = this._actLang;
		if(this.hasMultilang2Support())
			return (this._about ? this.getString(this._about, lang) : '');
		return (this._about && this._about.length > lang ? this._about[lang] : '');
	}
	
	/**
	 @function PDMeta#getCopyright
	 @desc Den im UML-Modell spezifizierten Copyright-Hinweis
		 zurückgeben.
	 @param {number} [lang] Nummer der Anwendungssprache.
		 Falls dieser Parameter fehlt, wird der Text
		 in der aktuellen Sprache abgefragt.
	 @return {string} Der Text.
	 */
	getCopyright(lang) {
		if(!lang || lang < 0)
			lang = this._actLang;
		if(this.hasMultilang2Support())
			return (this._copyright ? this.getString(this._copyright, lang) : '');
		return (this._copyright && this._copyright.length > lang ? this._copyright[lang] : '');
	}
	
	/**
	 @function PDMeta#getInfobarText
	 @desc Den im UML-Modell spezifizierten Text
		 für die Info-Leiste zurückgeben.
	 @param {number} [lang] Nummer der Anwendungssprache.
		 Falls dieser Parameter fehlt, wird der Text
		 in der aktuellen Sprache abgefragt.
	 @return {string} Der Text.
	 */
	getInfobarText(lang) {
		if(!lang || lang < 0)
			lang = this._actLang;
		if(this.hasMultilang2Support())
			return (this._copyright ? this.getString(this._infobarText, lang) : '');
		return (this._infobarText && this._infobarText.length > lang ? this._infobarText[lang] : '');
	}
	
	/**
	 @function PDMeta#getVersion
	 @desc Den im UML-Modell spezifizierten 
		 Versions-String zurückgeben.
	 @return {string} Versionsbezeichnung.
	 */
	getVersion() {
		return (this._version !== undefined ? this._version : -1);
	}
	
	/**
	 @function PDMeta#getAttributes
	 @desc Die technischen Namen der Attribute einer Klasse
		 ermitteln. Es werden alle Attribute, inklusive derjenigen
		 aus den Oberklassen zurückgegeben.
	 @param {string} clname Name der Fachkonzeptklasse, deren
		 Attribute ermittelt werden sollen.
	 @return {string[]} Array mit den Namen.
	 */
	getAttributes(clname) {
		if(!this["_"+clname+"_attrs"])
			this.loadDyn("_"+clname+"_attrs");
		if(!this["_"+clname+"_derAttrs"])
			this.loadDyn("_"+clname+"_derAttrs");
		// Basisklasse?
		const attrs = (this["_"+clname+"_attrs"] || []).concat(this["_"+clname+"_derAttrs"] || []);
		const baseCls = this.getSuperClass(clname);
		if(baseCls)
			return attrs.concat(this.getAttributes(baseCls)).unique();
		return attrs.unique();
	}

	/**
	 @function PDMeta#getAssocs
	 @desc Die technischen Namen der Beziehungen einer Klasse
		 ermitteln.
	 @param {string} clname Name der Fachkonzeptklasse, deren
		 Beziehungen ermittelt werden sollen.
	 @return {string[]} Array mit den Namen.
	 */
	getAssocs(clname) {
		if(!this["_"+clname+"_rels"])
			this.loadDyn("_"+clname+"_rels");
		// Basisklasse?
		const baseCls = this.getSuperClass(clname);
		if(baseCls)
			return this["_"+clname+"_rels"].concat(this.getAssocs(baseCls));
		return this["_"+clname+"_rels"];
	}

	/**
	 @function PDMeta#getOperations
	 @desc Die technischen Namen der modellierten
		 Operationen einer Klasse ermitteln. Es werden nur die GUI-relevanten
		 Operationen ermittelt, inklusive aller geerbten Operationen.
	 @param {string} clname Name der Fachkonzeptklasse, deren
		 Operationen ermittelt werden sollen.
	 @return {string[]} Array mit den Namen.
	 */
	getOperations(clname) {
		if(!this["_"+clname+"_ops"])
			this.loadDyn("_"+clname+"_ops");
		// Basisklasse?
		const baseCls = this.getSuperClass(clname);
		if(baseCls) {
			return this["_"+clname+"_ops"].
				concat(this.getOperations(baseCls)).
				removeDuplicates();
		}
		return this["_"+clname+"_ops"];
	} // TODO: sind nur die GUI-relevanten!

	/**
	 @function PDMeta#getType
	 @desc Gibt die Datentypbezeichnung zurück. Das ist insbesondere nützlich,
		 um, in Ergänzung zu [getTypeId()]{@link PDMeta#getTypeId}, die Namen von
		 Aufzählungstypen zu ermitteln.
	 @param {mixed} clname Name oder numerische Id der Fachkonzeptklasse.
	 @param {string} elem Name des Klassenelements, dessen Typ ermittelt
		 werden soll.
	 @return {string} Der Typbezeichner.
	 @see [getTypeId()]{@link PDMeta#getTypeId}
	 */
	getType(clname, elem) {
		var tmp = this.onGetType(clname, elem);
		if(tmp)
			return tmp;
		// die Typnamen fuer Enums stehen in this["_"+clname+"_ten"][elem],
		// alle anderen rekonstruieren wir aus der TypeId
		if(!clname || !elem)
			return "";
		// Traversierungspfade aufloesen
		if(/->/.test(elem)==true)
		{
			var trav = elem.split("->");
			var trgtCl = this.getAssocClass(clname, trav.shift());
			return this.getType(trgtCl, trav.join('->'));
		}
		else if(/\./.test(elem)==true)
		{
			// TODO: Ein Punkt wurde gefunden.
			// wenn ein Punkt im Klassennamen steht, dann in
			// dem eingebetteten Typ suchen
			
			// wenn ueber eine Beziehung gesucht wird ("->"),
			// das Zielattribut in der Zielklasse der Beziehung 
			// suchen
			var trav = elem.split(".");
			for(var i=0; i<trav.length-1; i++)
			{
				if(clname == "")
					return "";
				clname = this.getAssocClass(clname, trav[i]);
			}
			return this.getType(clname, trav[trav.length-1]);
		}
		var tid = this.getTypeId(clname, elem);
		// Werte unter 0 entsprechen der Klassen-Id eines
		// eingebettetem Typen!
		if(tid < 0)
			return this.getClass(-tid);
		tid = (tid & this.TIdMask);
		switch(tid)
		{
			case this.TIdEnum:
			case this.TIdUser:
				// in der konktreten Klasse?
				if(!this["_"+clname+"_ten"])
					this.loadDyn("_"+clname+"_ten");
				if(this["_"+clname+"_ten"] && this["_"+clname+"_ten"][elem])
					return this["_"+clname+"_ten"][elem];
				// Basisklasse?
				var baseCls = this.getSuperClass(clname);
				if(baseCls)
					return this.getType(baseCls, elem);
				return "";
			// TODO: Schreibweise der Bezeichner kontrollieren!
			case this.TIdNone:
				return "None";
			case this.TIdInt:
				return "Int";
			case this.TIdFloat:
				return "Float";
			case this.TIdString:
				return "String";
			case this.TIdDate:
				return "Date";
			case this.TIdTime:
				return "Time";
			case this.TIdTimestamp:
				return "Timestamp";
			case this.TIdSerial:
				return "Serial";
			case this.TIdEmail:
				return "Email";
			case this.TIdURL:
				return "URL";
			case this.TIdFilename:
				return "Filename";
			case this.TIdCurrency:
				return "Currency";
			case this.TIdBool:
				return "Bool";
			case this.TIdPassword:
				return "Password";
			case this.TIdAbstract:
				return "Abstract";
			case this.TIdCTime:
				return "Time";
			case this.TIdVarCurrency:
				return "VarCurrency";
			case this.TIdRelNN:
				return "RelNN";
			case this.TIdRel1N:
				return "Rel1N";
			case this.TIdRelN1:
				return "RelN1";
			case this.TIdRel11:
				return "Rel11";
			case this.TIdRef:
				return "Ref";
			case this.TIdOID:
				return "OID";
			default:
				return "";
		}
	}

	/**
	 @function PDMeta#getTypeId
	 @desc Den Datentyp eines Klassenelements ermitteln. Falls
		 eine Beziehung angegeben wird, wird die Typ-Id immer
		 nur für den ersten Teil des Traversierungspfads ermittelt,
		 auch wenn ein Zielattribut angegeben ist. Im Unterschied
		 dazu vgl. [getTypeIdViaAssoc()]{@link PDMeta#getTypeIdViaAssoc}.<br/>
		 <h4>Beispiel: Abfrage des Datentyps</h4>
		 <pre class="prettyprint"><code>const typeId = (PDMeta.getTypeId(classname, attr) & PDMeta.TIdMask);
if(typeId == PDMeta.TIdEnum) {
	const enumName = PDMeta.getType(classname, attr);
	...
}</code></pre>
		<h4>Beispiel: Abfrage der Typ-Flags</h4>
		<pre class="prettyprint"><code>const tflags = PDMeta.getTypeId(classname, attr);
if((tflags & PDMeta.TFlagClassGlobal) != 0)
	...</code></pre>
	 @param {string} clname Name der Klasse.
	 @param {string} elem Name des Elements.
	 @return {number} Wenn es sich um einen benutzerdefinierten 
	 Datentyp handelt, wird ein Wert < 0 zurückgegeben,
	 dessen Absolutwert die Id des Typen darstellt.
	 Bei den anderen Datentypen wird eine Kombination
	 aus Datentyp-Konstante und Flags. Vgl. dazu die
	 entsprechenden Konstanten <code>TId...</code> 
	 bzw. <code>TFlag...</code> in {@link PDMeta}.<br/>
	 <span class="important">Hinweis:</span> Um den Typen auch
	 über Traversierungspfade hinweg zu
	 ermitteln, verwenden Sie statt dieser die Funktion
	 [getTypeIdViaAssoc()]{@link PDMeta#getTypeIdViaAssoc}.
	 @see [getType()]{@link PDMeta#getType} zum Ermitteln der Namen von
	 Aufzählungstypen sowie [getTypeIdViaAssoc()]{@link PDMeta#getTypeIdViaAssoc}.
	 */
	getTypeId(clname, elem) {
		const tmp = this.onGetTypeId(clname, elem);
		if(tmp !== 0)
			return tmp;
		// TODO: bei benutzerdefinierten Typen
		// (Structs!) wird Wert < 0 zurueckgegeben,
		// dessen Absolutwert die Klassen-Id des
		// Typs darstellt
		if(!clname || !elem)
			return 0;
		if(typeof clname == 'number')
			clname = this.getClass(clname);
		if(/\./.test(elem)==true) {
			// Ein Punkt wurde gefunden.
			// wenn ein Punkt im Namen steht, dann in
			// dem eingebetteten Typ suchen
			const sep = elem.search(/\./);
			if(sep >= 0) {
				const struct = this.getType(clname, elem.substring(0, sep));
				if(struct)
					return this.getTypeId(struct, elem.substring(sep + 1));
			}
		}
		// in der konktreten Klasse?
		if(!this["_"+clname+"_t"])
			this.loadDyn("_"+clname+"_t");
		if(this["_"+clname+"_t"] && this["_"+clname+"_t"][elem])
			return this["_"+clname+"_t"][elem];
		// Basisklasse?
		const baseCls = this.getSuperClass(clname);
		if(baseCls)
			return this.getTypeId(baseCls, elem);
		return 0;
	}
	
	/**
	 @function PDMeta#getTypeIdViaAssoc
	 @desc Den Datentyp eines Klassenelements auch über
		 Beziehungen hinweg ermitteln.
	 @param {string} clname Name der Klasse.
	 @param {string} elem Name des Elements. Hier kann
		 ein beliebig langer Traversierungspfad angegeben werden
		 in der Form <code>to_A->to_B->attr</code>.
	 @return {number} Zurückgegeben wird eine Kombination
		 aus Datentyp-Konstante und Flags. Vgl. dazu die
		 entsprechenden Konstanten <code>TId...</code> 
		 bzw. <code>TFlag...</code> in <code>PDMeta</code>.
	 @see [getTypeId()]{@link PDMeta#getTypeId}.
	 */
	getTypeIdViaAssoc(clname, elem) {
		if(/->/.test(elem)==false)
			return this.getTypeId(clname, elem);
		var trav = elem.split("->");
		if(trav.length <= 1)
			return 0;
		for(var i=0; i<trav.length-1; i++)
			clname = this.getAssocClass(clname, trav[i]);
		return this.getTypeId(clname, trav[trav.length-1]);
	}
	
	/*
	 @function PDMeta#getEnumSelectionModel
		 Das Selektionsmodell eines Aufzählungstypen abfragen.
	 @return {Object} Objekt mit zwei ganzzahligen Properties
		 <code>min</code> und <code>max</code> für die Anzahl der
		 mindestens bzw. maximal zu selektierenden Ausprägungen.
		 Ein Wert von <code>-1</code> für <code>max</code> bedeutet
		 unbegrenzte Auswahl.
		 Wenn der Aufzählungtstyp nicht bekannt ist,
		 wird <code>null</code> zurückgegeben!
	 @note In JANUS noch nicht unterstuetzt (stammt aus EMT)!
	 */
	getEnumSelectionModel(enumname) {
		/*
		if(!enumname || (typeof this['_' + enumname + '_enum'] != 'object'))
			return null;
		return [(this['_' + enumname + '_enum']['MinSelect'] || 0),
			(this['_' + enumname + '_enum']['MaxSelect'] || 0)];
		*/
	}

	/**
	 @function PDMeta#getStringLength
	 @desc Die maximale Längee eines textuellen Attributs
		 bestimmen (Type String oder Text).
	 @param {string} clname Name der Klasse.
	 @param {string} attr Name des Attributs.
	 @return {number} Spezifizierte maximale Länge des
		 Attributwerts. Falls das Attributs nicht gefunden
		 wird, wird <code>-1</code>, falls es einen anderen
		 Datentyp hat, <code>0</code> zurückgeben.
	 @see [getLength()]{@link PDMeta#getLength}, mit dem die
		 Darstellungslänge auf der Benutzungsoberfläche
		 ermittelt wird.
	 */
	getStringLength(clname, attr) {
		if(!clname || !attr)
			return -1;
		if(!this["_"+clname+"_attrs_strlen"])
			this.loadDyn("_"+clname+"_attrs_strlen");
		if(this["_"+clname+"_attrs_strlen"] && this["_"+clname+"_attrs_strlen"][attr])
			return this["_"+clname+"_attrs_strlen"][attr];
		// Basisklasse?
		var baseCls = this.getSuperClass(clname);
		if(baseCls)
			return this.getStringLength(baseCls, attr);
		return 0;
	}

	/**
	 @function PDMeta#getMinCard
	 @desc Die minimale Kardinalität einer Beziehung 
		 ermitteln.
	 @param {string} clname Name der Klasse.
	 @param {string} rel Name der Beziehung.
	 @return {number} Minimale Kardinalität der Beziehung 
		 aus Sicht der angegebenen Klasse.
	 */
	getMinCard(clname, rel) {
		if(!this["_"+clname+"_rels_min"])
			this.loadDyn("_"+clname+"_rels_min");
		if(this["_"+clname+"_rels_min"] && this["_"+clname+"_rels_min"][rel])
			return this["_"+clname+"_rels_min"][rel];
		// Basisklasse?
		var baseCls = this.getSuperClass(clname);
		if(baseCls)
			return this.getMinCard(baseCls, rel);
		return undefined;
	}
	
	/**
	 @function PDMeta#getMaxCard
	 @desc Die maximale Kardinalität einer Beziehung 
		 ermitteln.
	 @param {string} clname Name der Klasse.
	 @param {string} rel Name des Beziehung.
	 @return {number} Maximale Kardinalität der Beziehung 
		 aus Sicht der angegebenen Klasse; wenn die
		 Kardinalität nicht nach oben begrenzt ist, ist der 
		 Rückgabewert <code>-1</code>.
	 */
	getMaxCard(clname, rel) {
		if(!this["_"+clname+"_rels_max"])
			this.loadDyn("_"+clname+"_rels_max");
		if(this["_"+clname+"_rels_max"] && this["_"+clname+"_rels_max"][rel])
			return this["_"+clname+"_rels_max"][rel];
		// Basisklasse?
		var baseCls = this.getSuperClass(clname);
		if(baseCls)
			return this.getMaxCard(baseCls, rel);
		return undefined;
	}
	
	/**
	 @function PDMeta#getAssocClass
	 @desc Den Namen der Zielklasse einer Beziehung bestimmen.
	 @param {string} clname Name der Klasse.
	 @param {string} rel Name des Beziehung.
	 @return {string} Name der Klasse, die der angegebenen
		 gegenüberliegt.
	 */
	getAssocClass(clname, rel) {
		if(typeof clname == 'number')
			clname = this.getClass(clname);
		var tmp = this.onGetAssocClass(clname, rel);
		if(tmp)
			return tmp;
		if(!this["_"+clname+"_rels_trgt"])
			this.loadDyn("_"+clname+"_rels_trgt");
		if(this["_"+clname+"_rels_trgt"] && this["_"+clname+"_rels_trgt"][rel])
			return this["_"+clname+"_rels_trgt"][rel];
		// Basisklasse?
		var baseCls = this.getSuperClass(clname);
		if(baseCls)
			return this.getAssocClass(baseCls, rel);
		return '';
	}
	
	/**
	 @function PDMeta#getInverseRelation
	 @desc Den inversen Namen einer Beziehung bestimmen.
	 @param {string} clname Name der Klasse.
	 @param {string} rel Name des Beziehung.
	 @return {string} Name der Klasse, die der angegebenen
		 gegenüberliegt.
	 */
	getInverseRelation(clname, rel) {
		var tmp = this.onGetInverseRelation(clname, rel);
		if(tmp)
			return tmp;
		if(!this["_"+clname+"_rels_inv"])
			this.loadDyn("_"+clname+"_rels_inv");
		if(this["_"+clname+"_rels_inv"] && this["_"+clname+"_rels_inv"][rel])
			return this["_"+clname+"_rels_inv"][rel];
		// Basisklasse?
		var baseCls = this.getSuperClass(clname);
		if(baseCls)
			return this.getInverseRelation(baseCls, rel);
		return '';
	}
	
	/*
	 @function PDMeta#getAttrMin
	 @desc Gibt den unteren Limitwert zurück.
	 @param {string} clname Name der Klasse.
	 @param {string} attr Attribut der übergebenen Klasse.
	 @return {number} Unterer Limitwert.
	 @note In JANUS noch nicht unterstuetzt (stammt aus EMT)!
	 */
	getAttrMin(clname, attr) {
		/*
		if(typeof clname == 'number')
			clname = this.getClass(clname);
		if(this['_' + clname + '_attrs_min'] &&
				(typeof this['_' + clname + '_attrs_min'][attr] == 'number'))
			return this['_' + clname + '_attrs_min'][attr];
		// return this.PDClass.MIN_INT;
		return this.PDClass.MIN_INT;
		*/
	}

	/*
	 @function PDMeta#getAttrMax
	 @desc In JANUS noch nicht unterstuetzt (stammt aus EMT)!
	 @desc Gibt den oberen Limitwert zurück.
	 @param {string} clname Name der Klasse.
	 @param {string} attr Attribut der übergebenen Klasse.
	 @return {number} Oberer Limitwert.
	 */
	getAttrMax(clname, attr) {
		/*
		if(typeof clname == 'number')
			clname = this.getClass(clname);
		if(this['_' + clname + '_attrs_max'] &&
				(typeof this['_' + clname + '_attrs_max'][attr] == 'number'))
			return this['_' + clname + '_attrs_max'][attr];
		// return this.PDClass.MAX_INT;
		return this.PDClass.MAX_INT;
		*/
	}
	
	/*
	 @function PDMeta#getAttrDefault
	 @desc Gibt den Vorbelegungswert eines Attributs zurück.
	 @param {string} clname Name der Klasse.
	 @param {string} attr Attribut der übergebenen Klasse.
	 @return {string} Der Vorgabewert.
	 @note In JANUS noch nicht unterstuetzt (stammt aus EMT)!
	 */
	getAttrDefault(clname, attr) {
		/*
		if(typeof clname == 'number')
			clname = this.getClass(clname);
		if(this['_' + clname + '_attrs_default'] &&
				(typeof this['_' + clname + '_attrs_default'][attr] != 'undefined'))
			return '' + this['_' + clname + '_attrs_default'][attr];
		return '';
		*/
	}

	/**
	 @function PDMeta#getIcon
	 @desc Gibt den Dateinamen des zu der angegebenen Id gehörenden
		 Icons zurück.
	 @param {string} iconId Die Id des zu ermittelnden
		 Icons. Die Ids sind nach folgendem Schema definiert:
		 <ul>
			<li>Icon für die Fachkonzeptklasse (Extent- oder Ordner-
			Icon): <code>IFI_&lt;Klassenname&gt;</code></li>
			<li>Icon für ein einzelnes Objekt einer Fachkonzeptklasse:
			<code>ICI_&lt;Klassenname&gt;</code></li>
			<li>Icons für Enum-Ausprägungen:
			<code>E_&lt;Enum-Name&gt;_val_&lt;Index&gt;</code></li>
			<li>Icon für eine Fachkonzeptmethode in der Toolbar:
			<code>IOPT_&lt;Klasse&gt;_&lt;Methode&gt;</code></li>
			<li>Icon für eine Fachkonzeptmethode im Baum:
			<code>IOP_&lt;Klasse&gt;_&lt;Methode&gt;</code></li>
			<li>Icon für eine Fachkonzeptmethode in der SideBar:
			<code>IOPO_&lt;Klasse&gt;_&lt;Methode&gt;</code></li>
		 </ul>
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 */
	getIcon(iconId, callback) {
		// Cache benutzen
		var result = this._iconCache[iconId];
		if(typeof result == 'undefined')
		{
			result = '';
			var pars = new JParamPacker(JafWebAPI.PDMeta.getIcon.eventName, this.PDClass);
			pars.add(JafWebAPI.PDMeta.getIcon.PAR_iconId, (iconId||""));
			var pdClass = this.PDClass;
			var successFn = function(req, options)
				{
					var resp = new JResponse(req, options, pdClass);
					if(resp.hasFatalError())
						throw "Request got empty or invalid response!";
					if(resp.hasError())
						throw resp.getErrorMessage();
					result = resp.getString(JafWebAPI.PDMeta.getIcon.PROP_icon, '');
					this._iconCache[iconId] = result;
					if(typeof callback == 'function')
						callback(result);
				};
			var failureFn = function(response, opts) {
					console.error("Network communication error in " + pars.getEventName() + "!");
					if(typeof callback == 'function')
						callback();
				};
			JafWeb.ajaxRequest({
					url: (this.PDClass || PDClass).getURL(),
					authToken: this.PDClass.getAuthToken(),
					method: "GET",
					async: (!!callback),
					params: pars.get(),
					scope: this,
					callerName: pars.getEventName(),
					disableCaching: false, // das Ergebnis aendert sich nicht, deshalb cachen lassen!
					success: successFn,
					failure: failureFn
				});
		}
		if(!callback)
			return result;
	}
	
	/**
	 @function PDMeta#hasMultilang2Support
	 @desc Ist das erweiterte Mehrsprachigkeitskonzept für den JafWeb-Client
		 aktiviert? Dieses Konzept erlaubt die Unterstützung weiterer
		 Sprachen (über die in JANUS vordefinierten 4 Sprachen hinaus)
		 und ist wesentlich flexibler.<br/>
		 Diese Einstellung wird durch das Modell-<i>Property</i>
		 <code>XMultilang2</code> gesteuert.
	 @return {boolean} <code>true</code>, falls das erweiterte
		 Mehrsprachigkeitskonzept unterstützt wird.
	 */
	hasMultilang2Support() {
		return this._multilang2;
	}
	
	/*
	 @function PDMeta#load
	 @desc Laden der Metainformationen.
	 @param {number} instanceId Instanz-Id, wenn das Feature zum Detektieren
		 von Mehrfachanmeldungen aktiviert ist.
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 */
	load(instanceId, callback) {
		if(typeof instanceId == 'number') {
			this._instanceID = instanceId;
		}
		const pars = new JParamPacker(JafWebAPI.PDMeta.load2.eventName, this.PDClass);
		//var pars = new JParamPacker(JafWebAPI.PDMeta.load.eventName, this.PDClass);
		//pars.add("lang", this._actLang);
		const pdClass = this.PDClass;
		const successFn = function(req, options) {
				try {
					const resp = new JResponse(req, options, pdClass);
					const tmpMeta = resp.getResponseText();
					if(typeof tmpMeta == 'string')
						eval('tmpMeta = ' + resp.getResponseText());
					JafWeb.apply(this, tmpMeta);
					// Datumsklasse initialisieren
					const days = ClientInfo.getWeekdays(); // beginnend bei Sonntag!
					days.unshift(days.pop());
					const monthNos = {};
					const monthShortNames = ClientInfo.getMonthnames(true);
					for(var i = 0; i < monthShortNames.length; i++)
						monthNos[monthShortNames[i]] = i;
					JafWeb.apply(Date, {
							dayNames : days,
							monthNames : ClientInfo.getMonthnames(false),
							monthNumbers : monthNos
						});
					this._loadCompleted = true;
				}
				catch(ex) {
					console.warn("Loading PDMeta failed!");
				}

				// Auf richtige API-Version testen:
				if(!JafWebAPI || !JafWebAPI.API_version || JafWebAPI.API_version !== this._apiVersion)
					throw "Request API version mismatch!" +
					" Client uses " + JafWebAPI.API_version + ", but server uses " + this._apiVersion;
				
				if(typeof callback == 'function')
					callback();
				
				/*var res = 0;
				var resp = new JResponse(req, options);
				try
				{
					eval(resp.getResponseText());
					// falls multilang2, nun die Sprachen laden
					if(this.hasMultilang2Support())
					{
						ClientInfo.setLanguage(this._actLang); // fuehrt auch PDMeta.loadLanguage() aus!
						//this.loadLanguage();
					}
				}
				catch(ex)
				{
					console.warn("Loading PDMeta failed!");
				}*/
			};
		const failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + " - reloading!");
				window.navigation.reload();
				/*console.error("Network communication error in " + pars.getEventName() +
						"! - Response text: " + (response['responseText'] ?
						(response.responseText.length > 400 ? response.responseText.substring(0, 400)+"..." : response.responseText) : '<empty>'));
				if(typeof callback == 'function')
					callback();*/
			};
		JafWeb.ajaxRequest({
				url: (this.PDClass || PDClass).getURL(),
				authToken: this.PDClass.getAuthToken(),
				method: "GET",
				async: (!!callback),
				params: pars.get(),
				scope: this,
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
	}
	
	/*
	 @memberof PDMeta#loadLanguage
	 @desc Laden der Sprachkonstanten für die aktuelle Sprache, wenn erweiterte
		 Mehrsprachigkeit unterstützt wird.
	 @param {string} Code der zu ladenden Sprache.
	 */
	loadLanguage(lang) {
		loadJavaScript(UIApplication.getResourceDir() + "jaf/" +
				"lang." + (lang || this.getLangCode(this._actLang)) + ".js", window);
		//loadJavaScript(UIApplication.getResourceDir() + "script/" +
		//		"lang." + (lang || this.getLangCode(this._actLang)) + ".js", window);
	}
	
	/**
	 @function PDMeta#getInstanceID
	 @desc Gibt die Instanz-ID für die aktuelle Anwendungssitzung zurück.
		 Diese wird verwendet, um zwischen Instanzen innerhalt einer
		 HTTP-Session unterscheiden zu können und so Doppelanmeldungen
		 zu vermeiden bzw. Mehrfachanmeldungen im selben Browser zu
		 ermöglichen.<br/>
		 Damit das funktioniert, muss die hier zurückgegebene ID in jedem
		 Request mitgegeben werden. Bei JafWeb-internen AJAX-Requests wird
		 das (vom {@link JParamPacker}) automatisch gemacht; wenn
		 Sie selbst Requests erstellen, müssen
		 Sie dafür sorgen, wenn Sie diese Funktionalität nutzen wollen.
		 Desweiteren müssen diese IDs auf seiten der Web Application ausgewertet
		 und die Sub-Sessions entsprechend verwaltet werden. Bei der
		 SoapWebapp ist dies der Fall.<br/>
		 <em>Achtung:</em> Diese Kennung entspricht nicht unbedingt der
		 [Client-Id]{@link PDMeta#clientId} auf der JANUS-Seite!
	 @return {number} Die ID: eine Ganzzahl größer oder gleich <code>0</code>.
		 Falls keine ID gesetzt wurde, wird <code>-1</code> zurückgegeben.
	 */
	getInstanceID() {
		return (this._instanceID || 0);
	}
	
	/**
	 @function PDMeta#checkRequestAPI
	 @desc Prüft, ob die Client- und die Server-seitige Version
		 der Web-API zusammenpassen. Es werden die Versionsnummern
		 verglichen, um zu validieren, ob sie aus der identischen
		 Schnittstellenbeschreibung erzeugt wurde.<br/>
		 Diese Funktion wird beim Laden der Metainformationen
		 automatisch ausgeführt.
	 @return {boolean} <code>true</code>, wenn die Versionsnummern genau
		 übereinstimmen, sonst <code>false</code>.
	 */
	checkRequestAPI(iconId, callback) {
		if(!JafWebAPI || !JafWebAPI.API_version)
			return false;
		var clientVers = JafWebAPI.API_version;
		if(typeof this._apiVersion == 'number')
			return (this._apiVersion === clientVers);
		var rest = 'false';
		var pars = new JParamPacker(JafWebAPI.PDMeta.checkRequestAPI.eventName, this.PDClass);
		pars.add(JafWebAPI.PDMeta.checkRequestAPI.PAR_clientVersion, clientVers);
		var pdClass = this.PDClass;
		var successFn = function(req, options)
				{
					var resp = new JResponse(req, options, pdClass);
					if(resp.hasFatalError())
						throw "Request got empty or invalid response!";
					if(resp.hasError())
						throw resp.getErrorMessage();
					res = resp.getBool(JafWebAPI.PDMeta.checkRequestAPI.PROP_result, false);
					if(typeof callback == 'function')
						callback();
				};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callback == 'function')
					callback();
			};
		JafWeb.ajaxRequest({
				url: (this.PDClass || PDClass).getURL(),
				authToken: this.PDClass.getAuthToken(),
				method: "GET",
				async: (!!callback),
				params: pars.get(),
				scope: this,
				callerName: pars.getEventName(),
				disableCaching: false, // das Ergebnis aendert sich nicht, deshalb cachen lassen!
				success: successFn,
				failure: failureFn
			});
		return res;
	}
	
	/**
	 @function PDMeta#getDelErr
	 @desc Fehlermeldung konstruieren, die angezeigt wird, wenn das Löschen
		 eines Objektes fehlschlägt.
	 @param {number} errCode Der Fehler-Code.
	 @param {PDObject} obj Das Objekt, das den Fehler verursacht hat.
		 Statt des Objekts kann auch dessen OID angegeben werden.
	 @param {PDObject} delObj Das Objekt, das gelöscht werden sollte.
		 Statt des Objekts kann auch dessen OID angegeben werden.
	 @param {Function} [callback] Callback-Funtion, die nach der Ausführung
		 aufgerufen werden soll; der Aufruf erfolgt mit dem Ergebnis-String als
		 einzigem Parameter. Wird diese nicht angegeben, wird der Request
		 synchron ausgeführt und der Ergebnis-String - wie dokumentiert -
		 zurückgegeben.
	 @return {string} Die Fehlermeldung. Server-seitig kann diese im User Code
		 manipuliert werden - s. <code>PDClass::getDelErrUser()</code>.
	 */
	getDelErr(errCode, obj, delObj, callback) {
		//console.log("PDMeta.getDelErr("+errCode+", "+obj+", "+delObj+")");
		var pars = new JParamPacker(JafWebAPI.PDMeta.getDelErr.eventName, this);
		var _cid = 0;
		var _oid = 0;
		var _delCid = 0;
		var _delOid = 0;
		var callbFn = null;
		pars.add(JafWebAPI.PDMeta.getDelErr.PAR_errCode, errCode);
		var pos = 1;
		if(arguments.length > pos && (arguments[pos] === null || JafWeb.isPDObject(arguments[pos])))
		{
			if(arguments[pos])
			{
				pars.add(JafWebAPI.PDMeta.getDelErr.PAR_cid, arguments[pos].oidHi);
				pars.add(JafWebAPI.PDMeta.getDelErr.PAR_oidLow, arguments[pos].oidLow);
			}
			pos++;
		}
		else if(arguments.length > pos && (typeof arguments[pos] == 'string') &&
				arguments[pos].match(/[0-9]+:[0-9]+/))
		{
			// zerlegen
			var tmp = arguments[pos].split(':');
			if(tmp.length == 2)
			{
				_cid = parseInt(tmp[0], 10);
				_oid = parseInt(tmp[1], 10);
			}
			pars.add(JafWebAPI.PDMeta.getDelErr.PAR_cid, _cid);
			pars.add(JafWebAPI.PDMeta.getDelErr.PAR_oidLow, _oid);
			pos++;
		}
		if(arguments.length > pos && (arguments[pos] === null || JafWeb.isPDObject(arguments[pos])))
		{
			if(arguments[pos])
			{
				pars.add(JafWebAPI.PDMeta.getDelErr.PAR_delCid, arguments[pos].oidHi);
				pars.add(JafWebAPI.PDMeta.getDelErr.PAR_delOidLow, arguments[pos].oidLow);
			}
			pos++;
		}
		else if(arguments.length > pos && (typeof arguments[pos] == 'string') &&
				arguments[pos].match(/[0-9]+:[0-9]+/))
		{
			// zerlegen
			var tmp = arguments[pos].split(':');
			if(tmp.length == 2)
			{
				_delCid = parseInt(tmp[0], 10);
				_delOid = parseInt(tmp[1], 10);
			}
			pars.add(JafWebAPI.PDMeta.getDelErr.PAR_delCid, _delCid);
			pars.add(JafWebAPI.PDMeta.getDelErr.PAR_delOidLow, _delOid);
			pos++;
		}
		if(arguments.length > pos && typeof arguments[pos] == 'function')
		{
			callbFn = arguments[pos];
			pos++;
		}
		this._lastMsg = '';
		this.retcode = -1;
		var result = "";
		var pdClass = this.PDClass;
		var successFn = function(req, options)
				{
					var resp = new JResponse(req, options, pdClass);
					if(resp.hasFatalError())
						throw "Request got empty or invalid response!";
					if(resp.hasError())
						throw resp.getErrorMessage();
					result = resp.getString(JafWebAPI.PDMeta.getDelErr.PROP_result);
					pdClass.retcode = resp.getInt(JafWebAPI.PROP_retCode, -1);
					if(typeof callbFn == 'function')
						callbFn(result);
				};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callbFn == 'function')
					callbFn(result);
			};
		JafWeb.ajaxRequest({
				url: (this.PDClass || PDClass).getURL(),
				authToken: this.PDClass.getAuthToken(),
				method: "GET",
				async: (!!callbFn),
				params: pars.getPostParams(),
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		if(!callback)
			return result;
	}
	
	/**
	 @function PDMeta#getInstallDir
	 @desc Gibt das "Installationsverzeichnis" zurück.
	 @return {string} Im JafWeb-Client liefert diese Funktion
		 das Ergebnis von [UIApplication.getScriptDir()]{@link UIApplication#getScriptDir}
	 */
	getInstallDir() {
		return UIApplication.getScriptDir();
	}
	
	/**
	 @function PDMeta#getDirSep
	 @desc Gibt den Pfadtrenner auf der aktuellen Plattform zurück.
	 @return {string} Es wird immer "/" zurückgegeben!
	 */
	getDirSep() {
		return '/';
	}
	
	/// Modellierte GUI-Eigenschaften
	/**
	 @function PDMeta#getMandatoryBold
	 @desc Diese Funktion gibt <code>true</code> zurück, wenn die GUI-Label von
		 Muss-Attributen im Normalfall fett dargestellt werden sollen (und damit
		 die Eingabefelder nicht mit gelber Hintergrundfarbe).
	 @return {boolean} Gibt im JafWeb-Client derzeit immer <code>true</code>
		 zurück.
	 */
	getMandatoryBold() {
		return true; // TODO
	}

	// die folgenden Attribute werden beim Laden des Workspace
	// durch ein vom Server angefordertes JavaScript belegt
	_actLang = -1;
	_actLangCode = '';
	_shortLang = null;
	_languages = null;
	_numLanguages = -1;
	_defaultLang = '';
	
	_strings = null;
	_numStrings = -1;
	_stringsIdent = null;
	_stringsIndex = null;
	_stringIdentMap = null;
	_errorMessages = null;
	_numErrorMessages = null;
	
	_copyright = null;
	_about = null;
	_version = null;
	_infobarText = null;

	_appName = "";
	_ergAppName = "";
	_classes = null;
	_abstractClass = null;
	_superClass = null;
	_subClasses = null;
	_ergClasses = null;
	
	_multilang2 = false;
	
	_instanceID = -1;
	_instanceCount = -1;
	_clientID = '';
}



/**
 @class ClientInfo
 @desc Browser-seitige Repräsentation der Klasse <code>ClientInfo</code>
	 der JANUS-Laufzeitumgebung.<br/>
	 Wie für die Klassen {@link PDMeta} und {@link PDClass}
	 wird auch für diese automatisch eine
	 globale Instanz erzeugt, die unter ihrem Namen abfragbar ist.
	 Falls mehrere Server-Verbindungen benutzt werden, gibt es pro
	 authentifizierter Verbindung genau ein Objekt dieser Klasse.<br/>
	 Von Bedeutung ist diese Klasse insbesondere im Zusammenhang mit
	 Berechtigungen.
 @author Frank Fiolka
 @since 1.0
 */
class ClientInfoClass {
	_userCid = 0;
	_userOis = 0;
	_princCid = 0;
	_princOid = 0;
	_dateformat = '';
	_timeformat = '';
	_timestampformat = '';
	_floatgroupsign = '';
	_currencygroupsign = '';
	_decimalsign = '';
	_truevalue = '';
	_falsevalue = '';
	_currencies = null;
	_createPermCache = null;
	_deletePermCache = null;
	_iteratePermCache = null;
	_adminPermCache = null;
	
	PDClass = null;
	
	/**
	 @memberof ClientInfo
	 @desc Konstante für den Rückgabewert von
		 [commitTransaction()]{@link ClientInfo#commitTransaction}:
		 Commit erfolgreich ausgeführt.
	 @const {number}
	 */
	COMMIT_OK = 0;
	/**
	 @memberof ClientInfo
	 @desc Konstante für den Rückgabewert von
		 [commitTransaction()]{@link ClientInfo#commitTransaction}:
		 Commit konnte wegen Fehler nicht ausgeführt
		 werden.
	 @const {number}
	 */
	COMMIT_ERRORS = 1;
	/**
	 @memberof ClientInfo
	 @desc Konstante für den Rückgabewert von
		 [commitTransaction()]{@link ClientInfo#commitTransaction}:
		 Commit ausgeführt, aber es gibt
		 Warnungen.
	 @const {number}
	 */
	COMMIT_WARNINGS = 2;
	/**
	 @memberof ClientInfo
	 @desc Konstante für den Rückgabewert von
		 [commitTransaction()]{@link ClientInfo#commitTransaction}:
		 Commit-Versuch ergab Fehler, die aber vom
		 Benutzer nach Rückfrage ignoriert werden
		 können. Beispiel: Überschreiben eines
		 konkurrierend geänderten Objekts.
	 @const {number}
	 */
	COMMIT_NEEDS_CONFIRMATION = 4;
	
	// Flags zur Abfrage von Admin-Rechten - s. getAdminPermission()
	/**
	 @memberof ClientInfo
	 @desc Konstante für Admin-Recht:
		 Der Editor für global erweiterbare Aufzählungen.
	 @see [getAdminPermission()]{@link ClientInfo#getAdminPermission}
	 @const {number}
	 */
	editEnums = 0;
	/**
	 @memberof ClientInfo
	 @desc Konstante für Admin-Recht:
		 Der Editor für Währungen (EURO).
	 @see [getAdminPermission()]{@link ClientInfo#getAdminPermission}
	 @const {number}
	 */
	editCurrencies = 1;
	/**
	 @memberof ClientInfo
	 @desc Konstante für Admin-Recht:
		 Währungen löschen.
	 @see [getAdminPermission()]{@link ClientInfo#getAdminPermission}
	 @const {number}
	 */
	deleteCurrencies = 2;
	/**
	 @memberof ClientInfo
	 @desc Konstante für Admin-Recht:
		 Währungen erzeugen
	 @see [getAdminPermission()]{@link ClientInfo#getAdminPermission}
	 @const {number}
	 */
	createCurrencies = 3;
	/**
	 @memberof ClientInfo
	 @desc Konstante für Admin-Recht:
		 Ändern beliebiger Benutzer und Gruppen.
	 @see [getAdminPermission()]{@link ClientInfo#getAdminPermission}
	 @const {number}
	 */
	editUsers = 4;
	/**
	 @memberof ClientInfo
	 @desc Konstante für Admin-Recht:
		 Anlegen neuer Benutzer.
	 @see [getAdminPermission()]{@link ClientInfo#getAdminPermission}
	 @const {number}
	 */
	createUsers = 5;
	/**
	 @memberof ClientInfo
	 @desc Konstante für Admin-Recht:
		 Löschen von Benutzern.
	 @see [getAdminPermission()]{@link ClientInfo#getAdminPermission}
	 @const {number}
	 */
	deleteUsers = 6;
	/**
	 @memberof ClientInfo
	 @desc Konstante für Admin-Recht:
		 Ändern von Passwörtern für Benutzer.
	 @see [getAdminPermission()]{@link ClientInfo#getAdminPermission}
	 @const {number}
	 */
	changePassword = 7;
	/**
	 @memberof ClientInfo
	 @desc Konstante für Admin-Recht:
		 Anlegen neuer Mandanten.
	 @see [getAdminPermission()]{@link ClientInfo#getAdminPermission}
	 @const {number}
	 */
	createPrincipals = 8;
	/**
	 @memberof ClientInfo
	 @desc Konstante für Admin-Recht:
		 Löschen von Mandanten.
	 @see [getAdminPermission()]{@link ClientInfo#getAdminPermission}
	 @const {number}
	 */
	deletePrincipals = 9;
	/**
	 @memberof ClientInfo
	 @desc Konstante für Admin-Recht:
		 Ändern von Mandanten.
	 @see [getAdminPermission()]{@link ClientInfo#getAdminPermission}
	 @const {number}
	 */
	editPrincipals = 10;
	/**
	 @memberof ClientInfo
	 @desc Konstante für Admin-Recht:
		 Zuordnen von Mandanten zu Benutzern.
	 @see [getAdminPermission()]{@link ClientInfo#getAdminPermission}
	 @const {number}
	 */
	assignPrincipals = 11;
	/**
	 @memberof ClientInfo
	 @desc Konstante für Admin-Recht:
		 Importieren von Daten. 
	 @see [getAdminPermission()]{@link ClientInfo#getAdminPermission}
	 @const {number}
	 */
	importObjects = 12;
	/**
	 @memberof ClientInfo
	 @desc Konstante für Admin-Recht:
		 Exportieren von Daten.
	 @see [getAdminPermission()]{@link ClientInfo#getAdminPermission}
	 @const {number}
	 */
	exportObjects = 13;
	/**
	 @memberof ClientInfo
	 @desc Konstante für Admin-Recht:
		 Single-User-Mode ein- und Ausschalten.
	 @see [getAdminPermission()]{@link ClientInfo#getAdminPermission}
	 @const {number}
	 */
	switchSingleUser = 14;
	/**
	 @memberof ClientInfo
	 @desc Konstante für Admin-Recht:
		 Ergonomische Namen editieren.
	 @see [getAdminPermission()]{@link ClientInfo#getAdminPermission}
	 @const {number}
	 */
	editErgnames = 15;
	/**
	 @memberof ClientInfo
	 @desc Konstante für Admin-Recht:
		 Den Server herunterfahren (nur AS/400).
	 @see [getAdminPermission()]{@link ClientInfo#getAdminPermission}
	 @const {number}
	 */
	shutdownServer = 16;
	/**
	 @memberof ClientInfo
	 @desc Konstante für Admin-Recht:
		 Properties für andere Benutzer zurücksetzen.
	 @see [getAdminPermission()]{@link ClientInfo#getAdminPermission}
	 @const {number}
	 */
	resetProperties = 17;
	/**
	@memberof ClientInfo
	 @desc Konstante für Admin-Recht:
	 HTML-Sichten editieren.
	 @see [getAdminPermission()]{@link ClientInfo#getAdminPermission}
	 @const {number}
	 */
	editHtmlView = 18;
	/**
	 @memberof ClientInfo
	 @desc Konstante für Admin-Recht:
		 Berechtigung zum Ändern der Journals.
	 @see [getAdminPermission()]{@link ClientInfo#getAdminPermission}
	 @const {number}
	 */
	editJournal = 19;
	/**
	 @memberof ClientInfo
	 @desc Konstante für Admin-Recht:
		 Journal-Objekte erzeugen.
	 @see [getAdminPermission()]{@link ClientInfo#getAdminPermission}
	 @const {number}
	 */
	createJournal = 20;
	/**
	 @memberof ClientInfo
	 @desc Konstante für Admin-Recht:
		 Journal-Objekte löschen. Normalerweise hat auch der Admin dieses Recht nicht.
	 @see [getAdminPermission()]{@link ClientInfo#getAdminPermission}
	 @const {number}
	 */
	deleteJournal = 21;
	/**
	 @memberof ClientInfo
	 @desc Konstante für Admin-Recht:
		 Feiertags-Definitionen verändern.
	 @see [getAdminPermission()]{@link ClientInfo#getAdminPermission}
	 @const {number}
	 */
	editHoliday = 22;
	/**
	 @memberof ClientInfo
	 @desc Konstante für Admin-Recht:
		 Feiertags-Objekte erzeugen.
	 @see [getAdminPermission()]{@link ClientInfo#getAdminPermission}
	 @const {number}
	 */
	createHoliday = 23;
	/**
	 @memberof ClientInfo
	 @desc Konstante für Admin-Recht:
		 Feiertags-Objekte löschen.
	 @see [getAdminPermission()]{@link ClientInfo#getAdminPermission}
	 @const {number}
	 */
	deleteHoliday = 24;
	/**
	 @memberof ClientInfo
	 @desc Konstante für Admin-Recht:
		 Darf der Benutzer sich mit einem leeren Mandantennamen anmelden?
	 @see [getAdminPermission()]{@link ClientInfo#getAdminPermission}
	 @const {number}
	 */
	emptyPrincipal = 25;
	/**
	 @memberof ClientInfo
	 @desc Konstante für Admin-Recht:
		 Darf der Benutzer sich für jeden (nicht leeren) Mandanten anmelden?
	 @see [getAdminPermission()]{@link ClientInfo#getAdminPermission}
	 @const {number}
	 */
	anyPrincipal = 26;
	/**
	 @memberof ClientInfo
	 @desc Konstante für Admin-Recht:
		 Allgemeine administative Tätigkeiten.
	 @see [getAdminPermission()]{@link ClientInfo#getAdminPermission}
	 @const {number}
	 */
	generic_ = 27;
	/**
	 @memberof ClientInfo
	 @desc Konstante für Admin-Recht:
		 Darf der Benutzer entscheiden, ob und wann ein automatisches Update
		 der Software durchgeführt wird?
	 @see [getAdminPermission()]{@link ClientInfo#getAdminPermission}
	 @const {number}
	 */
	onlineUpdate = 28;
	/**
	 @memberof ClientInfo
	 @desc Konstante für Admin-Recht:
		 Journal-Einträge aus dem Online-Update verändern.
	 @see [getAdminPermission()]{@link ClientInfo#getAdminPermission}
	 @const {number}
	 */
	editUpdateJournal = 29;
	/**
	 @memberof ClientInfo
	 @desc Konstante für Admin-Recht:
		 Journal-Einträge aus dem Online-Update erzeugen.
	 @see [getAdminPermission()]{@link ClientInfo#getAdminPermission}
	 @const {number}
	 */
	createUpdateJournal = 30;
	/**
	 @memberof ClientInfo
	 @desc Konstante für Admin-Recht:
		 Journal-Einträge aus dem Online-Update löschen.
	 @see [getAdminPermission()]{@link ClientInfo#getAdminPermission}
	 @const {number}
	 */
	deleteUpdateJournal = 31;
	/**
	 @memberof ClientInfo
	 @desc Konstante für Admin-Recht:
		 Darf der Benutzer den Server neu starten? Ist aus technischen Gründen
		 nur möglich, wenn das UpdateTool installiert ist.
	 @see [getAdminPermission()]{@link ClientInfo#getAdminPermission}
	 @const {number}
	 */
	restartServer = 32;
	/**
	 @memberof ClientInfo
	 @desc Konstante für Admin-Recht:
		 Darf der Benutzer vom Client aus Ini-Dateien verändern?
	 @see [getAdminPermission()]{@link ClientInfo#getAdminPermission}
	 @const {number}
	 */
	editServerIni = 33;
	/**
	 @memberof ClientInfo
	 @desc Konstante für Admin-Recht:
		 Darf der Benutzer vom Client aus Ini-Dateien einsehen?
	 @see [getAdminPermission()]{@link ClientInfo#getAdminPermission}
	 @const {number}
	 */
	viewServerIni = 34;
	
	/*
	 @constructs ClientInfo
	 @desc Dieser Konstruktor sollte niemals aufgerufen werden.
	 Eine Instanz dieser Klasse steht unter deren Namen global
	 zur Verfügung.
	 */
	constructor(pdClass) {
		this._userCid = 0;
		this._userOis = 0;
		this._princCid = 0;
		this._princOid = 0;
		this.PDClass = (pdClass || null);
		this._currencies = null;
		this._createPermCache = {};
		this._deletePermCache = {};
		this._iteratePermCache = {};
		this._adminPermCache = {};
	}
	
	/*
	 @memberof ClientInfo
	 @desc Datums- und Zeitformate laden.
	 */
	loadFormats() {
		var pars = new JParamPacker("ClientInfo.load", this.PDClass);
		var self = this;
		var successFn = function(req, options) {
				var resp = new JResponse(req, options, this.PDClass);
				if(!resp.hasFatalError() && !resp.hasError())
				{
					self._dateformat = resp.getString(JafWebAPI.ClientInfo.load.PROP__dateformat);
					self._timeformat = resp.getString(JafWebAPI.ClientInfo.load.PROP__timeformat);
					self._timestampformat = resp.getString(JafWebAPI.ClientInfo.load.PROP__timestampformat);
					self._floatgroupsign = resp.getString(JafWebAPI.ClientInfo.load.PROP__floatgroupsign);
					self._currencygroupsign = resp.getString(JafWebAPI.ClientInfo.load.PROP__currencygroupsign);
					self._decimalsign = resp.getString(JafWebAPI.ClientInfo.load.PROP__decimalsign);
					self._truevalue = resp.getString(JafWebAPI.ClientInfo.load.PROP__truevalue);
					self._falsevalue = resp.getString(JafWebAPI.ClientInfo.load.PROP__falsevalue);
				}
			};
		// synchroner Request!
		var req = JafWeb.ajaxRequest({
				url: (this.PDClass || PDClass).getURL(),
				authToken: this.PDClass.getAuthToken(),
				method: "GET",
				async: false,
				params: pars.get(),
				scope: this,
				disableCaching: true,
				success: successFn,
					failure: function() { }
				} );
	}
	
	// Flags fuer Berechtigungen
	/**
	 @memberof ClientInfo
	 @desc Attribut darf gelesen werden.
	 @const {number}
	 */
	AttrRead = 0x01;
	/**
	 @memberof ClientInfo
	 @desc Attribut darf geschrieben werden.
	 @const {number}
	 */
	AttrWrite = 0x02;
	/**
	 @memberof ClientInfo
	 @desc Attribut darf geschrieben werden, wenn es
		 sich um eine Neuanlage handelt.
	 @const {number}
	 */
	AttrNewWrite = 0x04;
	/**
	 @memberof ClientInfo
	 @desc Die Operation darf aufgerufen werden.
	 @const {number}
	 */
	OpCall = 0x08;
	/**
	 @memberof ClientInfo
	 @desc Über die Beziehung darf navigiert werden.
	 @const {number}
	 */
	RelTraverse = 0x01;
	/**
	 @memberof ClientInfo
	 @desc Aus der Beziehung dürfen Objekte entfernt (nicht:
		 gelöscht) werden.
	 @const {number}
	 */
	RelRemove = 0x02;
	/**
	 @memberof ClientInfo
	 @desc In die Beziehung dürfen Objekte eingefügt werden.
	 @const {number}
	 */
	RelInsert = 0x04;
	/**
	 @memberof ClientInfo
	 @desc Über die Beziehung dürfen Objekte neu angelegt und
		 in die Beziehung eingefügt werden.
	 @const {number}
	 */
	RelNewInsert = 0x08;
	/**
	 @memberof ClientInfo
	 @desc Die Objekte in der Beziehung dürfen neu angeordnet
		 werden.
	 @const {number}
	 */
	RelOrder = 0x10;
	/**
	 @memberof ClientInfo
	 @desc Aus der Beziehung dürfen Objekte gelöscht werden.<br/>
		 <span class="important">Hinweis:</span> Beachten Sie bitte,
		 dass dieses Flag keine direkte Entsprechung
		 auf der Server-Seite besitzt - dort, bzw. im GUI Client, wird
		 beim Löschen aus Beziehungen immer zusätzlich zum Flag
		 <code>RelRemove</code> die Berechtigung für das Löschen aus der
		 Klasse mit <code>getDelPermission()</code> abgefragt. Um im
		 JafWeb-Client Requests einzusparen, wird diese zusätzliche Abfrage
		 beim Aufruf von [getRelPermission()]{@link ClientInfo#getRelPermission}
		 und [getElementPermissions()]{@link ClientInfo#getElementPermissions}
		 vom <code>WebProxy</code> auf der Server-Seite vorgenommen und ggf.
		 mit diesem Flag in die Beziehungsrechte eingefügt.
	 @const {number}
	 */
	RelDelete = 0x20;
	
	// Wg. einhetil. API fuer Wizard-Skripte benoetigt:
	/**
	 @memberof ClientInfo
	 @desc Ist hier immer <code>false</code>.
	 @member {boolean}
	 */
	isGUIClient = false;
	/**
	 @memberof ClientInfo
	 @desc Ist hier immer <code>true</code>.
	 @member {boolean}
	 */
	isWebClient = true;
	/**
	 @memberof ClientInfo
	 @desc Ist hier immer <code>true</code>.
	 @member {boolean}
	 */
	isJafWebClient = true;
	
	/**
	 @function ClientInfo#getWindowsClient
	 @desc Fragt ab, ob es sich um einen GUI-Client unter
		 Windows handelt.
	 @return {boolean} Gibt hier immer <code>false</code> zurück.
	 */
	getWindowsClient() {
		return false;
	}

	/**
	 @function ClientInfo#getDecimal
	 @desc Das als Dezimaltrenner festgelegte Zeichen zurückgeben.
	 @return {string} Das Trennzeichen.
	*/
	getDecimal() {
		if(!this._decimalsign)
			this.loadFormats();
		return this._decimalsign;
	}
	
	/**
	 @function ClientInfo#getFloatGroup
	 @desc Das Zeichen zurückgeben, das als Tausendertrenner
		 verwendet wird.
	 @return {string} Das Trennzeichen.
	*/
	getFloatGroup() {
		if(!this._decimalsign) // _currencygroupsign kann auch leer sein!
			this.loadFormats();
		return this._currencygroupsign;
	}
	
	/**
	 @function ClientInfo#getFloatGroup
	 @desc Gibt das aktuell eingestellte Tausendertrennzeichen
		 für Währungsattribute zurück.
	 @return {string} Einzelnes Trennzeichen, z.B. ".",
		 oder Leerstring.
	*/
	getCurrencygroupSign() {
		if(!this._decimalsign) // _currencygroupsign kann auch leer sein!
			this.loadFormats();
		return this._currencygroupsign;
	}

	/**
	 @memberof ClientInfo
	 @function getDateFormat
	 @desc Gibt das aktuell eingestellte Datumsformat zurück.
		 Alle Datumsattribute sind nach diesem formatiert.
	 @return {string} Format-String, z.B. "%d.%m.%Y"
	 */
	getDateFormat() {
		if(!this._dateformat)
			this.loadFormats();
		return this._dateformat;
	}
	
	/**
	 @memberof ClientInfo
	 @function getTimestampFormat
	 @desc Gibt das aktuell eingestellte Format für Zeitstempel
		 zurück. Alle Zeitstempel sind nach diesem formatiert.
	 @return {string} Format-String, z.B. "%d.%m.%Y %h:%M:%s"
	 */
	getTimestampFormat() {
		if(!this._timestampformat)
			this.loadFormats();
		return this._timestampformat;
	}
	
	/**
	 @memberof ClientInfo
	 @function getShortTimestampFormat
	 @desc Gibt wie [getTimestampFormat()]{@link ClientInfo#getTimestampFormat} das
		 aktuell eingestellte Zeitstempelformat zurück, jedoch bevorzugt in
		 der um die Sekunden gekürzten Form.
	 @return {string} Format-String, z.B. "%d.%m.%Y %h:%M"
	 */
	getShortTimestampFormat() {
		const tf = this.getTimestampFormat();
		if(tf.endsWith(':%s'))
			return tf.substring(0, tf.length - 3);
		return tf;
	}

	/**
	 @memberof ClientInfo
	 @function getTimeFormat
	 @desc Gibt das aktuell eingestellte Zeitformat
		 zurück. Alle Zeit-Attribute sind nach diesem formatiert.
	 @return {string} Format-String, z.B. "%h:%M:%s"
	 */
	getTimeFormat() {
		if(!this._timeformat)
			this.loadFormats();
		return this._timeformat;
	}
	
	/**
	 @memberof ClientInfo
	 @function getShortTimeFormat
	 @desc Gibt wie [getTimeFormat()]{@link ClientInfo#getTimeFormat} das
		 aktuell eingestellte Zeitformat zurück, jedoch bevorzugt in
		 der um die Sekunden gekürzten Form.
	 @return {string} Format-String, z.B. "%h:%M"
	 */
	getShortTimeFormat() {
		var tf = this.getTimeFormat();
		var tmp = tf.split(':');
		if(tmp.length == 3)
			tf = tmp[0] + ':' + tmp[1];
		return tf;
	}

	/**
	 @memberof ClientInfo
	 @function getTrueValue
	 @desc Gibt den ergonomischen Bezeichner für den booleschen
		 Wert true zurück.
	 @return {string} Z.B. "true".
	 */
	getTrueValue() {
		if(!this._truevalue)
			this.loadFormats();
		return this._truevalue;
	}

	/**
	 @function ClientInfo#getFalseValue
	 @desc Gibt den ergonomischen Bezeichner für den booleschen
		 Wert false zurück.
	 @return {string} Z.B. "false".
	 */
	getFalseValue() {
		if(!this._falsevalue)
			this.loadFormats();
		return this._falsevalue;
	}

	/**
	 @function ClientInfo#getDefaultCurrencies
	 @desc Gibt ein Array mit Namen (gerade Stellen) und
		 Umrechnungswerten (ungerade Stellen) der 
		 Standardwährungen zurück.
	 @return Array mit folgenden Elementen:
<pre>
"EUR", 1.0,
"DM", 1.95583,
"ITL", 1936.27,
"FRF", 6.55957,
"BEF", 40.3399,
"ESP", 166.386,
"IEP", 0.787564,
"LUF", 40.3399,
"NLG", 2.20371,
"ATS", 13.7603,
"PTE", 200.482,
"FIM", 5.94573</pre>
	 */
	getDefaultCurrencies() {
		return new Array(
				"EUR", 1.0,
				"DM", 1.95583,
				"ITL", 1936.27,
				"FRF", 6.55957,
				"BEF", 40.3399,
				"ESP", 166.386,
				"IEP", 0.787564,
				"LUF", 40.3399,
				"NLG", 2.20371,
				"ATS", 13.7603,
				"PTE", 200.482,
				"FIM", 5.94573);
	}
	
	/**
	 @function ClientInfo#getCurrencies
	 @desc Gibt ein assoziatives Array mit Namen und Umrechnungsfaktoren
		 der Währungen zurück.
	 @return {Object} Objekt mit Properties aus Währungsname und Umrechnungsfaktor.
	 */
	getCurrencies() {
		if(this._currencies)
			return this._currencies;
		var defCurrs = this.getDefaultCurrencies();
		var tmp = new Object();
		for(var i=0; i < defCurrs.length - 1; i+=2)
			tmp[defCurrs[i]] = defCurrs[i+1];
		return tmp;
	}
	
	/**
	 @function ClientInfo#setCurrencies
	 @desc Legt die in der Anwendungsoberfläche unterstützten
		 Währungen fest.<br/>
		 <h4>Beispiel</h4>
		 <pre class="prettyprint></code>UIApplication.setCurrencies({
		"EUR": 1.0,
		"TEUR": 0.001
	});</code></pre>
	 @param {Object} currencies Objekt mit den Währungsbezeichnungen
		 als Properties und den zugehörigen Umrechnungsfaktoren als
		 Werte.
	 */
	setCurrencies(currencies) {
		this._currencies = currencies;
	}
	
	/**
	 @function ClientInfo#getCurrencyPrecision
	 @desc Gibt ein assoziatives Array mit Namen und Anzahl
		 Nachkommastellen der Währungen zurück.
	 @return {Object} Objekt mit Properties aus Währungsname
		 und Anzahl Nachkommastellen.
	 */
	getCurrencyPrecisions() {
		// TODO: via this.PDMeta vom Server holen
		return {
					"EUR": 2,
					"DM": 2,
					"ITL": 0,
					"FRF": 2,
					"BEF": 2,
					"ESP": 0,
					"IEP": 2,
					"LUF": 2,
					"NLG": 2,
					"ATS": 2,
					"PTE": 0,
					"FIM": 2
				};
	}
	
	/**
	 @function ClientInfo#getDefaultCurrency
	 @desc Gibt die Default-Währung zurück, die anwendungsweit als Basis für
		 Währungsumrechnungen verwendet wird.
	 @return {string} Der Währungsname.
	 */
	getDefaultCurrency() {
		if(!this.PDMeta)
			return "EUR";
		// TODO: via this.PDMeta vom Server holen
		return "EUR";
	}
	
	/**
	 @function ClientInfo#getMonthNames
	 @desc Gibt ein Array mit den Monatsnamen in der
		 angegebenen Anwendungssprache zurück. Diese
		 Funktion wird vom <code>UIDateField</code>
		 benutzt.
	 @param {boolean} shortForm <code>true</code>, wenn
		 die Namen in Kurzform zurückgegeben werden
		 sollen ("Jan" statt "Januar").
	 @param {string} lang Kürzel - oder Index (int) - der 
		 Anwendungssprache, in der die Namen zurückgegeben
		 werden sollen. Wenn der Parameter fehlt, wird
		 die aktive Anwendungssprache benutzt.
	 @return {string[]} Array von String mit den 
		 Monatsnamen. 
	 */
	getMonthnames(shortForm, lang) {
		if(this.PDMeta)
		{
			if(shortForm)
				return [
						(PDMeta.getString('SC::JWMonthJan') || 'Jan'),
						(PDMeta.getString('SC::JWMonthFeb') || 'Feb'),
						(PDMeta.getString('SC::JWMonthMar') || 'Mar'),
						(PDMeta.getString('SC::JWMonthApr') || 'Apr'),
						(PDMeta.getString('SC::JWMonthMay') || 'May'),
						(PDMeta.getString('SC::JWMonthJun') || 'Jun'),
						(PDMeta.getString('SC::JWMonthJul') || 'Jul'),
						(PDMeta.getString('SC::JWMonthAug') || 'Aug'),
						(PDMeta.getString('SC::JWMonthSep') || 'Sep'),
						(PDMeta.getString('SC::JWMonthOct') || 'Oct'),
						(PDMeta.getString('SC::JWMonthNov') || 'Nov'),
						(PDMeta.getString('SC::JWMonthDec') || 'Dec')
					];
			else
				return [
						(PDMeta.getString('SC::JWMonthAbbrJan') || 'January'),
						(PDMeta.getString('SC::JWMonthAbbrFeb') || 'February'),
						(PDMeta.getString('SC::JWMonthAbbrMar') || 'March'),
						(PDMeta.getString('SC::JWMonthAbbrApr') || 'April'),
						(PDMeta.getString('SC::JWMonthAbbrMay') || 'May'),
						(PDMeta.getString('SC::JWMonthAbbrJun') || 'June'),
						(PDMeta.getString('SC::JWMonthAbbrJul') || 'July'),
						(PDMeta.getString('SC::JWMonthAbbrAug') || 'August'),
						(PDMeta.getString('SC::JWMonthAbbrSep') || 'September'),
						(PDMeta.getString('SC::JWMonthAbbrOct') || 'October'),
						(PDMeta.getString('SC::JWMonthAbbrNov') || 'November'),
						(PDMeta.getString('SC::JWMonthAbbrDec') || 'December')
					];
		}
		if(!lang && this.PDMeta)
			lang = this.PDMeta.getLang();
		if(typeof lang == "number" && this.PDMeta)
			lang = this.PDMeta.getLangCode(lang);
		switch(lang)
		{
		case "de":
		case "de-DE":
			if(shortForm==true)
				return new Array('Jan','Feb','Mär','Apr','Mai','Jun',
					'Jul','Aug','Sep','Okt','Nov','Dez');
			return new Array('Januar','Februar','März','April','Mai','Juni',
				'Juli','August','September','Oktober','November','Dezember');
			break;
		case "fr":
		case "fr-FR":
			if(shortForm==true)
				return new Array('Jan','Feb','Mar','Apr','May','Jun',
					'Jul','Aug','Sep','Oct','Nov','Dec');
			return new Array('Janvier','F´evrier','Mars','Avril','Mai','Juin',
				'Juillet','Ao^ut','Septembre','Octobre','Novembre','D´ecembre');
			break;
		default: // uk
			if(shortForm==true)
				return new Array('Jan','Feb','Mar','Apr','May','Jun',
					'Jul','Aug','Sep','Oct','Nov','Dec');
			return new Array('January','February','March','April','May','June',
				'July','August','September','October','November','December');
		}
	}

	/**
	 @function ClientInfo#getWeekdays
	 @desc Gibt ein Array mit den Namen der Wochentage
		 in der angegebenen Anwendungssprache zurück. Diese
		 Funktion wird vom <code>UIDateField</code>
		 benutzt.
	 @param {boolean} shortForm <code>true</code>, wenn
		 die Namen in Kurzform zurückgegeben werden
		 sollen ("Mo" statt "Montag").
	 @param {string} [lang] Kürzel - oder Index (int) - der 
		 Anwendungssprache, in der die Namen zurückgegeben
		 werden sollen. Wenn der Parameter fehlt, wird
		 die aktive Anwendungssprache benutzt.
	 @return {string[]} Array von String mit den 
		 Wochentagsnamen, beginnend bei Montag.
	 */
	getWeekdays(shortForm, lang) {
		if(this.PDMeta)
		{
			if(shortForm)
				return [
						(PDMeta.getString('SC::JWWeekdayMo') || 'Mo'),
						(PDMeta.getString('SC::JWWeekdayTu') || 'Tu'),
						(PDMeta.getString('SC::JWWeekdayWe') || 'We'),
						(PDMeta.getString('SC::JWWeekdayTh') || 'Th'),
						(PDMeta.getString('SC::JWWeekdayFr') || 'Fr'),
						(PDMeta.getString('SC::JWWeekdaySa') || 'Sa'),
						(PDMeta.getString('SC::JWWeekdaySu') || 'Su')
					];
			else
				return [
						(PDMeta.getString('SC::JWWeekdayAbbrMo') || 'Monday'),
						(PDMeta.getString('SC::JWWeekdayAbbrTu') || 'Tuesday'),
						(PDMeta.getString('SC::JWWeekdayAbbrWe') || 'Wednesday'),
						(PDMeta.getString('SC::JWWeekdayAbbrTh') || 'Thursday'),
						(PDMeta.getString('SC::JWWeekdayAbbrFr') || 'Friday'),
						(PDMeta.getString('SC::JWWeekdayAbbrSa') || 'Saturday'),
						(PDMeta.getString('SC::JWWeekdayAbbrSu') || 'Sunday')
					];
		}
		if(!lang && this.PDMeta)
			lang = this.PDMeta.getLang();
		if(typeof lang == "number" && this.PDMeta)
			lang = this.PDMeta.getLangCode(lang);
		switch(lang)
		{
		case "de":
		case "de-DE":
			if(shortForm==true)
				return new Array('Mo','Di','Mi','Do','Fr','Sa','So');
			return new Array('Montag','Dienstag','Mittwoch','Donnerstag',
				'Freitag','Samstag','Sonntag');
			break;
		case "fr":
		case "fr-FR":
			if(shortForm==true)
				return new Array('Lun','Mar','Mer','Jeu','Ven','Sam','Dim');
			return new Array('Lundi','Mardi','Mercredi','Jeudi',
				'Vendredi','Samedi','Dimanche');
			break;
		default: // uk
			if(shortForm==true)
				return new Array('Mo','Tu','We','Th','Fr','Sa','Su');
			return new Array('Monday','Tuesday','Wednesday','Thursday',
				'Friday','Saturday','Sunday');
		}
	}

	/**
	 @function ClientInfo#getLoginName
	 @desc Den Loginnamen des angemeldeten Benutzers ermitteln.
	 @return {string} Der Loginname.
	 */
	getLoginName() {
		return this.PDClass.getUserName();
	}
	
	/**
	 @function ClientInfo#getUserId
	 @desc Die Kennung des angemeldeten Benutzers zurückgeben.
	 @return {number} Die Benutzer-Id. Ist kein Benutzer,
		 sondern der technische admin angemeldet, ist der Rückgabewert
		 <code>0</code>.
	 */
	getUserId() {
		return this.PDClass.getUserId();
	}
	
	/**
	 @function ClientInfo#getPrincipalId
	 @desc Die Kennung des angemeldeten Mandanten zurückgeben.
	 @return {number} Die Mandanten-Id. Ist kein Mandant
		 angemeldet, ist der Rückgabewert <code>0</code>.
	 */
	getPrincipalId() {
		return this.PDClass.getPrincipalId();
	}
	
	/**
	 @function ClientInfo#getPrincipalName
	 @desc Den Loginnamen des aktuellen Mandanten zurückgeben.
	 @return {string} Der Loginname des Mandanten.
	 */
	getPrincipalName() {
		return this.PDClass.getPrincipalName();
	}
	
	/**
	 @function ClientInfo#getPrincipalObject
	 @desc Das Fachkonzeptobjekt zurückgeben, das einen
		 Mandanten repräsentiert. Ein solches
		 Objekt gibt es nur, wenn im OOA-Modell eine Klasse mit
		 dem Stereotyp <code>Principal</code> definiert ist.
	 @param {Mixed} [princ] Optionale Angabe entweder des
		 technischen Namens (String) oder aber der numerischen
		 Kennung des gesuchten Mandanten. Wird dieser Parameter
		 nicht angegeben, so wird das Objekt des aktuell angemeldeten
		 Mandanten zurückgegeben - oder <code>null</code>, wenn keiner
		 angemeldet ist.
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @return {PDObject} Das Mandantenobjekt oder <code>null</code>,
		 wenn keine entspr. Klasse definiert oder kein Mandant
		 angemeldet ist.
	 */
	getPrincipalObject(princ, callback) {
		var _princ = null;
		var callbFn = null;
		var pos = 0;
		if(arguments.length > pos && ((typeof arguments[pos] == 'string') ||
				(typeof arguments[pos] == 'number')))
		{
			_princ = arguments[pos];
			pos++;
		}
		if(arguments.length > pos && (typeof arguments[pos] == 'function'))
		{
			callbFn = arguments[pos];
			pos++;
		}
		// Falls schon einmal geholt, om PDObject-Cache nachsehen
		if(this._princCid && this._princOid && this.PDClass.usePDObjectCache())
		{
			var obj = this.PDClass.PDObjectCache.get(this._princCid, this._princOid);
			if(obj != null)
			{
				if(typeof callback == 'function')
				{
					callback(obj);
					return;
				}
				return obj;
			}
		}
		var pars = new JParamPacker(JafWebAPI.ClientInfo.getPrincipalObject.eventName, this.PDClass);
		if(_princ)
		{
			if(typeof _princ == 'string')
				pars.add(JafWebAPI.ClientInfo.getPrincipalObject.PAR_pname, _princ);
			else if(typeof _princ == 'number')
				pars.add(JafWebAPI.ClientInfo.getPrincipalObject.PAR_pid, _princ);
		}
		var result = null;
		var pdClass = this.PDClass;
		var successFn = function(req, options)
				{
					var resp = new JResponse(req, options, pdClass);
					if(resp.hasFatalError())
						throw "Request got empty or invalid response!";
					if(resp.hasError())
						throw resp.getErrorMessage();
					result = resp.getPDObject(JafWebAPI.ClientInfo.getPrincipalObject.PROP_obj);
					if(result)
					{
						this._princCid = result.cid;
						this._princOid = result.oidLow;
					}
					if(typeof callbFn == 'function')
						callbFn(result);
				};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callbFn == 'function')
					callbFn();
			};
		JafWeb.ajaxRequest({
				url: (this.PDClass || PDClass).getURL(),
				authToken: this.PDClass.getAuthToken(),
				method: "GET",
				async: (!!callbFn),
				params: pars.get(),
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		if(!callbFn)
			return result;
	}
	
	/**
	 @function ClientInfo#getUserObject
	 @desc Das Fachkonzeptobjekt zurückgeben, das den aktuellen Benutzer
		 repräsentiert. Dieses gibt es nur, wenn im OOA-Modell eine
		 Klasse mit dem Stereotyp <code>User</code> definiert wurde.
	 @param {Mixed} [attrs=true] Die zu ermittelnden Attribute - entweder eine Liste der
		 technischen Attributnamen oder ein boolescher Typ, der anzeigt, ob alle Attribute
		 laut Modell (<code>true</code>) oder gar keine Attribute (<code>false</code>)
		 ermittelt werden sollen.
	 @param {Mixed} [rels=false] Die zu ermittelnden Beziehungen. Wenn ein boolescher Typ
		 angegeben wird, zeigt <code>false</code> an, dass keine Beziehungen geholt werden
		 sollen, <code>true</code> dagegen, dass alle Zu-1-Beziehungen laut Modell geholt
		 werden sollen. Dabei wird für jede Zu-1-Beziehung das ggf. verknüpfte {@link PDObject}
		 mit lediglich dem im Modell spezifizierten Object Ident, jedoch ohne die übrigen
		 Attribute geholt. Statt des booleschen Typs kann eine Liste der zu ermittelnden
		 Beziehungen angegeben werden. Darin kann jedem technischen Beziehungsnamen, getrennt
		 durch Komma, der Template-Ausdruck für die RelationInfo angehängt werden. Das ist
		 wichtig, um spätere, vereinzelte [getStatus()]{@link PDObject#getStatus}-Aufrufe
		 zu vermeiden.
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @return {PDObject} Das Benutzerobjekt oder <code>null</code>,
		 wenn keine entspr. Klasse definiert oder kein Benutzer, sondern
		 der technische <code>admin</code> angemeldet ist.
	 */
	getUserObject(attrs, rels, callback) {
		var pos = 0;
		var callbFn = null;
		var pars = new JParamPacker(JafWebAPI.ClientInfo.getUserObject.eventName, this.PDClass);
		if(attrs)
		{
			if(typeof attrs == 'boolean')
				pars.add(JafWebAPI.ClientInfo.getUserObject.PAR_allAttrs, attrs);
			else if(JafWeb.isArray(attrs))
			{
				for(var i=0; i < attrs.length; i++)
					pars.add(JafWebAPI.ClientInfo.getUserObject.PAR_attrs + i, attrs[i]);
			}
			pos++;
		}
		if(rels)
		{
			if(typeof rels == 'boolean')
				pars.add(JafWebAPI.ClientInfo.getUserObject.PAR_allTo1Rels, rels);
			else if(JafWeb.isArray(rels))
			{
				for(var i=0; i < rels.length; i++)
					pars.add(JafWebAPI.ClientInfo.getUserObject.PAR_rels + i, rels[i]);
			}
			pos++;
		}
		if(pos < arguments.length && typeof arguments[pos] == 'function')
		{
			callbFn = arguments[pos];
			pos++;
		}
		// Falls schon einmal geholt, im PDObject-Cache nachsehen
		if(this._userCid && this._userOid && this.PDClass.usePDObjectCache())
		{
			var obj = this.PDClass.PDObjectCache.get(this._userCid, this._userOid);
			if(obj != null)
			{
				if(typeof callbFn == 'function')
				{
					callbFn(obj);
					return;
				}
				return obj;
			}
		}
		var result = null;
		var pdClass = this.PDClass;
		var ci = this;
		var successFn = function(req, options)
				{
					var resp = new JResponse(req, options, pdClass);
					if(resp.hasFatalError())
						throw "Request got empty or invalid response!";
					if(resp.hasError())
						throw resp.getErrorMessage();
					result = resp.getPDObject(JafWebAPI.ClientInfo.getUserObject.PROP_obj);
					if(result)
					{
						ci._userCid = result.cid;
						ci._userOid = result.oidLow;
					}
					if(typeof callbFn == 'function')
						callbFn(result);
				};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callbFn == 'function')
					callbFn();
			};
		JafWeb.ajaxRequest({
				url: (this.PDClass || PDClass).getURL(),
				authToken: this.PDClass.getAuthToken(),
				method: "GET",
				async: (!!callbFn),
				params: pars.get(),
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		if(!callbFn)
			return result;
	}
	
	// Dynamische Berechtigungen ermitteln
	/**
	 @function ClientInfo#checkRelPermission
	 @desc Prüfen, ob ein bestimmtes Beziehungsrecht gegeben ist.
	 @param {string} clName Name der Fachkonzeptklasse.
	 @param {string} relName Name der Beziehung.
	 @param {number} what Welches Recht soll ermittelt werden?
		 Erwartet wird einer dier folgenden Werte:
		 <ul>
			<li>[ClientInfo.RelTraverse]{@link ClientInfo#RelTraverse}</li>
			<li>[ClientInfo.RelRemove]{@link ClientInfo#RelRemove}</li>
			<li>[ClientInfo.RelDelete]{@link ClientInfo#RelDelete}</li>
			<li>[ClientInfo.RelInsert]{@link ClientInfo#RelInsert}</li>
			<li>[ClientInfo.RelNewInsert]{@link ClientInfo#RelNewInsert}</li>
			<li>[ClientInfo.RelOrder]{@link ClientInfo#RelOrder}</li>
		 </ul>
	 @param {PDObject} obj Das <code>this</code>-Objekt.
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @return {number} Ein oder mehrere, miteinander "ver-oderte"
	 */
	checkRelPermission(clName, relName, what, obj, callback) {
		if(callback) {
			this.getRelPermission(clName, relName, obj, function(perm) {
					// RelNew auf RelNewInsert umleiten!
					if (what == ClientInfo.RelInsert && !(perm & what) && obj && obj.isNewTrans() &&
							(perm & ClientInfo.RelNewInsert))
						return true;
					callback((perm & what) ? true : false);
				});
			return;
		}
		const perm = this.getRelPermission(clName, relName, obj);
		// RelNew auf RelNewInsert umleiten!
		if (what == ClientInfo.RelInsert && !(perm & what) && obj && obj.isNewTrans() &&
				(perm & ClientInfo.RelNewInsert))
			return true;
		return (perm & what) ? true : false;
	}
	
	/**
	 @function ClientInfo#getElementPermissions
	 @desc Die Rechte für mehrere Klassenelemente in einem Aufruf
		 ermitteln. Je nachdem, um was für ein Element es sich handelt -
		 Attribut, Beziehung oder Methode - wird das Recht
		 über [getAttrPermission()]{@link ClientInfo#getAttrPermission()},
		 [getRelPermission()]{@link ClientInfo#getRelPermission()} oder
		 [getExecPermission()]{@link ClientInfo#getExecPermission()} ermittelt.
		 Zu den jeweils dazu möglichen Flags vgl. dort.
	 @param {string} clname Name der Fachkonzeptklasse.
	 @param {PDObject} obj Das <code>this</code>-Objekt. Wenn nur
	  	 statische Elemente abgefragt werden sollen, kann dieser
	 	 Parameter weggelassen werden.
	 @param {string[]} attrs Attribute. Enthält die Liste nur ein leeres
		 Element, werden die Rechte für alle Attribute der Klasse ermittelt.
	 @param {string[]} rels Beziehungen. Enthält die Liste nur ein leeres
		 Element, werden die Rechte für alle Beziehungen der Klasse ermittelt.
	 @param {string[]} ops Operationen. Enthält die Liste nur ein leeres
		 Element, werden die Rechte für alle Operationen der Klasse ermittelt.
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @return {Object} Ein JavaScript-Objekt mit einem Properties für
		 jedes in <code>attrs</code>, <code>rels</code> und <code>ops</code>
		 übergebene Element. Diese Properties haben jeweils numerische
		 Werte, deren Werte von der Art des Elements abhängen. Vgl. dazu
		 die Rückgabewerte von [getAttrPermission()]{@link ClientInfo#getAttrPermission},
		 [getRelPermission()]{@link ClientInfo#getRelPermission} bzw.
		 [getExecPermission()]{@link ClientInfo#getExecPermission}.
	 */
	getElementPermissions(clname, obj, attrs, rels, ops, callback) {
		/*console.log("### ClientInfo.getElementPermissions('" + clname + "', obj, " +
			(attrs ? "[" + attrs.join(',') + "]" : "<null>") + ", " +
			(rels ? "[" + rels.join(',') + "]" : "<null>") + ", " +
			(ops ? "[" + ops.join(',') + "]" : "<null>") + ", callback) - obj:", obj);*/
		var pars = new JParamPacker(JafWebAPI.ClientInfo.getElementPermissions.eventName, this.PDClass);
		pars.add(JafWebAPI.ClientInfo.getElementPermissions.PAR_clName, clname);
		var _callb = null;
		var pos = 1;
		if(arguments.length > pos && (JafWeb.isPDObject(arguments[pos]) || arguments[pos] === null))
		{
			if(arguments[pos])
			{
				pars.add(JafWebAPI.ClientInfo.getElementPermissions.PAR_clName, obj.classname);
				pars.add(JafWebAPI.ClientInfo.getElementPermissions.PAR_oidLow, obj.oidLow);
			}
			pos++;
		}
		if(arguments.length > pos)
		{
			// fuer ObjectIdent (leerer Name) immer mit rein
			if(arguments[pos].indexOf('') < 0)
				arguments[pos].push('');
			for(var i=0; i < arguments[pos].length; i++)
				pars.add(JafWebAPI.ClientInfo.getElementPermissions.PAR_attrs + i, arguments[pos][i]);
			pos++;
		}
		if(arguments.length > pos)
		{
			for(var i=0; i < arguments[pos].length; i++)
				pars.add(JafWebAPI.ClientInfo.getElementPermissions.PAR_rels + i, arguments[pos][i]);
			pos++;
		}
		if(arguments.length > pos)
		{
			for(var i=0; i < arguments[pos].length; i++)
				pars.add(JafWebAPI.ClientInfo.getElementPermissions.PAR_ops + i, arguments[pos][i]);
			pos++;
		}
		if(arguments.length > pos && (typeof arguments[pos] == 'function'))
		{
			_callb = arguments[pos];
			pos++;
		}
		var result = null;
		var pdClass = this.PDClass;
		var successFn = function(req, options)
			{
				var resp = new JResponse(req, options, pdClass);
				if(resp.hasFatalError())
					throw "Request got empty or invalid response!";
				if(resp.hasError())
					throw resp.getErrorMessage();
				result = resp.getData();
				if(typeof _callb == 'function')
					_callb(result);
			};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof _callb == 'function')
					_callb();
			};
		JafWeb.ajaxRequest({
				url: (this.PDClass || PDClass).getURL(),
				authToken: this.PDClass.getAuthToken(),
				method: "POST",
				async: (!!_callb),
				params: pars.get(),
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		if(!_callb)
			return result;
	}
	
	/**
	@function ClientInfo#getAttrPermission
	@desc Die möglichen Rechte für Attributzugriffe ermitteln.
	@param {string} clname Name der Fachkonzeptklasse.
	@param {string} attrName Name des Attributs.
	@param {PDObject} obj Das <code>this</code>-Objekt. Bei
	 	statischen Attributen wird dieser Parameter weggelassen.
	@param {Function} callback Optionale Callback-Funktion, die
		nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		Ergebnis wird direkt zurückgegeben.
	@return {number} Ein oder mehrere, miteinander "ver-oderte"
		Flags:
		<ul>
			<li>[ClientInfo.AttrRead]{@link ClientInfo#AttrRead}</li>
			<li>[ClientInfo.AttrWrite]{@link ClientInfo#AttrWrite}</li>
			<li>[ClientInfo.AttrNewWrite]{@link ClientInfo#AttrNewWrite}</li>
			<li>[ClientInfo.OpCall]{@link ClientInfo#OpCall}</li>
		</ul>
	*/
	getAttrPermission(clname, attrName, obj, callback) {
		// uns interessieren bevorzugt die Rechte auf dem echten Objekt
		//if(obj && obj.isTrans() && obj.getRealObject())
		//	obj = obj.getRealObject();
		// wenn ein Objekt angegeben ist und die Rechte bereits darin stehen,
		// koennen wir den Request einsparen
		if(obj)
		{
			var needReq = true;
			var right = undefined;
			if(JafWeb.isArray(attrName))
			{
				right = [];
				needReq = false;
				for(var i = 0; i < attrName.length; i++)
				{
					if(obj.getElementPermissions(attrName[i]) === undefined)
					{
						needReq = true;
						break;
					}
					right.push(obj.getElementPermissions(attrName[i]));
				}
			}
			else
			{
				right = obj.getElementPermissions(attrName);
				needReq = (right === undefined);
			}
			if(!needReq)
			{
				if(callback)
				{
					callback(right);
					return;
				}
				return right;
			}
		}
		var pars = new JParamPacker(JafWebAPI.ClientInfo.getAttrPermission.eventName, this.PDClass);
		pars.add(JafWebAPI.ClientInfo.getAttrPermission.PAR_clName, clname);
		var many = false;
		if(JafWeb.isArray(attrName))
		{
			many = true;
			var cnt = 0;
			for(var a = 0; a < attrName.length; a++)
			{
				pars.add(JafWebAPI.PDObject.getAttributes.PAR_attr + cnt, attrName[a]);
				cnt++;
			}
		}
		else
			pars.add(JafWebAPI.ClientInfo.getAttrPermission.PAR_attr, attrName);
		if(obj)
		{
			pars.add(JafWebAPI.ClientInfo.getAttrPermission.PAR_oidHi, obj.oidHi);
			pars.add(JafWebAPI.ClientInfo.getAttrPermission.PAR_oidLow, obj.oidLow);
		}
		var resultHolder = {};
		var pdClass = this.PDClass;
		var successFn = function(req, options)
			{
				var resp = new JResponse(req, options, pdClass);
				if(resp.hasFatalError())
					throw "Request got empty or invalid response!";
				if(resp.hasError())
					throw resp.getErrorMessage();
				if(many)
				{
					var arrTmp = resp.getArray(JafWebAPI.ClientInfo.getAttrPermission.PROP_res, [], 'number', 0);
					resultHolder.result = [];
					for(var i = 0; i < arrTmp.length; i++)
					{
						var right = (arrTmp[i] || 0);
						resultHolder.result.push(right);
						// im Rechte-Cache des Objekts eintragen
						if(obj)
							obj['__perm_' + attrName[i]] = right;
					}
				}
				else
				{
					resultHolder.result = resp.getInt(JafWebAPI.ClientInfo.getAttrPermission.PROP_res, 0);
					// im Rechte-Cache des Objekts eintragen
					if(obj)
						obj['__perm_' + attrName] = resultHolder.result;
				}
				if(typeof callback == 'function')
					callback(resultHolder.result);
			};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callback == 'function')
					callback();
			};
		JafWeb.ajaxRequest({
				url: (this.PDClass || PDClass).getURL(),
				authToken: this.PDClass.getAuthToken(),
				method: "GET",
				async: (!!callback),
				params: pars.get(),
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		if(!callback)
			return resultHolder.result;
	}
	
	/**
	@function ClientInfo#getRelPermission
	@desc Die möglichen Rechte für Zugriffe auf eine Beziehung
		ermitteln.
	@param {string} clname Name der Fachkonzeptklasse.
	@param {string} relName Name der Beziehung.
	@param {PDObject} obj Das <code>this</code>-Objekt.
	@param {Function} callback Optionale Callback-Funktion, die
		nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		Ergebnis wird direkt zurückgegeben.
	@return {number} Ein oder mehrere, miteinander "ver-oderte"
		Flags:
		<ul>
			<li>[ClientInfo.RelTraverse]{@link ClientInfo#RelTraverse}</li>
			<li>[ClientInfo.RelRemove]{@link ClientInfo#RelRemove}</li>
			<li>[ClientInfo.RelDelete]{@link ClientInfo#RelDelete}</li>
			<li>[ClientInfo.RelInsert]{@link ClientInfo#RelInsert}</li>
			<li>[ClientInfo.RelNewInsert]{@link ClientInfo#RelNewInsert}</li>
			<li>[ClientInfo.RelOrder]{@link ClientInfo#RelOrder}</li>
		</ul>
		<span class="important">Hinweis:</span>Beachten Sie bitte,
		dass im JafWeb-Client das Flag [RelDelete]{@link ClientInfo#RelDelete}
		zurückgegeben werden kann, das so auf der Server-Seite nicht
		bekannt ist. Dieses zeigt an, dass Objekte aus der Beziehung nicht nur
		getrennt, sondern auch gelöscht werden dürfen. Zur Bestimmung dieses
		Flags wird vom <code>WebProxy</code> das Löschrecht auf der Zielklasse
		der Beziehung mit <code>getDelPermission()</code> bestimmt. Sie
		brauchen in eigem Code also nicht zusätzlich zu den Beziehungsrechten
		noch die auf die Zielklasse abzufragen.
	*/
	getRelPermission(clname, relName, obj, callback) {
		// uns interessieren bevorzugt die Rechte auf dem echten Objekt
		//if(obj && obj.isTrans() && obj.getRealObject())
		//	obj = obj.getRealObject();
		// wenn ein Objekt angegeben ist und die Rechte bereits darin stehen,
		// koennen wir den Request einsparen
		if(obj)
		{
			var needReq = true;
			var right = undefined;
			if(JafWeb.isArray(relName))
			{
				right = [];
				needReq = false;
				for(var i = 0; i < relName.length; i++)
				{
					if(obj.getElementPermissions(relName[i]) === undefined)
					{
						needReq = true;
						break;
					}
					right.push(obj.getElementPermissions(relName[i]));
				}
			}
			else
			{
				right = obj.getElementPermissions(relName);
				needReq = (right === undefined);
			}
			if(!needReq)
			{
				if(callback)
				{
					callback(right);
					return;
				}
				return right;
			}
		}
		var pars = new JParamPacker(JafWebAPI.ClientInfo.getRelPermission.eventName, this.PDClass);
		pars.add(JafWebAPI.ClientInfo.getRelPermission.PAR_clName, clname);
		pars.add(JafWebAPI.ClientInfo.getRelPermission.PAR_relname, relName);
		if(obj)
		{
			pars.add(JafWebAPI.ClientInfo.getRelPermission.PAR_oidHi, obj.oidHi);
			pars.add(JafWebAPI.ClientInfo.getRelPermission.PAR_oidLow, obj.oidLow);
		}
		var result = 0;
		var pdClass = this.PDClass;
		var successFn = function(req, options)
			{
				var resp = new JResponse(req, options, pdClass);
				if(resp.hasFatalError())
					throw "Request got empty or invalid response!";
				if(resp.hasError())
					throw resp.getErrorMessage();
				result = resp.getInt(JafWebAPI.ClientInfo.getRelPermission.PROP_res);
				// im Rechte-Cache des Objekts eintragen
				if(obj)
					obj['__perm_' + relName] = result;
				if(typeof callback == 'function')
					callback(result);
			};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callback == 'function')
					callback();
			};
		JafWeb.ajaxRequest({
				url: (this.PDClass || PDClass).getURL(),
				authToken: this.PDClass.getAuthToken(),
				method: "GET",
				async: (!!callback),
				params: pars.get(),
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		if(!callback)
			return result;
	}
	
	/**
	 @function ClientInfo#getExecPermission
	 @desc Die möglichen Rechte zum Ausführen einer Server-Operation ermitteln.
	 @param {string} clname Name der Fachkonzeptklasse.
	 @param {string} opName Name der Operation.
	 @param {PDObject} obj Das <code>this</code>-Objekt. Bei
	 	 Klassenoperationen wird dieser Parameter weggelassen.
	 @param {boolean} [request=false] Um den Rechte-Cache zu umgehen und die
	 	 Aktualisierung der Berechtigung vom Server zu erwzingen, geben Sie
	 	 <code>true</code> an.
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @return {boolean} <code>true</code>, wenn das Recht gegeben ist, sonst
		 <code>false</code>.
	 */
	getExecPermission(clname, opName, obj, request, callback) {
		// uns interessieren bevorzugt die Rechte auf dem echten Objekt
		//if(obj && obj.isTrans() && obj.getRealObject())
		//	obj = obj.getRealObject();
		// wenn ein Objekt angegeben ist und die Rechte bereits darin stehen,
		// koennen wir den Request einsparen
		var needReq = false;
		var thisObj = null;
		var cb = null;
		var pos = 2;
		if(arguments.length > pos && JafWeb.isPDObject(arguments[pos]))
		{
			thisObj = arguments[pos];
			pos++;
		}
		if(arguments.length > pos && (typeof arguments[pos] == 'boolean'))
		{
			needReq = arguments[pos];
			pos++;
		}
		if(arguments.length > pos && (typeof arguments[pos] == 'function'))
		{
			cb = arguments[pos];
			pos++;
		}
		if(!needReq && obj)
		{
			needReq = true;
			var right = undefined;
			if(JafWeb.isArray(opName))
			{
				right = [];
				needReq = false;
				for(var i = 0; i < opName.length; i++)
				{
					if(obj.getElementPermissions(opName[i]) === undefined)
					{
						needReq = true;
						break;
					}
					right.push(obj.getElementPermissions(opName[i]));
				}
			}
			else
			{
				right = obj.getElementPermissions(opName);
				needReq = (right === undefined);
			}
			if(!needReq)
			{
				if(cb)
				{
					cb(right);
					return;
				}
				return right;
			}
		}
		var pars = new JParamPacker(JafWebAPI.ClientInfo.getExecPermission.eventName, this.PDClass);
		pars.add(JafWebAPI.ClientInfo.getExecPermission.PAR_clName, clname);
		pars.add(JafWebAPI.ClientInfo.getExecPermission.PAR_opname, opName);
		if(obj)
		{
			pars.add(JafWebAPI.ClientInfo.getExecPermission.PAR_oidHi, obj.oidHi);
			pars.add(JafWebAPI.ClientInfo.getExecPermission.PAR_oidLow, obj.oidLow);
		}
		var result = false;
		var pdClass = this.PDClass;
		var successFn = function(req, options)
			{
				var resp = new JResponse(req, options, pdClass);
				if(resp.hasFatalError())
					throw "Request got empty or invalid response!";
				if(resp.hasError())
					throw resp.getErrorMessage();
				result = resp.getBool(JafWebAPI.ClientInfo.getExecPermission.PROP_res, false);
				// im Rechte-Cache des Objekts eintragen
				if(obj)
					obj['__perm_' + opName] = result;
				if(cb)
					cb(result);
			};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(cb)
					cb();
			};
		JafWeb.ajaxRequest({
				url: (this.PDClass || PDClass).getURL(),
				authToken: this.PDClass.getAuthToken(),
				method: "GET",
				async: (!!callback),
				params: pars.get(),
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		if(!cb)
			return result;
	}

	/**
	 @function ClientInfo#getCreatePermission
	 @desc Ermittelt, ob der Benutzer ein neues Objekt einer bestimmten
		 Klasse anlegen darf.
	 @param {string} clname Name der Fachkonzeptklasse.
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @return {boolean} <code>true</code>, wenn das Recht gegeben ist, sonst
		 <code>false</code>.
	 */
	getCreatePermission(clname, callback) {
		if(!clname)
			throw "ClientInfo.getCreatePermission() called without classsname parameter!";
		// Cache abfragen
		if(this._createPermCache[clname] !== undefined)
		{
			if(callback)
			{
				callback(this._createPermCache[clname]);
				return;
			}
			return this._createPermCache[clname];
		}
		var pars = new JParamPacker(JafWebAPI.ClientInfo.getCreatePermission.eventName, this.PDClass);
		pars.add(JafWebAPI.ClientInfo.getCreatePermission.PAR_clName, clname);
		var result = false;
		var pdClass = this.PDClass;
		var ci = this;
		var successFn = function(req, options)
			{
				var resp = new JResponse(req, options, pdClass);
				if(resp.hasFatalError())
					throw "Request got empty or invalid response!";
				if(resp.hasError())
					throw resp.getErrorMessage();
				result = resp.getBool(JafWebAPI.ClientInfo.getCreatePermission.PROP_res, false);
				// in Cache eintragen
				ci._createPermCache[clname] = result;
				if(typeof callback == 'function')
					callback(result);
			};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callback == 'function')
					callback();
			};
		JafWeb.ajaxRequest({
				url: (this.PDClass || PDClass).getURL(),
				authToken: this.PDClass.getAuthToken(),
				method: "GET",
				async: (!!callback),
				params: pars.get(),
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		if(!callback)
			return result;
	}
	
	/**
	 @function ClientInfo#getIteratePermission
	 @desc Prüft, ob der Benutzer das Recht hat, über alle
		 Objekte einer Klasse zu iterieren. Diese Berechtigung regelt nur den
		 Zugriff auf den Gesamt-Iterator. Iteratoren für Beziehungen werden
		 bei den Beziehungen selbst reglementiert.<br/>
		 <span class="important">Hinweis:</span> Das JANUS-Laufzeitsystem
		 geht davon aus, dass dieses Recht für einen Benutzer konstant bleibt,
		 so lange der Benutzer angemeldet ist. Dadurch ist es möglich, auf dem
		 Client einen Zwischenspeicher zu verwalten, der wiederholte
		 Anfragen mit demselben Klassennamen ohne Konsultation des Servers
		 beantworten kann.
	 @param {string} clname Name der Fachkonzeptklasse.
	 @param {Function} [callback] Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @return {boolean} Wenn der Benutzer das Recht zum Iterieren hat, wird
		 <code>true</code> zurückgegeben, sonst <code>false</code>.
	 */
	getIteratePermission(clname, callback) {
		if(!clname)
			throw "ClientInfo.getIteratePermission() called without classsname parameter!";
		// Cache abfragen
		if(this._iteratePermCache[clname] !== undefined)
		{
			if(callback)
			{
				callback(this._iteratePermCache[clname]);
				return;
			}
			return this._iteratePermCache[clname];
		}
		var pars = new JParamPacker(JafWebAPI.ClientInfo.getIteratePermission.eventName, this.PDClass);
		pars.add(JafWebAPI.ClientInfo.getIteratePermission.PAR_clName, clname);
		var result = false;
		var pdClass = this.PDClass;
		var ci = this;
		var successFn = function(req, options)
			{
				var resp = new JResponse(req, options, pdClass);
				if(resp.hasFatalError())
					throw "Request got empty or invalid response!";
				if(resp.hasError())
					throw resp.getErrorMessage();
				result = resp.getBool(JafWebAPI.ClientInfo.getIteratePermission.PROP_res, false);
				// in Cache eintragen
				ci._iteratePermCache[clname] = result;
				if(typeof callback == 'function')
					callback(result);
			};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callback == 'function')
					callback();
			};
		JafWeb.ajaxRequest({
				url: (this.PDClass || PDClass).getURL(),
				authToken: this.PDClass.getAuthToken(),
				method: "GET",
				async: (!!callback),
				params: pars.get(),
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		if(!callback)
			return result;
	}
	
	/**
	 @function ClientInfo#getDelPermission
	 @desc Ermittelt, ob der Benutzer ein bestimmtes Objekt löschen darf.
	 @param {string} clname Name der Fachkonzeptklasse.
	 @param {PDObject} obj Das <code>this</code>-Objekt. Fehlt dieser
	 	 Parameter, so wird ein allgemeines Löschrecht für die Klasse
	 	 ermittelt.
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @return {boolean} <code>true</code>, wenn das Recht gegeben ist, sonst
		 <code>false</code>.
	 */
	getDelPermission(clname, obj, callback) {
		if(!clname)
			throw "ClientInfo.getDelPermission() called without classsname parameter!";
		// uns interessieren bevorzugt die Rechte auf dem echten Objekt
		if(obj && obj.isTrans() && obj.getRealObject())
			obj = obj.getRealObject();
		// Cache abfragen
		if(this._deletePermCache[clname] === false || obj)
		{
			var delPerm = (obj ? obj.deleteAllowed() : false);
			if(callback)
			{
				callback(delPerm);
				return;
			}
			return delPerm;
		}
		var pars = new JParamPacker(JafWebAPI.ClientInfo.getDelPermission.eventName, this.PDClass);
		pars.add(JafWebAPI.ClientInfo.getDelPermission.PAR_clName, clname);
		if(obj)
		{
			pars.add(JafWebAPI.ClientInfo.getDelPermission.PAR_oidHi, obj.oidHi);
			pars.add(JafWebAPI.ClientInfo.getDelPermission.PAR_oidLow, obj.oidLow);
		}
		var result = false;
		var pdClass = this.PDClass;
		var ci = this;
		var successFn = function(req, options)
			{
				var resp = new JResponse(req, options, pdClass);
				if(resp.hasFatalError())
					throw "Request got empty or invalid response!";
				if(resp.hasError())
					throw resp.getErrorMessage();
				result = resp.getBool(JafWebAPI.ClientInfo.getDelPermission.PROP_res, false);
				// in Cache eintragen
				if(!obj)
					ci._deletePermCache[clname] = result;
				if(typeof callback == 'function')
					callback(result);
			};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callback == 'function')
					callback();
			};
		JafWeb.ajaxRequest({
				url: (this.PDClass || PDClass).getURL(),
				authToken: this.PDClass.getAuthToken(),
				method: "GET",
				async: (!!callback),
				params: pars.get(),
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		if(!callback)
			return result;
	}

	/**
	 @function ClientInfo#getAdminPermission
	 @desc Ermittelt administrative Rechte.
	 @param {Number} what Das abzufragende Recht.
	 @param {PDObject} [obj] Hier kann ein Objekt übergeben werden,
		 für das das Recht ermittelt werden soll (Mandant oder Anwender).  
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @return {boolean} <code>true</code>, wenn das Recht gegeben ist, sonst
		 <code>false</code>.
	 */
	getAdminPermission(what, obj, callback) {
		if(arguments.length == 0 || (typeof arguments[0] != 'number'))
			throw "ClientInfo.getAdminPermission() called without a parameter!";
		var pars = new JParamPacker(JafWebAPI.ClientInfo.getAdminPermission.eventName, this.PDClass);
		pars.add(JafWebAPI.ClientInfo.getAdminPermission.PAR_what, what);
		var pos = 1;
		var otherObj = false;
		if(arguments.length >= pos && JafWeb.isPDObject(arguments[pos]))
		{
			otherObj = true;
			pars.add(JafWebAPI.ClientInfo.getAdminPermission.PAR_clName, arguments[pos].classname);
			pars.add(JafWebAPI.ClientInfo.getAdminPermission.PAR_oidLow, arguments[pos].oidLow);
			pos++;
		}
		var cb = null;
		if(arguments.length >= pos && (typeof arguments[pos] == 'function'))
		{
			cb = arguments[pos];
			pos++;
		}
		// Cache abfragen
		if(this._adminPermCache['_' + what] !== undefined && !otherObj)
		{
			var adminPerm = this._adminPermCache['_' + what];
			if(callback)
			{
				callback(adminPerm);
				return;
			}
			return adminPerm;
		}
		var result = false;
		var pdClass = this.PDClass;
		var ci = this;
		var successFn = function(req, options)
			{
				var resp = new JResponse(req, options, pdClass);
				if(resp.hasFatalError())
					throw "Request got empty or invalid response!";
				if(resp.hasError())
					throw resp.getErrorMessage();
				result = resp.getBool(JafWebAPI.ClientInfo.getDelPermission.PROP_res, false);
				// in Cache eintragen
				if(!otherObj)
					ci._adminPermCache['_' + what] = result;
				if(typeof callback == 'function')
					callback(result);
			};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callback == 'function')
					callback();
			};
		JafWeb.ajaxRequest({
				url: (this.PDClass || PDClass).getURL(),
				authToken: this.PDClass.getAuthToken(),
				method: "GET",
				async: (!!callback),
				params: pars.get(),
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		if(!callback)
			return result;
	}

	/**
	 @function ClientInfo#setLanguage
	 @desc Die Sprache für die Web-GUI festlegen. Im Unterschied
		 zur in <code>ClientInfo</code> bzw. <code>PDMeta</code>
		 auf der Server-Seite verwalteten Sprache, können hier
		 auch andere, speziell für die JafWeb-GUI definierte
		 Sprachen angegeben werden (vgl. Modell-<i>Property</i>
		 <code>XMultilang2</code>).
	 @param {mixed} lang Das Kürzel (String) oder der numerische
		 Index der zu setzenden Sprache.
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @return {boolean} <code>true</code>, falls die Sprache
		 gesetzt werden konnte.
	 */
	setLanguage(lang, callback) {
		// pruefen, ob ein gueltiges Kuerzel
		var ok = false;
		if(typeof lang == 'number')
		{
			if(lang >= 0 && lang < this.PDClass.PDMeta._shortLang.length)
			{
				ok = true;
				this.PDClass.PDMeta._actLang = lang;
				lang = this.PDClass.PDMeta._shortLang[lang];
			}
		}
		else if(this.PDClass.PDMeta._shortLang && (typeof this.PDClass.PDMeta._shortLang.length == 'number'))
		{
			for(var l=0; l < this.PDClass.PDMeta._shortLang.length; l++)
			{
				if(lang == this.PDClass.PDMeta._shortLang[l])
				{
					ok = true;
					this.PDClass.PDMeta._actLang = l;
					break;
				}
			}
		}
		if(!ok)
			throw "The language code '" + lang + "' is not valid.";
		var pars = new JParamPacker(JafWebAPI.ClientInfo.setLanguage.eventName, this.PDClass);
		pars.add(JafWebAPI.ClientInfo.setLanguage.PAR_lang, lang);
		var result = false;
		var pdClass = this.PDClass;
		var successFn = function(req, options)
				{
					var resp = new JResponse(req, options, pdClass);
					if(resp.hasFatalError())
						throw "Request got empty or invalid response!";
					if(resp.hasError())
						throw resp.getErrorMessage();
					result = (resp.getInt(JafWebAPI.PROP_retCode) == 0);
					// TODO?
					if(pdClass.PDMeta.hasMultilang2Support())
						pdClass.PDMeta.loadLanguage();
					if(typeof callback == 'function')
						callback(result);
				};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callback == 'function')
					callback();
			};
		JafWeb.ajaxRequest({
				url: (this.PDClass || PDClass).getURL(),
				authToken: this.PDClass.getAuthToken(),
				method: "GET",
				async: (!!callback),
				params: pars.get(),
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		if(!callback)
			return result;
	}
	
	/**
	 @function ClientInfo#getLang
	 @desc Gibt den Index der aktuellen Anwendunsgsprache
		 der Web-GUI zurück.
	 @return {number} Der Index der Sprache (0-basiert).
	 */
	getLang() {
		return this.PDClass.PDMeta.getLang();
	}

	/**
	 @function ClientInfo#getLanguage
	 @desc Gibt die Sprache der Web-GUI zurück. Wenn sie nicht
		 eigens mit [setLanguage()]{@link PDMeta#setLanguage} gesetzt wurde,
		 wird die in {@link PDMeta} angegebene Sprache
		 zurückgegeben.
	 @return {string} Das Kürzel der Sprache.
	 */
	getLanguage() {
		return this.PDClass.PDMeta.getLangCode();
	}
	
	/*
	 * Intern
	 * Ermittelt den invalidText fuer die nativen Komponenten.
	 */
	getInvalidText() {
		if(ClientInfo.getLanguage().startsWith('de'))
			return "Der Wert in diesem Feld ist ungültig";
		else
			return "The value in this field is invalid";
	}
	
	/**
	 @function ClientInfo#disconnectClient
	 @desc Die aktuelle Sitzung beenden. Diese Funktion sollte nur
		 benutzt werden, wenn man den Benutzer per
		 [PDClass.changeUser()]{@link PDClass#changeUser} direkt 
		 von der Client-Seite angemeldet hat.
		 Im standardmäßigen JafWeb-Workspace sollte
		 man stattdessen [UIWorkspace.logout()]{@link UIWorkspace#logout}
		 verwenden.
	 @param {Function} [callb] Optionale Callback-Funktion, die
		 nach erfolgter Abmeldung aufgerufen werden soll. Bei
		 deren Aufruf wird ein boolescher Parameter angegeben, der
		 anzeigt, ob die Aktion geklappt hat.
	 */
	disconnectClient(callb) {
		// Abmelden mit setBeacon() bevorzugen - das gibt es genau zu
		// diesem Zweck und arbeitet asynchron, siehe
		// https://developer.mozilla.org/en-US/docs/Web/API/Navigator/sendBeacon
		// Aber es gibt dann keinen Callback!
		const pdMeta = this.PDClass.PDMeta;
		let logoutUrl = this.PDClass.getLogoutEvent();
		if(pdMeta.getInstanceID() >= 0)
			logoutUrl += ((logoutUrl.indexOf('?') >= 0) ? '&' : '?') + "iid=" + pdMeta.getInstanceID();
		if(!callb && navigator.sendBeacon) {
			const url = logoutUrl;
			const pars = new JParamPacker("ClientInfo.disconnectClient", this.PDClass);
			// TODO: authToken: this.PDClass.getAuthToken(),
			navigator.sendBeacon(url, pars.get());
			return;
		}
		const params = {};
		if(pdMeta.getInstanceID() >= 0)
			params.iid = pdMeta.getInstanceID();
		JafWeb.ajaxRequest({
				url: logoutUrl,
				authToken: this.PDClass.getAuthToken(),
				method: 'GET',
				params: params,
				async: false,
				success: function() {
						pdMeta._instanceID = -1;
						if(callb)
							callb(true);
					},
				failure: function() {
						if(callb)
							callb(false);
					}
			});
	}
	
	// Erweitertes Transaktionskonzept
	/**
	 @function ClientInfo#startTransaction
	 @desc Startet eine neue Transaktion, die beliebig viele
		 Transaktionsobjekte verwaltet.<br/>
		 <h4>Beispiel</h4>
		 <pre class="prettyprint"><code>const tid = ClientInfo.startTransaction();
const transObjDevice = PDClass.startTransaction(thisObj.classname, thisObj.oid, tid);
// Weitere Objekttransaktionen...
//...
// gesamte Transaktion speichern:
ClientInfo.commitTransaction(tid);
// oder verwerfen:
ClientInfo.abortTransaction(tid);
// transObjDevice existiert nun nicht mehr!</code></pre>
	 @param {number} [transId=0] Optionale Transaktions-ID. Wird diese
		 angegeben, so wird eine Unteraktion zu der angegebenen Transaktion
		 gestartet, sonst eine Top Level-Transaktion.
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @return {number} Transaktions-ID. Diese muss angegeben
		 werden, wenn auf die Transaktion zugegriffen werden
		 soll. Die Transaktion muss mit [commitTransaction()]{@link ClientInfo#commitTransaction}
		 bestätigt oder mit [abortTransaction()]{@link ClientInfo#abortTransaction} verworfen
		 werden.
	 */
	startTransaction(transId, addParams, callback) {
		//console.log("### ClientInfo.startTransaction()");
		var pars = new JParamPacker(JafWebAPI.ClientInfo.startTransaction.eventName, this.PDClass);
		pars.add(JafWebAPI.ClientInfo.startTransaction.PAR_tid, (transId || 0));
		// benutzerdefinierte Parameter
		if(JafWeb.isArray(addParams))
		{
			for(var i=0; i + 1 < addParams.length; i += 2)
				pars.add(addParams[i], addParams[i + 1]);
		}
		var tid = 0;
		var pdClass = this.PDClass;
		var successFn = function(req, options)
				{
					var resp = new JResponse(req, options, pdClass);
					if(resp.hasFatalError())
						throw "Request got empty or invalid response!";
					if(resp.hasError())
						throw resp.getErrorMessage();
					tid = resp.getInt(JafWebAPI.ClientInfo.startTransaction.PROP_tid, 0);
					if(typeof callback == 'function')
						callback(tid);
				};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callback == 'function')
					callback();
			};
		JafWeb.ajaxRequest({
				url: (this.PDClass || PDClass).getURL(),
				authToken: this.PDClass.getAuthToken(),
				method: "GET",
				async: (!!callback),
				params: pars.get(),
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		if(!callback)
			return tid;
	}
	
	/**
	 @function ClientInfo#commitTransaction
	 @desc Bestätigt die Transaktion. Alle beteiligten Fachkonzeptobjekte
		 werden gespeichert.
		 Wenn kein Fehler aufgetreten ist, ist die Transaktion
		 anschließend beendet.<br/>
		 <span class="important">Hinweis:</span> Diese Funktion führt keine Eingabevalidierung aus!
		 Sie sollte deshalb nur aufgerufen werden, nachdem für jedes
		 beteiligte Fachkonzeptobjekt durch Aufruf von [checkConstraints()]{@link PDObject#checkConstraints}
		 geprüft wurde, ob das voraussichtlich gespeichert werden kann.
	 @param {number} transId Die Transaktions-ID, wie sie von
		 [startTransaction()]{@link ClientInfo#startTransaction} zurückgegeben wurde.
	 @param {PDObject} masterTransObj (optional) Falls die Funktion
		 nach dem Commit das zugehörige echte Objekt zurückgeben muss - z.B.
		 um es im Dialog zu bearbeiten oder weiterzugeben -, muss hier das
		 entsprechende Transaktionsobjekt angegeben werden.
	 @param {string[]} addParams Optionales Array für zusätzliche Parameter.
		 In diesem Array werden paarweise Einträge erwartet, deren erster der
		 Name und deren zweiter der Wert des zusätzlichen Parameters ist.
	 @param {boolean} force Interner Parameter, durch den sich die Funktion
		 selbst erneut aufrufen kann, nachdem der Benutzer eine Rückfrage
		 ("...trotzdem speichern?") beantwortet hat.
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @return {Object} Wenn <code>masterTransObj</code> angegeben wurde, wird
		 ein JavaScript-Objekt mit folgenden Properties zurückgegeben:
		 <ul>
			<li><code>retCode</code> Wenn alles o.k., wird <code>0</code>
				zurückgegeben.</li>
			<li><code>commitRes</code> Ergebnis des Commit-Vorgangs. Einer der
				folgenden Werte:
				<ul>
					<li><code>ClientInfo.COMMIT_OK</code>: Commit erfolgreich, kein Fehler
						aufgetreten.</li>
					<li><code>ClientInfo.COMMIT_ERRORS</code>: Commit wegen nicht behebbarem
						Fehler fehlgeschlagen.</li>
					<li><code>ClientInfo.COMMIT_WARNINGS</code>: Commit ausgeführt, aber mit
						Warnungen (siehe <code>errMsg</code>).</li>
					<li><code>ClientInfo.COMMIT_NEEDS_CONFIRMATION</code>: Commit wegen Fehlern
						nicht ausgeführt, die vom Benutzer ignoriert werden können.
						In diesem Fall steht in <code>errors</code> die
						Rückfrage an den Benutzer. Wird diese bestätigt, ruft sich die Funktion
						mit dem Paramter <code>force</code> erneut auf, um den Commit zuende
						zu führen.</li>
				</ul>
			</li>
			<li><code>errors String[]</code> String-Array mit Fehlermeldungen. Falls
				<code>stopOnError</code> <code>true</code> angegeben wurde, enthält
				das Array nur ein Element.</li>
			<li><code>warnings String[]</code> String-Array mit Meldungen, die nicht
				zum Abbruch führen.</li>
			<li><code>pdobject PDObject</code> Hier steht das gespeicherte und ggf.
				aktualisierte Originalobjekt drin.</li>
		 </ul>
	 */
	commitTransaction(transId, masterTransObj, addParams, force, callback) {
		//console.log("### ClientInfo.commitTransaction("+transId+", "+masterTransObj+")");
		var pars = new JParamPacker(JafWebAPI.ClientInfo.commitTransaction.eventName, this.PDClass);
		pars.add(JafWebAPI.ClientInfo.commitTransaction.PAR_tid, transId);
		if(masterTransObj)
			pars.add(JafWebAPI.ClientInfo.commitTransaction.PAR_oid, masterTransObj.oid);
		// benutzerdefinierte Parameter
		if(JafWeb.isArray(addParams))
		{
			for(var i=0; i + 1 < addParams.length; i += 2)
				pars.add(addParams[i], addParams[i + 1]);
		}
		pars.add(JafWebAPI.ClientInfo.commitTransaction.PAR_force, (true === force));
		var result = { };
		var pdClass = this.PDClass;
		var successFn = function(req, options)
				{
					var resp = new JResponse(req, options, pdClass);
					if(resp.hasFatalError())
						throw "Request got empty or invalid response!";
					//if(resp.hasError())
					//	throw resp.getErrorMessage();
					if(masterTransObj)
					{
						result.retCode = resp.getInt(JafWebAPI.PROP_retCode, -1);
						result.retmsg = (resp.getString(JafWebAPI.ClientInfo.commitTransaction.PROP_retMsg) ||
							resp.getErrorMessage());
						result.commitRes = resp.getInt(JafWebAPI.ClientInfo.commitTransaction.PROP_commitRes, ClientInfo.COMMIT_ERRORS);
						//result.transObj = resp.getPDObject('transObj');
						//result.tid = resp.getInt('tid', -1);
						//if(result.transObj && result.tid >= 0)
						//	result.transObj._tid = result.tid;
						result.pdobject = resp.getPDObject(JafWebAPI.ClientInfo.commitTransaction.PROP_realObj);
						if(result.pdobject && pdClass.usePDObjectCache())
							pdClass.PDObjectCache.add(result.pdobject);
						result.errors = resp.getArray(JafWebAPI.ClientInfo.commitTransaction.PROP_errMsgs, [], 'string', '');
						if(result.retmsg && !result.errors.length)
							result.errors.push(result.retmsg);
						result.warnings = resp.getArray(JafWebAPI.ClientInfo.commitTransaction.PROP_warnMsgs, [], 'string', '');
						//if(!result.pdobject && andCommit && holdTrans)
						//	UIMessage.ok('No PDObject found in result', 'PDObject.checkConstraints()');
					}
					else
						result.retCode = resp.getInt(JafWebAPI.PROP_retCode, -1);
					// Transaktionsobjekte Client-seitig entfernen
					var oids = resp.getArray(JafWebAPI.ClientInfo.commitTransaction.PROP_delOids);
					for(var i=0; i < oids.length; i++) {
						// aus dem PDObjectCache entfernen
						if(pdClass.usePDObjectCache())
							pdClass.PDObjectCache.removeObject(OID_HI(oids[i]), OID_LO(oids[i]));
					}
					// PDObjects im Cache aktualisieren
					if(pdClass.usePDObjectCache()) {
						var updObjs = resp.getArray(JafWebAPI.ClientInfo.commitTransaction.PROP_updObjs);
						for(var i=0; i < updObjs.length; i++)
							pdClass.PDObjectCache.add(updObjs[i]);
					}
					if(typeof callback == 'function')
						callback(result);
				};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callback == 'function')
					callback();
			};
		JafWeb.ajaxRequest({
				url: (this.PDClass || PDClass).getURL(),
				authToken: this.PDClass.getAuthToken(),
				method: "GET",
				async: (!!callback),
				params: pars.get(),
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		if(!callback)
			return result;
	}
	
	/**
	 @function ClientInfo#abortTransaction
	 @desc Verwirft die Transaktion. Es wird nichts gespeichert.
		 Wenn kein Fehler aufgetreten ist, ist die Transaktion
		 anschließend beendet.
	 @param {number} transId Die Transaktions-ID, die von
		 [startTransaction()]{@link ClientInfo#startTransaction} zurückgegeben
		 wurde.
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em>.
	 */
	abortTransaction(transId, addParams, callback) {
		//console.log("### ClientInfo.abortTransaction("+transId+")");
		var pars = new JParamPacker(JafWebAPI.ClientInfo.abortTransaction.eventName, this.PDClass);
		pars.add(JafWebAPI.ClientInfo.abortTransaction.PAR_tid, transId);
		// benutzerdefinierte Parameter
		if(JafWeb.isArray(addParams))
		{
			for(var i=0; i + 1 < addParams.length; i += 2)
				pars.add(addParams[i], addParams[i + 1]);
		}
		var result = 0;
		var pdClass = this.PDClass;
		var successFn = function(req, options)
				{
					var resp = new JResponse(req, options, pdClass);
					if(resp.hasFatalError())
						throw "Request got empty or invalid response!";
					if(resp.hasError())
						throw resp.getErrorMessage();
					result = resp.getInt(JafWebAPI.PROP_retCode, -1);
					// Transaktionsobjekte Client-seitig entfernen
					var oids = resp.getArray(JafWebAPI.ClientInfo.abortTransaction.PROP_oids);
					for(var i=0; i < oids.length; i++)
					{
						// aus dem PDObjectCache entfernen
						if(pdClass.usePDObjectCache())
							pdClass.PDObjectCache.removeObject(OID_HI(oids[i]), OID_LO(oids[i]));
					}
					if(typeof callback == 'function')
						callback(result);
				};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callback == 'function')
					callback();
			};
		JafWeb.ajaxRequest({
				url: (this.PDClass || PDClass).getURL(),
				authToken: this.PDClass.getAuthToken(),
				method: "GET",
				async: (!!callback),
				params: pars.get(),
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		if(!callback)
			return result;
	}

	/**
	 @function ClientInfo#removeFromTransaction
	 @desc Entfernt ein Objekt aus einer laufenden Transaktion.
	 @param {number} transId Die Transaktions-ID, die von
		 [startTransaction()]{@link ClientInfo#startTransaction} zurückgegeben
		 wurde.
	 @param {PDObject} transObj Das zu entfernende
		 Transaktionsobjekt.
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 */
	removeFromTransaction(transId, transObj, addParams, callback) {
		//console.log("### ClientInfo.removeFromTransaction("+transId+", "+transObj+")");
		var pars = new JParamPacker(JafWebAPI.ClientInfo.removeFromTransaction.eventName, this.PDClass);
		pars.add(JafWebAPI.ClientInfo.removeFromTransaction.PAR_tid, transId);
		pars.add(JafWebAPI.ClientInfo.removeFromTransaction.PAR_oid, transObj.GetPDObjectId());
		// benutzerdefinierte Parameter
		if(JafWeb.isArray(addParams))
		{
			for(var i=0; i + 1 < addParams.length; i += 2)
				pars.add(addParams[i], addParams[i + 1]);
		}
		var result = 0;
		var pdClass = this.PDClass;
		var _cid = transObj.cid;
		var _oidLow = transObj.oidLow;
		var successFn = function(req, options)
				{
					var resp = new JResponse(req, options, pdClass);
					if(resp.hasFatalError())
						throw "Request got empty or invalid response!";
					if(resp.hasError())
						throw resp.getErrorMessage();
					result = resp.getInt(JafWebAPI.PROP_retCode, -1);
					// Transaktionsobjekt Client-seitig entfernen
					if(result == 0 && pdClass.usePDObjectCache())
						pdClass.PDObjectCache.removeObject(_cid, _oidLow);
					if(typeof callback == 'function')
						callback(result);
				};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callback == 'function')
					callback();
			};
		JafWeb.ajaxRequest({
				url: (this.PDClass || PDClass).getURL(),
				authToken: this.PDClass.getAuthToken(),
				method: "GET",
				async: (!!callback),
				params: pars.get(),
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		if(!callback)
			return result;
	}

	/*
	 @function ClientInfo#setParameter
	 @desc Zur Speicherung eines numerischen Parameters von
		 JavaScript bzw. aus dem Client. Der Wert wird im Server-seitigen
		 ClientInfo-Objekt gesetzt und kann dort abgefragt werden.
	 @param {number} val Der Wert.
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 */
	setParameter(val, callback) {
		//console.log("### ClientInfo.setParameter("+val+")");
		var pars = new JParamPacker(JafWebAPI.ClientInfo.setParameter.eventName, this.PDClass);
		pars.add(JafWebAPI.ClientInfo.setParameter.PAR_val, val);
		var result = 0;
		var pdClass = this.PDClass;
		var successFn = function(req, options)
				{
					var resp = new JResponse(req, options, pdClass);
					if(resp.hasFatalError())
						throw "Request got empty or invalid response!";
					if(resp.hasError())
						throw resp.getErrorMessage();
					result = resp.getInt(JafWebAPI.PROP_retCode, -1);
					if(typeof callback == 'function')
						callback(result);
				};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callback == 'function')
					callback();
			};
		JafWeb.ajaxRequest({
				url: (this.PDClass || PDClass).getURL(),
				authToken: this.PDClass.getAuthToken(),
				method: "GET",
				async: (!!callback),
				params: pars.get(),
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		if(!callback)
			return result;
	}
}



/**
 @class PDTools
 @desc Klasse mit Client-seitigen Helferfunktionen rund ums
	 Fachkonzept.<br>
	 Von dieser Klasse sollen keine Objekte instanziiert werden.
 @since 1.0
 @author Frank Fiolka
 */
class PDToolsClass {
	PDClass = null;
	_validators = null;

	/*
	 @constructs PDTools
	 @desc Dieser Konstruktor sollte niemals aufgerufen werden.
	 Eine Instanz dieser Klasse steht unter deren Namen global
	 zur Verfügung.
	 */
	constructor(pdClass) {
		this.PDClass = (pdClass || null);
	}

	/*
	 */
	initValidators() {
		const that = this;
		that._validators = [];
		//
		// Booleans:
		//
		let sBoolRegex = '^(';
		sBoolRegex += that.PDClass.ClientInfo.getTrueValue() + '|';
		sBoolRegex += that.PDClass.ClientInfo.getFalseValue() + ')$';
		const boolRegex = new RegExp(sBoolRegex, 'i');
		that._validators['_bool'] = {
				// validation function
				validate: function(val, field) {
						const valid = boolRegex.test(val);
						//console.log('validate bool: '+val+', result: '+valid);
						return valid;
					},
				// error Text
				text: (that.PDClass.PDMeta.getString('SC::JWNoBoolean') || 'The value in field %f is not a valid boolean.')
				// mask property; keystroke filter mask
				//intMask: /[\d]/i
			};
		
		//
		// Ganzzahlen:
		//
		let sIntRegex = '^[\\-+]?\\d+$';
		const intRegex = new RegExp(sIntRegex, 'i');
		const currGroupSign = that.PDClass.ClientInfo.getFloatGroup();
		that._validators['_int'] = {
				validate: function(val, field) {
						if(currGroupSign)
							val = val.replace(currGroupSign, '');
						const valid = intRegex.test(val);
						//console.log('validate int: '+val+', result: '+valid);
						return valid;
					},
				text: (that.PDClass.PDMeta.getString('SC::JWNoInteger') || 'The value in field %f is not a valid integer.')
			};
		
		//
		// Gleitkommazahlen:
		//
		let sFloatRegex = '^[\\-+]?[0-9]+';
		const floatGroupSign = that.PDClass.ClientInfo.getFloatGroup();
		const dec = that.PDClass.ClientInfo.getDecimal();
		if(dec) {
			sFloatRegex += '(';
			if(dec == '.')
				sFloatRegex += '\\.';
			else if(dec == ',')
				sFloatRegex += '\\,';
			else
				sFloatRegex += dec;
			sFloatRegex += '[0-9]+)?';
		}
		sFloatRegex += '$';
		const floatRegex = new RegExp(sFloatRegex, 'i');
		that._validators['_float'] = {
				validate: function(val, field, type) {
						if(type == 'currency' && currGroupSign)
							val = val.replace(currGroupSign, '');
						else if(floatGroupSign) {
							if(floatGroupSign == '.')
								val = val.replace(/\./g, '');
							else {
								while(val.indexOf(floatGroupSign) >= 0)
									val = val.replace(floatGroupSign, '');
							}
						}
						const valid = floatRegex.test(val);
						return valid;
					},
				text: (that.PDClass.PDMeta.getString('SC::JWNoFloat') || 'The value in field %f is not a valid floating point number.')
			};
		
		//
		// Datum: %d.%m.%Y
		//
		let sDateMask = "[\\d";
		const tmpDateFormat = that.PDClass.ClientInfo.getDateFormat();
		if(tmpDateFormat.indexOf('.') >= 0)
			sDateMask += "\\.";
		else if(tmpDateFormat.indexOf('-') >= 0)
			sDateMask += "-";
		else if(tmpDateFormat.indexOf(':') >= 0)
			sDateMask += ":";
		sDateMask += "]";
		that._validators['_date'] = {
				validate: function(val, field) {
						const mnt = moment(val, Date.janus2momentFormat(tmpDateFormat));
						return mnt && mnt.isValid();
					},
				text: (that.PDClass.PDMeta.getString('SC::JWNoDate') ||
						'The value in field %f is not a valid date (%t).').replace(/%t/, tmpDateFormat),
				// mask property; keystroke filter mask
				mask: new RegExp(sDateMask, "i")
			};

		//
		// Zeit: %h:%M:%s (kurz und lang)
		//
		let sTimeMask = '^([01]?[0-9]|2[0-3])(';
		const tmpTimeFormat = that.PDClass.ClientInfo.getTimeFormat();
		if(tmpTimeFormat.indexOf(':'))
			sTimeMask += ':';
		else if(tmpTimeFormat.indexOf('-'))
			sTimeMask += '-';
		else if(tmpTimeFormat.indexOf('.'))
			sTimeMask += '\\.';
		else if(tmpTimeFormat.indexOf(' '))
			sTimeMask += '\\s';
		sTimeMask += '[0-5][0-9]){1,2}$';
		that._validators['_time'] = {
				validate: function(val, field) {
						const mnt = moment(val, Date.janus2momentFormat(tmpTimeFormat));
						return mnt && mnt.isValid();
					},
				text: (that.PDClass.PDMeta.getString('SC::JWNoTime') ||
						'The value in field %f is not a valid time.').replace(/%t/, tmpTimeFormat),
				mask: new RegExp(sTimeMask, "i")
			};
		// Der JANUS-Typ time meint eigtl. daytime. Dagegen koennte auch etwas wie duration sinnvoll
		// sein, der nicht auf gueltige Tageszeiten validiert werden muesste; den kann man aber auch als
		// Float mit Unit darstellen.

		//
		// Zeitstempel: %d.%m.%Y %h:%M:%s
		//
		let sTimestampMask = '^([01]?[0-9]|2[0-3])(';
		const tmpTimestampFormat = that.PDClass.ClientInfo.getTimestampFormat();
		if(tmpTimestampFormat.indexOf(':'))
			sTimestampMask += ':';
		else if(tmpTimestampFormat.indexOf('-'))
			sTimestampMask += '-';
		else if(tmpTimestampFormat.indexOf('.'))
			sTimestampMask += '\\.';
		else if(tmpTimestampFormat.indexOf(' '))
			sTimestampMask += '\\s';
		sTimestampMask += '[0-5][0-9]){1,2}$';
		that._validators['_timestamp'] = {
				validate: function(val, field) {
						const mnt = moment(val, Date.janus2momentFormat(tmpTimestampFormat));
						return mnt && mnt.isValid();
					},
				text: (that.PDClass.PDMeta.getString('SC::JWNoTimestamp') ||
						'The value in field %f is not a valid timestamp.').replace(/%t/, tmpTimestampFormat),
				mask: new RegExp(sTimeMask, "i")
			};
		
		//
		// Waehrung
		//
		that._validators['_currency'] = {
				validate: function(val, field) {
						const tmp = val.split(' ');
						if(tmp.length != 2)
							return false;
						const currs = that.PDClass.getCurrencies();
						let f = false;
						for(let c in currs) {
							if(tmp[1] == c) {
								f = true;
								break;
							}
						}
						if(f === false)
							return false;
						const vt = that.getValidator('float');
						if(vt)
							return vt.validate(tmp[0], field, 'currency');
						return false;
					},
				text: (that.PDClass.PDMeta.getString('SC::JWNoCurrency') || 'The value in field %f is not a valid currency.')
			};

		//
		// Url
		// (auch Angabe ohne Protokoll zulassen)
		//var urlRegex = /^(((https?|ftp|gopher|telnet|file|notes|ms-help):((\/\/)|(\\\\))+)?(localhost|[\w\d\-_]+\.)[\w\d:#@%\/;$()~_?\+\-=\\\.&]*)$/i;
		// weniger restriktiv - "http://privacytests:8080/privacy/janus" (ohne Top level domain) sollte auch o.k. sein:
		//const urlRegex = /^(((https?|ftp|gopher|telnet|file|notes|ms-help):((\/\/)|(\\\\))+)?([\w\d\-_]+\.)*[\w\d:#@%\/;$\(\)~_?\+\-=\\\.&]*)$/i;
		//const urlRegex = /^((https?|ftp|gopher|telnet|file|notes|ms-help):((\/\/)|(\\\\))+)?(www\.)?[-\w\d@:%._\+~#=]{1,256}\.[\w\d\(\)]{1,6}\b([-\w\d\(\)@:%_\+.~#?&//=]*)$/i;
		const urlRegex = /^((ftp|http|https):((\/\/)|(\\\\))|file:(\/\/?))?(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?$/i;
		that._validators['_url'] = {
				validate: function(val, field) {
						const valid = urlRegex.test(val);
						return valid;
					},
				text: (that.PDClass.PDMeta.getString('SC::JWNoURL') || 'The value in field %f is not a valid URL.')
			};
		// TODO: vgl. a. https://www.freeformatter.com/url-parser-query-string-splitter.html

		//
		// E-Mail
		//
		const emailRegex = /^(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i;
		// (Quelle: http://www.regular-expressions.info)
		that._validators['_email'] = {
				validate: function(val, field) {
						const valid = emailRegex.test(val);
						//console.log('validate email address: '+val+', result: '+valid);
						return valid;
					},
				text: (that.PDClass.PDMeta.getString('SC::JWNoMailAddress') || 'The value in field %f is not a valid email address.')
			};

		//
		// Dokumentpfad
		//
		// TODO:

		//
		// IPv4-Adresse
		//
		const IPv4AddressRegex = /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/i;
		that._validators['_IPv4Address'] = {
				validate: function(val, field) {
						const valid = IPv4AddressRegex.test(val);
						return valid;
					},
				text: (that.PDClass.PDMeta.getString('SC::JWNoIPv4Address') || 'The value in field %f is not a valid IPv4-address.')
			};

		//
		// IPv6-Adresse
		//
		const IPv6AddressRegex = /^([0-9a-fA-F]{4}|0)(\:([0-9a-fA-F]{4}|0)){7}$/i;
		that._validators['_IPv6Address'] = {
				validate: function(val, field) {
						const valid = IPv6AddressRegex.test(val);
						return valid;
					},
				text: (that.PDClass.PDMeta.getString('SC::JWNoIPv6Address') || 'The value in field %f is not a valid IPv6-address.')
			};
	}
	
	/**
	 @function PDTools#getValidator
	 @desc Gibt ein Validierungsobjekt, bestehdn aus einer Validierungsfunktion
		 sowie einer passenden Meldung, für den angegebenen Datentyp zurück.
		 Für die Validierung werden die in {@link ClientInfo} benutzerabhängig
		 eingestellten Formate angewandt.
	 @param {string} type Der Datentyp. Mögliche Werte, für die
		 ein Validator zurückgegeben wird:
		 <ul>
			 <li><code>bool</code></li>
			 <li><code>int</code></li>
			 <li><code>float</code></li>
			 <li><code>currency</code></li>
			 <li><code>date</code></li>
			 <li><code>time</code></li>
			 <li><code>timestamp</code></li>
			 <li><code>url</code></li>
			 <li><code>email</code></li>
			 <li><code>IPv4Address</code></li>
			 <li><code>IPv6Address</code></li>
		 </ul>
	 */
	getValidator(type) {
		if(!this._validators)
			this.initValidators();
		if(this._validators && this._validators['_' + type])
			return this._validators['_' + type];
		return null;
	}
	
	/**
	 @function PDTools#convertFilterForRelation
	 @desc Verändert einen für einen Extent formulierten JANUS-Filterausdruck
		 so, dass er über eine Zu-1-Beziehung zur Klasse des Extents verwendet
		 werden kann. Dabei wird den im Filter vorkommenden
		 Attributen die Beziehung vorgeschaltet.
	 @param {string} filter Der Filterausdruck.
	 @param {string} relation Der technische Name der Beziehung.
	 @return {string} Der resultierende Filterausdruck.
	 */
	convertFilterForRelation(filter, relation) {
		if(!relation)
			return filter;
		let filt = "";
		let rest = filter;
		let pos = rest.search(/[A-Za-z_][A-Za-z0-9_.]*=/);
		while(pos >= 0) {
			let eq = rest.indexOf('=');
			let rExpr = rest.substring(pos, eq + 1);
			// "->object" an letzter Stelle wegkuerzen
			if(rExpr.substring(0, 7) == "object=")
				rExpr = "=";
			filt += rest.substring(0, pos) + relation + (rExpr.length > 1 ? "->" : "") + rExpr;
			rest = rest.substring(eq + 1);
			pos = rest.search(/[A-Za-z_][A-Za-z0-9_.]*=/);
		}
		filt += rest;
		//console.log("PDTools.convertFilterForRelation('"+filter+"', '"+relation+"') returns: '"+filt+"'");
		return filt;
	}

	/**
	 @function PDTools#getSelectFilter
	 @desc Auswahlfilter für eine Beziehung zusammenbauen. Dabei wird
		 das Property "FilterConnected" aus den Metainformationen
		 abgefragt und ggf. ein entspr. Filterausdruck erzeugt, um
		 bereits verbundene Objekte nicht in der Auswahlliste
		 anzuzeigen.
	 @param {PDObject} pdo Das aktuelle Fachkonzeptobjekt, von dem
		 die Beziehung ausgeht.
	 @param {string} relation Der Beziehungsname.
	 @param {string} filter Zusätzlicher Filterausdruck, der um den
		 automatisch erzeugten erweitert werden soll.
	 @return {string} Der komplette Filterausdruck.
	 */
	getSelectFilter(pdo, relation, filter) {
		let f = (filter || '');
		if(!pdo)
			return f;
		const cid = pdo.cid;
		let fl = (this.PDClass.PDMeta.getTypeId(cid, relation) & this.PDClass.PDMeta.TFlagMask);
		if (fl & this.PDClass.PDMeta.TFlagRelFilterConnected) {
			if(f.charAt(0) == '$' || f.charAt(0) == '~')
				f = f.substring(1);
			let needParen = false;
			if (this.PDClass.PDMeta.getMaxCard(cid, relation) == 1) {
				const relObj = pdo.getFirstLink(relation);
				if(relObj) {
					if(f) {
						f = '(' + f + ')&&(';
						needParen = true;
					}
					f += 'object!=' + pdo.GetPDObjectIdLow();
				}
			}
			else {
				const oids = pdo.getConnectedOids(relation);
				let i = 0;
				for(; i < oids.length; i++) {
					if(i == 0) {
						if(f) {
							f = '(' + f + ')&&(';
							needParen = true;
						}
						f += 'object!=[';
					}
					else
						f += ',';
					f += OID_LO(oids[i]);
				}
				if(i > 0)
					f += ']';
			}
			if(f)
				f = '$' + f;
			if(needParen)
				f += ')';
		}
		//console.log("PDTools.getSelectFilter(pdo, '"+relation+"', "+filter+"') returns: '"+f+"'");
		return f;
	}

	/**
	 @function PDTools#makeHTML
	 @desc Mit Hilfe dieser Methode wandeln Sie einen beliebigen Text so um, dass
		 HTML-Codes ihre Bedeutung verlieren. Insbesondere werden die
		 Tag-Startzeichen &lt; und &gt; in &amp;lt; bzw. &amp;gt; umgewandelt.<br/>
		 <span class="important">Hinweis:</span> Abweichend von der Server-seitigen API wird hier ein zweiter
		 Parameter ("unicode") zur Umwandlung von Unicode-Zeichen in hexadezimale
		 Entities ignoriert!
	 @param {string} text Der zu konvertierende Text.
	 @example PDTools.makeHTML("<b>Test</b>") // returns "&lt;b&gt;Test&lt;/b&gt;"
	 */
	makeHTML(text) {
		if(!text)
			return '';
		return text.escapeHTML();
	}

	/**
	 @function PDTools#makeURL
	 */
	makeURL(val) {
		// TODO
		throw "PDTools.makeURL() - Not implemented yet!";
	}

	/**
	 @function PDTools#makeLink
	 @desc Diese Methode kann bei der Erstellung einer HTML-Sicht auf ein Objekt
		 verwendet werden. Sie gibt einen Link auf das übergebene Objekt mit dem
		 angegebenen Link-Text zurück. Der optionale String <code>tooltip</code>
		 wird als Tooltip angezeigt, wenn der Benutzer mit dem Mauszeiger über
		 dem Element verweilt.
	 @param {PDObject} obj Das Fachkonzeptobjekt, auf das der Link verweisen soll.
	 @param {string} text Der Link-Text.
	 @param {string} tooltip Tooltip-Text (optional).
	 */
	makeLink(obj, text, tooltip) {
		// TODO
		throw "PDTools.makeLink() - Not implemented yet!";
	}

	/**
	 @function PDTools#makeFilter
	 @desc Ein Aufruf dieser Funktion setzt den übergebenen Text in
		 Anführungszeichen und maskiert eventuell vorhandene Anführungszeichen,
		 so dass das Ergebnis zur Bildung eines Filterausdrucks für
		 <code>PDIterator</code> verwendet werden kann.<br/>
		 <h4>Beispiel</h4>
		 <pre class="prettyprint"><code>let filt = "Number=";
filt += PDTools.makeFilter(currentEquipmentNumber);
var eq = PDClass.findObject(PDMeta.getId("Equipment"), filt);</code></pre>
	 @param {string} text Der zu konvertierende Text.
	 @return {string} Der konvertierte Text.<br/>
	 */
	makeFilter(text) {
		// TODO
		return "'" + text.replace(/'/g, "\'\'") + "'"; // macht nur das, was appendQuoted() auf dem Server macht!
	}

	/**
	 @function PDTools#makeCSV
	 @desc Diese Funktion dient zur einfachen Implementierung eines CSV-Exports in
		 JavaScript. An die Methode müssen die Daten für eine CSV-Zeile übergeben
		 werden.<br>
		 Sie gibt eine Zeichenkette mit den CSV-konformen Daten zurück. An diese Zeile
		 muss lediglich noch ein Zeilenumbruch angehängt werden.
	 @param {string} sep Das Zeichen, das die einzelnen Datenfelder voneinander trennt.
		 Normalerweise wird ein Semikolon oder ein Komma verwendet.
	 @param {string} delim Das Zeichen, mit dem die zu exportierenden Daten »geklammert«
		 werden sollen. Die Klammerung erfolgt nur, wenn im Text ein Trennzeichen oder
		 ein Klammerungszeichen erscheint. überlicherweise verwendet man hierfür
		 Anführungszeichen.
	 @param {string} mask Das Maskierungszeichen wird vor das Klammerzeichen gesetzt, falls
		 das Klammerzeichen (<code>delim</code>) im Text vorkommt. Überlicherweise nimmt man
		 hier einen Backslash oder man verdoppelt das Klammerungszeichen, indem man den Wert
		 von <code>delim</code> wiederholt.
	 */
	makeCSV(sep, delim, mask) {
		// TODO???
		throw "PDTools.makeCSV() - Not implemented yet!";
	}

	/**
	 @function PDTools#getUnique
	 @desc Die Aufruf dieser Methode erzeugt einen global eindeutigen Wert (ähnlich einer
		 aus Windows bekannten GUID).
	 @param {boolean} alpha Wird die Funktion ohne Parameter oder mit <code>false</code>
		 als Parameter aufgerufen, wird eine 30 Zeichen lange Zeichenkette gebildet, für
		 die folgende Eigenschaften gelten:
		 <ul>
			<li>Der Wert setzt sich aus den Ziffern 0 bis 9, Groß- und Kleinbuchstaben sowie
			den Sonderzeichen mit den ASCII-Codes 59 bis 64 und 91 bis 96 zusammen.
			<li>Er enthält keine einfachen oder doppelten Anführungszeichen, keine Slashes
			und keine Backslashes sowie keinen Doppelpunkt.
			<li>Wird true übergeben, enthält der Wert nur Kleinbuchstaben (ohne Umlaute).
			Die Länge vergrößert sich dadurch auf 40 Zeichen.
		 </ul>
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 */
	getUnique(alpha, callback) {
		var pars = new JParamPacker(JafWebAPI.PDTools.getUnique.eventName, this.PDClass);
		if(true === alpha)
			pars.add(JafWebAPI.PDTools.getUnique.PAR_alpha, true);
		var result = '';
		var pdClass = this.PDClass;
		var successFn = function(req, options)
			{
				var resp = new JResponse(req, options, pdClass);
				if(resp.hasFatalError())
					throw "Request got empty or invalid response!";
				if(resp.hasError())
					throw resp.getErrorMessage();
				result = resp.getString(JafWebAPI.PDTools.getUnique.PROP_res);
				if(typeof callback == 'function')
					callback(result);
			};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callback == 'function')
					callback();
			};
		JafWeb.ajaxRequest({
				url: (this.PDClass || PDClass).getURL(),
				authToken: this.PDClass.getAuthToken(),
				method: "GET",
				async: (!!callback),
				params: pars.get(),
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		if(!callback)
			return result;
	}
	
	/**
	 @function PDTools#getUsrDataDir
	 @desc Funktion zum Ermitteln des Benutzer-Datenverzeichnisses.<br/>
		 <span class="important">Hinweis:</span> Diese Funktion hat im JafWeb mangels Zugriff auf das lokale Dateisystem
		 keine Implementierung!
	 @return {string}
	 */
	getUsrDataDir(val) {
		throw "PDTools.getUrsDataDir(): Web apps are not able to access client-filesystem";
	}

	/**
	 @function PDTools#getDataDir
	 @desc Funktion zum Ermitteln des System-Datenverzeichnisses.<br/>
		 <span class="important">Hinweis:</span> Diese Funktion hat im JafWeb mangels Zugriff auf das lokale Dateisystem
		 keine Implementierung!
	 @return {string}
	 */
	getDataDir(val) {
		throw "PDTools.getDataDir(): Web apps are not able to access client-filesystem";
	}

	/**
	 @function PDTools#getTempDir
	 @desc Funktion zum Ermitteln eines temporären Verzeichnisses.<br/>
		 <span class="important">Hinweis:</span> Diese Funktion hat im JafWeb mangels Zugriff auf das lokale Dateisystem
		 keine Implementierung!
	 @return {string}
	 */
	getTempDir(val) {
		throw "PDTools.getTempDir(): Web apps are not able to access client-filesystem";
	}

	/**
	 @function PDTools#isAbsoluteDir
	 @desc Funktion zum Prüfen eines Dateipfades.<br/>
		 <span class="important">Hinweis:</span> Diese Funktion hat im JafWeb mangels Zugriff auf das lokale Dateisystem
		 keine Implementierung!
	 @param {string} name
	 @return {boolean}
	 */
	isAbsoluteDir(name) {
		throw "PDTools.isAbsoluteDir(): Web apps are not able to access client-filesystem";
	}

	/**
	 @function PDTools#sleep
	 @desc Das Skript vorübergehend anhalten.
	 @param {number} ms Die Unterbrechungszeit in Millisekunden.
	 */
	sleep(ms) {
		// TODO
		throw "PDTools.sleep() - Not implemented yet!";
	}

	/**
	 @function PDTools#run
	 @desc Funktion zum Aufrufen externer Programme.<br/>
		 <span class="important">Hinweis:</span> Diese Funktion hat im JafWeb mangels Zugriff auf das lokale Dateisystem
		 keine Implementierung!
	 */
	run(val) {
		throw "PDTools.run(): Web apps are not able to access client-filesystem";
	}

	/**
	 @function PDTools#env
	 @desc Funktion zum Abfragen von Umgebungsvariablen.<br/>
		 <span class="important">Hinweis:</span> Diese Funktion hat im JafWeb mangels Zugriff auf das lokale Dateisystem
		 keine Implementierung!
	 @param {string} variable
	 @return {string}
	 */
	env(variable) {
		throw "PDTools.env(): Web apps are not able to access client-system";
	}

	/**
	 @function PDTools#renameFile
	 @desc Funktion zum Umbenennen von Dateien.<br/>
		 <span class="important">Hinweis:</span> Diese Funktion hat im JafWeb mangels Zugriff auf das lokale Dateisystem
		 keine Implementierung!
	 @param {string} from
	 @param {string} to
	 @return {string}
	 */
	renameFile(from, to) {
		throw "PDTools.renameFile(): Web apps are not able to access client-filesystem";
	}

	/**
	 @function PDTools#receiveFile
	 @desc Eine Datei vom Server holen.<br/>
		 <span class="important">Hinweis:</span> Diese Funktion besitzt im JafWeb keine Implementierung! Um eine
		 Datei vom Server zu holen, starten Sie einen Download. Vgl.
		 <code>PDOperationCall.execDownload()</code>.
	 @param {string} remoteFile
	 @param {string} localFile
	 */
	receiveFile(remoteFile, localFile) {
		throw "PDTools.receiveFile()\nUse download instead.";
	}

	/**
	 @function PDTools#sendFile
	 @desc Eine lokale Datei zum Server übertragen.<br/>
		 <span class="important">Hinweis:</span> Diese Funktion besitzt im JafWeb keine Implementierung! Um eine
		 Datei zum Server zu übertragen, verwenden Sie bitte den Upload-Dialog.
	 @param {string} remoteFile
	 @param {string} localFile
	 */
	sendFile(localFile, remoteFile) {
		throw "PDTools.sendFile()\nUse upload instead.";
	}

	/**
	 @function PDTools#getDir
	 @desc Funktion zum Ermitteln von Dateien und Unterverzeichnissen.<br/>
		 <span class="important">Hinweis:</span> Diese Funktion hat im JafWeb mangels Zugriff auf das lokale Dateisystem
		 keine Implementierung!
	 @param {string} name
	 @param {string[]} files
	 @param {string[]} directories (optional)
		 Verzeichnis.
	 @return {string}
	 */
	getDir(name, files, directories) {
		throw "PDTools.getDir(): Web apps are not able to access client-filesystem";
	}

	/**
	 @function PDTools#beep
	 @desc Der Aufruf erzeugt einen Signalton. Die Parameter sind hier unwirksam!
	 @param {number} freq
	 @param {number} duration
	 */
	beep(freq, duration) {
		// TODO: Implementierung mit SoundManager?
		throw "PDTools.beep() - Not implemented yet!";
	}

	/**
	 @function PDTools#Beep
	 @desc Siehe [beep()]{@link PDTools#beep}.
	 */
	Beep(freq, duration) {
		return this.beep(freq, duration);
	}

	/**
	 @function PDTools#chdir
	 @desc Das aktuelle Arbeitsverzeichnis wechseln.<br/>
		 <span class="important">Hinweis:</span> Diese Funktion hat im JafWeb
		 mangels Zugriff auf das lokale Dateisystem
		 keine Implementierung!
	 @param {string} dir
	 */
	chdir(dir) {
		throw "PDTools.chdir(): Web apps are not able to access client-filesystem";
	}

	/**
	 @function PDTools#getcwd
	 @desc Das aktuelle Arbeitsverzeichnis abfragen.<br/>
		 <span class="important">Hinweis:</span> Diese Funktion hat im JafWeb
		 mangels Zugriff auf das lokale Dateisystem
		 keine Implementierung!
	 @return {string}
	 */
	getcwd() {
		throw "PDTools.getcwd(): Web apps are not able to access client-filesystem";
	}

	/**
	 @function PDTools#fileCopy
	 @desc Funktion zum Kopieren von Dateien.<br/>
		 <span class="important">Hinweis:</span> Diese Funktion hat im JafWeb
		 mangels Zugriff auf das lokale Dateisystem
		 keine Implementierung!
	 @param {string} source
	 @param {string} dest
	 @return {string}
	 */
	fileCopy(source, dest) {
		throw "PDTools.fileCopy(): Web apps are not able to access client-filesystem";
	}

	/**
	 @function PDTools#fileMove
	 @desc Funktion zum Verschieben von Dateien.<br/>
		 <span class="important">Hinweis:</span> Diese Funktion hat im JafWeb
		 mangels Zugriff auf das lokale Dateisystem
		 keine Implementierung!
	 @param {string} source
	 @param {string} dest
	 @return {string}
	 */
	fileMove(source, dest) {
		throw "PDTools.fileMove(): Web apps are not able to access client-filesystem";
	}

	/**
	 @function PDTools#deleteFile
	 @desc Löscht eine Datei <em>auf dem Server</em>.<br/>
		 <span class="important">Hinweis:</span> Die Aktion wird grundsätzlich
		 auf dem Server ausgeführt und bezieht sich auf dessen Dateisystem!
	 @param {string} filename Der qualifizierte Pfad der Datei auf dem Server.
	 @param {Function} [callback] Optionale Callback-Funktion. Falls angegeben,
		 wird der Request asynchron ausgeführt und statt die Funktion wird mit dem
		 als Rüeckgabewert beschriebenen Wert als Parameter aufgerufen.
	 @return {string} Wenn die Datei erfolgreich gelöscht werden konnte, gibt die
		 Funktion einen leeren String zurück. Im Fehlerfall wird statt des Leerstrings
		 ein Meldungstext zurückgegeben.
	 */
	deleteFile(filename, callback) {
		var res = "";
		var pars = new JParamPacker(JafWebAPI.PDOperationCall.delFile.eventName, this.PDClass);
		pars.add(JafWebAPI.PDOperationCall.delFile.PAR_file, filename);
		var pdClass = this.PDClass;
		var successFn = function(req, options)
			{
				var resp = new JResponse(req, options, pdClass);
				if(resp.hasError())
					res = resp.getErrorMessage();
				if(typeof callback == 'function')
					callback(res);
			};
		var failureFn = function() {
				res = "Error in PDTools.deleteFile()";
				if(typeof callback == 'function')
					callback(res);
			};
		var req = JafWeb.ajaxRequest({
				url: (this.PDClass || PDClass).getURL(),
				authToken: this.PDClass.getAuthToken(),
				method: "GET",
				async: (!!callback),
				params: pars.get(),
				disableCaching: true,
				callerName: pars.getEventName(),
				success: successFn,
				failure: failureFn
			});
		if(!callback)
			return res;
	}

	/**
	 @function PDTools#fileSize
	 @desc Ermittelt die Größe einer Datei <em>auf dem Server</em>.<br/>
		 <span class="important">Hinweis:</span> Die Aktion wird grundsätzlich
		 auf dem Server ausgeführt und bezieht sich auf dessen Dateisystem!
	 @param {string} dir (optional) Falls Sie den Parameter <code>dir</code>
		 angeben, wird er als Verzeichnisname interpretiert, in dem sich die
		 Datei <code>fname</code> befindet. Fehlt dieser Parameter, muss <code>fname</code>
		 inklusive Pfad angegeben werden.
	 @param {string} fname Dateiname.
	 */
	fileSize(dir, fname) {
		throw "PDTools.fileSize(): Web apps are not able to access client-filesystem";
	}

	/**
	 @function PDTools#breakLine
	 @desc Diese Funktion fügt in den übergebenen String nach <code>length</code>
		 Zeichen einen Zeilenumbruch ein. Ein Zeilenumbruch wird nur anstelle
		 eines Leerzeichens oder Tabulators eingefügt, so dass einzelne Zeilen
		 das Maximum auch überschreiten können. Wird <code>length</code> nicht
		 angegeben, erfolgt der Umbruch nach jeweils 60 Zeichen.
	 @param {string} text
	 @param {number} length (optional)
	 @return {string} Der veränderte Text.
	 */
	breakLine(text, length) {
		var autoSplit = (length || 60);
		var cCount = 0;
		for (var n = 0; n < text.length; n++)
		{
			if ((text[n] == ' ' || text[n] == '\t') && cCount >= autoSplit)
			{
				text[n] = '\n';
				cCount = 0;
			}
			else
				cCount++;
		}
		return text;
	}

	/**
	 @function PDTools#zip
	 @desc Funktion zum Zippen.<br/>
		 <span class="important">Hinweis:</span> Ist im JafWeb nur aus Kompatibilitätsgründen vorhanden und
		 hat hier keine Implementierung. Zum Komprimieren und
		 Dekomprimieren von Strings vgl. aber <code>lzwCompress()</code> bzw.
		 <code>lzwDecompress()</code>.
	 */
	zip() {
		throw "PDTools.zip(): Web apps are not able to access client-filesystem";
	}

	/**
	 @function PDTools#lzwCompress
	 @desc Komprimiert den übergebenen String.<br/>
		 <span class="important">Hinweis:</span> Wenn Sie diese Funktion benutzen, müssen Sie
		 die LZW-Bibliothek einbinden. Geben Sie dazu das Tag
		 <code>lzwCompression</code> in der Datei <code>JAFparameters</code>
		 mit dem Wert "true" an.
	 @param {string} source Der zu komprimierende Wert.
	 @return {string} Das komprimierte Ergebnis.
	 @see <code>lzwDecompress()</code>
	 */
	lzwCompress(source) {
		// erst bei Bedarf laden
		if(!window.LZW)
		{
			if(!loadJavaScript(UIApplication.getResourceDir() + 'script/jaf/lzw.js', window))
				return;
		}
		return (window.LZW.compress(source) || '');
	}

	/**
	 @function PDTools#lzwDecompress
	 @desc Dekomprimiert den übergebenen String.<br/>
		 <span class="important">Hinweis:</span> Wenn Sie diese Funktion benutzen, müssen Sie
		 die LZW-Bibliothek einbinden. Geben Sie dazu das Tag
		 <code>lzwCompression</code> in der Datei <code>JAFparameters</code>
		 mit dem Wert "true" an.
	 @param {string} source Der zu dekomprimierende Wert.
	 @return {string} Das unkomprimierte Ergebnis.
	 @see <code>lzwCompress()</code>
	 */
	lzwDecompress(source) {
		// erst bei Bedarf laden
		if(!window.LZW)
		{
			if(!loadJavaScript(UIApplication.getResourceDir() + 'script/jaf/lzw.js', window))
				return;
		}
		return (window.LZW.decompress(source) || '');
	}

	/**
	 @function PDTools#md5sum
	 @desc MD5-Prüfsumme für die übergebene Zeichenkette ermitteln.
	 @param {string} input Die Zeichenkette.
	 @return {string} 32 Zeichen langer String mit der Prüfsumme
		 der übergebenen Zeichenkette.
	 */
	md5sum(input) {
		/* TODO?
		raw = raw || false;	
		hexcase = hexcase || false;
		chrsz = chrsz || 8;*/
		const raw = false;
		const hexcase = false;
		const chrsz = 8;

		function safe_add(x, y){
			var lsw = (x & 0xFFFF) + (y & 0xFFFF);
			var msw = (x >> 16) + (y >> 16) + (lsw >> 16);
			return (msw << 16) | (lsw & 0xFFFF);
		}
		function bit_rol(num, cnt){
			return (num << cnt) | (num >>> (32 - cnt));
		}
		function md5_cmn(q, a, b, x, s, t){
			return safe_add(bit_rol(safe_add(safe_add(a, q), safe_add(x, t)), s),b);
		}
		function md5_ff(a, b, c, d, x, s, t){
			return md5_cmn((b & c) | ((~b) & d), a, b, x, s, t);
		}
		function md5_gg(a, b, c, d, x, s, t){
			return md5_cmn((b & d) | (c & (~d)), a, b, x, s, t);
		}
		function md5_hh(a, b, c, d, x, s, t){
			return md5_cmn(b ^ c ^ d, a, b, x, s, t);
		}
		function md5_ii(a, b, c, d, x, s, t){
			return md5_cmn(c ^ (b | (~d)), a, b, x, s, t);
		}

		function core_md5(x, len){
			x[len >> 5] |= 0x80 << ((len) % 32);
			x[(((len + 64) >>> 9) << 4) + 14] = len;
			var a =  1732584193;
			var b = -271733879;
			var c = -1732584194;
			var d =  271733878;
			for(var i = 0; i < x.length; i += 16){
				var olda = a;
				var oldb = b;
				var oldc = c;
				var oldd = d;
				a = md5_ff(a, b, c, d, x[i+ 0], 7 , -680876936);
				d = md5_ff(d, a, b, c, x[i+ 1], 12, -389564586);
				c = md5_ff(c, d, a, b, x[i+ 2], 17,  606105819);
				b = md5_ff(b, c, d, a, x[i+ 3], 22, -1044525330);
				a = md5_ff(a, b, c, d, x[i+ 4], 7 , -176418897);
				d = md5_ff(d, a, b, c, x[i+ 5], 12,  1200080426);
				c = md5_ff(c, d, a, b, x[i+ 6], 17, -1473231341);
				b = md5_ff(b, c, d, a, x[i+ 7], 22, -45705983);
				a = md5_ff(a, b, c, d, x[i+ 8], 7 ,  1770035416);
				d = md5_ff(d, a, b, c, x[i+ 9], 12, -1958414417);
				c = md5_ff(c, d, a, b, x[i+10], 17, -42063);
				b = md5_ff(b, c, d, a, x[i+11], 22, -1990404162);
				a = md5_ff(a, b, c, d, x[i+12], 7 ,  1804603682);
				d = md5_ff(d, a, b, c, x[i+13], 12, -40341101);
				c = md5_ff(c, d, a, b, x[i+14], 17, -1502002290);
				b = md5_ff(b, c, d, a, x[i+15], 22,  1236535329);
				a = md5_gg(a, b, c, d, x[i+ 1], 5 , -165796510);
				d = md5_gg(d, a, b, c, x[i+ 6], 9 , -1069501632);
				c = md5_gg(c, d, a, b, x[i+11], 14,  643717713);
				b = md5_gg(b, c, d, a, x[i+ 0], 20, -373897302);
				a = md5_gg(a, b, c, d, x[i+ 5], 5 , -701558691);
				d = md5_gg(d, a, b, c, x[i+10], 9 ,  38016083);
				c = md5_gg(c, d, a, b, x[i+15], 14, -660478335);
				b = md5_gg(b, c, d, a, x[i+ 4], 20, -405537848);
				a = md5_gg(a, b, c, d, x[i+ 9], 5 ,  568446438);
				d = md5_gg(d, a, b, c, x[i+14], 9 , -1019803690);
				c = md5_gg(c, d, a, b, x[i+ 3], 14, -187363961);
				b = md5_gg(b, c, d, a, x[i+ 8], 20,  1163531501);
				a = md5_gg(a, b, c, d, x[i+13], 5 , -1444681467);
				d = md5_gg(d, a, b, c, x[i+ 2], 9 , -51403784);
				c = md5_gg(c, d, a, b, x[i+ 7], 14,  1735328473);
				b = md5_gg(b, c, d, a, x[i+12], 20, -1926607734);
				a = md5_hh(a, b, c, d, x[i+ 5], 4 , -378558);
				d = md5_hh(d, a, b, c, x[i+ 8], 11, -2022574463);
				c = md5_hh(c, d, a, b, x[i+11], 16,  1839030562);
				b = md5_hh(b, c, d, a, x[i+14], 23, -35309556);
				a = md5_hh(a, b, c, d, x[i+ 1], 4 , -1530992060);
				d = md5_hh(d, a, b, c, x[i+ 4], 11,  1272893353);
				c = md5_hh(c, d, a, b, x[i+ 7], 16, -155497632);
				b = md5_hh(b, c, d, a, x[i+10], 23, -1094730640);
				a = md5_hh(a, b, c, d, x[i+13], 4 ,  681279174);
				d = md5_hh(d, a, b, c, x[i+ 0], 11, -358537222);
				c = md5_hh(c, d, a, b, x[i+ 3], 16, -722521979);
				b = md5_hh(b, c, d, a, x[i+ 6], 23,  76029189);
				a = md5_hh(a, b, c, d, x[i+ 9], 4 , -640364487);
				d = md5_hh(d, a, b, c, x[i+12], 11, -421815835);
				c = md5_hh(c, d, a, b, x[i+15], 16,  530742520);
				b = md5_hh(b, c, d, a, x[i+ 2], 23, -995338651);
				a = md5_ii(a, b, c, d, x[i+ 0], 6 , -198630844);
				d = md5_ii(d, a, b, c, x[i+ 7], 10,  1126891415);
				c = md5_ii(c, d, a, b, x[i+14], 15, -1416354905);
				b = md5_ii(b, c, d, a, x[i+ 5], 21, -57434055);
				a = md5_ii(a, b, c, d, x[i+12], 6 ,  1700485571);
				d = md5_ii(d, a, b, c, x[i+ 3], 10, -1894986606);
				c = md5_ii(c, d, a, b, x[i+10], 15, -1051523);
				b = md5_ii(b, c, d, a, x[i+ 1], 21, -2054922799);
				a = md5_ii(a, b, c, d, x[i+ 8], 6 ,  1873313359);
				d = md5_ii(d, a, b, c, x[i+15], 10, -30611744);
				c = md5_ii(c, d, a, b, x[i+ 6], 15, -1560198380);
				b = md5_ii(b, c, d, a, x[i+13], 21,  1309151649);
				a = md5_ii(a, b, c, d, x[i+ 4], 6 , -145523070);
				d = md5_ii(d, a, b, c, x[i+11], 10, -1120210379);
				c = md5_ii(c, d, a, b, x[i+ 2], 15,  718787259);
				b = md5_ii(b, c, d, a, x[i+ 9], 21, -343485551);
				a = safe_add(a, olda);
				b = safe_add(b, oldb);
				c = safe_add(c, oldc);
				d = safe_add(d, oldd);
			}
			return [a, b, c, d];
		}
		function str2binl(str){
			var bin = [];
			var mask = (1 << chrsz) - 1;
			for(var i = 0; i < str.length * chrsz; i += chrsz) {
				bin[i>>5] |= (str.charCodeAt(i / chrsz) & mask) << (i%32);
			}
			return bin;
		}
		function binl2str(bin){
			var str = "";
			var mask = (1 << chrsz) - 1;
			for(var i = 0; i < bin.length * 32; i += chrsz) {
				str += String.fromCharCode((bin[i>>5] >>> (i % 32)) & mask);
			}
			return str;
		}
		
		function binl2hex(binarray){
			var hex_tab = hexcase ? "0123456789ABCDEF" : "0123456789abcdef";
			var str = "";
			for(var i = 0; i < binarray.length * 4; i++) {
				str += hex_tab.charAt((binarray[i>>2] >> ((i%4)*8+4)) & 0xF) +
						hex_tab.charAt((binarray[i>>2] >> ((i%4)*8  )) & 0xF);
			}
			return str;
		}
		return (raw ? binl2str(core_md5(str2binl(input), input.length * chrsz)) :
				binl2hex(core_md5(str2binl(input), input.length * chrsz))	);
	}
	
	//
	// Base64 (ehem. in JUtil)
	//
	_base64KeyStr = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";
	/**
	 @function PDTools#base64Encode
	 @desc Die übergebenen Daten einer Base64-Kodierung unterziehen.<br/>
		 <span class="important">Hinweis:</span>Beachten Sie bitte, dass Sie für Strings,
		 die auf Server-Seite dekodiert werden sollen, statt dieser Funktion unbedingt
		 [PDTools#stringToBase64()]{@link PDTools#stringToBase64} benutzen
		 sollten, um ein korrekt UTF-8-kodiertes Ergebnis zu bekommen!
	 @param {string} input Die zu kodierenden Daten.
	 @return {string} Der Base64-kodierte String.
	 */
	base64Encode(input) {
		//return JUtil.base64.encode(inp);
		if(typeof window['btoa'] == 'function')
			return window.btoa(input);
		var output = "";
		var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
		var i = 0;
		//input = Ext.util.base64._utf8_encode(input);
		while (i < input.length) {
			chr1 = input.charCodeAt(i++);
			chr2 = input.charCodeAt(i++);
			chr3 = input.charCodeAt(i++);

			enc1 = chr1 >> 2;
			enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
			enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
			enc4 = chr3 & 63;

			if (isNaN(chr2)) {
				enc3 = enc4 = 64;
			} else if (isNaN(chr3)) {
				enc4 = 64;
			}

			output = output +
			this._base64KeyStr.charAt(enc1) + this._base64KeyStr.charAt(enc2) +
			this._base64KeyStr.charAt(enc3) + this._base64KeyStr.charAt(enc4);
		}
		return output;
	}

	/**
	 @function PDTools#base64Decode
	 @desc Einen Base64-kodierten Wert dekodieren.<br/>
		 <span class="important">Hinweis:</span>Beachten Sie bitte, dass Sie für auf
		 Server-Seite Base64-kodierte Strings statt dieser unbedingt die Funktion
		 [PDTools#base64ToString()]{@link PDTools#base64ToString} benutzen
		 sollten, um ein korrekt UTF-8-kodiertes Ergebnis zu bekommen!<br/>
		 <span class="important">Hinweis:</span>
		 <a href="http://stackoverflow.com/questions/30106476/using-javascripts-atob-to-decode-base64-doesnt-properly-decode-utf-8-strings">
		 http://stackoverflow.com/questions/30106476/using-javascripts-atob-to-decode-base64-doesnt-properly-decode-utf-8-strings</a>
	 @param {string} input Der kodierte String.
	 @return {string} Der dekodierte Wert.
	 */
	base64Decode(input) {
		//return JUtil.base64.decode(str);
		if(typeof window['atob'] == 'function')
			return window.atob(input);
		var output = "";
		var chr1, chr2, chr3;
		var enc1, enc2, enc3, enc4;
		var i = 0;

		input = input.replace(/[^A-Za-z0-9\+\/\=]/g, "");

		while (i < input.length) {
			enc1 = this._base64KeyStr.indexOf(input.charAt(i++));
			enc2 = this._base64KeyStr.indexOf(input.charAt(i++));
			enc3 = this._base64KeyStr.indexOf(input.charAt(i++));
			enc4 = this._base64KeyStr.indexOf(input.charAt(i++));

			chr1 = (enc1 << 2) | (enc2 >> 4);
			chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
			chr3 = ((enc3 & 3) << 6) | enc4;

			output = output + String.fromCharCode(chr1);

			if (enc3 != 64) {
				output = output + String.fromCharCode(chr2);
			}
			if (enc4 != 64) {
				output = output + String.fromCharCode(chr3);
			}
		}
		//output = Ext.util.base64._utf8_decode(output);
		return output;
	}

	/**
	 @function PDTools#base64Encode
	 @desc Einen String einer Base64-Kodierung unterziehen.
	 @param {string} inp Die zu kodierenden Daten. Der String
		wird als UTF8-kodiert angenommen - siehe
		[utf8ToBase64()]{@link PDTools#utf8ToBase64}.
	 @return {string} Der Base64-kodierte String.
	 */
	stringToBase64(inp) {
		return this.utf8ToBase64(inp);
	}

	/**
	 @function PDTools#base64Decode
	 @desc Einen Base64-kodierten String dekodieren.
	 @param {string} str Der kodierte String.
	 @return {string} Der dekodierte Wert. Der String
		wird UTF8-kodiert - siehe
		[base64ToUtf8()]{@link PDTools#base64ToUtf8}
	 */
	base64ToString(str) {
		return this.base64ToUtf8(str);
	}

	/**
	 @function PDTools#base64ToUtf8
	 @desc Einen Base64-kodierten Wert in einen String dekodieren.<br/>
		 <span class="important">Hinweis:</span> ECMA Script schreibt vor, dass
		 JavaScript-Strings UTF-16-kodiert sind (<a href="http://www.ecma-international.org/publications/files/ECMA-ST/Ecma-262.pdf">ECMA 262</a>,
		 Seite 158). Das kann zu Problemen führen, wenn
		 ein auf Server-Seite mit <code>PDTools.base64Encode()</code> kodierter
		 String in der JafWeb-UI wieder dekodiert werden soll! Siehe
		 <a href="http://stackoverflow.com/questions/30106476/using-javascripts-atob-to-decode-base64-doesnt-properly-decode-utf-8-strings">
		 http://stackoverflow.com/questions/30106476/using-javascripts-atob-to-decode-base64-doesnt-properly-decode-utf-8-strings</a>
	 @param {string} input Base64-kodierte Eingabe.
	 @return {string} Das UTF8-kodierte Ergebnis.
	 */
	base64ToUtf8(input) {
		input = input.replace(/\s/g, '');
		return decodeURIComponent(escape(this.base64Decode(input)));
	}

	/**
	 @function PDTools#utf8ToBase64
	 @desc Einen String in einen Base64-kodierten Wert enkodieren.<br/>
	 @param {string} input Der Eingabe-String (als Encoding gilt immer UTF-8).
	 @return {string} Das Base64-kodierte Ergebnis. Dieses kann in einer
		 JANUS-<em>UnicodeApplication</em> mit <code>PDTools::base64Decode()</code>
		 wieder in einen UTF-8-String gewandelt werden.
	 */
	utf8ToBase64(input) {
		return this.base64Encode(unescape(encodeURIComponent(input)));
	}

	/**
	 @function PDTools#deleteDirectory
	 @desc Löscht ein Verzeichnis inklusive aller Unterverzeichnisse und enthaltenen
		 Dateien.
	 @param {string} dir Das zu löschende Verzeichnis.
	 @return {string} Wenn alles erfolgreich gelöscht werden konnte, gibt die
		 Funktion einen leeren String zurück. Im Fehlerfall wird statt des Leerstrings
		 ein Meldungstext zurückgegeben.
	 */
	deleteDirectory(dir) {
		throw "PDTools.deleteDirectory(): Web apps are not able to access client-filesystem";
	}

	/**
	 @function PDTools#createTempName
	 @desc Diese Funktion ist nur der Vollständigkeit halber da. Im
		 Web Client hat sie mangels Zugriff auf das lokale Dateisystem
		 keine Funktion.
	 @see <code>createTempNameOnServer()</code>.
	 */
	createTempName(prefix, ext) {
		throw "PDTools.createTempName(): Web apps are not able to access client-filesystem";
	}

	/**
	 @function PDTools#createTempNameOnServer
	 @desc Erzeugt einen <em>im Kontext des Servers</em> eindeutigen Namen für
		 eine temporäre Datei mit dem Präfix <code>prefix</code> und der
		 Dateierweiterung <code>ext</code>.
	 @param {string} prefix Namenspräfix.
	 @param {string} ext Dateinamenserweiterung.
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @return {string} Der erzeugte Dateiname.
	 */
	createTempNameOnServer(prefix, ext, callback) {
		var pars = new JParamPacker(JafWebAPI.PDTools.createTempNameOnServer.eventName, this.PDClass);
		pars.add(JafWebAPI.PDTools.createTempNameOnServer.PAR_prefix, (prefix || ''));
		pars.add(JafWebAPI.PDTools.createTempNameOnServer.PAR_ext, (ext || ''));
		var result = '';
		var pdClass = this.PDClass;
		var successFn = function(req, options)
			{
				var resp = new JResponse(req, options, pdClass);
				if(resp.hasFatalError())
					throw "Request got empty or invalid response!";
				if(resp.hasError())
					throw resp.getErrorMessage();
				result = resp.getString(JafWebAPI.PDTools.createTempNameOnServer.PROP_res);
				if(typeof callback == 'function')
					callback(result);
			};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callback == 'function')
					callback();
			};
		JafWeb.ajaxRequest({
				url: (this.PDClass || PDClass).getURL(),
				authToken: this.PDClass.getAuthToken(),
				method: "GET",
				async: (!!callback),
				params: pars.get(),
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		if(!callback)
			return result;
	}

	/**
	 @function PDTools#makeFullDir
	 @desc Das Verzeichnis <code>name</code> inklusive aller eventuell erforderlichen
		 Unterverzeichnisse wird angelegt.
	 @param {string} name Pfad des anzulegenden Verzeichnisses.
	 @return {string} Im Erfolgsfall gibt die Funktion einen leeren String zurück.
		 Im Fehlerfall wird statt des Leerstrings ein Meldungstext zurückgegeben.
	 */
	makeFullDir(name) {
		throw "PDTools.makeFullDir(): Web apps are not able to access client-filesystem";
	}

	/**
	 @function PDTools#isValidUserName
	 @desc Für den übergebenen String wird geprüft, ob er als Benutzername (Login-Name)
		 verwendet werden könnte. Es wird nur die syntaktische Korrektheit (maximale Länge,
		 Sonderzeichen) geprüft. Die Funktion erlaubt keine Aussage darüber, ob der
		 Benutzername bereits vergeben ist.
	 @param {string} name Der zu prüfende Name.
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @return {boolean} Ergebnis.
	 */
	isValidUserName(name, callback) {
		var pars = new JParamPacker(JafWebAPI.PDTools.isValidUserName.eventName, this.PDClass);
		var encName = PDTools.stringToBase64((name || ''));
		pars.add(JafWebAPI.PDTools.isValidUserName.PAR_name, encName);
		var result = false;
		var pdClass = this.PDClass;
		var successFn = function(req, options)
			{
				var resp = new JResponse(req, options, pdClass);
				if(resp.hasFatalError())
					throw "Request got empty or invalid response!";
				if(resp.hasError())
					throw resp.getErrorMessage();
				result = resp.getBool(JafWebAPI.PDTools.isValidUserName.PROP_res, false);
				if(typeof callback == 'function')
					callback(result);
			};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callback == 'function')
					callback();
			};
		JafWeb.ajaxRequest({
				url: (this.PDClass || PDClass).getURL(),
				authToken: this.PDClass.getAuthToken(),
				method: "GET",
				async: (!!callback),
				params: pars.get(),
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		if(!callback)
			return result;
	}

	/**
	 @function PDTools#isValidPrincipalName
	 @desc Für den übergebenen String wird geprüft, ob er als Name eines Mandanten
		 (Login-Name) verwendet werden könnte. Es wird nur die syntaktische
		 Korrektheit (maximale Länge, Sonderzeichen) geprüft. Die Funktion erlaubt
		 keine Aussage darüber, ob der Mandantenname bereits vergeben ist.
	 @param {string} name Der zu prüfende Name.
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @return {boolean} Ergebnis.
	 */
	isValidPrincipalName(name, callback) {
		var pars = new JParamPacker(JafWebAPI.PDTools.isValidPrincipalName.eventName, this.PDClass);
		var encName = PDTools.stringToBase64((name || ''));
		pars.add(JafWebAPI.PDTools.isValidPrincipalName.PAR_name, encName);
		var result = false;
		var pdClass = this.PDClass;
		var successFn = function(req, options)
			{
				var resp = new JResponse(req, options, pdClass);
				if(resp.hasFatalError())
					throw "Request got empty or invalid response!";
				if(resp.hasError())
					throw resp.getErrorMessage();
				result = resp.getBool(JafWebAPI.PDTools.isValidPrincipalName.PROP_res, false);
				if(typeof callback == 'function')
					callback(result);
			};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callback == 'function')
					callback();
			};
		JafWeb.ajaxRequest({
				url: (this.PDClass || PDClass).getURL(),
				authToken: this.PDClass.getAuthToken(),
				method: "GET",
				async: (!!callback),
				params: pars.get(),
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		if(!callback)
			return result;
	}

	/**
	 @function PDTools#isValidPassword
	 @desc Mit Hilfe dieser Funktion können Sie prüfen, ob ein Passwort den bei
		 Aktivierung von <code>Secure Passwords</code> gültigen Kriterien entspricht.
		 Es gibt zwei Möglichkeiten, diese Funktion zu verwenden:<br>
		 Geben Sie als ersten Parameter die Kennung des Benutzers an, die Sie z. B.
		 mit Hilfe von <code>PDClass.getUserId()</code> ermittelt haben. Als zweiter
		 Parameter folgt dann das (unverschlüsselte) Passwort.<br>
		 Alternativ können Sie den gewünschten Login-Namen und den Langnamen
		 des Benutzers angeben. Das Passwort folgt dann als dritter Parameter. Auf diese
		 Weise kann ein Passwort auch schon für einen noch nicht angelegten Benutzer
		 geprüft werden.<br/>
		 <span class="important">Hinweis:</span> Bitte beachten Sie aber, dass nicht geprüft
		 wird, ob das Passwort bereits von dem Anwender verwendet wurde. Dies macht dann
		 erst die Methode <code>PDClass.changePassword()</code>.
	 @param {Mixed} uid Numerische Benutzerkennung oder Loginname (String).
	 @param {string} [fullname] Name des Benutzers. Dieser wird benötigt, um bei
		 noch nicht existierendem Benutzer zu prüfen, ob das Passwort Teile des
		 Namens enthält.
	 @param {string} pass Passwort (unverschlüsselt).
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @return {boolean} Die Methode gibt <code>true</code> zurück, wenn das Passwort
		 den Kriterien entspricht.
	 */
	isValidPassword(uid, fullname, pass, callback) {
		var pars = new JParamPacker(JafWebAPI.PDTools.isValidPassword.eventName, this.PDClass);
		var pos = 0;
		var callb = null;
		if(arguments.length > pos && typeof arguments[pos] == 'number')
		{
			pars.add(JafWebAPI.PDTools.isValidPassword.PAR_uid, (arguments[pos] || 0));
			pos++;
		}
		else if(arguments.length > pos && typeof arguments[pos] == 'string')
		{
			pars.add(JafWebAPI.PDTools.isValidPassword.PAR_name, (arguments[pos] || ''));
			pos++;
		}
		if(arguments.length > pos && typeof arguments[pos] == 'string')
		{
			pars.add(JafWebAPI.PDTools.isValidPassword.PAR_name, (arguments[pos] || ''));
			pos++;
		}
		if(arguments.length > pos && typeof arguments[pos] == 'string')
		{
			pars.add(JafWebAPI.PDTools.isValidPassword.PAR_passw, (arguments[pos] || ''));
			pos++;
		}
		if(arguments.length > pos && typeof arguments[pos] == 'function')
		{
			callb = arguments[pos];
			pos++;
		}
		var result = false;
		var pdClass = this.PDClass;
		var successFn = function(req, options)
			{
				var resp = new JResponse(req, options, pdClass);
				if(resp.hasFatalError())
					throw "Request got empty or invalid response!";
				if(resp.hasError())
					throw resp.getErrorMessage();
				result = (resp.getInt(JafWebAPI.PDTools.isValidPassword.PROP_res, 0) == 0);
				if(typeof callb == 'function')
					callb(result);
			};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callb == 'function')
					callb();
			};
		JafWeb.ajaxRequest({
				url: (this.PDClass || PDClass).getURL(),
				authToken: this.PDClass.getAuthToken(),
				method: "GET",
				async: (!!callb),
				params: pars.get(),
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		if(!callb)
			return result;
	}
	
	/**
	 @function PDTools#passwordError
	 @desc Zeigt eine Fehlermeldung an, die während des Passwortwechsels
		 entstanden ist. Bei aktiviertem <i>Property</i>
		 <code>PasswordScript</code> wird die Meldung
		 eventuell durch ein externes Skript bestimmt.
	 @param {string} [msgId='SC::PasswordInvalidCustom1'] Code der
		 Fehlermeldung.
	 @param {Function} callback Optionale Callback-Funktion, die
		 nach Verarbeitung der Antwort aufgerufen werden soll. Wird
		 diese angegeben, erfolgt der Request-Aufruf <em>asynchron</em>
		 und das Ergebnis wird an die Callback-Funktion übergeben. Fehlt
		 dieser Parameter, erfolgt der Aufruf <em>synchron</em> und das
		 Ergebnis wird direkt zurückgegeben.
	 @return {string} Die Fehlermeldung.
	 */
	passwordError(msgId, callback) {
		// vgl. PDlg_ChangePassword::PasswordError() - pdguidlg.cpp(2103)
		var pars = new JParamPacker(JafWebAPI.PDTools.passwordError.eventName, this.PDClass);
		pars.add(JafWebAPI.PDTools.passwordError.PAR_msgId,
				(msgId || 'SC::PasswordInvalidCustom1'));
		var result = false;
		var pdClass = this.PDClass;
		var successFn = function(req, options)
			{
				var resp = new JResponse(req, options, pdClass);
				if(resp.hasFatalError())
					throw "Request got empty or invalid response!";
				if(resp.hasError())
					throw resp.getErrorMessage();
				result = resp.getString(JafWebAPI.PDTools.passwordError.PROP_res, 0);
				if(typeof callback == 'function')
					callback(result);
			};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callback == 'function')
					callback();
			};
		JafWeb.ajaxRequest({
				url: (this.PDClass || PDClass).getURL(),
				authToken: this.PDClass.getAuthToken(),
				method: "GET",
				async: (!!callback),
				params: pars.get(),
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		if(!callback)
			return result;
	}

	/**
	 @function PDTools#isValidInteger
	 @desc Prüft für den übergebenen String-Wert, ob es sich um einen
		 gemäß den aktuellen Einstellungen gültigen ganzzahligen Wert
		 handelt.
	 @param {string} val Der zu prüfende Wert.
	 @param {number} minVal (optional) Minimaler Wert (inklusive).
	 @param {number} maxVal (optional) Maximaler Wert (inklusive).
	 @return {number} Der Zahlenwert, wenn der String gültig ist,
		 sonst <code>undefined</code>.
	 */
	isValidInteger(val, minVal, maxVal) {
		// gueltig?
		var vt = this.getValidator('int');
		if(vt && !vt.validate(val))
			return undefined;
		// gemaess aktuellem Tausendertrenner in Zahl konvertieren
		var fTmp = this.isValidFloat(val, minVal, maxVal);
		if(fTmp !== undefined)
		{
			// Floats sollen NICHT akzeptiert werden:
			if(Math.floor(fTmp) !== fTmp)
				return undefined; // NICHT Number.NaN zurueckgeben; die kann man nicht vergleichen!
		}
		return fTmp;
	}

	/**
	 @function PDTools#isValidUInteger
	 @desc Prüft für den übergebenen String-Wert, ob es sich um einen
		 gemäß den aktuellen Einstellungen gültigen positiv-ganzzahligen
		 Wert handelt.
	 @param {string} val Der zu prüfende Wert.
	 @param {number} minVal (optional) Minimaler Wert (inklusive).
	 @param {number} maxVal (optional) Maximaler Wert (inklusive).
	 @return {number} Der Zahlenwert, wenn der String gültig ist,
		 sonst <code>undefined</code>.
	 */
	isValidUInteger(val, minVal, maxVal) {
		if(arguments.length > 1)
			return this.isValidInteger(val, minVal, maxVal);
		return this.isValidInteger(val, 0, maxVal);
	}

	/**
	 @function PDTools#isValidFloat
	 @desc Prüft für den übergebenen String-Wert, ob es sich um einen
		 gemäß den aktuellen Einstellungen gültigen Fließkommawert
		 handelt.
	 @param {string} val Der zu prüfende Wert.
	 @param {number} minVal (optional) Minimaler Wert (inklusive).
	 @param {number} maxVal (optional) Maximaler Wert (inklusive).
	 @return {number} Der Zahlenwert, wenn der String gültig ist,
		 sonst <code>undefined</code>.
	 */
	isValidFloat(val, minVal, maxVal) {
		// gueltig?
		var vt = this.getValidator('float');
		if(vt && !vt.validate(val))
			return undefined;
		// gemaess aktuellem Dezimal- und Tausendertrenner in Zahl konvertieren
		var tmp = '' + val;
		if(this.PDClass.ClientInfo.getFloatGroup() === '.')
			tmp = tmp.replace(/\./g, '');
		var decSign = this.PDClass.ClientInfo.getDecimal();
		if(decSign && decSign != '.')
			tmp = tmp.replace(new RegExp(decSign), '.');
		var fTmp = parseFloat(tmp);
		if(fTmp != undefined)
		{
			if(arguments.length > 1 && undefined !== minVal && fTmp < minVal)
				return undefined;
			if(arguments.length > 2 && undefined !== maxVal && fTmp > maxVal)
				return undefined;
		}
		return fTmp;
	}

	/**
	 @function PDTools#toString
	 @desc Diese Methode konvertiert einen JavaScript-Datentyp in eine Zeichenkette, so
		 dass sie z. B. in Filterausdrücken für Iteratoren verwendet werden kann.<br/>
		 Wird als Parameter eine Zahl übergeben, bestimmt ein zweiter Parameter die
		 Anzahl der Nachkommastellen, die der String enthalten soll.<br/>
		 Bei einem <code>Date</code>-Objekt wird der zweite Parameter als Umschalter für die
		 verschiedenen JANUS-Datumswerte interpretiert. Dabei gilt folgender Zusammenhang:
		 <ul>
			<li><code>1</code>: Es wird ein JANUS-Timestamp erzeugt.</li>
			<li><code>2</code>: Es wird ein JANUS-Date erzeugt.</li>
			<li><code>3</code>: Es wird ein JANUS-Time-Element erzeugt.</li>
		 </ul>
		 Falls kein zweiter Parameter angegeben wurde, wird Timestamp angenommen.
	 @param {mixed} val Der zu konvertierende Wert.
	 @param {number} opt (optional)
	 */
	toString(val, opt) {
		if(typeof val == 'string')
			return val;
		if(typeof val == 'boolean')
			return (val == true ? this.PDClass.ClientInfo.getTrueValue() : this.PDClass.ClientInfo.getFalseValue());
		if(typeof val == 'number')
		{
			if(!opt) // ganzzahlig
				return '' + Math.round(val);
			else
			{
				var tmp = '' + Math.round(val * Math.pow(10, opt)) / Math.pow(10, opt);
				// mit Nullen auffuellen
				if(tmp != 'NaN')
				{
					var sep = tmp.indexOf('.');
					var i = 0;
					if(sep < 0)
						tmp += '.';
					else
						i = tmp.length - sep - 1;
					for(; i < opt; i++)
						tmp += '0';
					if(this.PDClass.ClientInfo.getDecimal() != '.')
						tmp = tmp.replace(/\./, this.PDClass.ClientInfo.getDecimal());
				}
				return tmp;
			}
		}
		if(typeof val == 'object')
		{
			if((val instanceof Date) || // funktioniert nicht zuevrlaessig!
				typeof val['isValid'] == 'function' && typeof val['toGMTString'] == 'function')
			{
				switch(opt)
				{
					case 2:
					{
						// Date
						const frmt = Date.janus2momentFormat(this.PDClass.ClientInfo.getDateFormat());
						return moment(val).format(frmt);
					}
					case 3:
					{
						// Time
						const frmt = Date.janus2momentFormat(this.PDClass.ClientInfo.getTimeFormat());
						return moment(val).format(frmt);
					}
					default: {
						// Timestamp
						const frmt = Date.janus2momentFormat(this.PDClass.ClientInfo.getTimestampFormat());
						return moment(val).format(frmt);
					}
				}
			}
			else if(JafWeb.isPDObject(val))
				return val.getAttribute(''); // ObjectIdent
		}
		return '';
	}

	/**
	 @function PDTools#toDouble
	 @desc Wie [toFloat()]{@link PDTools#toFloat}.
	 @param {string} val Der zu konvertierende Wert.
	 @example PDTools.toDouble("12,34") // returns 12.34
	 */
	toDouble(val) {
		return this.toFloat(val);
	}

	/**
	 @function PDTools#toNumber
	 @desc Konvertiert eine Zeichenkette in einen numerischen JavaScript-Wert. Die
		 Zeichenkette muss in "JANUS-Syntax" angegeben sein, d. h. es gelten die
		 Komma- und Tausender-Trennungen der in der Anwendung aktuell eingestellten
		 Sprache. Wenn der angegeben String einen Dezimaltrenner enthält, wird er eine
		 Fließkommazahl umgewandelt, sonst in einen ganzzahligen Wert.<br/>
		 Eine Maßeinheit wird ggf. abgeschnitten.
	 @param {string} val Der zu konvertierende Wert.
	 @see [toInt()]{@link PDTools#toInt}, [toFloat()]{@link PDTools#toFloat}
	 */
	toNumber(val) {
		if(typeof val == 'number')
			return val;
		var tmp = (val || '');
		if(tmp.indexOf(this.PDClass.ClientInfo.getDecimal()))
			return this.toFloat(val);
		else
			return this.toInt(val);
	}

	/**
	 @function PDTools#toFloat
	 @desc Konvertiert eine Zeichenkette in einen JavaScript-double-Wert. Die
		 Zeichenkette muss in "JANUS-Syntax" angegeben sein, d. h. es gelten die
		 Komma- und Tausender-Trennungen der in der Anwendung aktuell eingestellten
		 Sprache.<br/>
		 Eine Maßeinheit wird ggf. abgeschnitten.
	 @param {string} val Der zu konvertierende Wert.
	 @see [toNumber()]{@link PDTools#toNumber}
	 */
	toFloat(val) {
		if(typeof val == 'number')
			return val;
		var tmp = (val || '');
		// Tausendertrenner entfernen
		if(this.PDClass.ClientInfo.getFloatGroup() == '.')
			tmp = tmp.replace(new RegExp('\\' + this.PDClass.ClientInfo.getFloatGroup()), '');
		else if(this.PDClass.ClientInfo.getFloatGroup())
			tmp = tmp.replace(new RegExp(this.PDClass.ClientInfo.getFloatGroup()), '');
		// Dezimaltrenner ggf. konvertieren
		if(this.PDClass.ClientInfo.getDecimal() != '.')
			tmp = tmp.replace(new RegExp(this.PDClass.ClientInfo.getDecimal()), '.');
		// Masseinheit ggf. abtrennen
		return parseFloat(tmp.replace(/[^\.0-9-]/g, ''));
	}

	/**
	 @function PDTools#toInt
	 @desc Konvertiert eine Zeichenkette in einen JavaScript-Integer-Wert. Die Zeichenkette
		 muss in "JANUS-Syntax" angegeben sein, d. h. es gilt das Zahlenformat der
		 JANUS-Anwendung.<br/>
		 Eine Maßeinheit wird ggf. abgeschnitten.
	 @param {string} val Der zu konvertierende Wert.
	 @see [toNumber()]{@link PDTools#toNumber}
	 */
	toInt(val) {
		if(typeof val == 'number')
			return Math.round(val);
		var tmp = (val || '');
		// Tausendertrenner entfernen
		if(this.PDClass.ClientInfo.getFloatGroup() == '.')
			tmp = tmp.replace(new RegExp('\\' + this.PDClass.ClientInfo.getFloatGroup()), '');
		else if(this.PDClass.ClientInfo.getFloatGroup())
			tmp = tmp.replace(new RegExp(this.PDClass.ClientInfo.getFloatGroup()), '');
		// Masseinheit ggf. abtrennen
		return parseInt(tmp.replace(/[^\.0-9-]/g, ''), 10);
	}
	
	/**
	 @function PDTools#toDate
	 @desc Konvertiert eine Zeichenkette in einen JavaScript-Datumswert. Die Zeichenkette
		 muss in "JANUS-Syntax" angegeben sein, d. h. es gilt das Datumsformat der
		 JANUS-Anwendung in der aktuell eingestellten Sprache. Dabei kann sowohl ein
		 Timestamp- als auch ein Date-Wert angegeben werden.<br/>
		 <h4>Beispiele</h4>
		 <pre class="prettyprint"><code>PDTools.toDate('25.07.2016')
PDTools.toDate('01.01.2001', '%d.%m.%Y')
PDTools.toDate('2016-07-25', '%Y-%m-%d')
PDTools.toDate('2016-07-25 11:17', '%Y-%m-%d %h:%M')
PDTools.toDate('2016-07-25 11:17:22pm', '%Y-%m-%d %h:%M:s%p')
PDTools.toDate('25.Jul.2016', '%d.%b.%Y')
PDTools.toDate('25. Juli 2016', '%d. %B %Y')
PDTools.toDate('Tag 207 in 2016', 'Tag %j in %Y')</code></pre>
	 @param {string} val Der zu konvertierende Wert.
	 @param {string} [format] Angabe des JANUS-Formats, das auf die Eingabe
		 angewendet werden soll. Fehlt der Paremeter, wird das Ergebnis von
		 <code>ClientInfo.getDateFormat()</code> angewendet.<br/>
		 Folgende Zeichensequenzen werden unterstützt:
		 <table class="props">
			<tbody>
				<tr>
					<td>%a</td><td class="last">Name des Wochentags (in der
						aktuell selektierten Sprache). Es werden nur die ersten drei Buchstaben ausgegeben</td>
				</tr>
				<tr>
					<td>%A</td><td class="last">Name des Wochentags (in der aktuell selektierten Sprache),
						komplettes Wort</td>
				</tr>
				<tr>
					<td>%b</td><td class="last">Name des Monats (in der aktuell selektierten Sprache),
						3 Buchstaben</td>
				</tr>
				<tr>
					<td>%B</td><td class="last">Name des Monats (in der aktuell selektierten Sprache),
						komplettes Wort</td>
				</tr>
				<tr>
					<td>%Y</td><td class="last">Jahr eines Datums, vierstellig</td>
				</tr>
				<tr>
					<td>%y</td><td class="last">Jahr eines Datums, zweistellig</td>
				</tr>
				<tr>
					<td>%m</td><td class="last">Monat eines Datums, zweistellig</td>
				</tr>
				<tr>
					<td>%d</td><td class="last">Monatstag eines Datums, zweistellig</td>
				</tr>
				<tr>
					<td>%j</td><td class="last">Nummer des Tags im Jahr, dreistellig</td>
				</tr>
				<tr>
					<td>%w</td><td class="last">Nummer des Tags in der Woche</td>
				</tr>
				<tr>
					<td>%h</td><td class="last">Stunde einer Uhrzeit, 24h-Format</td>
				</tr>
				<tr>
					<td>%i</td><td class="last">Stunde einer Uhrzeit, 12h-Format</td>
				</tr>
				<tr>
					<td>%p</td><td class="last">"am", wenn die Zeit vor 12:00 Uhr liegt bzw.
						"pm", wenn die Zeit danach liegt</td>
				</tr>
				<tr>
					<td>%P</td><td class="last">"AM" bzw. "PM", je nach Stunde</td>
				</tr>
				<tr>
					<td>%M</td><td class="last">Minuten einer Uhrzeit, zweistellig</td>
				</tr>
				<tr>
					<td>%s</td><td class="last">Sekunden einer Uhrzeit, zweistellig</td>
				</tr>
				<!--
				<tr>
					<td>%U</td><td class="last">Woche, in die der erste Sonntag des Jahres fällt</td>
				</tr>
				<tr>
					<td>%W</td><td class="last">Woche des Jahres. Zur Berechnung wird der europaweit
						gültige Algorithmus nach DIN 1355 angewandt</td>
				</tr>
				-->
				<tr>
					<td>%%</td><td class="last">Das %-Zeichen selbst</td>
				</tr>
			</tbody>
		 </table>
	 @return {Date} Das Datum oder <code>null</code>, wenn nicht konvertiert
		 werden konnte.
	 */
	toDate(val, format) {
		if((typeof val == 'object') && (typeof val['getDate'] == 'function' /*&& val instanceof Date*/))
			return val;
		if(typeof val == 'string')
		{
			if(!format)
				format = this.PDClass.ClientInfo.getDateFormat();
			//if(typeof Date.parseDate == 'function') // von ExtJS!
			//	return Date.parseDate(val, Date.janus2extFormat(format));
			var y2kYear = 50;
			var d = new Date(0, 0, 1, 0, 0, 0, 0);
			var idxF = 0, idxV = 0;
			var hours = 0;
			var addYearDays = 0;
			while(idxF < format.length)
			{
				if(format.charAt(idxF) == '%')
				{
					idxF++;
					if(idxF >= format.length) break;
					switch(format.charAt(idxF))
					{
						case 'a': // Name des Wochentags (erste 3 Buchstaben, in der aktuell selektierten Sprache)
							var names = UIApplication.getWeekdays(false);
							for(var wd = 0; wd < names.length; wd++)
							{
								if(names[wd].substring(0, 3).toLowerCase() == val.substring(idxV, idxV + 3).toLowerCase())
								{
									idxV += 3;
									break;
								}
							}
							break;
						case 'A': // Name des Wochentags (komplettes Wort, in der aktuell selektierten Sprache)
							var names = UIApplication.getWeekdays(false);
							for(var wd = 0; wd < names.length; wd++)
							{
								if(names[wd].toLowerCase() == val.substring(idxV, idxV + names[wd].length).toLowerCase())
								{
									idxV += names[wd].length;
									break;
								}
							}
							break;
						case 'b': // Name des Monats (in der aktuell selektierten Sprache), 3 Buchstaben
							var names = UIApplication.getMonthnames(true);
							for(var mn = 0; mn < names.length; mn++)
							{
								if(names[mn].toLowerCase() == val.substring(idxV, idxV + 3).toLowerCase())
								{
									d.setMonth(mn);
									idxV += 3;
									break;
								}
							}
							break;
						case 'B': // Name des Monats (in der aktuell selektierten Sprache), komplettes Wort
							var names = UIApplication.getMonthnames(false);
							for(var mn = 0; mn < names.length; mn++)
							{
								if(names[mn].toLowerCase() == val.substring(idxV, idxV + names[mn].length).toLowerCase())
								{
									d.setMonth(mn);
									idxV += names[mn].length;
									break;
								}
							}
							break;
						case 'Y': // Jahr eines Datums, vierstellig
							d.setFullYear(parseInt(val.substring(idxV, idxV + 4), 10));
							idxV += 4;
							break;
						case 'y': // Jahr eines Datums, zweistellig
							var y = parseInt(val.substring(idxV, idxV + 2), 10);
							y = y + (y > y2kYear ? 1900 : 2000);
							d.setFullYear(y);
							idxV += 2;
							break;
						case 'm': // Monat eines Datums, zweistellig
							d.setMonth(parseInt(val.substring(idxV, idxV + 2), 10) - 1);
							idxV += 2;
							break;
						case 'd': // Tag eines Datums, zweistellig
							d.setDate(parseInt(val.substring(idxV, idxV + 2), 10));
							idxV += 2;
							break;
						case 'j': // Nummer des Tags im Jahr, dreistellig
							addYearDays = parseInt(val.substring(idxV, idxV + 3), 10);
							idxV += 3;
							break;
						case 'w': // Nummer des Tags in der Woche
							idxV++;
							break;
						case 'h': // Stunde einer Uhrzeit, 24h-Format
							hours = parseInt(val.substring(idxV, idxV + 2), 10);
							idxV += 2;
							break;
						case 'i': // Stunde einer Uhrzeit, 12h-Format
							hours = parseInt(val.substring(idxV, idxV + 2), 10);
							idxV += 2;
							break;
						case 'p': // am, wenn die Zeit vor 12:00 Uhr liegt bzw. pm, wenn die Zeit danach liegt
							hours += (val.substring(idxV, idxV + 2) == 'pm' ? 12 : 0);
							idxV += 2;
							break;
						case 'P': // AM bzw. PM, je nach Stunde
							hours += (val.substring(idxV, idxV + 2) == 'PM' ? 12 : 0);
							break;
						case 'M': // Minuten einer Uhrzeit, zweistellig
							d.setMinutes(parseInt(val.substring(idxV, idxV + 2), 10));
							idxV += 2;
							break;
						case 's': // Sekunden einer Uhrzeit, zweistellig
							d.setSeconds(parseInt(val.substring(idxV, idxV + 2), 10));
							idxV += 2;
							break;
						case 'U': // Woche, in die der erste Sonntag des Jahres fällt
							// TODO
							break;
						case 'W': // Woche des Jahres. Zur Berechnung wird der europaweit gültige Algorithmus nach DIN 1355 angewandt
							// TODO
							break;
						case '%': // Das %-Zeichen selbst
							break;
						default: ;
					}
				}
				else
					idxV++;
				idxF++;
			}
			if(addYearDays > 0)
			{
				d.setMonth(0);
				d.setDate(1);
				d.setTime(d.getTime() + (addYearDays - 1) * 86400000);
			}
			d.setHours(hours);
			if(d.toString() == 'Invalid Date')
				return null;
			return d;
		}
		return null;
	}
	
	/**
	 @function PDTools#join
	 @desc Wandelt ein Array in einen String um.
	 @param {Array} arr Das umzuwandelnde Array.
	 @param {string} sep Das zu benutzende Trennzeichen.
	 @param {string} [mask='\'] Maskierungszeichen für das
		 Trennzeichen.
	 @return {string} Das Ergebnis.
	 @see [split()]{@link PDTools#split}
	 */
	join(arr, sep, mask) {
		if(!mask)
			return arr.join(sep);
		var tmp = '';
		for(var i = 0; i < arr.length; i++)
		{
			var s = '' + (arr[i] || '');
			var pos = 0;
			var tmp2 = '';
			while(pos < s.length)
			{
				if(sep && s[pos] == sep)
					tmp2 += mask + s[pos];
				else if(mask && s[pos] == mask)
					tmp2 += mask + mask;
				else
					tmp2 += s[pos];
				pos++;
			}
			if(tmp)
				tmp += sep;
			tmp += tmp2;
		}
		return tmp;
	}

	/**
	 @function PDTools#split
	 @desc Zerlegt einen String anhand eines Trennzeichens
		 in ein Array.
	 @param {string} str Der zu zerlegende String.
	 @param {string} sep Das Trennzeichen, an dem zerlegt
		 werden soll.
	 @param {string} [mask='\'] Maskierungszeichen für das
		 Trennzeichen.
	 @return {Arsay} Das Ergebnis.
	 @see [join()]{@link PDTools#join}
	 */
	split(str, sep, mask) {
		if(!mask)
			return str.split(sep);
		var i = 0;
		var arr = [];
		var tmp = '';
		while(i < str.length)
		{
			if(str[i] == mask)
			{
				if(i + 1 < str.length && str[i + 1] == sep)
				{
					tmp += sep;
					++i;
				}
				else if(str[i + 1] == mask)
				{
					tmp += mask;
					++i;
				}
				++i;
				continue;
			}
			if(str[i] != sep)
				tmp += str[i];
			else
			{
				arr.push(tmp);
				tmp = '';
			}
			i++;
		}
		if(tmp || arr.length > 0) // auch einen Leereintrag am Ende einfuegen!
			arr.push(tmp);
		return arr;
	}

	/**
	 @function PDTools#compareNoSpace
	 @desc Vergleich zweier Strings unter Nichtbeachtung von Weißraum am Anfang
		 und Ende.
	 @param {string} str1 Erster Vergleichsstring.
	 @param {string} str2 Zweiter Vergleichsstring.
	 @return {boolean} Die Funktion entfernt White Spaces am Anfang und am
		 Ende der Zeichenketten und vergleicht die resultierenden Strings.
		 Im Fall der Gleichheit gibt die Methode <code>true</code> zurück,
		 sonst <code>false</code>.
	 */
	compareNoSpace(str1, str2) {
		return (str1.removeSpaces() == str2.removeSpaces());
	}

	/**
	 @function PDTools#removeSpaces
	 @desc Entfernt Weißraum am Anfang und Ende des übergebenen Strings.
	 @param {string} str
	 @return {string}
	 @example PDTools.removeSpaces("   abc    ") // returns "abc"
	 */
	removeSpaces(str) {
		return str.removeSpaces();
	}

	/**
	 @function PDTools#transcode
	 @desc Wandeln Sie das Encoding einer Zeichenkette mit Hilfe dieser Methode
		 von <code>srcEnc</code> in <code>destEnc</code> um.
		 Folgende Zeichenkodierungen unterstützt die Funktion (die Namen der
		 Kodierungen m¨ussen als Zeichenketten an die Methode übergeben werden):
		 <ul>
			<li><code>Local</code>: Das auf dem System eingestellte Encoding. Auf
			Windows-Systemen ist dies meist Windows-1252, auf modernen
			Linux-Distributionen UTF-8.</li>
			<li><code>UTF-8</code>: Unicode-Zeichensatz in ASCII-kompatibler 8-Bit-Kodierung.</li>
			<li><code>ISO-8859-1</code>: Westeropäischer Zeichensatz ohne Euro-Zeichen.</li>
			<li><code>ISO-8859-15</code>: Westeropäischer Zeichensatz mit Euro-Zeichen.</li>
			<li><code>Windows-1252</code></li>
			<li><code>Windows-1250</code></li>
		 </ul>
		 <h4>Beispiel</h4>
		 <pre class="prettyprint"><code>// um z. B. eine Zeichenkette vom Windows-1252-Encoding
// in UTF-8 umzuwandeln, rufen Sie die Methode wie folgt auf:
PDTools.transcode("Windows-1252", "Mein Text", "UTF-8");</code></pre>
	 @param {string} srcEnc Quell-Encoding.
	 @param {string} text Der zu transkodierende Text.
	 @param {string} destEnc Ziel-Encoding.
	 @return {string} Rückgabewert der Methode ist die transkodierte Zeichenkette.
	 */
	transcode(srcEnc, text, destEnc) {
		// TODO:
		// Hier gibt es ein Problem mit dem uebergebenen String, wenn er Umlaute enthaelt! Er kommt
		// dann auf der Server-Seite nicht an! Fehlt encodeURIComponent()? Ist aber ein POST-Request!
		// Per GET funktioniert die Uebergabe des Parameters. Allerdings kommt der String in der Non-
		// UnicodeApplication im Server lokal kodiert an, sodass die Transkodierung deshalb nicht
		// klappt. Geht transcode() in der Non-UnicodeApplication gar nicht?!? Ist vllt. auch nicht
		// sinnvoll.
		var pars = new JParamPacker(JafWebAPI.PDTools.transcode.eventName, this.PDClass);
		pars.add(JafWebAPI.PDTools.transcode.PAR_srcEnc, (srcEnc || ''));
		pars.add(JafWebAPI.PDTools.transcode.PAR_src, (text || ''));
		pars.add(JafWebAPI.PDTools.transcode.PAR_destEnc, (destEnc || ''));
		var result = '';
		var pdClass = this.PDClass;
		var successFn = function(req, options)
			{
				var resp = new JResponse(req, options, pdClass);
				if(resp.hasFatalError())
					throw "Request got empty or invalid response!";
				if(resp.hasError())
					throw resp.getErrorMessage();
				result = resp.getString(JafWebAPI.PDTools.transcode.PROP_res, '');
			};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
			};
		JafWeb.ajaxRequest({
				url: (this.PDClass || PDClass).getURL(),
				authToken: this.PDClass.getAuthToken(),
				method: "GET",
				async: false,
				params: pars.get(),
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		return result;
	}

	/**
	 @function PDTools#isJEXFile
	 @desc Prüft, ob die übergebene Datei eine gültige JEX-Datei ist.<br/>
		 <span class="important">Hinweis:</span> Diese Funktion hat im JafWeb
		 mangels Zugriff auf das lokale Dateisystem
		 keine Implementierung!
	 @param {string} name
	 */
	isJEXFile(name) {
		throw "PDTools.isJEXFile(): Web apps are not able to access client-filesystem";
	}

	/**
	 @function PDTools#getCSVFields
	 @desc Diese Methode dient zur Implementierung eines CSV-Imports. Sie ermittelt die
		 Namen der Felder (Überschriften) einer CSV-Datei. An die Methode muss dazu
		 der Dateiname sowie die Informationen über den Aufbau der CSV-Datei übergeben
		 werden. Dazu dienen die drei Parameter <code>sep</code>, <code>delim</code>
		 und <code>mask</code>.<br/>
		 <span class="important">Hinweis:</span> Bitte beachten Sie, dass es sich hierbei um
		 eine Datei <em>im Dateisystem des Anwendungs-Servers</em> handelt! Diese Funktion
		 sollte aufgerufen werden, nachdem eine entsprechende, zu importierende CSV-Datei
		 zum Server hochgeladen wurde.
	 @param {string} fName Der Dateiname.
	 @param {string} [sep=';'] Das Zeichen, das die einzelnen Datenfelder voneinander trennt.
	 @param {string} [delim='"'] Das Zeichen, mit dem die zu importierenden Daten "geklammert"
		 sind.
	 @param {string} [mask='\\'] Das Maskierungszeichen, mit dem Klammerzeichen innerhalb der
		 Feldwerte geschützt sind.
	 @param {string} [newline='\x00D'] Das anzunehmende Zeilenumbruchzeichen.
	 @param {boolean} [addEmpty=false] Fügt ggf. einen Leereintrag am Anfang der Spalten ein.
	 @param {Function} [callback] Optionale Callback-Funktion. Falls angegeben, wird der
		 Request asynchron ausgeführt und die Callback-Funktion mit dem beschriebenen
		 Rückgabewert als Parameter aufgerufen.
	 @return {string[]} Die Funktion gibt eine Liste mit den gefundenen Spaltenüberschriften
		 zurück. Falls die Datei nicht geöffnet werden konnte oder falls es Formatfehler gibt,
		 ist der Rückgabewert <code>null</code>.
	 */
	getCSVFields(fName, sep, delim, mask, newline, addEmpty, callback) {
		var pars = new JParamPacker(JafWebAPI.PDTools.getCSVFields.eventName, this.PDClass);
		pars.add(JafWebAPI.PDTools.getCSVFields.PAR_fname, (fName || ''));
		pars.add(JafWebAPI.PDTools.getCSVFields.PAR_sep, (sep || ';'));
		pars.add(JafWebAPI.PDTools.getCSVFields.PAR_delim, (delim || '"'));
		pars.add(JafWebAPI.PDTools.getCSVFields.PAR_mask, (mask || '\\'));
		//pars.add(JafWebAPI.PDTools.getCSVFields.PAR_newline, (newline || '\x00D'));
		var result = [];
		var pdClass = this.PDClass;
		var successFn = function(req, options)
			{
				var resp = new JResponse(req, options, pdClass);
				if(resp.hasFatalError())
					throw "Request got empty or invalid response!";
				if(resp.hasError())
					throw resp.getErrorMessage();
				result = resp.getArray(JafWebAPI.PDTools.getCSVFields.PROP_res, null, 'string', '');
				if(addEmpty && result)
					result.unshift("");
				if(typeof callback == 'function')
					callback(result);
			};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
				if(typeof callback == 'function')
					callback();
			};
		JafWeb.ajaxRequest({
				url: (this.PDClass || PDClass).getURL(),
				authToken: this.PDClass.getAuthToken(),
				method: "GET",
				async: (!!callback),
				params: pars.get(),
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
		if(!callback)
			return result;
	}

	/// Zugriff auf das benutzerabhängige Property-System
	// TODO: wozu ist das hier? Es gibt doch schon PDProperties!
	/**
	 @deprecated
	 @function PDTools#getPropertyValue
	 @desc Liest den Wert eines Properties.
	 @param {string} name Name des zu lesenden Properties.
	 @param {function} Diese Funktion wird mit dem aus der Browser-Datenbank gelesenen
		 Wert aufgerufen. Falls kein Wert gespeichert ist, wird die Funktion
		 mit einem Leerstring aufgerufen.
	 */
	getPropertyValue(name, callb) {
		if(!callb) {
			// wg. alter Wizard-Skripte unterstuetzen wir noch
			// die PDProperties - s. #52891
			if(!this.PDClass.PDProperties) {
				console.warn("PDTools.getPropertyValue() - PDProperties not available" +
						", call getPropertyValue() with callback to get local stored value!");
				return '';
			}
			return this.PDClass.PDProperties.get('', name);
		}
		UIApplication.getState('', name, callb);
	}

	/**
	 @deprecated
	 @function PDTools#setPropertyValue
	 @desc Mit Hilfe dieser Funktion setzen Sie den Wert eines Properties.
	 @param {string} name Name des zu setzendes Properties. Die
		 maximale Länge des Bezeichners liegt bei 80 Zeichen.
	 @param {string} value Wert, auf den das Property gesetzt werden soll.
		 Bitte beachten Sie, dass Properties in der Datenbank immer als Zeichenkette abgelegt
		 werden. Wenn Sie also eine Zahl oder einen booleschen Wert im Property
		 ablegen, erhalten Sie später eine Zeichenkette zurück.
	 */
	setPropertyValue(name, value) {
		if(!name) return;
		// wg. alter Wizard-Skripte unterstuetzen wir noch
		// die PDProperties - s. #52891
		if(this.PDClass.PDProperties) {
			if(name.length > 80)
				name = name.substring(0, 80);
			var v = '' + value;
			if(v.length > 255)
				v = v.substring(0, 255);
			this.PDClass.PDProperties.set('', name, v);
		}
		else
			UIApplication.storeState('', name, value);
	}

	/**
	 @deprecated
	 @function PDTools#hasPropertyValue
	 @desc Diese Funktion gibt <code>true</code> zurück, wenn das angegebene
		 Property für den aktuell angegebenen Benutzer existiert.
	 @param {string} name Der Property-Name.
	 @return {boolean}
	 */
	hasPropertyValue(name) {
		return this.PDClass.PDProperties.contains('', name);
	}

	/**
	 @deprecated
	 @function PDTools#removePropertyValue
	 @desc Diese Funktion entfernt das übergebene Property für den
		 angemeldeten Benutzer.
	 @param {string} name Der Property-Name.
	 */
	removePropertyValue(name) {
		this.PDClass.PDProperties.remove('', name);
	}
	
	/// Erzeugen von Debug-Ausgaben
	/**
	 @function PDTools#DEB
	 @desc Erzeugt eine Debug-Ausgabe.
	 @param {string} msg Die Ausgabe. Sämtliche Parameter werden in
		 Zeichenketten umgewandelt und hintereinander ausgegeben.
	 */
	DEB(msg) {
		var pars = new JParamPacker(JafWebAPI.PDTools.DEB.eventName, this.PDClass);
		pars.add(JafWebAPI.PDTools.DEB.PAR_info, (msg || ''));
		var pdClass = this.PDClass;
		var successFn = function(req, options)
			{
				var resp = new JResponse(req, options, pdClass);
				if(resp.hasFatalError())
					throw "Request got empty or invalid response!";
				if(resp.hasError())
					throw resp.getErrorMessage();
			};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
			};
		JafWeb.ajaxRequest({
				url: (this.PDClass || PDClass).getURL(),
				authToken: this.PDClass.getAuthToken(),
				method: "POST",
				async: true,
				params: pars.getPostParams(),
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			} );
	}

	/**
	 @function PDTools#LEVEL
	 @desc Diese Funktion erwartet als numerischen Parameter die auszugebenen
		 Debug-Level. Die in JavaScript erzeugten Debug-Ausgaben befinden sich auf
		 Level 16. Wenn Sie nur diese Ausgaben sehen möchten, lautet der Aufruf
		 <code>PDTools.LEVEL(1 << 16)</code>.
		 Wenn Sie keine Ausgaben erhalten möchten, geben Sie <code>0</code> als
		 Parameter an. Mit <code>-1</code> erhalten Sie sämtliche Ausgaben.
	 @param {number} level Der Debug-Level.
	 */
	LEVEL(level) {
		var pars = new JParamPacker(JafWebAPI.PDTools.LEVEL.eventName, this.PDClass);
		pars.add(JafWebAPI.PDTools.LEVEL.PAR_sel, (level || 0));
		var pdClass = this.PDClass;
		var successFn = function(req, options)
			{
				var resp = new JResponse(req, options, pdClass);
				if(resp.hasFatalError())
					throw "Request got empty or invalid response!";
				if(resp.hasError())
					console.error(resp.getErrorMessage());
			};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
			};
		JafWeb.ajaxRequest({
				url: (this.PDClass || PDClass).getURL(),
				authToken: this.PDClass.getAuthToken(),
				method: "POST",
				async: false,
				params: pars.getPostParams(),
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
	}

	/**
	 @function PDTools#DEBFILE
	 @desc Diese Funktion erhält als Parameter den Namen der Datei, in die sämtliche
		 Debug-Ausgaben mit <code>DEB()</code> geschrieben werden sollen.
		 Wenn Sie diese Funktion nicht vor dem Aktivieren der Debug-Ausgaben aufrufen,
		 erfolgen die Ausgaben auf der Server-Konsole.
	 @param {string} fname Der Dateiname.
	 */
	DEBFILE(fname) {
		var pars = new JParamPacker(JafWebAPI.PDTools.DEBFILE.eventName, this.PDClass);
		pars.add(JafWebAPI.PDTools.DEBFILE.PAR_name, (fname || ''));
		var pdClass = this.PDClass;
		var successFn = function(req, options)
			{
				var resp = new JResponse(req, options, pdClass);
				if(resp.hasFatalError())
					throw "Request got empty or invalid response!";
				if(resp.hasError())
					Ext.MessageBox.alert("DEBFILE", resp.getErrorMessage());
			};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
			};
		JafWeb.ajaxRequest({
				url: (this.PDClass || PDClass).getURL(),
				authToken: this.PDClass.getAuthToken(),
				method: "POST",
				async: false,
				params: pars.getPostParams(),
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
	}

	/*
	 @function PDTools#testResult
	 */
	testResult(val) {
		var pars = new JParamPacker(JafWebAPI.PDTools.testResult.eventName, this.PDClass);
		pars.add(JafWebAPI.PDTools.testResult.PAR_info, (val || ''));
		var pdClass = this.PDClass;
		var successFn = function(req, options)
			{
				var resp = new JResponse(req, options, pdClass);
				if(resp.hasFatalError())
					throw "Request got empty or invalid response!";
				if(!resp.hasFatalError())
				{
					if(resp.hasError())
						Ext.MessageBox.alert("testResult", resp.getErrorMessage());
				}
			};
		var failureFn = function(response, opts) {
				console.error("Network communication error in " + pars.getEventName() + "!");
			};
		JafWeb.ajaxRequest({
				url: (this.PDClass || PDClass).getURL(),
				authToken: this.PDClass.getAuthToken(),
				method: "POST",
				async: true,
				params: pars.getPostParams(),
				callerName: pars.getEventName(),
				disableCaching: true,
				success: successFn,
				failure: failureFn
			});
	}

	/**
	 @function PDTools#getFileContentAsString
	 */
	getFileContentAsString(val) {
		// TODO???
		throw "PDTools.getFileContantAsString() - Not implemented yet!";
	}

	/**
	 @function PDTools#runScriptFromFile
	 */
	runScriptFromFile(val) {
		// TODO???
		throw "PDTools.runScriptFile() - Not implemented yet!";
	}

	/**
	 @function PDTools#runWizardFromFile
	 */
	runWizardFromFile(val) {
		// TODO???
		throw "PDTools.runWizardFromFile() - Not implemented yet!";
	}

	/**
	 @function PDTools#makeDOCX
	 */
	makeDOCX(val) {
		// TODO???
		throw "PDTools.makeDOCX() - Not implemented yet!";
	}
}



JafWeb.createNamespace('JafWeb.PD');
/**
 @function JafWeb#loadPD
 @desc Lädt die PD-Schicht des JafWeb.<br/>
	 Dabei ist es möglich, das JafWeb mehrfach zu instantiieren, um zu
	 PD-Objektwelten mehrerer Server zu verbinden. Geben Sie dazu einen
	 Instanznamen (Konfigurations-Property <code>instanceName</code>) an,
	 der quasi die Rolle eines Namespaces spielt.<br/>
	 <h4>Beispiel</h4>
	 <pre class="prettyprint"><code>JafWeb.loadPD({
		instanceName: 'PD1',
		rootURL: 'janus',
		downloadURL: 'download',
		uploadURL: 'upload',
		authToken: '123456789',
		usePDObjectCache: true,
		useEnumCache: true
	});</code></pre>
	 Auf jede so gebildete Instanz können Sie zugreifen,
	 <ul>
		 <li>indem Sie die Rückgabe dieser Funktion in einer Variablen speichern
		 und diese benutzen, oder</li>
		 <li>über den angegebenen Namen innerhalb des globalen Namespaces {@link JafWeb}.</li>
	 </ul>
	 <h4>Beispiel</h4>:
	 <pre class="prettyprint"><code>JafWeb.Elevator1.PDClass.ptr('Landing', 1007);</code></pre>
	 Anders als bisher müssen Sie in dieser Verwendung alle für die Verbindung
	 benötigten Parameter - wie URLs und ggf. Authentifizierungs-Token - beim
	 Erzteugen der Instanz angeben.<br/>
	 Für die Kompatibilität mit der bisherigen Verwendung werden die
	 nicht belegten Konfigurations-Properties aus den entsprechenden
	 Angaben bei {@link UIApplication} belegt.<br/>
	 <span class="important">Wichtige Hinweise:</span>
	 <ul>
		 <li>Beachten Sie bitte, dass im Falle mehrerer Instanzen <em>jede einzelne</em> mit
		 einem eindeutigen Instanznamen erzeugt werden muss. Der Zugriff
		 über die globalen Singletons ist dann nicht möglich und verursacht
		 JavaScript-Fehler!</li>
		 <li>Jede der Instanzen benutzt eine von den anderen fachlich getrennte
		 Objektwelt, die auf keinen Fall vermischt werden dürfen (die Objekt-IDs
		 sind nur innerhalb einer Instanz eindeutig)! Zuweisungen zwischen Objekten
		 verschiedener Instanzen dürfen ausschließlich auf String-Basis erfolgen!</li>
		 <li>Falls Sie die Klasse {@link PDIterator} verwenden möchten (vgl. dagegen
		 aber die Hinweise dort), können Sie das nicht direkt, sondern müssen den
		 Iterator durch Aufruf von [PDClass.createIterator()]{@link PDClass#createIterator}
		 der jeweiligen PD-Instanz erzeugen.</li>
		 <li>Fachkonzeptobjekte {@link PDObject} werden ohnehin nur über die zugehörige
		 {@link PDClass} verwaltet. Gleiches gilt für {@link PDGroupInfo}.</li>
	 </ul>
 @param {Object} config Properties des Konfigurationsobjekts:
	 <ul>
		 <li><code>instanceName</code> (string) Instanzname. Kann auch leer bleiben,
		 um eine globale Instanz zu erzeugen.</li>
		 <li><code>rootURL</code> (string) Die in der erzeugten Instanz von
		 AJAX-Requests zu verwendende URL. Falls nicht angegeben,
		 wird die aus [PDClass.getURL()]{@link UIApplication#getUrlRoot}
		 verwendet.</li>
		 <li><code>downloadURL</code> (string) Die für Downloads zu verwendende
		 URL. Falls nicht angegeben, wird die aus
		 [UIApplication.getDownloadURL()]{@link UIApplication#getDownloadURL}
		 benutzt.</li>
		 <li><code>uploadURL</code> (string) Die URL für Datei-Uploads. Falls
		 nicht angegeben, wird dies aus
		 [UIApplication.getUploadURL()]{@link UIApplication#getUploadURL}
		 verwendet.</li>
		 <li><code>authToken</code> (string) Authentifizierungs-Token, falls über
		 Token im Request-Header authentifiziert wird. Wenn nicht angegeben,
		 wird das aus [UIApplication.getAuthToken()]{@link UIApplication#getAuthToken}
		 belegt.</li>
		 <li><code>usePDObjectCache</code> (boolean) Gibt an, ob die PD-Schnittstelle
		 die geholten Fachkonzeptobjekte lokal speichern und wiederverwenden soll.
		 (Standardwert: <code>true</code>)</li>
		 <li><code>useEnumCache</code> (boolean) Gibt an, ob die PD-Schnittstelle
		 die geholten Ausprägungen von Aufzählungstypen speichern und wiederverwenden soll.
		 (Standardwert: <code>true</code>)</li>
	 </ul>
 @return {PDClassClass} Die erzeugte {@link PDClass}-Instanz.
 */
JafWeb.loadPD = function(config)
{
	var url = (config ? config['rootURL'] : UIApplication.getUrlRoot());
	var dwnlUrl = (config ? config['downloadURL'] : UIApplication.getDownloadURL());
	var uplUrl = (config ? config['uploadURL'] : UIApplication.getUploadURL());
	var authToken = (config ? config['authToken'] : UIApplication.getAuthToken());
	var logoutEvt = (config ? config['logoutEvent'] : UIApplication.getLogoutEvent());
	var useCache = (config ? config['usePDObjectCache'] : true);
	var useEnumCache = (config ? config['useEnumCache'] : true);
	// Standardverhalten, wie bisher: Nur eine, glonale Instanz:
	if(!config || !config['instanceName'])
	{
		// abwaertskompatibel!
		window.PDClass = new PDClassClass(url, dwnlUrl, uplUrl, authToken, logoutEvt, useCache, useEnumCache);
		window.PDMeta = new PDMetaClass(window.PDClass);
		window.ClientInfo = new ClientInfoClass(window.PDClass);
		window.PDTools = new PDToolsClass(window.PDClass);
		window.PDProperties = new PDPropertiesClass(false, window.PDClass);
		// miteinander bekannt machen
		window.PDClass.PDMeta = window.PDMeta;
		window.PDClass.ClientInfo = window.ClientInfo;
		window.PDClass.PDTools = window.PDTools;
		return window.PDClass;
	}
	// Separate Instanz:
	var pdClass = new PDClassClass(url, dwnlUrl, uplUrl, authToken, logoutEvt, useCache, useEnumCache);
	var pdMeta = new PDMetaClass(pdClass);
	var clientInfo = new ClientInfoClass(pdClass);
	var pdTools = new PDToolsClass(pdClass);
	var pdProperties = new PDPropertiesClass(false, pdClass);
	// miteinander bekannt machen
	pdClass.PDMeta = pdMeta;
	pdClass.ClientInfo = clientInfo;
	pdClass.PDProperties = pdProperties;
	pdClass.PDTools = pdTools;
	JafWeb[config.instanceName] = {};
	JafWeb[config.instanceName].PDClass = pdClass;
	JafWeb[config.instanceName].ClientInfo = clientInfo;
	JafWeb[config.instanceName].PDMeta = pdMeta;
	JafWeb[config.instanceName].PDTools = pdTools;
	// Ankerklasse ist die Instanz von PDClassClass
	return JafWeb[config.instanceName];
}
