/*
 * @class PDObjectCacheClass
 * @desc {@link PDObject}-Cache, zu intantiieren in jeder
 * PDClass-Instanz.
 * @author Frank Fiolka
 */
class PDObjectCacheClass {
	
	_objects = null;
	_fifo = null;
	MAX_SIZE = 0;
	
	constructor() {
		this._objects = { };
		this._fifo = [];
		this.MAX_SIZE = 50;
	}

	add(obj) {
		if(obj && obj.oidHi && obj.oidLow) {
			const key = this._getKey(obj.oidHi, obj.oidLow);
			if(this._objects[key]) {
				if(obj.oidLow > 0) {
					for(let p in this._objects[key]) {
						if(this._objects[key][p] !== obj[p]) {
							PDClass.handleObjectChanged(obj, p, this._objects[key][p], obj[p]);
						}
					}
				}
				this._touch(key);
				return true; // ist bereits drin
			}
			this._objects[key] = obj;
			this._touch(key);
			this._reduce();
			PDClass.handleObjectCreated(obj);
			return true;
		}
		return false;
	}
	
	_reduce() {
		while(this._fifo.length > this.MAX_SIZE) {
			if(this._remove(this._fifo[0]))
				this._fifo.shift();
		}
		//console.log("### PDObjectCache._reduce() - now " + this._fifo.length + " objects");
	}
	
	_touch(key) {
		const i = this._fifo.indexOf(key);
		if(i >= 0)
			this._fifo.splice(i, 1);
		this._fifo.push(key);
	}
	
	removeAll() {
		for(let sProp in this._objects) {
			this._objects[sProp].Delete();
			delete this._objects[sProp];
		}
		this._objects = { };
		this._fifo = [ ];
	}

	Delete() {
		for(let sProp in this._objects) {
			this._objects[sProp].Delete();
			delete this._objects[sProp];
		}
		this._objects = null;
		this._fifo = null;
	}
	
	_getKey(oidHi, oidLow) {
		let key = "";
		if(arguments.length == 2)
			key = ""+oidHi+":"+oidLow;
		else if(oidHi && typeof oidHi == "string")
			key = oidHi.replace(/_/,":");
		return key;
	}
	
	_remove(key) {
		if(this._objects[key]) {
			delete this._objects[key];
			return true;
		}
		return false;
	}
	
	removeObject(oidHi, oidLow) {
		const key = this._getKey(oidHi, oidLow);
		if(key) {
			const i = this._fifo.indexOf(key);
			if(i >= 0)
				this._fifo.splice(i, 1);
			return this._remove(key);
		}
		return false;
	}
	
	get(oidHi, oidLow) {
		const key = this._getKey(oidHi, oidLow);
		if(key != "" && this._objects[key]) {
			//console.log("PDObjectCache: object "+oidHi+":"+oidLow+" found");
			this._touch(key);
			return this._objects[key];
		}
		//console.log("PDObjectCache: object "+oidHi+":"+oidLow+" not found");
		return null;
	}
}
